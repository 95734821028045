import TopBar from "../TopBar/Component";
import ContinueOnDesktop from "../ContinueOnDesktop/Component";
import UserMenu from "@/components/UserMenu";

export default {
  name: "LayoutDefaultFull",
  components: {
    TopBar,
    ContinueOnDesktop,
    UserMenu,
  },
};
