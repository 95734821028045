import { mapGetters, mapMutations } from "vuex";

import { GREENHOUSE } from "@/constants";
import logEvent from "@/common/Analytics";
import ProfilesApi from "@/common/http/ProfilesApi";

import MessageEditorWrapper from "@/components/MessageGeneration/MessageEditorWrapper/MessageEditorWrapper";

import AllRejectReasonsModal from "./AllRejectReasonsModal";
import ReviewFeedbackModal from "./ReviewFeedbackModal";

export default {
  name: "CandidateReviewButtons",
  components: {
    MessageEditorWrapper,
    ReviewFeedbackModal,
    AllRejectReasonsModal,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      isReviewFeedbackModalVisible: false,
      isAllRejectReasonsModalVisible: false,
      reviewFeedbackModalDecision: null,
      approving: false,
      rejecting: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentCandidateList",
      "currentProject",
      "currentReviewContextReviewData",
      "currentUser",
      "currentUserSettings",
      "isCandidateSidebarOpen",
      "undoReviewData",
    ]),
    isApplicant() {
      return this.candidate.recentTouchpoint?.activityKey === "applied"
    },
    isAtsGreenhouse() {
      return this.currentUser.primaryTeamAtsName === GREENHOUSE.label;
    },
    isRejectSplitButton() {
      // Currently only Greenhouse allows rejecting applicants without a reject reason
      // If a reject reason is required then do not show a split button, but the button becomes a dropdown
      if (this.isApplicant) {
        return this.isAtsGreenhouse;
      }

      return true; // Allow rejecting without a reject reason if not applicants
    },
    candidateTpr() {
      return this.currentReviewContextReviewData?.tprs.find(tpr => tpr.profileId === this.candidate.profileId);
    },
    rejectLabel() {
      if (this.rejecting) {
        return "Rejecting...";
      } else if (this.candidateTpr?.demoMode) {
        return "Demo Reject";
      } else {
        return "Reject";
      }
    },
    disabled() {
      return this.approving || this.rejecting;
    },
    approveLabel() {
      if (this.approving && this.candidateTpr?.isAutosend) {
        return this.isApplicant ? "Advance" : "Sending...";
      } else if (this.approving) {
        return "Inserting...";
      } else if (this.candidateTpr?.demoMode) {
        return "Demo Approve";
      } else if (this.candidateTpr?.isAutosend) {
        return this.isApplicant ? "Advance" : "Send";
      } else {
        return "Insert draft";
      }
    },
    rejectReasons() {
      // If there are reject reasons and there is an ATS use the ATS reject reasons
      if (this.currentUserSettings?.recentRejectReasons?.length && !!this.currentUser.primaryTeamAtsName) {
        return this.currentUserSettings.recentRejectReasons;
      }

      if (this.currentUserSettings?.atsRejectReasons?.length && !!this.currentUser.primaryTeamAtsName) {
        return this.currentUserSettings.atsRejectReasons.slice(0, 4);
      }

      let reasons = ["Not enough experience"];

      // these are engineering-specific reasons
      if (this.candidate.roleType === "engineer") {
        reasons.push("Not enough frontend experience");
        reasons.push("Not enough backend experience");
      }

      reasons = reasons.concat([
        "Too senior",
        "Current company/industry is not a good fit",
        "Employment gaps or job-hopping is a concern",
        "Have already talked",
      ]);

      return reasons;
    },
  },
  methods: {
    ...mapMutations([
      "setUndoReviewData",
    ]),
    handleReviewCommand(command) {
      if (command === "all-reject-reasons") {
        this.isAllRejectReasonsModalVisible = true;
        return;
      }

      if (this.disabled || this.candidateTpr?.demoMode) {
        return;
      }

      if (command.startsWith("edit-messaging")) {
        let decision = command === "edit-messaging" ? "approve" : "reject";

        logEvent("candidate-review", {
          action: "edit-messaging",
          candidateSidebarOpen: this.isCandidateSidebarOpen,
          inbound: this.isApplicant,
          decision,
        });

        if (this.isApplicant) {
          return this.$router.replace({
            query: {
              ...this.$route.query,
              modal: "confirm-auto-reply",
              campaignId: this.candidateTpr.campaignId,
              taskId: this.candidateTpr.taskId,
              decision,
            },
          });
        }
        let query = Object.assign({}, this.$route.query);
        query.modal = "edit-messaging";
        query.outreachId = this.candidateTpr.taskId;
        this.$router.replace({ query });

      } else if (command === "reject-feedback") {
        logEvent("candidate-review", { action: "reject-feedback", candidateSidebarOpen: this.isCandidateSidebarOpen });
        this.showReviewFeedbackModal("reject");

      } else if (command === "approve-feedback") {
        logEvent("candidate-review", { action: "approve-feedback", candidateSidebarOpen: this.isCandidateSidebarOpen });
        this.showReviewFeedbackModal("approve");

      } else {
        // The command is a note in this case
        this.review("reject", command);
      }
    },
    reviewFeedbackAdded(note) {
      this.isReviewFeedbackModalVisible = false;
      this.review(this.reviewFeedbackModalDecision, note);
    },
    candidateRejectedFromModal(note) {
      this.isAllRejectReasonsModalVisible = false;
      this.review("reject", note);
    },
    showReviewFeedbackModal(decision) {
      this.isReviewFeedbackModalVisible = true;
      this.reviewFeedbackModalDecision = decision;
    },
    review(decision, note, messageParams = {}, skipConfirmation = false) {
      // After confirmation it will return here only with skipConfirmation = true
      if (!skipConfirmation && !this.candidateTpr?.demoMode && this.isApplicant) {
        if (
          (decision === "approve" && !this.currentProject.isInboundAcceptMessageConfirmed) ||
          (decision === "reject" && !this.currentProject.isInboundRejectMessageConfirmed)
        ) {
          return this.$router.replace({
            query: {
              ...this.$route.query,
              decision,
              modal: "confirm-auto-reply",
              campaignId: this.candidateTpr.campaignId,
              taskId: this.candidateTpr.taskId,
              note,
            },
          });
        }
      }

      if (this.disabled) {
        return;
      }

      if (decision === "approve") {
        this.approving = true;
      } else {
        this.rejecting = true;
      }

      logEvent("candidate-review", { action: decision, hasNote: !!note, candidateSidebarOpen: this.isCandidateSidebarOpen });

      const reviewParams = {
        profileId: this.candidate.profileId,
        reviewAction: decision,
        note: note,
        taskId: this.candidateTpr.taskId,
        messageParams,
      };

      const candidateIndex = this.currentCandidateList.findIndex(({ tfId }) => tfId === this.candidate.tfId);

      ProfilesApi.review(reviewParams).then(({ data }) => {
        this.approving = false;
        this.rejecting = false;

        // Clear previous timeout if new review happened before the old review data was cleared
        if (this.undoReviewData?.id) {
          clearTimeout(this.undoReviewData.timeoutId);
        }

        if (data.teamProfileReviewId) {
          this.setUndoReviewData({
            id: data.teamProfileReviewId,
            candidate: this.candidate,
            tpr: this.candidateTpr,
            decision,
            candidateIndex,
            candidateSidebarOpen: this.isCandidateSidebarOpen,
            // Should keep in sync with TeamProfileReviewService::REVIEW_UNDO_TIME
            // Backend should be a little longer since clocks aren't synced
            timeoutId: setTimeout(() => this.setUndoReviewData(null), 5000),
          });
        }

        this.$bus.$emit("candidate-recruited", { candidate: this.candidate, showNext: this.isCandidateSidebarOpen });
      });
    },
    candidateReviewMessageEdited({ decision = "approve", note, taskId, messageParams, skipConfirmation = false }) {
      if (taskId == this.candidateTpr.taskId) {
        this.review(decision, note, messageParams, skipConfirmation);
      }
    },
  },
  created() {
    this.$bus.$on("candidate-review-message-edited", this.candidateReviewMessageEdited);
  },
  beforeDestroy() {
    this.$bus.$off("candidate-review-message-edited", this.candidateReviewMessageEdited);
  },
}
