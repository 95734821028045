export const CANDIDATE_LIST_CONTEXT = Object.freeze({
  reviewRequested: "reviewRequested",
  reviewApplicants: "reviewApplicants",
  reviewForApproval: "reviewForApproval",
  index: "index",
  campaign: "campaign",
  project: "project",
  recommendations: "recommendations",
  searchResults: "searchResults",
  // reviewRequested: "review-requested",
  // reviewApplicants: "review-applicants",
  // reviewForApproval: "review-for-approval",
  // index: "index",
  // campaign: "campaign",
  // project: "project",
  // recommendations: "recommendations",
  // searchResults: "search-results",
});
