import Vue from "vue";
import "@/assets/styles/global.scss";
import "@/default-theme.scss";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

import App from "./views/App/Component";
import router from "./router";
import store from "./store";
import ApiClient from "./common/http/ApiClient";
import ApiClientOldDomain from "./common/http/ApiClientOldDomain";
import deepEqual from "deep-equal";
import BootstrapVue from "bootstrap-vue";
import {
  Alert,
  Aside, // Look into removing
  Autocomplete,
  Avatar,

  // Backtop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,

  Cascader,
  Carousel,
  CarouselItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  CollapseTransition,
  Container, // Look into removing

  DatePicker,
  Dialog,
  Divider,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,

  Form,
  FormItem,

  Image,
  InfiniteScroll,
  Input,
  InputNumber,

  Loading,

  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,

  Notification,

  Option,
  OptionGroup,

  Popconfirm,
  Popover,
  Progress,

  Radio,
  RadioButton,
  RadioGroup,

  Select,
  Slider,
  Skeleton,
  SkeletonItem,
  Step,
  Steps,
  Submenu,
  Switch,

  Upload,

  Table,
  TableColumn,
  Tabs,
  TabPane,
  Tag,
  Timeline,
  TimelineItem,
  TimeSelect,
  TimePicker,
  Tooltip,
} from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

locale.use(lang);

import { setupErrorHandling } from "./common/Errors";
import { setupAnalytics } from "./common/Analytics";

// Sentry.init({
//   Vue,
//   environment: process.env.VUE_APP_ENVIRONMENT,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [process.env.VUE_APP_MAIN_APP_URL, /^\//],
//     }),
//   ],
//   logErrors: process.env.VUE_APP_ENVIRONMENT === "production" ? false : true, // Error will not show up in console if false
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === "production" ? 0.25 : 1,
//   tracingOptions: {
//     trackComponents: true,
//   },
//   attachProps: true,
//   attachStacktrace: true,
// });

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["stream"];
Vue.use(BootstrapVue);

require("default-passive-events");

import { VueQueryPlugin } from "@tanstack/vue-query";
Vue.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  },
});

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo)

Vue.prototype.$ELEMENT = { size: "default" };
Vue.use(Alert);
Vue.use(Aside);
Vue.use(Autocomplete);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Button);
Vue.use(Cascader);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(Container);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Loading);
Vue.use(Image);
Vue.use(InfiniteScroll);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.component(Message.name, Message);
Vue.component(MessageBox.name, MessageBox);
Vue.component(Notification.name, Notification);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Progress);
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(RadioButton.name, RadioButton);
Vue.use(Select);
Vue.use(Slider);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Submenu);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(TimelineItem);
Tooltip.props.enterable.default = false;
Vue.use(Tooltip);
Vue.use(Upload);

// Convenience methods, can be used as this.$methodName in Vue components
Vue.prototype.$bus = new Vue();
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$deepEqual = deepEqual;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$forEach = function(obj, callback) {
  Object.entries(obj).forEach(([k, v]) => {
    callback(k, v);
  });
};

import "vue-resize/dist/vue-resize.css";
import VueResize from "vue-resize";
Vue.use(VueResize); // Why don't we just use window.addEventListener("resize", _.throttle(someHandleMethod, 100));

setupErrorHandling(Vue.config);
setupAnalytics(Vue, router);
ApiClient.init();
ApiClientOldDomain.init();

// Ensure we checked auth before each page load.
router.beforeEach(async (to, from, next) => {
  await store.dispatch("checkAuth", to.meta.skipAuthRedirect);
  next();
});

router.beforeResolve((to, from, next) => {
  // Resetting error before entering the route,
  // this makes sure that clicking links and back button are resetting the error.
  // Important to call here and not in beforeEach,
  // cause there it will cause current component rerender.
  store.commit("setError", null);
  next();
});

router.afterEach((to) => {
  window.Beacon("event", {
    type: "page-viewed",
    url: `${process.env.VUE_APP_MAIN_APP_URL}${to.fullPath}`,
    title: to.meta?.title || document.title,
  });

  window.Beacon("suggest");
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
