import { render, staticRenderFns } from "./UserSendAsPermissionModal.html?vue&type=template&id=45559aa8&scoped=true&external"
import script from "./UserSendAsPermissionModal.js?vue&type=script&lang=js&external"
export * from "./UserSendAsPermissionModal.js?vue&type=script&lang=js&external"
import style0 from "./UserSendAsPermissionModal.scss?vue&type=style&index=0&id=45559aa8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45559aa8",
  null
  
)

export default component.exports