<script setup>
import { computed } from "vue";

const props = defineProps({
  primary: {
    type: String,
    default: "No results.",
  },
  secondary: {
    type: String,
    default: "We couldn't find any results. Sorry about that!",
  },
  type: {
    type: String,
    default: "candidates",
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});

const imageName = computed(() => {
  switch (props.type) {
    case "candidates":
      return "no-candidates";
    case "review":
      return "no-candidates-to-review";
    case "projects":
      return "no-projects";
    case "reports":
      return "no-reports";
    case "sourcing":
      return "select-project";
    default:
      return "no-candidates";
  }
});
</script>

<template>
  <div id="no-results">
    <img v-if="!hideIcon" :class="type" :src="require(`@/assets/images/illustrations/${imageName}.svg`)" alt="Oops..." />
    <div id="text">
      <div id="primary">{{ primary }}</div>
      <div id="secondary" class="mt-16 w-70">{{ secondary }}</div>
    </div>

    <slot name="action-button"></slot>
  </div>
</template>

<style lang="scss" scoped>
#no-results {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    &.candidates {
      width: 100px;
      height: 89px;
    }
    &.review {
      width: 100px;
      height: 98px;
    }
    &.projects {
      width: 100px;
      height: 78px;
    }
  }

  #text {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    cursor: default;

    #primary {
      font-size: $tf-no-results-primary-text-font-size;
      font-weight: $tf-no-results-primary-text-font-weight;
      color: $tf-no-results-primary-text-color;
      text-transform: uppercase;
    }

    #secondary {
      font-size: $tf-no-results-secondary-text-font-size;
      font-weight: $tf-no-results-secondary-text-font-weight;
      color: $tf-no-results-secondary-text-color;
      margin: 0 auto;
    }
  }
}
</style>