import { render, staticRenderFns } from "./Replies.vue?vue&type=template&id=69d4c0d0&scoped=true"
import script from "./Replies.vue?vue&type=script&setup=true&lang=js"
export * from "./Replies.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Replies.vue?vue&type=style&index=0&id=69d4c0d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69d4c0d0",
  null
  
)

export default component.exports