import { Notifier } from "@airbrake/browser";
import { AIRBRAKE_PROJECT_ID, AIRBRAKE_API_KEY, AIRBRAKE_ENVIRONMENT } from "@/common/Env";
import store from "@/store/index";

let airbrake = null;

if (AIRBRAKE_PROJECT_ID && AIRBRAKE_API_KEY) {
  airbrake = new Notifier({
    projectId: AIRBRAKE_PROJECT_ID,
    projectKey: AIRBRAKE_API_KEY,
    environment: AIRBRAKE_ENVIRONMENT,
    onerror: false, // we are handling onerror ourselves, disabling
  });

  // we are handling promise rejections ourselves, disabling
  window.removeEventListener("unhandledrejection", airbrake.onUnhandledrejection);
}

export const handleError = (error, info) => {
  try {
    store.commit("setError", error);
    if (error?.name !== "ApiError") {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Can't display error: ${error}`);
  }

  if (airbrake && error?.name !== "ApiError") {
    const { currentUser } = store.getters;
    airbrake.notify({
      error,
      params: {
        user_id: currentUser.id,
        user_email: currentUser.email,
        info,
      },
    });
  }
};

export const setupErrorHandling = (vueConfig) => {
  // Main Vue error handler
  // error: complete error trace, contains the message and error stack
  // vm: Vue component/instance in which error is occurred (a lot of not very useful info)
  // info: Vue specific error information such as lifecycle hooks, events etc.
  vueConfig.errorHandler = (error, vm, info) => {
    handleError(error, info);
  };

  // For promise rejections.
  // With async/await exception correctly bubbles up and stops execution vs.
  // in case of promise, when it's just an error report.
  window.addEventListener("unhandledrejection", (event) => {
    // event.reason contains the error object
    // event.promise contains the promise object, no valuable extra info there
    handleError(event.reason, {
      promise: true,
      warning: "Consider converting to async/await to properly bubble exception up.",
    });
  });

  // For errors that happened outside Vue framework,
  // like if something breaks during initialization in main.js or during lib initialization.
  // Should be very rare.
  window.onerror = (message, source, lineno, colno, error) => {
    handleError(error, {
      message,
      source,
      lineno,
      colno,
    });
  };
};
