import { render, staticRenderFns } from "./Step3.vue?vue&type=template&id=37dc604f&scoped=true"
import script from "./Step3.vue?vue&type=script&setup=true&lang=js"
export * from "./Step3.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Step3.vue?vue&type=style&index=0&id=37dc604f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37dc604f",
  null
  
)

export default component.exports