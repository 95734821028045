export const TO_DO_TAB_TOOLTIP = "This tab contains candidates you've sourced that are stuck or blocked for various reasons, and require your attention.";
export const PIPELINE_TAB_TOOLTIP = "These are all top of the funnel prospects that you've added for this job. Here you can view all your candidates, their progress, and filter across statuses.";

export const SAVED_FOR_LATER_TAB_TOOLTIP = "These are candidates you've collected as favorites, but have not taken any action to advance, initiate outreach, etc. You can recruit them right from here.";
export const NO_EMAIL_TAB_TOOLTIP = "You sourced these candidates, but the emails bounced or Teamable was unable to locate an email address for them. We've made it easy for you to contact them via InMail here.";
export const FOR_APPROVAL_TAB_TOOLTIP = "These candidates require approval from a teammate before initiating outreach or advancing them in the hiring process. You can review them and make a decision here.";
export const SCREENING_TAB_TOOLTIP = "Mutual interest has been observed for these candidates, but still must pass the screening stage to advance in the hiring pipeline. You can change their status or advance them here.";

export const RECOMMENDATIONS_TAB_TOOLTIP = "Find candidates who have not yet been contacted by your team, and easily reach out to them since we have reliable email information for each one.";
export const LIKELY_TAB_TOOLTIP = "Find candidates likely to respond, based on our internal predictions, who have not been previously contacted. You can easily reach out to them, as we have reliable email information for each one.";
export const APPLICANTS_TAB_TOOLTIP = "Easily find and take action on applicants who match your criteria, as well as those who don't. To view applicants that do not meet your hard criteria, simply switch the default view from 'Filters applied' to 'Filters inverted'.";
export const REFERRAL_TAB_TOOLTIP = "Find suitable candidates within your team's referral network and request an introduction. To view the entire network, simply switch from the 'Filters applied' view to 'See all candidates'.";
export const CRM_TAB_TOOLTIP = "Find candidates previously contacted by your team to re-engage with them. You can use the filters in the Team & Activity section to either view or exclude candidates based on specific activities.";
