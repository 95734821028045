<script setup>

const emit = defineEmits(["closed"]);

defineProps({
  atsCandidate: {
    type: Object,
    required: true,
    default: () => {},
  },
});

function onClose() {
  emit("closed");
}

</script>

<template>
  <div id="ats-candidate">
    <div class="d-flex align-items-top justify-content-between">
      <div>
        <div>Job: <b>{{ atsCandidate.projectName }}</b><span v-if="atsCandidate.projectClosed"> (closed)</span></div>
        <div>Applied at: <b>{{ atsCandidate.appliedAt }}</b></div>
        <div>Source: <b>{{ atsCandidate.atsSource }}</b></div>
        <div>Credited to: <b>{{ atsCandidate.creditedTo }}</b></div>
        <div>Current stage: <b>{{ atsCandidate.stageName }}</b></div>
      </div>

      <div>
        <span class="d-flex align-items-center">
          <router-link :to="{ name: 'job-root', params: { projectId: atsCandidate.projectId } }">
            <el-button type="secondary">
              View job
            </el-button>
          </router-link>

          <span class="material-icons-round text-color-black-50 ml-8 md-18 close-touchpoint" @click="onClose">
            close
          </span>
        </span>
      </div>
    </div>

    <div v-if="atsCandidate.answers?.length">Questions and answers:
      <div class="card-container mt-16">
        <div
          v-for="(qa, index) in atsCandidate.answers"
          :key="index"
          class="mb-16"
        >
          {{ qa.question }}<br>
          <b>{{ qa.answer || '- Not answered - '}}</b>
        </div>
      </div>
    </div>

    <template v-if="atsCandidate.archivingReason">
      Archiving reason: <b>{{ atsCandidate.archivingReason }}</b><br>
      Archiving description: <b>{{ atsCandidate.archivingDescription }}</b><br>
      Archived at: <b>{{ atsCandidate.archivingTime }}</b><br>
    </template>
  </div>
</template>


<style scoped lang="scss">
.card-container {
  background-color: $black-5;
}
</style>