<script setup>
import { ref, computed } from "vue";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";

import logEvent from "@/common/Analytics";
import ProfilesApi from "@/common/http/ProfilesApi";
import RouteContext from "@/common/RouteContext";

import addToCampaignRequestData from "./lib/addToCampaignRequestData";
import Dedupes from "../../components/Dedupes/Dedupes";

const emit = defineEmits(["close"]);

const {
  candidateConnectors,
  currentUser,
  currentProject,
  inAppSourcingMessage,
  inAppSourcingSelectedProject,
  inAppSourcingSelectedCampaign,
  isCampaignFirstMessageGenerating,
  isCampaignFollowUpGenerating,
  isCandidateSidebarOpen,
  isGeneratingNewCampaignForInAppSourcing,
  isReferralsEnabled,
  isRequestIntroEnabled,
  profileIdsInReview,
  requestIntroConnectorsId,
  requestIntroFromAllConnectors,
  bulkSelectionAttributes,
  selectedCandidateInAppSourcing,
} = useGetters([
  "candidateConnectors",
  "currentUser",
  "currentProject",
  "inAppSourcingMessage",
  "inAppSourcingSelectedProject",
  "inAppSourcingSelectedCampaign",
  "isCampaignFirstMessageGenerating",
  "isCampaignFollowUpGenerating",
  "isCandidateSidebarOpen",
  "isGeneratingNewCampaignForInAppSourcing",
  "isReferralsEnabled",
  "isRequestIntroEnabled",
  "profileIdsInReview",
  "requestIntroConnectorsId",
  "requestIntroFromAllConnectors",
  "bulkSelectionAttributes",
  "selectedCandidateInAppSourcing",
]);

const {
  addCandidateToProject,
} = useActions([
  "addCandidateToProject",
]);

const {
  setCurrentProjectSavedForLaterCount,
} = useMutations([
  "setCurrentProjectSavedForLaterCount",
]);

const root = useRoot();
const route = useRoute();

const isLoading = ref(false);
const isSaving = ref(false);
const saveLaterButtonLoading = ref(false);
const isSavedForLater = ref(false);
const allowDupes = ref(false);
const routeContext = ref(new RouteContext(route.name));

const isFirstMessageValid = computed(() => {
  return !!inAppSourcingMessage.value?.firstMessage.body && !!inAppSourcingMessage.value?.firstMessage.subject;
});

const isIntroRequestable = computed(() => {
  return (
    (inAppSourcingSelectedCampaign.value ? inAppSourcingSelectedCampaign.value.id !== "save" : inAppSourcingSelectedProject.value?.isIntroRequestable) &&
    isReferralsEnabled.value &&
    isRequestIntroEnabled.value &&
    !bulkSelectionAttributes.value &&
    !!candidateConnectors.value?.length &&
    (requestIntroFromAllConnectors.value || !!requestIntroConnectorsId.value?.length)
  );
});

const isProjectSourceable = computed(() => {
  if (isIntroRequestable.value) {
    return !!inAppSourcingSelectedProject.value?.isIntroRequestable;
  }
  return false;
});

const introRequestButtonDisabledContent = computed(() => {
  return `The linked ${currentUser.value.primaryTeamAtsName || ""} job for job: "${inAppSourcingSelectedProject.value?.name}" must have an active posting in order to initiate a referral.`;
});

const areFollowUpsMessagesValid = computed(() => {
  return !inAppSourcingMessage.value?.followUps.some(item => !item.body);
});

const areRepliesMessagesValid = computed(() => {
  return (inAppSourcingMessage.value?.yourReplyOoo.enabled ? inAppSourcingMessage.value?.yourReplyOoo.body : true) &&
    (inAppSourcingMessage.value?.yourReplyNegative.enabled ? inAppSourcingMessage.value?.yourReplyNegative.body : true) &&
    (inAppSourcingMessage.value?.yourReplyPositive.enabled ? inAppSourcingMessage.value?.yourReplyPositive.body : true);
});

const candidateActionButtonDisabledContent = computed(() => {
  if (isSaving.value || isLoading.value) {
    return;
  }

  if (!inAppSourcingSelectedProject.value && !inAppSourcingSelectedCampaign.value) {
    return "Please select a job or campaign";
  }
  if (inAppSourcingSelectedProject.value && !inAppSourcingSelectedCampaign.value) {
    return "Please select a messaging option";
  }
  if (candidateActionButtonDisabled.value) {
    return "Invalid message exits";
  }

  // Excludes recs since we do email finding after recruit
  if (!bulkSelectionAttributes.value && !selectedCandidateInAppSourcing.value.email && selectedCandidateInAppSourcing.value.profileId) {
    return "Candidate has no email address";
  }

  if (isProjectSourceable.value) {
    return introRequestButtonDisabledContent.value;
  }

  return "";
});

const candidateActionButtonDisabled = computed(() => {
  return (
    !inAppSourcingSelectedProject.value ||
    !inAppSourcingSelectedCampaign.value ||
    isLoading.value ||
    isSaving.value ||
    !isFirstMessageValid.value ||
    !areFollowUpsMessagesValid.value ||
    !areRepliesMessagesValid.value ||
    isGeneratingNewCampaignForInAppSourcing.value ||
    isCampaignFirstMessageGenerating.value ||
    isCampaignFollowUpGenerating.value ||
    saveLaterButtonLoading.value
  );
});

const isIntroRequestButtonDisabled = computed(() => {
  return (
    isLoading.value ||
    isSaving.value ||
    !isProjectSourceable.value ||
    isGeneratingNewCampaignForInAppSourcing.value ||
    isCampaignFollowUpGenerating.value ||
    saveLaterButtonLoading.value
  );
});

const isSaveForLaterButtonDisabled = computed(() => {
  return (
    isGeneratingNewCampaignForInAppSourcing.value ||
    isCampaignFirstMessageGenerating.value ||
    isCampaignFollowUpGenerating.value ||
    isCandidateAlreadySavedForLater.value ||
    saveCandidateButtonLoading.value
  );
});

const saveCandidateButtonLoading = computed(() => {
  return isLoading.value || isSaving.value;
});

const isInReview = computed(() => {
  return route.path.startsWith("/review") && route.path.includes("for-approval");
});

const isAutoSend = computed(() => {
  const senderId = inAppSourcingMessage.value?.senderId || "";
  return senderId.toString().split("/")[1] === undefined;
});

const isCandidateAlreadySavedForLater = computed(() => {
  if (routeContext.value.savedForLaterContext()) {
    return true;
  }
  if (bulkSelectionAttributes.value) {
    return false;
  }
  return selectedCandidateInAppSourcing.value.projectIdsSavedForLater.includes(inAppSourcingSelectedProject.value.id);
});

const isRecsContext = computed(() => {
  return (
    (selectedCandidateInAppSourcing.value && !selectedCandidateInAppSourcing.value.profileId) ||
    (route.query.sourcingTab === "recommendations" || route.query.sourcingTab === "likely")
  );
});

const saveCandidateButtonLabel = computed(() => {
  if (isSaving.value) {
    return "Saving...";
  }
  if (isLoading.value) {
    return "Loading...";
  }

  if (!inAppSourcingSelectedProject.value && !inAppSourcingSelectedCampaign.value) {
    return "Choose job or campaign";
  }

  const skipReview = isInReview.value && inAppSourcingMessage.value?.reviewerIds?.includes(currentUser.value.id);

  if (!skipReview && inAppSourcingMessage.value?.reviewerIds?.length) {
    return `Request review`;
  } else {
    if (isAutoSend.value) {
      return "Send";
    } else {
      return "Create draft";
    }
  }
});

const saveForLaterTooltipContent = computed(() => {
  if (isCandidateAlreadySavedForLater.value) {
    return "Candidate is already saved for later in this job.";
  }

  if (selectedCandidateInAppSourcing.value) {
    return `Add ${selectedCandidateInAppSourcing.value.name} to favorites list for this job if you aren't sure you want to reach out yet.`;
  }

  return `Add selected ${bulkSelectionAttributes.value?.count > 1 ? "candidates" : "candidate"} to favorites list for this job if you aren't sure you want to reach out yet.`;
});

const candidateAddedToProjectMessage = computed(() => {
  let msg;
  if (selectedCandidateInAppSourcing.value) {
    if (routeContext.value.reviewContext()) {
      msg = `${selectedCandidateInAppSourcing.value.name} has been moved to another job.`;
    }
  } else {
    if (routeContext.value.reviewContext() && bulkSelectionAttributes.value?.isIndividualCandidates) {
      msg = '"Move to another job" request successful. Candidates have been removed from your review queue.';
    } else if (routeContext.value.reviewContext()) {
      msg = '"Move to another job" request successful. Candidates will be removed from your review queue in a few moments.';
    }
  }

  return msg;
});

function emitCloseAndNotification({ message, type = "success", title = "Success" }) {
  isSaving.value = false;

  if (message) {
    root.$notify({
      title,
      message,
      position: "bottom-right",
      type,
    });
  }
  emit("close");
}

function emitUpdatesAfterSave({ isRealRecruit }) {
  if (selectedCandidateInAppSourcing.value) {
    const candidateRecruitedFromSavedForLater = routeContext.value.savedForLaterContext() && isRealRecruit;
    const recruitedProjectIsCurrent = currentProject.value.id && currentProject.value.id === inAppSourcingSelectedProject.value.id;

    const savedForLater = candidateRecruitedFromSavedForLater && recruitedProjectIsCurrent;
    const sourcingOrReview = routeContext.value.sourcingContext() || profileIdsInReview.values?.includes(selectedCandidateInAppSourcing.value?.profileId);

    if (sourcingOrReview || savedForLater) {
      root.$bus.$emit("candidate-recruited", { candidate: selectedCandidateInAppSourcing.value, showNext: isCandidateSidebarOpen.value });

      if (savedForLater) {
        updateSavedForLaterCount({ increment: false });
      }

      return;
    }

    if (isCandidateSidebarOpen.value) {
      root.$bus.$emit("refresh-current-candidate"); // This will update the candidate in the list as well
    } else {
      // TODO: Test this some more, doesn't seem like it's
      // UPDATE: It won't work as is, need to update the candidate with the most recent touchpoint
      root.$bus.$emit("candidate-list-item-changed", selectedCandidateInAppSourcing.value);
    }
  } else if (isRecsContext.value) {
    root.$bus.$emit("bulk-candidates-removed");
  } else {
    // TODO: Improve generic bulk handling maybe?
  }
}

function updateSavedForLaterCount({ increment }) {
  if (currentProject.value.id && currentProject.value.id === inAppSourcingSelectedProject.value.id) {
    let newSavedForLaterCount = currentProject.value.savedForLaterCount;

    if (increment) {
      newSavedForLaterCount += bulkSelectionAttributes.value?.count || 1;
    } else {
      newSavedForLaterCount -= bulkSelectionAttributes.value?.count || 1;
    }

    setCurrentProjectSavedForLaterCount(newSavedForLaterCount);
  }
}

function requestIntro() {
  logEvent("candidate-action", { action: "request-intro" });

  const params = {
    campaignId: inAppSourcingSelectedCampaign.value?.id,
    projectId: inAppSourcingSelectedProject.value?.id,
    profileId: selectedCandidateInAppSourcing.value?.profileId,
    tfId: selectedCandidateInAppSourcing.value?.tfId,
    requestAll: requestIntroFromAllConnectors.value,
    connectorIds: requestIntroConnectorsId.value,
  };

  ProfilesApi.saveCandidateConnectors(params).then(() => {
    emitUpdatesAfterSave({ isRealRecruit: true });
    const notification = {
      message: `Intro has been requested for ${selectedCandidateInAppSourcing.value.name}`,
    };
    emitCloseAndNotification(notification); // Figure out if maybe the touchpoint should be updated as well
  }, (err) => {
    isSaving.value = false;
    throw err;
  });
}

async function saveToCampaign() {
  logEvent("candidate-action", { action: "add-to-campaign" });
  isSaving.value = true;

  const params = addToCampaignRequestData({
    campaignId: inAppSourcingSelectedCampaign.value?.id || "personalized",
    selectedProject: inAppSourcingSelectedProject.value,
    candidate: selectedCandidateInAppSourcing.value,
    bulkSelectionAttributes: bulkSelectionAttributes.value,
    inAppSourcingMessage: inAppSourcingMessage.value,
    playbookId: inAppSourcingSelectedCampaign.value?.playbookId,
    allowDupes,
    reviewContext: routeContext.value.reviewContext(),
    route,
  });

  try {
    const { data } = await ProfilesApi.addToCampaign(params, isRecsContext.value);
    emitUpdatesAfterSave({ isRealRecruit: true });
    let notification = data;

    // Bulk selection
    if (!selectedCandidateInAppSourcing.value) {
      if (isRecsContext.value) {
        // Bulk selection
        notification = {
          message: "Success! You can view the job's pipeline to track the status of each candidate.",
        }
      } else {
        notification = {
          message: "Success! Refresh the page in a few moments to see updated data.",
        }
      }
    }

    emitCloseAndNotification(notification);
  } catch (error) {
    isSaving.value = false;
    throw error; // Finally throw error to show general error message
  }
}

function saveToProject() {
  logEvent("candidate-action", { action: "save-for-later" });

  saveLaterButtonLoading.value = true;

  const params = {
    isRerouteFromReview: routeContext.value.reviewContext(),
    candidate: selectedCandidateInAppSourcing.value,
    projectId: inAppSourcingSelectedProject.value.id,
    bulkSelectionAttributes: bulkSelectionAttributes.value,
    recommendationsProjectId: route.params.projectId || route.query.projectId,
  };

  addCandidateToProject(params).then(() => {
    saveLaterButtonLoading.value = false;
    isSavedForLater.value = true;

    setTimeout(() => {
      updateSavedForLaterCount({ increment: true });
      emitUpdatesAfterSave({ isRealRecruit: false });

      isSavedForLater.value = false;

      const notification = {
        message: candidateAddedToProjectMessage.value,
      }
      emitCloseAndNotification(notification);
    }, 1000);
  }, () => {
    saveLaterButtonLoading.value = false;
  });
}
</script>

<template>
  <div id="in-app-sourcing-footer" class="d-flex flex-column justify-content-end">
    <Dedupes
      :allow-dupes.sync="allowDupes"
    />

    <div class="w-100 d-flex justify-content-end">
      <el-tooltip
        v-if="!routeContext.savedForLaterContext() && (inAppSourcingSelectedProject || inAppSourcingSelectedCampaign)"
        :content="saveForLaterTooltipContent"
        :open-delay="250"
      >
        <span class="w-20">
          <el-button
            class="w-100 d-flex justify-content-center"
            :disabled="isSaveForLaterButtonDisabled"
            :loading="saveLaterButtonLoading"
            @click="saveToProject"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div v-if="!saveLaterButtonLoading">
                <div v-if="isSavedForLater" class="material-icons-round md-14 text-color-success">check_circle</div>
                <div
                  v-else
                  class="material-icons-round md-14"
                  :class="{ 'text-color-magenta-100': !isSaveForLaterButtonDisabled }"
                >
                  favorite_border
                </div>
              </div>
              <div>{{ isSavedForLater ? 'Saved' : 'Save' }} for later</div>
            </div>
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip
        v-if="isIntroRequestable"
        :content="introRequestButtonDisabledContent"
        :disabled="isProjectSourceable"
        :open-delay="250"
      >
        <span class="w-20 ml-8">
          <el-button
            type="primary"
            class="w-100"
            :disabled="isIntroRequestButtonDisabled"
            :loading="saveCandidateButtonLoading"
            @click="requestIntro"
          >
            Request Intro
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip
        v-else
        :content="candidateActionButtonDisabledContent"
        :disabled="!candidateActionButtonDisabledContent"
        :open-delay="250"
      >
        <span class="w-20 ml-8">
          <el-button
            type="primary"
            class="w-100"
            :disabled="!!candidateActionButtonDisabledContent"
            :loading="saveCandidateButtonLoading"
            @click="saveToCampaign"
          >
            {{ saveCandidateButtonLabel }}
          </el-button>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<style lang="scss">
#in-app-sourcing-footer {
  flex-grow: 1;
  align-items: flex-end;
  padding: 24px;
  box-shadow: 0 -2px 4px -1px rgba(2,15,18,.1);
}
</style>
