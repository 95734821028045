import CampaignsApi from "@/common/http/CampaignsApi";
import logEvent from "@/common/Analytics";

const state = {
  generatedCampaignStatus: null,
  campaignsAwaitingGptResponse: [],
  campaignsNotificationList: [],
  newGeneratedMessage: null,
  isCampaignFollowUpGenerating: false,
  isCampaignFirstMessageGenerating: false,
  generatedFollowUp: null, // this for "saving" currently generated followup id, to show loader after tab change
  isCampaignMessageValid: true,
  hasMessageChanged: false,
  unsavedMessages: 0,
};

const getters = {
  generatedCampaignStatus(state) {
    return state.generatedCampaignStatus;
  },
  campaignsAwaitingGptResponse(state) {
    return state.campaignsAwaitingGptResponse;
  },
  campaignsNotificationList(state) {
    return state.campaignsNotificationList;
  },
  newGeneratedMessage(state) {
    return state.newGeneratedMessage;
  },
  isCampaignFollowUpGenerating(state) {
    return state.isCampaignFollowUpGenerating;
  },
  isCampaignProjectSelectDisabled(state) {
    return state.isCampaignFollowUpGenerating ||
      state.isCampaignFirstMessageGenerating ||
      state.isGeneratingNewCampaignForInAppSourcing;
  },
  isCampaignFirstMessageGenerating(state) {
    return state.isCampaignFirstMessageGenerating;
  },
  generatedFollowUp(state) {
    return state.generatedFollowUp;
  },
  isCampaignMessageValid(state) {
    return state.isCampaignMessageValid;
  },
  hasMessageChanged(state) {
    return state.hasMessageChanged;
  },
  unsavedMessages(state) {
    return state.unsavedMessages;
  },
};

const actions = {
  campaignGenerationHandler({ getters, commit, dispatch }) {
    commit("setCampaignsAwaitingGptResponse", [...state.campaignsAwaitingGptResponse, getters.currentCampaign]);
    commit("setCampaignsNotificationList", [...state.campaignsNotificationList, {
      name: getters.currentCampaign.name,
      id: getters.currentCampaign.id,
      projectId: getters.currentCampaign.projectId,
      isGenerating: true,
      show: true,
    }]);

    if (state.campaignsAwaitingGptResponse.length === 1) {
      dispatch("pollCampaignsStatuses");
    }
  },
  pollCampaignsStatuses({ state, getters, commit, dispatch }) {
    const responseHandler = (object, notificationListItem, index) => {
      notificationListItem.show = true;
      notificationListItem.isGenerating = false;

      object.splice(index, 1);
      setTimeout(() => {
        notificationListItem.show = false;
        state.campaignsNotificationList.splice(index, 1);
      }, 10000);

      if (!state.campaignsAwaitingGptResponse.length) {
        clearInterval(pollingInterval);
        commit("setNewCampaign", null);
        setTimeout(() => {
          dispatch("fetchSourceableCampaignList");
          commit("setCampaignsNotificationList", [])
        }, 10000);
      }
    }

    const pollingInterval = setInterval(() => {
      if (!state.campaignsAwaitingGptResponse.length) {
        dispatch("fetchSourceableCampaignList");
        clearInterval(pollingInterval);
      }

      state.campaignsAwaitingGptResponse.forEach(async (item, index, object) => {
        const notificationListItem = state.campaignsNotificationList[index];
        try {
          const { data } = await CampaignsApi.templateGenerationStatus(item.id);

          if (data.status === "success") {
            if (getters.currentCampaign.id === item.id) {
              dispatch("fetchCampaign", item.id);
            }
            notificationListItem.failed = false;
            commit("setGeneratedCampaignStatus", "success");
            responseHandler(object, notificationListItem, index);
          }
          if (data.status === "failed") {
            if (getters.currentCampaign.id === item.id) {
              dispatch("fetchCampaign", item.id);
            }
            notificationListItem.failed = true;
            commit("setGeneratedCampaignStatus", "failed");
            responseHandler(object, notificationListItem, index);
          }
        } catch (e) {
          commit("setCurrentCampaign", {
            ...getters.currentCampaign,
            generatingTemplates: false,
          });
          // Don't rethrow since UI handles error messages
        }
      });
    }, 3000);
  },
  async generateNewCampaign({ getters, commit, dispatch }) {
    const newCampaign = getters.newCampaign;
    const params = Object.assign({}, newCampaign);

    params.autosend = newCampaign.pseudoSenderId.split("/")[1] === undefined;

    const { data } = await CampaignsApi.create({ campaign: params });
    const { campaign } = data;

    commit("setCurrentCampaign", campaign);
    commit("setGeneratedCampaignStatus", null);
    dispatch("fetchSourceableCampaignList");

    if (campaign.generatingTemplates) {
      dispatch("campaignGenerationHandler");
    }
  },
  async regenerateAllMessages({ getters, commit, dispatch }) {
    await CampaignsApi.regenerateAllMessages(getters.currentCampaign.id, { campaign: getters.currentCampaign });

    commit("setCurrentCampaign", {
      ...getters.currentCampaign,
      generatingTemplates: true,
    });
    dispatch("campaignGenerationHandler");
  },
  async generateNewFirstMessage({ getters, commit }, cancelRequest = false ) {
    if (cancelRequest) {
      await CampaignsApi.generateFirstMessage(getters.currentCampaign.id, { cancelRequest: true });
      return;
    }

    commit("setIsCampaignFirstMessageGenerating", true);

    try {
      const { data } = await CampaignsApi.generateFirstMessage(getters.currentCampaign.id);

      state.isCampaignFirstMessageGenerating && commit("setNewGeneratedMessage", data.message);

      logEvent("outbound-first-message-generated", {
        campaignName: getters.currentCampaign.name,
        campaignId: getters.currentCampaign.id,
      });

      commit("setIsCampaignFirstMessageGenerating", false);
    } catch (e) {
      commit("setIsCampaignFirstMessageGenerating", false);
      throw (e)
    }
  },
  async generateNewFollowUp({ getters, commit }, { idx }) {
    commit("setIsCampaignFollowUpGenerating", true);
    commit("setGeneratedFollowUp", idx);

    try {
      const { data } = await CampaignsApi.generateFollowUp({
        campaign_id: getters.currentCampaign.id,
        sequence_num: idx,
      });

      logEvent("outbound-new-followup-generated", {
        campaignName: getters.currentCampaign.name,
        campaignId: getters.currentCampaign.id,
      });

      commit("setGeneratedFollowUp", null);

      state.isCampaignFollowUpGenerating && commit("setNewGeneratedMessage", { ...data.message, idx });
      commit("setIsCampaignFollowUpGenerating", false);
    } catch (e) {
      commit("setIsCampaignFollowUpGenerating", false);
      commit("setGeneratedFollowUp", null);
      throw (e);
    }
  },
  async stopGenerateNewMessage({ state, getters, commit }) {
    commit("setCampaignsNotificationList", state.campaignsNotificationList.filter(item => item.id !== getters.currentCampaign.id));
    commit("setCampaignsAwaitingGptResponse", state.campaignsAwaitingGptResponse.filter(item => item.id !== getters.currentCampaign.id));

    const { data } = await CampaignsApi.stopGeneration(getters.currentCampaign.id);
    commit("setCurrentCampaign", {
      ...data.campaign,
      generatingTemplates: false,
    });
  },
  resetInAppSourcingMessageFlags({ commit }) {
    commit("setMessageChanged", false);
    commit("setUnsavedMessages", false);
    commit("setIsCampaignFollowUpGenerating", false);
    commit("setIsCampaignFirstMessageGenerating", false);
  },
};

const mutations = {
  setGeneratedCampaignStatus(state, value) {
    state.generatedCampaignStatus = value;
  },
  setCampaignsAwaitingGptResponse(state, value) {
    state.campaignsAwaitingGptResponse = value;
  },
  setCampaignsNotificationList(state, value) {
    state.campaignsNotificationList = value;
  },
  setNewGeneratedMessage(state, value) {
    state.newGeneratedMessage = value;
  },
  setIsCampaignFollowUpGenerating(state, value) {
    state.isCampaignFollowUpGenerating = value;
  },
  setIsCampaignFirstMessageGenerating(state, value) {
    state.isCampaignFirstMessageGenerating = value;
  },
  setGeneratedFollowUp(state, value) {
    state.generatedFollowUp = value;
  },
  setIsCampaignMessageValid(state, value) {
    state.isCampaignMessageValid = value;
  },
  setMessageChanged(state, value) {
    state.hasMessageChanged = value;
  },
  setUnsavedMessages(state, value) {
    state.unsavedMessages = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
