import ApiClient from "@/common/http/ApiClient.js";

class TasksApi {
  static show(id) {
    return ApiClient.get(`tasks/${id}`);
  }

  static update(id, params) {
    return ApiClient.put(`tasks/${id}`, params);
  }

  static gmailThreadUrl(id, params = {}) {
    return ApiClient.get(`tasks/${id}/gmail_url`, params);
  }

  static chatbotPdf(id) {
    return ApiClient.get(`tasks/${id}/chatbot_pdf`);
  }
}

export default TasksApi;
