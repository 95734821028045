<script setup>
import { ref, computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import CandidateChangeActivityStatusModal from "./CandidateChangeActivityStatusModal/Component";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  touchpoint: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  plain: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
});

const isUpdateStatusModalVisible = ref(false);

const {
  bulkSelectionAttributes,
  currentUser,
} = useGetters([
  "bulkSelectionAttributes",
  "currentUser",
]);

const disabled = computed(() => {
  if (props.touchpoint) {
    return !props.touchpoint.overridable;
  } else if (props.candidate) {
    return !props.candidate.recentTouchpoint || !props.candidate.recentTouchpoint.overridable
  }

  return false;
});

const touchpointTooltip = computed(() => {
  if (!props.touchpoint.overridable) {
    if (props.touchpoint?.touchpointType === "IntroPack") {
      return `Intro request status is synced from ${currentUser.value.primaryTeamAtsName}`;
    } else if (props.touchpoint?.touchpointType === "Task") {
      return "Unable to manually change the status of this outreach";
    } else {
      return null; // Is there a third option?
    }
  } else {
    if (props.touchpoint?.touchpointType === "IntroPack") {
      return "Update intro pack status";
    } else if (props.touchpoint?.touchpointType === "Task") {
      return "Update outreach status";
    } else {
      return null; // Is there a third option?
    }
  }
});

const candidateTooltip = computed(() => {
  if (!props.candidate.recentTouchpoint) {
    return `${props.candidate?.name || "Candidate"} must have an outreach in order to update the status`;
  }

  if (!props.candidate.recentTouchpoint.overridable) {
    return "Unable to manually change the status of this outreach";
  }

  return "Update status of the most recent outreach";
});

const tooltip = computed(() => {
  if (props.touchpoint) {
    return touchpointTooltip.value;
  } else if (props.candidate) {
    return candidateTooltip.value;
  }

  return `Update status for ${bulkSelectionAttributes.value?.count} candidates`;
});

const currentTask = computed(() => {
  if (props.touchpoint?.touchpointType === "Task") {
    return props.touchpoint;
  }

  return null;
});

const currentIntroPack = computed(() => {
  if (props.touchpoint?.touchpointType === "IntroPack") {
    return props.touchpoint;
  }

  return null;
});

function showUpdateStatusModal() {
  isUpdateStatusModalVisible.value = true;
}
</script>

<template>
  <el-skeleton class="w-100" :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <el-tooltip
      :content="tooltip"
      :open-delay="500"
      :disabled="!tooltip"
    >
      <div v-if="dropdownItem">
        <el-dropdown-item :disabled="disabled">
          <div class="d-flex align-items-center" @click="showUpdateStatusModal">
            <slot>
              <span class="material-icons-round md-18 mr-8">upgrade</span>
              <span>Update status...</span>
            </slot>
          </div>
        </el-dropdown-item>
      </div>

      <el-button
        v-else
        class="m-0 w-100"
        type="primary"
        :size="size"
        :plain="plain"
        :disabled="disabled"
        @click="showUpdateStatusModal"
      >
        <span v-if="onlyIcon" class="material-icons-round md-14 m-0">upgrade</span>
        <span v-else>
          <slot>Update status...</slot>
        </span>
      </el-button>
    </el-tooltip>

    <CandidateChangeActivityStatusModal
      :visible.sync="isUpdateStatusModalVisible"
      :candidate="candidate"
      :current-task="currentTask"
      :current-intro-pack="currentIntroPack"
    />
  </el-skeleton>
</template>
