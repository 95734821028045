<script setup>
import { useActions, useGetters } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
});

const root = useRoot();

const {
  currentCandidate,
} = useGetters([
  "currentCandidate",
]);

const {
  hideRecommendation,
} = useActions([
  "hideRecommendation",
]);

async function reject() {
  await hideRecommendation({ candidate: props.candidate });

  const showNext = currentCandidate.value.tfId === props.candidate.tfId;

  root.$bus.$emit("candidate-recruited", { candidate: props.candidate, showNext });
}

</script>

<template>
  <el-tooltip
    slot="reference"
    content="Do not recommended this candidate again for this job"
    :open-delay="500"
  >
    <el-button
      class="w-100 m-0"
      type="warning"
      :size="size"
      @click="reject"
    >
      <span v-if="onlyIcon" class="material-icons-round md-14 m-0">thumb_down_alt</span>
      <span v-else>Reject</span>
    </el-button>
  </el-tooltip>
</template>
