import { render, staticRenderFns } from "./LayoutSimple.html?vue&type=template&id=4ed5d78d&scoped=true&external"
import script from "./LayoutSimple.js?vue&type=script&lang=js&external"
export * from "./LayoutSimple.js?vue&type=script&lang=js&external"
import style0 from "./LayoutSimple.scss?vue&type=style&index=0&id=4ed5d78d&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed5d78d",
  null
  
)

export default component.exports