import { mapGetters, mapActions, mapMutations } from "vuex";
import { MAIN_APP_URL } from "@/common/Env";
import logEvent from "@/common/Analytics";
import { useProvideIsMutatedRecommendationFeedbacks } from "@/composables/recommendationFeedbacks/useProvideIsMutatedRecommendationFeedbacks";

import UsersApi from "@/common/http/UsersApi";

import LayoutDefaultFull from "@/layouts/LayoutDefaultFull/Component";
import LayoutSimple from "@/layouts/LayoutSimple/Component";
import LayoutNoSidebar from "@/layouts/LayoutNoSidebar/Component";

import AtsConnectModal from "@/components/AtsConnectModal/Component";
import CandidateReviewUndo from "@/components/CandidateReviewUndo";
import NewCampaignStatusNotification from "@/components/NewCampaignStatusNotification/NewCampaignStatusNotification";
import CandidateSidebar from "@/components/CandidateSidebar/Root/Component";
import ErrorLogo from "@/components/ErrorLogo";
import InviteTeammateModal from "@/components/InviteTeammateModal";
import CopyCampaignModal from "@/components/CopyCampaignModal/Component";
import NewCampaignMessageModal from "@/components/NewCampaignMessageModal";
import NewProjectModal from "@/components/NewProjectModal/NewProjectModal";
import SearchObserver from "@/components/SearchObserver";
import ReleaseNotesModal from "@/components/ReleaseNotesModal";
import UserSendAsPermissionModal from "@/components/UserSendAsPermissionModal/Component";
import CreateCampaignModal from "@/components/CreateCampaignModal/CreateCampaignModal";

export default {
  name: "App",
  components: {
    AtsConnectModal,
    CandidateReviewUndo,
    CandidateSidebar,
    CreateCampaignModal,
    ErrorLogo,
    InviteTeammateModal,
    CopyCampaignModal,
    NewCampaignMessageModal,
    NewProjectModal,
    ReleaseNotesModal,
    SearchObserver,
    UserSendAsPermissionModal,
    NewCampaignStatusNotification,
  },
  setup() {
    useProvideIsMutatedRecommendationFeedbacks();
  },
  props: {
    reportName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      layout: "div",
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      "authError",
      "currentCandidate",
      "currentTeam",
      "currentUser",
      "newCampaign",
      "generalError",
      "isAuthenticated",
      "isUserSudoing",
      "isChooseRoleNeeded",
      "lobbyPage",
      "showSendAsPermissionOptIn",
      "userJustLoggedIn",
      "vueBuildSha",
    ]),
    showLayout() {
      return this.isAuthenticated || this.isChooseRoleNeeded || this.lobbyPage || this.$route.meta.skipAuthRedirect;
    },
    sendAsPermissionModalVisible() {
      return this.$route.name === "user-send-as-permission-modal";
    },
    atsConnectModalVisible() {
      return this.$route.name === "ats-connect-modal";
    },
    releaseNotesModalVisible() {
      if (this.currentUser?.releaseNotes) {
        return Object.keys(this.currentUser.releaseNotes)?.length > 0;
      }
      return false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.notice) {
          this.$message({
            showClose: true,
            message: to.query.notice,
            type: "info",
          });
          const query = Object.assign({}, to.query);
          delete query.notice;
          this.$router.replace({ query });
        }

        this.setLayout();

        if (to.meta?.title && !this.currentCandidate?.tfId ) {
          this.setDocumentTitle(to.meta.title);
        }

        this.showAtsConnectModal();
      },
    },
    "currentUser.id"() {
      if (this.isAuthenticated) {
        if (this.currentUser.role !== "recruiter_accord") {
          this.showSendAsPermissionModal();
          this.fetchReviewCount();
          // 10 minutes
          setInterval(this.fetchReviewCount, 600000);
        }

        localStorage.setItem("vueBuildSha", this.vueBuildSha);

        this.requestHeavyData();

        const twelveHours = 12 * 60 * 60 * 1000; /* ms */

        if (!this.currentUser.appVisitedAt || (Date.now() - this.currentUser.appVisitedAt) > twelveHours) {
          UsersApi.update({ appVisitedAt: new Date() });
        }

        // Load the user for the beacon support/help if there is a user
        window.Beacon("identify", {
          name: `${this.currentUser.name}`,
          email: `${this.currentUser.email}`,
          company: `${this.currentUser.primaryTeamName || ""}`,
        });
      }
    },
    lobbyPage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$router.replace({ name: val });
        }
      },
    },
    isChooseRoleNeeded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$router.replace({ name: "choose-role" });
        }
      },
    },
    authError: {
      immediate: true,
      handler(error) {
        if (error && error.status !== 500) {
          // root URL is a hub that picks the right URL for the user
          window.location.href = MAIN_APP_URL;
        }
      },
    },
    userJustLoggedIn(justLoggedIn) {
      if (justLoggedIn && !this.isUserSudoing) {
        if (justLoggedIn === "initial") {
          this.updateUserTimezone();
        }

        this.$router.replace({ name: "dashboard" });

        setTimeout(() => {
          this.$message({
            showClose: true,
            message: `Welcome, ${this.currentUser.name}! You've successfully signed in.`,
            type: "success",
            duration: 3000,
          });
        }, 2000);
      }
    },
    generalError(error) {
      if (!error) {
        return;
      }

      const errorMessage = error.message || error.toString();
      if (error.name === "SemanticError") {
        const message = `Oops. ${errorMessage}.`;
        this.$message({
          showClose: true,
          message,
          type: "info",
          duration: 10000,
        });
      }

      logEvent("error", { message: errorMessage });
    },
  },
  methods: {
    ...mapActions([
      "fetchSourceableCampaignList",
      "fetchSourceableProjectsList",
      "fetchReviewCount",
      "fetchReviewData",
    ]),
    ...mapMutations([
      "setCurrentUserTimezone",
      "setDocumentTitle",
      "setWindowInnerWidth",
    ]),
    setLayout() {
      this.setWindowInnerWidth(window.innerWidth);

      if (this.$route.meta.layout === false || this.$route.name === null) {
        this.layout = "div";
      // Checking for authentication prevents search box from appearing and then
      // disappearing for the lobby pages.
      } else if (this.$route.meta.layout === "simple" || !this.isAuthenticated) {
        this.layout = LayoutSimple;
      } else if (this.$route.meta.layout === "nosidebar") {
        this.layout = LayoutNoSidebar;
      } else {
        this.layout = LayoutDefaultFull;
      }
    },
    updateUserTimezone() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.setCurrentUserTimezone(timezone);
      UsersApi.update({ timezone });
    },
    showSendAsPermissionModal() {
      if (this.showSendAsPermissionOptIn && this.$route.name !== "user-send-as-permission-modal") {
        this.$router.replace({ name: "user-send-as-permission-modal" });
      }
    },
    showAtsConnectModal() {
      if (this.currentUser.showAtsConnect && this.$route.name !== "ats-connect-modal") {
        this.$router.replace({ name: "ats-connect-modal" });
      }
    },
    onPageScrollHandler() {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxTop = documentHeight - windowHeight - scrollPosition;
      if (windowHeight + 500 >= maxTop) {
        this.$bus.$emit("page-scroll-end");
      }
    },
    requestHeavyData() {
      this.fetchSourceableProjectsList();
      this.fetchSourceableCampaignList();
      this.fetchReviewData();
    },
  },
  created() {
    window.addEventListener("scroll", this.onPageScrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onPageScrollHandler);
  },
};
