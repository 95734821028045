import { mapGetters, mapActions } from "vuex";

import _ from "lodash";
import UsersApi from "@/common/http/UsersApi";
import AtsStages from "@/components/AtsStages/Component";
import TalentWall from "@/components/TalentWall/Component";
import AtsLogo from "@/components/AtsLogo/AtsLogo";
import { loadScript as loadMergeDevScript, initialize as initializeMergeDev, openLink as openMergeDevLink } from "@/common/MergeDev";
import AtsApi from "@/common/http/AtsApi";

export default {
  name: "AtsConnect",
  components: {
    AtsStages,
    AtsLogo,
    TalentWall,
  },
  props: {
    requireAts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyValid: false,
      stagesSelected: false,
      atsName: null,
      atsGreenhouseKeyCheck: {
        isLoading: false,
        success: null,
        status: null,
        description: null,
        originalKey: null,
        isEditing: false,
      },
      atsLeverKeyCheck: {
        isLoading: false,
        success: null,
        status: null,
        description: null,
        originalKey: null,
        isEditing: false,
      },
      leverIntegrationGuideLink: "https://support.teamable.com/article/714-integrating-lever-with-teamable",
      greenhouseIntegrationGuideLink: "https://support.teamable.com/article/59-integrating-greenhouse-with-teamable",
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      isInboundsEnabled: "isInboundsEnabled",
      settings: "currentUserSettings",
    }),
    atsNameComputed: {
      get() {
        return this.atsName || this.user.primaryTeamAtsName || "None";
      },
      set(val) {
        this.atsName = val;
      },
    },
    showGreenhouseTab() {
      return (
        (this.user.primaryTeamAtsName === "Greenhouse" && this.user.primaryTeamAtsName === this.atsNameComputed) ||
        (this.atsNameComputed === "Greenhouse" && !this.user.primaryTeamAtsName)
      );
    },
    showLeverTab() {
      return (
        (this.user.primaryTeamAtsName === "Lever" && this.user.primaryTeamAtsName === this.atsNameComputed) ||
        (this.atsNameComputed === "Lever" && !this.user.primaryTeamAtsName)
      );
    },
    showMergeAtsTab() {
      return (
        this.settings.mergeDevApiKey &&
        this.user.primaryTeamAtsName !== "Greenhouse" && // Because it's already there
        this.user.primaryTeamAtsName !== "Lever" // Because it's already there
      );
    },
    submitButtonDisabled() {
      return !["Finish", "Save", "OK"].includes(this.submitButtonLabel);
    },
    showSubmitButton() {
      if (this.atsName === "None" && !this.user.primaryTeamAtsName) {
        return true;
      }
      if (this.requireAts) {
        return this.submitButtonLabel === "OK" || this.submitButtonLabel === "Finish";
      }
      return false;
    },
    showGreenhouseCancelButton() {
      return this.atsGreenhouseKeyCheck.isEditing && !this.requireAts;
    },
    showLeverCancelButton() {
      return this.atsLeverKeyCheck.isEditing && !this.requireAts;
    },
    submitButtonLabel() {
      if (this.atsName === "None" && !this.requireAts) {
        return "Save";
      }

      if (!this.atsName) {
        return "Choose an ATS";
      }

      if (!this.keyValid) {
        return "Enter an API key";
      }

      return "Finish";
    },
  },
  watch: {
    atsName: "resetAtsKeyCheck",
    atsNameComputed: {
      handler(newValue, oldValue) {
        if (newValue === "Other" && !this.settings.mergeDevApiKey && !this.user.primaryTeamAtsName) {
          openMergeDevLink();
          setTimeout(() => {
            this.atsNameComputed = oldValue;
          }, 500);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "updateCurrentUserSettings",
    ]),
    onInput(key) {
      this.keyValid = false;
      if (key === "greenhouseApiKey") {
        this.atsGreenhouseKeyCheck.isEditing = true;
      } else if (key === "leverApiKey") {
        this.atsLeverKeyCheck.isEditing = true;
      }
    },
    atsStagesUpdated() {
      this.throttlePromptSettingsSaved();
    },
    saveSettings() {
      this.updateCurrentUserSettings();
      this.throttlePromptSettingsSaved();
    },
    throttlePromptSettingsSaved: _.throttle(function() {
      this.$notify({
        position: "bottom-right",
        message: "Your settings have been saved.",
        title: "Saved",
        type: "success",
      });
    }, 4000),
    cancelEditKey(key) {
      if (key === "greenhouseApiKey") {
        this.settings.greenhouseApiKey = this.atsGreenhouseKeyCheck.originalKey;
        this.atsGreenhouseKeyCheck.isEditing = false;
      } else if (key === "leverApiKey") {
        this.settings.leverApiKey = this.atsLeverKeyCheck.originalKey;
        this.atsLeverKeyCheck.isEditing = false;
      }
    },
    editKey(key) {
      if (key === "greenhouseApiKey") {
        this.atsGreenhouseKeyCheck.isEditing = true;
        this.atsGreenhouseKeyCheck.originalKey = this.settings.greenhouseApiKey;
      } else if (key === "leverApiKey") {
        this.atsLeverKeyCheck.isEditing = true;
        this.atsLeverKeyCheck.originalKey = this.settings.leverApiKey;
      }

      this.settings[key] = null;
    },
    async saveGreenhouseKey() {
      this.atsGreenhouseKeyCheck.isLoading = true;
      const { data } = await UsersApi.verifyGreenhouseKey(this.settings.greenhouseApiKey);
      const isSuccess = !data.issues;

      if (isSuccess) {
        this.keyValid = true;
        this.settings.leverApiKey = null;
        this.user.primaryTeamAtsName = this.atsName;
        await this.updateCurrentUserSettings({ greenhouseKeyChanged: true, leverKeyChanged: true });
        const { data } = await AtsApi.allStages();
        this.settings.availableAtsStages = data.availableAtsStages;
      }

      this.atsGreenhouseKeyCheck.description = isSuccess ? "Success" : data.issues;
      this.atsGreenhouseKeyCheck.success = isSuccess;
      this.atsGreenhouseKeyCheck.status = isSuccess ? "success" : "error";
      this.atsGreenhouseKeyCheck.isLoading = false;
      this.atsGreenhouseKeyCheck.isEditing = false;
    },
    async saveLeverKey() {
      this.atsLeverKeyCheck.isLoading = true;
      const { data } = await UsersApi.verifyLeverKey(this.settings.leverApiKey);
      const isSuccess = !data.issues;

      if (isSuccess) {
        this.keyValid = true;
        this.settings.greenhouseApiKey = null;
        this.user.primaryTeamAtsName = this.atsName;
        await this.updateCurrentUserSettings({ leverKeyChanged: true, greenhouseKeyChanged: true });
        const { data } = await AtsApi.allStages();
        this.settings.availableAtsStages = data.availableAtsStages;
      }

      this.atsLeverKeyCheck.description = isSuccess ? "Success" : data.issues;
      this.atsLeverKeyCheck.success = isSuccess;
      this.atsLeverKeyCheck.status = isSuccess ? "success" : "error";
      this.atsLeverKeyCheck.isLoading = false;
      this.atsLeverKeyCheck.isEditing = false;
    },
    testGreenhouseKey() {
      this.atsGreenhouseKeyCheck.isLoading = true;
      UsersApi.testGreenhouseKey().then(({ data }) => {
        this.atsGreenhouseKeyCheck.isLoading = false;
        const isSuccess = !data.issues;
        this.atsGreenhouseKeyCheck.description = isSuccess ? "Success" : data.issues
        this.atsGreenhouseKeyCheck.success = isSuccess;
        this.atsGreenhouseKeyCheck.status = isSuccess ? "success" : "error";
      })
    },
    testLeverKey() {
      this.atsLeverKeyCheck.isLoading = true;
      UsersApi.testLeverKey().then(({ data }) => {
        this.atsLeverKeyCheck.isLoading = false;
        const isSuccess = !data.issues;
        this.atsLeverKeyCheck.description = isSuccess ? "Success" : data.issues
        this.atsLeverKeyCheck.success = isSuccess;
        this.atsLeverKeyCheck.status = isSuccess ? "success" : "error";
      });
    },
    submit() {
      if (this.atsName === "None") {
        this.user.primaryTeamAtsName = null;
        this.settings.greenhouseApiKey = null;
        this.settings.leverApiKey = null;

        this.updateCurrentUserSettings({ greenhouseKeyChanged: true, leverKeyChanged: true });
      }

      this.user.showAtsConnect = false;
      this.$emit("finish-connect");
    },
    resetAtsKeyCheck() {
      Object.assign(this.atsGreenhouseKeyCheck, this.$options.data.call(this).atsGreenhouseKeyCheck);
      Object.assign(this.atsLeverKeyCheck, this.$options.data.call(this).atsLeverKeyCheck);
    },
    confirmBackfill(setting) {
      this.$confirm(`Do you want this setting to apply to all of your team's jobs? This will not affect candidates already in ${this.user.primaryTeamAtsName}.`, "Apply to existing jobs?", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "info",
      }).then(() => {
        this.settings[setting] = true;
        this.saveSettings();
      }).catch(() => {
        this.settings[setting] = false;
        this.saveSettings();
      });
    },
    getMergeLinkToken() {
      loadMergeDevScript();

      UsersApi.mergeDevModalLinkToken().then(({ data }) => {
        initializeMergeDev(data.linkToken);
      })
    },
  },
  mounted() {
    if (!this.user.isTeamAdmin) {
      this.$router.replace({ name: "settings-root" });
    }
    if (this.user.primaryTeamAtsName) {
      this.keyValid = true;
      this.atsName = this.user.primaryTeamAtsName;
    }

    if (this.settings.mergeDevApiKey || this.atsNameComputed === "None") {
      // linkToken retrieved from backend (Step 1)
      this.getMergeLinkToken();
    }
  },
};
