<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";

const props = defineProps({
  allowDupes: {
    type: Boolean,
    default: false,
  },
});

const root = useRoot();
const emit = defineEmits(["update:allowDupes"]);

const {
  bulkSelectionAttributes,
  candidateDedupeMessage,
  inAppSourcingSelectedCampaign,
  profileIdsInReview,
  selectedCandidateInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "candidateDedupeMessage",
  "inAppSourcingSelectedCampaign",
  "profileIdsInReview",
  "selectedCandidateInAppSourcing",
]);

const computedAllowDupes = computed({
  get() { return props.allowDupes; },
  set(value) { emit("update:allowDupes", value); },
});

const isProfileInReview = computed(() => {
  return profileIdsInReview.value?.includes(selectedCandidateInAppSourcing.value?.profileId);
});

const showDupeSwitch = computed(() => {
  return (
    bulkSelectionAttributes.value &&
    !selectedCandidateInAppSourcing.value &&
    inAppSourcingSelectedCampaign.value?.id !== "save" &&
    !isProfileInReview.value
  );
});

const candidateDedupeMessageAndIcon = computed(() => {
  return `<i class="material-icons-round mr-1 md-14">open_in_new</i> ${candidateDedupeMessage.value}`;
});

function viewCandidate() {
  root.$bus.$emit("view-candidate", { candidate: selectedCandidateInAppSourcing.value });
}

</script>

<template>
  <div class="w-100">
    <div v-if="!!candidateDedupeMessage" @click="viewCandidate">
      <el-alert
        id="dedupe-message"
        class="mb-8"
        type="warning"
        :closable="false"
        v-html="candidateDedupeMessageAndIcon"
      />
    </div>

    <template v-if="showDupeSwitch">
      <el-tooltip :open-delay="500" content="How would you like to handle duplicate outreach for candidates that have been previously messaged?">
        <el-switch
          v-model="computedAllowDupes"
          active-text="Allow reaching out to previously messaged candidates"
          class="mt-8"
        />
      </el-tooltip>
    </template>
  </div>
</template>

<style lang="scss">
#dedupe-message {
  cursor: pointer;
  color: $tf-color-warning-dark !important;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}
</style>
