import { Quill } from "vue2-editor";
import Emoji from "quill-emoji/dist/quill-emoji";
import TokenBlot from "@/common/quill/TokenBlot";
import TokenDrop, { TOKEN_MODULE_NAME } from "@/common/quill/TokenDrop";
import "quill-emoji/dist/quill-emoji.css"

class QuillInit {
  static run() {
    window.Quill = Quill;

    Quill.register({
      "formats/emoji": Emoji.EmojiBlot,
      "modules/short_name_emoji": Emoji.ShortNameEmoji,
      "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    }, true);

    Quill.register(TokenBlot);
    Quill.register(`modules/${TOKEN_MODULE_NAME}`, TokenDrop);

    Quill.registered = true;
  }
}

export default QuillInit;
