<script setup>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useGetters, useActions } from "vuex-composition-helpers";
import { useRouter, useRoute } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";

import DateTimeHelper from "@/common/DateTimeHelper";
import ProfilesApi from "@/common/http/ProfilesApi";

import CampaignMessageTemplate from "@/components/CampaignMessageTemplate/Component";
import UserAvatar from "@/components/UserAvatar";

import { INTRO_TIMELINE_STATUSES } from "@/constants";

const root = useRoot();
const route = useRoute();
const router = useRouter();

const currentIntroStatus = ref(INTRO_TIMELINE_STATUSES.requested.id);
const additionalConnectors = ref([]);
const requestAdditionalConnectorsPopupVisible = ref(false);

const props = defineProps({
  introPack: {
    type: Object,
    required: true,
    default: () => {},
  },
});

watch(() => props.introPack, (newIntroPack) => {
  if (newIntroPack?.intros?.length) {
    // assigns the default selected status
    if (newIntroPack.intros[0].status === INTRO_TIMELINE_STATUSES.resumeUploaded.id) {
      currentIntroStatus.value = INTRO_TIMELINE_STATUSES.resumeUploaded.id;
    } else if (newIntroPack.isEmployeeIntro && newIntroPack.intros[0].status !== INTRO_TIMELINE_STATUSES.resumeUploaded.id) {
      currentIntroStatus.value = INTRO_TIMELINE_STATUSES.linkGenerated.id;
    } else {
      currentIntroStatus.value = INTRO_TIMELINE_STATUSES.requested.id;
    }
  }
}, { immediate: true });

const {
  candidateConnectors,
  currentCandidate,
  isLoadingCandidateConnectors,
} = useGetters([
  "candidateConnectors",
  "currentCandidate",
  "isLoadingCandidateConnectors",
]);

const {
  fetchCandidateConnectors,
} = useActions([
  "fetchCandidateConnectors",
]);

const showRequestMore = computed(() => {
  return props.introPack.editable && additionalConnectors.value.length;
});

const fallbackTask = computed(() => {
  if (props.introPack.taskId && currentCandidate.value.tasks) {
    return currentCandidate.value.tasks.find(t => t.id === props.introPack.taskId);
  }
  return null;
});

const fallbackTaskLabel = computed(() => {
  let label = fallbackTask.value.activity.status;
  if (fallbackTask.value.activity.isNegative) {
    label = `<i class="fas fa-ban mr-8"></i> ${label}`;
  }
  if (fallbackTask.value.activity.wasOverridden) {
    label = `${label}*`;
  }
  return label;
});

const introStatuses = computed(() => {
  const statuses = [];

  if (!props.introPack.isEmployeeIntro) {
    const requestedLength = introsForStatus(INTRO_TIMELINE_STATUSES.requested.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.requested.id,
      label: INTRO_TIMELINE_STATUSES.requested.label,
      count: requestedLength,
      type:  requestedLength ? "sourced" : null,
      timestamp: `${props.introPack.initiator.name}, ${DateTimeHelper.MMMDoYYYY(props.introPack.createdAt)}`,
    });

    const employeeRespondedLength = introsForStatus(INTRO_TIMELINE_STATUSES.employeeResponded.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.employeeResponded.id,
      label: INTRO_TIMELINE_STATUSES.employeeResponded.label,
      count: employeeRespondedLength,
      type: employeeRespondedLength ? "sourced" : null,
    });
  }

  if (!resumeUploaded.value) {
    const linkGeneratedLength = introsForStatus(INTRO_TIMELINE_STATUSES.linkGenerated.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.linkGenerated.id,
      label: INTRO_TIMELINE_STATUSES.linkGenerated.label,
      count: linkGeneratedLength,
      type: linkGeneratedLength ? "reached" : null,
      timestamp: props.introPack.isEmployeeIntro
        ? `${props.introPack.initiator.name}, ${DateTimeHelper.MMMDoYYYY(props.introPack.createdAt)}`
        : "",
    });

    const linkOpenedLength = introsForStatus(INTRO_TIMELINE_STATUSES.linkOpened.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.linkOpened.id,
      label: INTRO_TIMELINE_STATUSES.linkOpened.label,
      count: linkOpenedLength,
      type: linkOpenedLength ? "responded" : null,
    });

    const appliedLength = introsForStatus(INTRO_TIMELINE_STATUSES.applied.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.applied.id,
      label: INTRO_TIMELINE_STATUSES.applied.label,
      count: appliedLength,
      type: appliedLength ? "interested" : null,
      hideTail: !!props.introPack.fallbackCampaign, // Hide tail if there is a fallback campaign
    });

    if (props.introPack.fallbackCampaign && !introPackFallbackOutreachCanceled.value || fallbackTask.value) {
      let timestamp = null;
      const time = DateTimeHelper.relativeTimeFromNow(props.introPack.fallbackScheduledAt);

      if (isOutreachStatusPending.value) {
        timestamp = `Scheduled ${time}`;
      }

      statuses.push({
        id: INTRO_TIMELINE_STATUSES.outreach.id,
        label: INTRO_TIMELINE_STATUSES.outreach.label,
        timestamp,
        type: props.introPack.outreachStatus,
      });
    }
  }

  if (resumeUploaded.value) {
    const submitLength = introsForStatus(INTRO_TIMELINE_STATUSES.resumeUploaded.id).length;
    statuses.push({
      id: INTRO_TIMELINE_STATUSES.resumeUploaded.id,
      label: INTRO_TIMELINE_STATUSES.resumeUploaded.label,
      type: "reached",
      count: submitLength,
      hideTail: !!props.introPack.fallbackCampaign, // Hide tail if there is a fallback campaign
    })
  }

  return statuses;
});

const isAnyIntroRejected = computed(() => {
  return props.introPack.intros.some(item => item.status === "form_rejected");
});

const resumeUploaded = computed(() => {
  return props.introPack.intros.some(item => item.status === INTRO_TIMELINE_STATUSES.resumeUploaded.id);
});

const isOutreachStatusPending = computed(() => {
  return props.introPack.outreachStatus === "pending";
});

const introPackFallbackOutreachCanceled = computed(() => {
  return props.introPack.outreachStatus === "aborted" || props.introPack.outreachStatus === "canceled";
});

const currentIntros = computed(() => {
  return introsForStatus(currentIntroStatus.value);
});

const requestableConnectors = computed(() => {
  const introConnectors = props.introPack.intros.map(intro => intro.connector.id);
  return candidateConnectors.value.filter(connector => {
    return !introConnectors.includes(connector.id);
  });
});

const formatIntroDate = (intro) => {
  return DateTimeHelper.format(intro.statusDate, "MMM D YYYY");
};

const updateCurrentIntroStatus = (status) => {
  if (timelineNodeIsClickableForStatus(status)) {
    currentIntroStatus.value = status.id;
  }
};

const timelineNodeIsClickableForStatus = (status) => {
  return status.count || (status.id === INTRO_TIMELINE_STATUSES.outreach.id && status.type !== "aborted" && status.type !== "canceled");
};

const introsForStatus = (status) => {
  return props.introPack.intros.filter(intro => {
    if (status === INTRO_TIMELINE_STATUSES.requested.id) {
      return true;
    } else if (status === INTRO_TIMELINE_STATUSES.employeeResponded.id) {
      return !!intro.formGeneratedAt || ["reject_no_comment", "reject_with_comment", "mention_allowed", "mention_not_allowed", INTRO_TIMELINE_STATUSES.resumeUploaded.id].some(status => status === intro.status);
    } else if (status === INTRO_TIMELINE_STATUSES.linkGenerated.id) {
      return !!intro.formGeneratedAt;
    } else if (status === INTRO_TIMELINE_STATUSES.linkOpened.id) {
      return !!intro.formOpenedAt;
    } else if (status === INTRO_TIMELINE_STATUSES.applied.id) {
      return intro.status === "form_applied";
    } else if (status === INTRO_TIMELINE_STATUSES.resumeUploaded.id) {
      return intro.status === INTRO_TIMELINE_STATUSES.resumeUploaded.id;
    }
  });
};

const introStatusType = (status) => {
  switch (status) {
    case INTRO_TIMELINE_STATUSES.requested.id:
    case "form_generated":
    case "form_opened":
      return "info";

    case "reject_no_comment":
    case "reject_with_comment":
    case "mention_not_allowed":
    case "form_rejected":
    case "expired":
      return "danger";

    case "canceled":
    case "ats_aborted":
    case "lost_aborted":
      return "warning";

    case "mention_allowed":
    case "form_applied":
    case INTRO_TIMELINE_STATUSES.resumeUploaded.id:
      return "success";

    default:
      return "info";
  }
};

const handleCancelIntroPack = () => {
  root.$confirm(`Are you sure you want to cancel the intro request for ${currentCandidate.value.name}?`, "Caution", {
    confirmButtonText: "Yes",
    cancelButtonText: "No, thanks",
    type: "warning",
  }).then(() => {
    cancelIntroPack();
  }).catch(() => {});
};

const handleCancelIntro = ({ intro, introIndex }) => {
  root.$confirm(`Are you sure you want to cancel a request for the intro from ${intro.connector.name}?`, "Caution", {
    confirmButtonText: "Yes",
    cancelButtonText: "No, thanks",
    type: "warning",
  }).then(() => {
    cancelIntro({ intro, introIndex });
  }).catch(() => {});
};

const handleIntroPackCommand = (command) => {
  switch (command) {
    case "cancelIntroPack":
      handleCancelIntroPack();
      return;
    case "forceFallback":
      forceFallback();
      return;
  }
};

const viewOutreachForCurrentTaskHandle = () => {
  const query = Object.assign({}, route.query);
  query.outreachId = props.introPack.taskId;
  delete query.referral;
  router.push({ query });
};

const selectedIntroStatus = (intro) => {
  return intro.status === INTRO_TIMELINE_STATUSES.resumeUploaded.id ? INTRO_TIMELINE_STATUSES.resumeUploaded.label : intro.statusDisplay;
};

const forceFallback = async () => {
  const { data } = await ProfilesApi.forceFallbackForIntroPacks(props.introPack.id);

  const index = currentCandidate.value.introPacks.findIndex(ip => ip.id === props.introPack.id);
  if (~index) {
    currentCandidate.value.introPacks.splice(index, 1, data);
  }
};

const cancelIntroPack = async () => {
  const { data } = await ProfilesApi.cancelIntroPack(props.introPack.id);

  const introPackIndex = currentCandidate.value.introPacks.findIndex(ip => ip.id === props.introPack.id);
  if (~introPackIndex) {
    currentCandidate.value.introPacks.splice(introPackIndex, 1, data);
  }
};

const cancelIntro = async ({ intro, introIndex }) => {
  const { data } = await ProfilesApi.cancelIntro(intro.id);

  const idx = currentCandidate.value.introPacks.findIndex(ip => ip.id === props.introPack.id);
  if (~idx) {
    currentCandidate.value.introPacks[idx].intros.splice(introIndex, 1, data);
  }
};

const addConnectors = async () => {
  requestAdditionalConnectorsPopupVisible.value = false;
  const params = { connectorIds: additionalConnectors.value };
  const { data } = await ProfilesApi.addIntroToIntroPack(props.introPack.id, params);

  const idx = currentCandidate.value.introPacks.findIndex(ip => ip.id === props.introPack.id);
  if (~idx) {
    currentCandidate.value.introPacks.splice(idx, 1, data);
  }
};

async function onNavigateOutsideOfSidebar(path) {
  root.$bus.$emit("close-sidebar");
  await nextTick();
  router.push(path);
}

onMounted(() => {
  if (currentCandidate.value.profileId && !isLoadingCandidateConnectors.value) {
    fetchCandidateConnectors({ profileId: currentCandidate.value.profileId });
  }
});

</script>

<template>
  <div id="intro-pack">
    <div class="d-flex justify-content-end align-items-center mt-16">
      <el-button
        type="secondary"
        size="small"
        plain
        @click="onNavigateOutsideOfSidebar({ name: 'job-campaign-root', params: { projectId: introPack.projectId, campaignId: introPack.campaignId } })"
      >
        <div class="d-flex align-items-center">
          View campaign
        </div>
      </el-button>

      <el-button
        class="ml-8"
        type="secondary"
        size="small"
        plain
        @click="onNavigateOutsideOfSidebar({ name: 'job-root', params: { projectId: introPack.projectId }})"
      >
        <div class="d-flex align-items-center">
          View job
        </div>
      </el-button>

      <template v-if="showRequestMore">
        <el-divider direction="vertical" />

        <el-popover
          v-model="requestAdditionalConnectorsPopupVisible"
          class="d-flex justify-content-center"
          placement="bottom-start"
          :visible-arrow="false"
        >
          <div>
            <div class="mb-24">
              <el-select
                v-model="additionalConnectors"
                class="w-100"
                placeholder="Additional connectors"
                :loading="isLoadingCandidateConnectors"
                no-data-text="No additional connectors"
                multiple
                clearable
                filterable
              >
                <el-option
                  v-for="connector in requestableConnectors"
                  :key="connector.id"
                  :value="connector.id"
                  :label="connector.name"
                >
                  <UserAvatar
                    :image-url="connector.avatarUrl"
                    :full-name="connector.name"
                  />
                </el-option>
              </el-select>
            </div>

            <div class="d-flex">
              <el-button class="w-50" type="text" @click="requestAdditionalConnectorsPopupVisible = false">
                Cancel
              </el-button>
              <el-button class="w-50" type="primary" @click="addConnectors">
                Request
              </el-button>
            </div>
          </div>

          <el-button
            v-if="showRequestMore"
            slot="reference"
            type="text"
            size="small"
            @click="fetchAdditionalIntrosForCandidates"
          >
            <div class="d-flex align-items-center">
              <span class="material-icons-round mr-4">add</span>
              <span>Request more intros</span>
            </div>
          </el-button>
        </el-popover>
      </template>

      <el-divider v-if="introPack.editable" direction="vertical" />

      <el-dropdown
        v-if="introPack.editable"
        class="d-flex align-items-center"
        trigger="click"
        :hide-timeout="500"
        @command="handleIntroPackCommand"
      >
        <el-button class="only-icon px-8 py-4" type="secondary" size="small">
          <span class="material-icons-round">more_horiz</span>
        </el-button>

        <el-dropdown-menu slot="dropdown">
          <el-tooltip
            v-if="introPack.fallbackCampaign"
            content="Option not available as the candidate has declined an invitation to apply for this role"
            :disabled="!isAnyIntroRejected"
            :open-delay="500"
          >
            <span>
              <el-dropdown-item
                :disabled="isAnyIntroRejected"
                command="forceFallback"
              >
                Force fallback to '{{ introPack.fallbackCampaign.name }}'
              </el-dropdown-item>
            </span>
          </el-tooltip>

          <el-dropdown-item command="cancelIntroPack">
            Cancel intro request
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-divider />

    <div class="d-flex mt-16">
      <!-- TIMELINE -->
      <div class="d-flex flex-column mr-16 activity-timeline">
        <div class="mb-24 column-title">Activity</div>

        <el-timeline class="p-0">
          <el-timeline-item
            v-for="(status, index) in introStatuses"
            :key="index"
            :class="{ 'hide-tail': status.hideTail }"
            :type="status.type"
            :timestamp="status.timestamp"
          >
            <span
              class="timeline-item"
              :class="{
                active: currentIntroStatus === status.id,
                disabled: !status.type,
                clickable: timelineNodeIsClickableForStatus(status),
              }"
              @click="updateCurrentIntroStatus(status)"
            >
              <span>{{ status.label }}</span> <span v-if="status.count">({{ status.count }})</span>
            </span>
          </el-timeline-item>
        </el-timeline>

        <div v-if="fallbackTask" class="fallback-task" @click="viewOutreachForCurrentTaskHandle">
          <span
            class="candidate-status p-4"
            :class="fallbackTask.activity.statusKey"
            v-html="fallbackTaskLabel"
          />
        </div>
      </div>

      <div v-if="currentIntroStatus !== 'outreach'" class="w-75 d-flex flex-column">
        <div class="w-100 d-flex">
          <div class="column-title w-100 mb-24">Connectors</div>
          <div class="column-title w-100 pl-24 ml-24 mb-24">Status</div>
          <div class="column-title w-50 mb-24 text-right">Actions</div>
        </div>

        <div v-for="(intro, introIndex) in currentIntros" :key="introIndex" class="w-100">
          <div class="w-100 d-flex details-row">
            <UserAvatar
              class="w-100 connector"
              :image-url="intro.connector.avatarUrl"
              :full-name="intro.connector.name"
            />

            <div class="intro-status d-flex justify-content-start align-items-center w-100 pl-16">
              <div class="d-flex flex-column">
                <span class="d-flex align-items-center" :class="introStatusType(intro.status)">
                  {{ selectedIntroStatus(intro) }}
                </span>

                <span v-if="intro.comment" class="comment mt-4 ml-16">
                  "{{ intro.comment }}"
                </span>

                <span v-if="intro.statusDate" class="date mt-4 ml-16">
                  {{ formatIntroDate(intro) }}
                </span>
              </div>
            </div>

            <div class="w-50 d-flex justify-content-end">
              <el-button
                v-if="intro.editable"
                type="text"
                size="small"
                @click="handleCancelIntro({ intro, introIndex })"
              >
                Cancel
              </el-button>
            </div>
          </div>

          <el-divider />
        </div>
      </div>

      <div v-else class="w-75">
        <div class="mb-24 column-title">Outreach message</div>

        <CampaignMessageTemplate
          v-if="introPack.fallbackCampaign"
          type="firstMessage"
          :subject="introPack.fallbackCampaign.firstMessageSubject"
          :body="introPack.fallbackCampaign.firstMessageBody"
          :cc="introPack.fallbackCampaign.firstMessageCc"
          :bcc="introPack.fallbackCampaign.firstMessageBcc"
        />

        <router-link
          v-if="isOutreachStatusPending"
          class="float-right"
          :to="{ name: 'job-campaign-root', params: { projectId: introPack.projectId, campaignId: introPack.campaignId } }"
        >
          <el-button type="text">
            <div class="d-flex align-items-center">
              <span>Edit fallback campaign</span>
              <span class="material-icons-round">open_in_new</span>
            </div>
          </el-button>
        </router-link>

        <div v-if="introPack.taskId" class="p-8 outreach-thread-link">
          You can find your messaging history on the Outreach tab.

          <span class="link clickable" @click="viewOutreachForCurrentTaskHandle">View outreach</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

#intro-pack {
  .activity-timeline {
    width: 33%;
  }

  .timeline-item {
    font-size: 12px;
    color: $black-70;

    &:not(.clickable) {
      font-weight: 400;
    }

    &.active, &.clickable:hover {
      font-weight: 600;
      color: $blue-100;
    }
    &.disabled {
      color: $black-50;
    }
  }

  ::v-deep .el-timeline-item__tail {
    left: 5px !important;
  }

  ::v-deep .el-timeline-item.hide-tail {
    .el-timeline-item__tail {
      display: none;
    }
  }

  .column-title {
    font-size: 10px;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  .intro-status {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    ::before {
      margin-right: 4px;
      font-family: "Material Icons Round";
      font-size: 14px;
    }

    .danger::before {
      color: $magenta-100;
      content: "block";
    }
    .warning::before {
      color: black;
      content: "block";
    }
    .success::before, .info::before {
      color: $green-100;
      content: "check";
    }

    .comment {
      padding: 4px;
      background-color: $black-5;
      border: 1px solid $black-10;
      border-radius: $tf-global-border-radius;
    }

    .date {
      color: $black-30;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .outreach-thread-link {
    font-size: 14px;
    color: $black-50;
  }

  .fallback-task {
    margin-left: 24px;
    margin-top: -24px;
    display: inline-flex;
    .candidate-status {
      font-size: 12px;
    }
    cursor: pointer !important;
    z-index: 2;
  }
}


.intropack-status, .candidate-status {
  color: white;
  border-radius: $tf-global-border-radius;
  white-space: nowrap;
  height: fit-content;

  &.sourced-stopped {
    background-color: $tf-sourced-stopped-color;
  }
  &.sourced {
    background-color: $tf-sourced-color;
  }
  &.reached {
    background-color: $tf-reached-color;
  }
  &.responded {
    background-color: $tf-responded-color;
  }
  &.interested {
    background-color: $tf-interested-color;
  }
  &.interview {
    background-color: $tf-interview-color;
  }
  &.hired {
    background-color: $tf-hired-color;
  }
}
::v-deep .el-timeline-item__node--sourced {
  background-color: $tf-sourced-color !important;
}
::v-deep .el-timeline-item__node--reached {
  background-color: $tf-reached-color !important;
}
::v-deep .el-timeline-item__node--responded {
  background-color: $tf-responded-color !important;
}
::v-deep .el-timeline-item__node--interested {
  background-color: $tf-interested-color !important;
}
::v-deep .el-timeline-item__node--interview {
  background-color: $tf-interview-color !important;
}
::v-deep .el-timeline-item__node--hired {
  background-color: $tf-hired-color !important;
}
::v-deep .el-timeline-item__node--initiated {
  background-color: $tf-color-success !important;
}

::v-deep .el-dropdown-menu__item {
  margin-bottom: 4px;
}

</style>
