import CampaignListItemBase from "@/components/CampaignListItem/CampaignListItemBase";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "CampaignListItemSearchPopover",
  extends: CampaignListItemBase,
  components: {
    UserAvatar,
  },
};
