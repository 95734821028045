<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const {
  bulkSelectionAttributes,
  selectedCandidateInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "selectedCandidateInAppSourcing",
]);

const title = computed(() => {
  let suffix = "reach out or save for later";

  if (bulkSelectionAttributes.value) {
    const candidateWord = bulkSelectionAttributes.value.count === 1 ? "candidate" : "candidates";
    return `<strong>${bulkSelectionAttributes.value.count} ${candidateWord}</strong> - ${suffix}`;
  }

  if (selectedCandidateInAppSourcing.value) {
    return `<strong>${selectedCandidateInAppSourcing.value.name || "<Name Unknown>"}</strong> - ${suffix}`;
  }

  return null;
});
</script>

<template>
  <div id="in-app-sourcing-header">
    <el-skeleton :loading="loading" animated>
      <div slot="template">
        <el-skeleton-item class="w-40" variant="text"/>
      </div>

      <div class="title mb-16" v-html="title"></div>
    </el-skeleton>
  </div>
</template>

<style lang="scss">
#in-app-sourcing-header {
  .title {
    font-size: 16px;
  }
}

</style>
