import { mapGetters } from "vuex";
import RouteContext from "@/common/RouteContext";
import logEvent from "@/common/Analytics";

import ResumeView from "./ResumeView";
import TeamableView from "./TeamableView";

export default {
  name: "CandidateSidebarProfileTab",
  components: {
    ResumeView,
    TeamableView,
  },
  data() {
    return {
      profileView: "teamable",
      routeContext: new RouteContext(this.$route.name),
    };
  },
  computed: {
    ...mapGetters([
      "currentCandidate",
      "currentReviewContext",
    ]),
    isTeamableView() {
      return this.profileView === "teamable";
    },
    isResumeViewOptionsVisible() {
      return !!this.currentCandidate.profileId;
    },
  },
  methods: {
    onProfileViewChange(newValue) {
      logEvent("candidate-sidebar-profile-view-changed", { view: newValue });
      localStorage.setItem("candidate-sidebar-profile-view", newValue);
    },
  },
  beforeMount() {
    // If there is no resume don't even bother checking the cache
    if (this.currentCandidate.resumeFileUrl && this.isResumeViewOptionsVisible) {
      this.profileView = localStorage.getItem("candidate-sidebar-profile-view") || "teamable";
    }
    logEvent("candidate-sidebar-profile-view", { view: this.profileView, resumeExists: !!this.currentCandidate.resumeFileUrl });
  },
};
