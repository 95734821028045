<script setup>
import { computed, nextTick } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRouter, useRoute } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";

import { MAIN_APP_URL } from "@/common/Env";

import UpdateStatusButton from "@/components/candidates/UpdateStatusButton/UpdateStatusButton";
import CampaignMessageModal from "@/components/CampaignMessageModal/Component";
import CandidateOutreachMessage from "@/components/CandidateOutreachMessage/Component";

import TasksApi from "@/common/http/TasksApi";
import OutreachApi from "@/common/http/OutreachApi"

const root = useRoot();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  task: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const {
  currentUser,
  currentCandidate,
} = useGetters([
  "currentCandidate",
  "currentUser",
]);

const roboAlert = computed(() => {
  if (isUserSender.value) {
    return `Replies to this thread are being handled by ${props.task.replyHandler}.`;
  } else if (currentUser.value.id === props.task.replyHandlerId) {
    return `You are currently managing this thread. Messages will not be shown in ${props.task.senderName}'s inbox`;
  }
  return "";
});

const isComposeDisabled = computed(() => {
  return !props.task.senderActive || !props.task.canComposeNewMessage;
});

const isUserSender = computed(() => {
  return currentUser.value.id == props.task.senderId;
});

const composeTooltip = computed(() => {
  if (props.task.isInbound && props.task.inReview) {
    return "Applicant must first be reviewed";
  } else if (props.task.inReview) {
    return "Candidate must first be reviewed";
  } else if (props.task.isLinkedinInmail) {
    return "Cannot compose for Linkedin InMail"
  }

  if (!props.task.senderActive) {
    return "Sender is inactive";
  }

  if (!props.task.canComposeNewMessage) {
    if (!progressItemsWithMessages.value.length) {
      if (props.task.activity.isInOps) {
        return "Outreach is being prepared by Teamable";
      } else if (!props.task.sentAt && props.task.activity.isNegative) {
        return "This outreach was stopped before the initial message was sent";
      } else if (!props.task.sentAt) {
        return "Unable to compose a message before the initial campaign message is sent"
      }
    }
  }
  return "You are not permitted by the campaign owner to send messages in this thread";
});
const composeLabel = computed(() => {
  const asWhom = isUserSender.value || !props.task.senderActive ? "" : ` as ${props.task.senderName.split(" ")[0]}`;
  return `Compose new${asWhom}...`;
});

const lastMessageSubject = computed(() => {
  return `Re: ${subjectFromFirstMessage.value}`;
});

const isMessageModalVisible = computed(() => {
  return props.task && route.query.modal === "reply";
});

const progressItemsWithMessages = computed(() => {
  if (!props.task) {
    return [];
  }
  const items = props.task?.progressItems || [];
  return items.filter(i => i.message && i.message.body).reverse();
});

const subjectFromFirstMessage = computed(() => {
  const firstMessage = progressItemsWithMessages.value[progressItemsWithMessages.value.length - 1];
  if (firstMessage) {
    return firstMessage.message.subject;
  }
  return null;
});

const openGmailThread = async () => {
  let { data } = await TasksApi.gmailThreadUrl(props.task.id);
  window.open(data.url, "_blank");
};

const composeMessage = () => {
  let query = Object.assign({}, route.query);
  query.modal = "reply";
  router.replace({ query });
};

const messageSaved = (message) => {
  const params = {
    taskId: props.task.id,
    ...message,
  };
  OutreachApi.sendMessage(params).then(() => {
    root.$bus.$emit("refresh-current-candidate");
  });
  messageModalClosed();
};

const messageModalClosed = () => {
  let query = Object.assign({}, route.query);
  delete query.modal;
  router.replace({ query });
};


function viewOpsTask() {
  const url = `${MAIN_APP_URL}/worker/tasks/${props.task.id}`;
  window.open(url, "_blank");
};

async function onNavigateOutsideOfSidebar(path) {
  root.$bus.$emit("close-sidebar");
  await nextTick();
  router.push(path);
}
</script>

<template>
  <div id="campaign-message-container">
    <el-divider />

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <el-tooltip :content="composeTooltip" :disabled="!isComposeDisabled" :open-delay="250">
          <el-button
            size="small"
            :disabled="isComposeDisabled"
            type="primary"
            @click="composeMessage"
          >
            {{ composeLabel }}
          </el-button>
        </el-tooltip>

        <el-button
          v-if="task.canOpenInGmail"
          type="secondary"
          size="small"
          @click="openGmailThread"
        >
          Open in Gmail
        </el-button>
      </div>

      <div class="d-flex align-items-center">
        <el-button
          type="secondary"
          size="small"
          plain
          @click="onNavigateOutsideOfSidebar({ name: 'job-campaign-root', params: { projectId: task.projectId, campaignId: task.campaignId } })"
        >
          <div class="d-flex align-items-center">
            View campaign
          </div>
        </el-button>

        <el-button
          class="ml-8"
          type="secondary"
          size="small"
          plain
          @click="onNavigateOutsideOfSidebar({ name: 'job-root', params: { projectId: task.projectId }})"
        >
          <div class="d-flex align-items-center">
            View job
          </div>
        </el-button>

        <el-button
          v-if="currentUser.actualUserIsInternal"
          type="secondary"
          size="small"
          class="ml-8"
          @click="viewOpsTask"
        >
          Task
        </el-button>

        <UpdateStatusButton
          v-if="!task.inReview"
          :candidate="currentCandidate"
          :touchpoint="task"
          class="ml-8"
          size="small"
          plain
          @click.native.stop
        />
      </div>
    </div>

    <div v-if="progressItemsWithMessages.length === 0" class="mt-16">
      <i v-if="task.activity.isInOps">
        Messages are being compiled for this outreach.
      </i>
      <i v-else>
        Selected outreach has no sent or scheduled messages.
      </i>
    </div>

    <template v-else>
      <el-alert
        v-if="task.roboEnabled && roboAlert"
        class="my-8"
        type="warning"
        :title="roboAlert"
        :closable="false"
        show-icon
      />

      <div v-if="!task.isLinkedinInmail" class="my-16 mb-24">
        <div v-if="task.campaignEmailTrackingEnabled" class="mb-16">
          <div>
            <span class="field">Opens:</span>
            <span class="field-description">{{ task.emailOpens }}</span>
          </div>
          <div>
            <span class="field">Clicks:</span>
            <span class="field-description">{{ task.emailClicks }}</span>
          </div>
        </div>

        <span class="field">Subject: </span>
        <span class="field-description">{{ subjectFromFirstMessage }}</span>
      </div>

      <div v-for="(item, index) in progressItemsWithMessages" :key="index">
        <el-divider v-if="index > 0" />
        <CandidateOutreachMessage
          :key="index"
          :expanded="item.status !== 'pending'"
          :item="item"
          :is-linkedin-inmail="task.isLinkedinInmail"
        />
      </div>

      <CampaignMessageModal
        v-if="task.canComposeNewMessage"
        :show="isMessageModalVisible"
        type="threadReply"
        :subject="lastMessageSubject"
        save-button-label="Send"
        :allow-sentence="false"
        :allow-calendar="task.senderHasCalendar"
        :allow-company="false"
        :sender-id="task.senderId"
        @closed="messageModalClosed"
        @saved="messageSaved"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
</style>
