<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";
import { FILTER_DELIMITER } from "@/constants/index";
import logEvent from "@/common/Analytics";

export default {
  name: "SearchObserver",
  computed: {
    ...mapGetters([
      "globalSearchObjectFilters",
      "globalSearchStringFilters",
      "globalSearchDateFilters",
      "globalSearchBooleanFilters",
    ]),
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler() {
        this.updateFiltersFromUrl();
      },
    },
  },
  methods: {
    updateFiltersFromUrl() {
      // Clear filters that were removed
      for (const [key, value] of Object.entries(this.globalSearchObjectFilters)) {
        if (value?.length && !this.$route.query[key]) {
          this.globalSearchObjectFilters[key] = [];
        }
      }

      for (const [key, value] of Object.entries(this.globalSearchStringFilters)) {
        if (value && !this.$route.query[key]) {
          this.globalSearchStringFilters[key] = "";
        }
      }

      for (const [key, value] of Object.entries(this.globalSearchDateFilters)) {
        if (value && !this.$route.query[key]) {
          this.globalSearchDateFilters[key] = "";
        }
      }

      for (const [key, value] of Object.entries(this.globalSearchBooleanFilters)) {
        if (value && !this.$route.query[key]) {
          this.globalSearchBooleanFilters[key] = null;
        }
      }

      // Update filters from URL only if they are not already there
      let parsed = null;
      this.$forEach(this.$route.query, (key, value) => {
        if (Object.keys(this.globalSearchObjectFilters).includes(key)) {
          if (key === "tag") {
            // TODO: Backfill all tags to replace spaces with "-" and then remove this
            parsed = decodeURI(value.toString()).split(FILTER_DELIMITER);
          } else {
            parsed = value.toString().split(FILTER_DELIMITER).map(id => /^\d+$/.test(id) ? Number(id) : id);
          }
          if (this.globalSearchObjectFilters[key].join(FILTER_DELIMITER) != parsed) {
            this.globalSearchObjectFilters[key] = parsed
            logEvent("advance-search", { key, value: parsed, context: this.$route.name });
          }
        } else if (Object.keys(this.globalSearchStringFilters).includes(key)) {
          if (this.globalSearchStringFilters[key] != value) {
            this.globalSearchStringFilters[key] = value;
            logEvent("advance-search", { key, value, context: this.$route.name });
          }
        } else if (Object.keys(this.globalSearchDateFilters).includes(key)) {
          if (this.globalSearchDateFilters[key] != value) {
            this.globalSearchDateFilters[key] = value;
            logEvent("advance-search", { key, value, context: this.$route.name });
          }
        } else if (Object.keys(this.globalSearchBooleanFilters).includes(key)) {
          parsed = value === "true" || value === true;
          if (this.globalSearchBooleanFilters[key] != parsed) {
            this.globalSearchBooleanFilters[key] = parsed;
            logEvent("advance-search", { key, parsed, context: this.$route.name });
          }
        }
      });

      this.$bus.$emit("global-search-triggered");
    },
  },
};
</script>
