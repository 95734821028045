import TopBar from "../TopBar/Component";
import ContinueOnDesktop from "../ContinueOnDesktop/Component";

export default {
  name: "LayoutSimple",
  components: {
    TopBar,
    ContinueOnDesktop,
  },
};
