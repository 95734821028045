import { render, staticRenderFns } from "./ChatbotTranscriptModal.vue?vue&type=template&id=ba361e08&scoped=true"
import script from "./ChatbotTranscriptModal.vue?vue&type=script&setup=true&lang=js"
export * from "./ChatbotTranscriptModal.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ChatbotTranscriptModal.vue?vue&type=style&index=0&id=ba361e08&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba361e08",
  null
  
)

export default component.exports