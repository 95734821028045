import { mapGetters, mapActions, mapMutations } from "vuex";

import Navigation from "@/mixins/Navigation";
import NewCampaignModalLoader from "@/components/Loaders/NewCampaignModalLoader";
import UserSendAsPermissionRequestModal from "@/components/UserSendAsPermissionRequestModal/Component";
import { TITLE_REGEX } from "@/constants";
import JobAndCampaignSelector from "@/components/JobAndCampaignSelector/JobAndCampaignSelector";

export default {
  name: "CopyCampaignModal",
  mixins: [Navigation],
  components: {
    JobAndCampaignSelector,
    NewCampaignModalLoader,
    UserSendAsPermissionRequestModal,
  },
  props: {
    isFirstMessage: Boolean,
  },
  data() {
    const validateNameForNotAllowedCharacters = (rule, value, callback) => {
      if (!!value && !value?.match(TITLE_REGEX)) {
        callback(new Error("\" < > not allowed "));
      } else {
        callback();
      }
    };

    return {
      isLoadingNewCampaign: false,
      isLoadingAtsStages: false,
      isSaving: false,
      requestSendAsTeammate: null,
      requestingSendAsTeammate: null,
      rules: {
        name: [
          { required: true, message: "Please input a campaign name", trigger: "blur" },
          { validator: validateNameForNotAllowedCharacters, trigger: "blur" },
        ],
        projectId: [
          { required: true, message: "Please choose a job", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      campaign: "newCampaign",
      currentCampaign: "currentCampaign",
      currentProject: "currentProject",
      currentUser: "currentUser",
      currentTeam: "currentTeam",
      wasSourceableProjectListFetched: "wasSourceableProjectListFetched",
      sourceableProjectList: "sourceableProjectList",
      isValidForFirstMessage: "isNewCampaignValidForFirstMessage",
    }),
    isSaveDisabled() {
      return this.isSaving || !this.isValidForFirstMessage;
    },
    isUserSendAsPermissionRequestModalVisible() {
      return this.requestSendAsTeammate && this.$route.query["send-as-teammate"] != null;
    },
    show() {
      return this.$route.query.modal === "new-campaign" && !!this.$route.query.parentCampaignId;
    },
    replyHandlingTooltip() {
      return "When a different reply handler is chosen, replies won't appear in the sender's inbox. Teamable will send the handler a notification, and they may respond on behalf of the sender from the app.";
    },
    sandAsTooltip() {
      return "We typically see a 2x return when candidates receive outreach from VPs, executives or hiring managers for the role. You can also choose someone else to reply on their behalf so they don't have to take any action."
    },
    sender: {
      get() {
        return this.campaign.teammateSenderOptions?.find(item => item.id == this.campaign.pseudoSenderId);
      },
      set(teammate) {
        const teammateId = parseInt(teammate.id?.replace("/drafts", ""));

        if (!teammate.canSendAs) {
          const query = Object.assign({}, this.$route.query);
          query["send-as-teammate"] = teammateId;
          this.requestSendAsTeammate = teammate;
          this.$router.push({ query });
          return;
        }

        this.campaign.pseudoSenderId = teammate.id;
        this.campaign.senderId = teammateId;
        this.campaign.replyHandlerId = teammateId;
      },
    },
    replyHandler: {
      get() {
        return this.campaign.teammateReplyHandlerOptions?.find(item => item.id === this.campaign.replyHandlerId);
      },
      set(teammate) {
        if (!teammate.canSendAs && this.campaign.senderId !== this.currentUser.id) {
          const query = Object.assign({}, this.$route.query);
          query["send-as-teammate"] = teammate.id;
          this.requestSendAsTeammate = this.sender;
          this.requestingSendAsTeammate = teammate;
          this.$router.push({ query });
          return;
        }

        this.campaign.replyHandlerId = teammate.id;
      },
    },
    campaignProjectId: {
      get() {
        const query = Object.assign({}, this.$route.query);

        if (this.wasSourceableProjectListFetched && query.projectId) {
          const id = +query.projectId;

          delete query.projectId;
          this.$router.push({ query });
          this.campaign.projectId = id;
        }

        return this.campaign.projectId;
      },
      set(id) {
        this.campaign.projectId = id;
      },
    },
  },
  methods: {
    ...mapMutations([
      "setNewCampaign",
    ]),
    ...mapActions([
      "fetchCampaignForCopy",
      "fetchNewCampaign",
      "fetchSourceableProjectsList",
    ]),
    onProjectChange(newProject) {
      this.campaignProjectId = newProject.id;
    },
    async initNewCampaignOptions() {
      this.isLoadingNewCampaign = true;

      if (!this.wasSourceableProjectListFetched) {
        await this.fetchSourceableProjectsList();
      }

      const params = {
        parentCampaignId: this.$route.query.parentCampaignId,
        currentUserId: this.currentUser.id,
      }
      await this.fetchCampaignForCopy(params);
      this.isLoadingNewCampaign = false;

      if (this.sourceableProjectList.some(project => project.id == this.currentProject.id)) {
        this.campaign.projectId = this.currentProject.id;
      }
    },
    modalClosed() {
      if (this.show) {
        this.setNewCampaign(null);

        const query = Object.assign({}, this.$route.query);
        delete query.modal;
        delete query.parentCampaignId;
        this.$router.replace({ query: query });
      }
    },
    nextStepToCreateCampaign() {
      this.$refs["newCampaignForm"].validate((valid) => {
        if (!valid || !this.isValidForFirstMessage) {
          return false;
        }

        if (this.$route.query.modal === "new-campaign") {
          const query = Object.assign({}, this.$route.query);
          delete query.parentCampaignId;
          query.modal = "new-campaign-first-message";
          this.$router.replace({ query });
        }
      });
    },
  },
};
