import HTTPClient from "@/common/http/HttpClient";
import { MAIN_APP_URL } from "@/common/Env";

class ApiClientOldDomain extends HTTPClient {
  static get baseURL() {
    return MAIN_APP_URL.replace("teamable.com", "topfunnel.co");
  }
}

export default ApiClientOldDomain;
