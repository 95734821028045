import HTTPClient from "@/common/http/HttpClient";
import { MAIN_APP_URL } from "@/common/Env";

class ApiClient extends HTTPClient {
  static get baseURL() {
    return MAIN_APP_URL + "/api";
  }
}

export default ApiClient;
