<script setup>
import ProfilesApi from "@/common/http/ProfilesApi";
import logEvent from "@/common/Analytics";
import { useRoot } from "@/composables/misc/useRoot";
import { useGetters } from "vuex-composition-helpers";

const root = useRoot();
const { undoReviewData } = useGetters(["undoReviewData"]);

const undoCandidateReview = async () => {
  logEvent("candidate-review", { action: "undo", decision: undoReviewData.value.decision });
  await ProfilesApi.undoReview(undoReviewData.value.id);
  clearTimeout(undoReviewData.value.timeoutId);
  root.$bus.$emit("candidate-review-undo");
}
</script>

<template>
<div v-if="undoReviewData" class="candidate-review-undo">
  <div class="h-100 d-flex justify-content-between align-items-center">
    <span class="candidate-name">{{ undoReviewData.candidate.name }}</span>

    <el-button class="icon-only undo-button" type="text" @click="undoCandidateReview">
      <span class="material-icons-round">restart_alt</span>
      <span>Undo {{ undoReviewData.decision }}</span>
    </el-button>
  </div>

  <div
    :key="undoReviewData.id"
    class="progress"
    :class="{ active: !!undoReviewData }"
  >
  </div>
</div>
</template>

<style scoped lang="scss">
.candidate-review-undo {
  width: 400px;
  height: 48px;
  padding: 8px 16px;
  border-radius: $tf-global-border-radius;
  background: $beige-30;
  border: 1px solid $beige-100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 20px;
  left: calc(100vw / 2 - 200px);
  z-index: 9999;

  .progress {
    width: 100%;
    height: 5px;
    background: $blue-100;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 $tf-global-border-radius $tf-global-border-radius;

    &.active {
      animation: progressAnimation 5s; // Should keep in sync with TeamProfileReviewService::REVIEW_UNDO_TIME
    }
  }

  @keyframes progressAnimation {
    from { width: 100% };
    to { width: 0 };
  }
}
</style>
