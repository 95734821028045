import moment from "moment-timezone";

class DateTimeHelper {
  static exactDateTimeInTimezone(dateTime) {
    if (!dateTime) {
      return null;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(dateTime).tz(timezone).format("MMMM Do YYYY, h:mm:ss a z");
  }

  static relativeTimeFromNow(dateTime) {
    if (!dateTime) {
      return null;
    }
    return moment(dateTime).fromNow();
  }

  static dateIsTodayOrInFuture(dateTime) {
    return moment(dateTime).isSameOrAfter();
  }

  static MMMDoYYYY(dateTime) {
    return moment(String(dateTime)).format("MMM Do, YYYY");
  }

  static MMMDDYYYY(dateTime) {
    return moment(String(dateTime)).format("MMM DD YYYY");
  }

  static MMDDYYYYWithHyphens(dateTime) {
    return moment(String(dateTime)).format("MM-DD-YYYY");
  }

  static format(dateTime, format) {
    if (dateTime) {
      return moment(String(dateTime)).format(format);
    }
    return null;
  }

  static dateDaysAgoInThePast(daysAgo) {
    const today = new Date();
    return new Date(new Date().setDate(today.getDate() - daysAgo));
  }
}

export default DateTimeHelper;
