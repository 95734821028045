import Vue from "vue";
import Vuex from "vuex";

import App from "./modules/AppStore";
import Campaign from "./modules/CampaignStore";
import OutboundCampaign from "./modules/OutboundCampaignStore";
import Candidate from "./modules/CandidateStore";
import Project from "./modules/ProjectStore";
import Review from "./modules/ReviewStore";
import Subscription from "./modules/SubscriptionStore";
import User from "./modules/UserStore";
import InAppSourcing from "./modules/InAppSourcingStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    App,
    Campaign,
    OutboundCampaign,
    Candidate,
    Project,
    Review,
    Subscription,
    User,
    InAppSourcing,
  },
});
