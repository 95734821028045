import { render, staticRenderFns } from "./CampaignListItemSearchPopover.html?vue&type=template&id=3648c845&scoped=true&external"
import script from "./CampaignListItemSearchPopover.js?vue&type=script&lang=js&external"
export * from "./CampaignListItemSearchPopover.js?vue&type=script&lang=js&external"
import style0 from "./CampaignListItemSearchPopover.scss?vue&type=style&index=0&id=3648c845&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3648c845",
  null
  
)

export default component.exports