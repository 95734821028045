<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  tooltip: {
    type: String,
    default: null,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  imageUrl: {
    type: String,
    default: "",
  },
  fullName: {
    type: String,
    default: "",
  },
  hideNameLabel: {
    type: Boolean,
    default: false,
  },
  isTopTalent: {
    type: Boolean,
    default: false,
  },
  topTalentTooltip: {
    type: String,
    default: "",
  },
  watched: {
    type: Boolean,
    default: false,
  },
  highIntent: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  email: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "small",
    validator: function (value) {
      return ["mini", "small", "medium", "large", "extra-large"].includes(value);
    },
  },
  customStyle: {
    type: String,
    default: "",
  },
});

const avatarUrlError = ref(false);

const previewSrcList = computed(() => {
  if (!props.preview) {
    return [];
  }
  return [props.imageUrl];
});

const initials = computed(() => {
  if (!props.fullName?.trim() || props.fullName === "Name Unknown") {
    return "?";
  }

  return props.fullName
    .split(" ")
    .map((n) => n[0])
    .join("")
    .substring(0, 2);
});

function avatarImageError() {
  avatarUrlError.value = true;
}

</script>

<template>
  <div id="avatar-container" class="d-flex align-items-center">
    <el-tooltip
      :open-delay="500"
      :content="tooltip"
      :disabled="!tooltip"
    >
      <div class="d-flex align-items-center">
        <!-- Avatar -->
        <el-skeleton :loading="loading" animated>
          <div slot="template" class="avatar-wrapper d-flex justify-content-center align-items-center">
            <el-skeleton-item
              class="avatar"
              :class="size"
              variant="circle"
            />
          </div>

          <div class="avatar-wrapper relative" :class="size">
            <el-image
              v-if="imageUrl && !avatarUrlError"
              class="avatar"
              fit="cover"
              :class="size"
              :src="imageUrl"
              :preview-src-list="previewSrcList"
              :style="customStyle"
              @error="avatarImageError"
            />
            <div v-else class="avatar initials" :class="size" :style="customStyle">
              <div>{{ initials }}</div>
            </div>

            <div v-if="isTopTalent || watched" class="top-talent-and-watched" :class="size">
              <el-tooltip :content="topTalentTooltip">
                <span v-if="isTopTalent" class="material-icons-round">star</span>
              </el-tooltip>

              <el-tooltip content="Following">
                <span v-if="watched" class="material-icons-round watched">visibility</span>
              </el-tooltip>
            </div>

            <div v-if="highIntent" class="high-intent-arrow">
              <i class="material-icons-round arrow">arrow_circle_up</i>
            </div>
          </div>
        </el-skeleton>

        <!-- Name -->
        <el-skeleton v-if="!hideNameLabel" :loading="loading" animated>
          <div slot="template" class="d-flex justify-content-center align-items-center">
            <el-skeleton-item class="full-name-loader" variant="text" />
          </div>

          <span class="full-name" :class="size">
            {{ fullName }}
            <span v-if="email" class="email">
              {{ email }}
            </span>
          </span>
        </el-skeleton>
      </div>
    </el-tooltip>
  </div>
</template>

<style lang="scss" scoped>
$textSizeSmall: 14px;
$textSizeMedium: 18px;
$textSizeLarge: 22px;

.selected > #avatar-container {
  background-color: $tf-table-row-background-color-selected;

  &:hover {
    background-color: $tf-table-row-background-color-hover;
  }
}

#avatar-container {
  .avatar-wrapper {
    height: $tf-icon-size;
    cursor: pointer;

    &.mini {
      height: 16px;
      line-height: 16px;
    }

    &.medium {
      height: $tf-icon-size-large;
    }
    &.large {
      height: $tf-icon-size-extra-large;
    }
    &.extra-large {
      height: 80px;
    }

    .avatar {
      height: $tf-icon-size;
      width: $tf-icon-size;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 100%;
      background-color: $magenta-10;
      color: $magenta-100;

      img {
        border-radius: 100%;
      }

      &.initials {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $textSizeSmall;
        font-weight: 500;
      }

      &.mini {
        height: 16px;
        width: 16px;
        font-size: 10px;
      }

      &.medium {
        height: $tf-icon-size-large;
        width: $tf-icon-size-large;
        font-size: $textSizeMedium;
      }

      &.large {
        height: $tf-icon-size-extra-large;
        width: $tf-icon-size-extra-large;
        font-size: $textSizeLarge;
      }

      &.extra-large {
        height: 80px;
        width: 80px;
        font-size: 26px;
      }
    }

    .top-talent-and-watched {
      position: absolute;
      bottom: 0;
      right: 0;
      text-align: center;
      border-radius: 8px;
      padding: 2px;
      color: #FB8731;
      background-color: $tf-app-background-color;
      border: 1px solid $beige-50;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 2px;

      &.extra-large {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;

        >.material-icons-round {
          font-size: 16px;
        }
      }
      &.large {
        min-width: 16px;
        height: 16px;
        line-height: 16px;
        >.material-icons-round {
          font-size: 12px;
        }
      }
      &.medium {
        min-width: 16px;
        height: 16px;
        line-height: 16px;
        >.material-icons-round {
          font-size: 12px;
        }
      }
      &.small {
        min-width: 12px;
        height: 12px;
        line-height: 12px;
        >.material-icons-round {
          font-size: 10px;
        }
      }
    }

    .watched {
      color: $spring-green-100;
    }

    .high-intent-arrow {
      color: #663399;
      background-color: #EFEAF4;
      width: 20px;
      height: 20px;
      border: 0.5px solid white;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;

      i {
        font-size: 20px;
      }

      .arrow {
        font-size: 16px;
        color: #663399;
      }
    }
  }

  .email {
    color: $tf-font-ghost;
    font-size: $tf-font-size-default;
  }

  .full-name {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    color: $tf-font-color-regular;
    font-size: $tf-font-size-default;

    &.mini {
      font-size: calc(#{$tf-font-size-default} - 2px);
    }

    &.medium {
      font-size: calc(#{$tf-font-size-default} + 2px);
    }
    &.large {
      font-size: calc(#{$tf-font-size-default} + 4px);
    }
    &.extra-large {
      font-size: calc(#{$tf-font-size-default} + 8px);
    }
  }

  .full-name-loader {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    width: 60px;
    height: 12px;
  }
}
</style>
