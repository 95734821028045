import { mapActions } from "vuex";
import logEvent from "@/common/Analytics";

export default {
  name: "CandidateRemindModal",
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    bulkSelectionAttributes: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      remindForm: {
        when: null,
        chosenDate: null,
        reason: null,
      },
      reasons: [
        "Candidate requested to contact them later",
        "Save for future job openings",
        "More time in the industry is needed",
        "More time in the current role is needed",
      ],
      datePickerOptions: {
        disabledDate (date) {
          return date < new Date()
        },
      },
      rules: {
        reason: [
          { required: true, message: "Please enter a reason", trigger: "blur" },
        ],
        when: [
          { required: true, message: "Please select an option", trigger: "blur" },
        ],
      },
    }
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit("update:visible", isVisible);
      },
    },
    title() {
      if (this.candidate) {
        return `Remind me about ${this.candidate.name} in:`;
      } else if (this.bulkSelectionAttributes) {
        const { count } = this.bulkSelectionAttributes;
        const candidates = count === 1 ? "this one candidate" : `these ${count} candidates`;
        return `Remind me about ${candidates} in:`
      }

      return null;
    },
    isDisabled() {
      const whenToRemindNotSpecified = this.remindForm.when === "Choose a date" && this.remindForm.chosenDate === null;
      const reasonNotGiven = this.remindForm.reason === null || this.remindForm.reason === "";
      return this.remindForm.when === null || whenToRemindNotSpecified || reasonNotGiven;
    },
    remindAt() {
      const today = new Date();
      if (this.remindForm.when === "1 week") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      } else if (this.remindForm.when === "1 month") {
        return new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
      } else if (this.remindForm.when === "3 months") {
        return new Date(today.getFullYear(), today.getMonth() + 3, today.getDate());
      } else {
        return this.remindForm.chosenDate;
      }
    },
  },
  methods: {
    ...mapActions([
      "addCandidateReminder",
    ]),
    onModalOpened() {
      logEvent("candidate-action-intent", { action: "remind-me-later" });
    },
    onModalClose() {
      this.$refs.remindForm.resetFields();
    },
    createReminder() {
      this.addCandidateReminder({
        candidate: this.candidate,
        bulkSelectionAttributes: this.bulkSelectionAttributes,
        remindAt: this.remindAt,
        reason: this.remindForm.reason,
      }).then(() => {
        this.$notify({
          position: "bottom-right",
          message: "Reminder set",
          title: "Success",
          type: "success",
        });

        this.innerVisible = false;
      })
    },
    submit() {
      this.$refs.remindForm.validate((valid) => {
        if (valid) {
          this.createReminder();
        } else {
          return false;
        }
      });
    },
  },
};
