import { mapGetters } from "vuex";
import _ from "lodash";

import IntroPack from "./components/IntroPack";
import ProjectProfile from "./components/ProjectProfile";
import AtsCandidate from "./components/AtsCandidate";
import Task from "./components/Task";
import NoResults from "@/components/NoResults";
import RouteContext from "@/common/RouteContext";
import ThreadRequiresUserAttentionIcon from "@/components/ThreadRequiresUserAttentionIcon";
import UpdateStatusButton from "@/components/candidates/UpdateStatusButton/UpdateStatusButton";
import CandidateStatusDiagram from "@/components/CandidateStatusDiagram/Component";

export default {
  name: "CandidateSidebarOutreachTab",
  components: {
    AtsCandidate,
    CandidateStatusDiagram,
    IntroPack,
    NoResults,
    ProjectProfile,
    Task,
    ThreadRequiresUserAttentionIcon,
    UpdateStatusButton,
  },
  data() {
    return {
      currentTouchpoint: null,
      routeContext: new RouteContext(this.$route.name),
    };
  },
  computed: {
    ...mapGetters([
      "currentCandidate",
      "currentUser",
      "isLoadingCurrentCandidateHistory",
    ]),
    showHistory() {
      return !this.isLoadingCurrentCandidateHistory && this.touchpointsList.length > 0;
    },
    touchpointsList() {
      if (this.isLoadingCurrentCandidateHistory) {
        return [];
      }

      const list = _.concat(
        this.currentCandidate.introPacks,
        this.currentCandidate.tasks,
        this.currentCandidate.savedForLaters,
        this.currentCandidate.atsCandidates
      );

      return _.compact(list);
    },
    isTouchpointTask() {
      return this.currentTouchpoint?.touchpointType === "Task";
    },
    isTouchpointIntroPack() {
      return this.currentTouchpoint?.touchpointType === "IntroPack";
    },
    isTouchpointAtsCandidate() {
      return this.currentTouchpoint?.touchpointType === "AtsCandidate";
    },
    isTouchpointProjectProfile() {
      return this.currentTouchpoint?.touchpointType === "ProjectProfile";
    },
  },
  watch: {
    isLoadingCurrentCandidateHistory: {
      immediate: true,
      handler(isLoading) {
        if (isLoading || (!isLoading && !this.touchpointsList.length)) {
          return;
        }

        let touchpoint = null;
        if (this.$route.query.outreachId) {
          touchpoint = this.currentCandidate.tasks.find(t => t.id.toString() === this.$route.query.outreachId);
        } else if (this.$route.query.referral) {
          touchpoint = this.currentCandidate.introPacks.find(ip => ip.id.toString() === this.$route.query.referral);
        } else if (this.$route.query.sflId) {
          touchpoint = this.currentCandidate.savedForLaters.find(sfl => sfl.id.toString() === this.$route.query.sflId);
        } else if (this.$route.query.acId) {
          touchpoint = this.currentCandidate.atsCandidates.find(ac => ac.id.toString() === this.$route.query.acId);
        } else {
          touchpoint = this.touchpointsList[0];
        }

        this.selectTouchpoint(touchpoint);
      },
    },
    "$route.query.outreachId"() {
      if (this.$route.query.outreachId) {
        const task = this.currentCandidate.tasks?.find(t => t.id.toString() === this.$route.query.outreachId);
        if (task) {
          this.currentTouchpoint = task;
        }
      }
    },
    "$route.query.acId"() {
      if (this.$route.query.acId) {
        const atsCandidate = this.currentCandidate.atsCandidates?.find(t => t.id.toString() === this.$route.query.acId);
        if (atsCandidate) {
          this.currentTouchpoint = atsCandidate;
        }
      }
    },
    "$route.query.referral"() {
      if (this.$route.query.referral) {
        const ip = this.currentCandidate.introPacks.find(t => t.id.toString() === this.$route.query.referral);
        if (ip) {
          this.currentTouchpoint = ip;
        }
      }
    },
    "$route.query.sflId"() {
      if (this.$route.query.sflId) {
        const sfl = this.currentCandidate.savedForLaters.find(t => t.id.toString() === this.$route.query.sflId);
        if (sfl) {
          this.currentTouchpoint = sfl;
        }
      }
    },
  },
  methods: {
    closeTouchpoint() {
      this.selectTouchpoint(null);
    },
    viewTouchpoint({ id , type }) {
      let tp = null;

      if (type === "IntroPack") {
        tp = this.currentCandidate.introPacks.find(t => t.id === id);
      } else if (type === "Task") {
        tp = this.currentCandidate.tasks.find(t => t.id === id);
      } else if (type === "ProjectProfile") {
        tp = this.currentCandidate.savedForLaters.find(t => t.id === id);
      } else if (type === "AtsCandidate") {
        tp = this.currentCandidate.atsCandidates.find(t => t.id === id);
      }

      this.selectTouchpoint(tp);
    },
    campaignNameLabel(touchpoint) {
      if (touchpoint.touchpointType === "Task") {
        return `${touchpoint.projectName} - ${touchpoint.campaignName}`;
      } else if (touchpoint.touchpointType === "IntroPack") {
        return `${touchpoint.projectName} - Referrals`;
      }
    },
    touchpointStatusKey(touchpoint) {
      if (touchpoint.activity.isNegative && touchpoint.activity.statusKey === "sourced") {
        return "sourced-stopped";
      }
      return touchpoint.activity.statusKey;
    },
    touchpointStatusLabel(touchpoint) {
      let status = touchpoint.activity.name;
      if (touchpoint.activity.isNegative) {
        if (status === "Sourced") {
          status = "Stopped";
        }
      }
      if (touchpoint.activity.wasOverridden) {
        status = `${status}*`;
      }

      return status;
    },
    selectTouchpoint(touchpoint) {
      let query = Object.assign({}, this.$route.query);

      delete query.referral;
      delete query.outreachId;
      delete query.sflId;
      delete query.acId;

      if (touchpoint?.touchpointType === "Task") {
        if (this.$route.query.outreachId !== touchpoint.id.toString()) {
          query.outreachId = touchpoint.id;
        }
      } else if (touchpoint?.touchpointType === "IntroPack") {
        if (this.$route.query.referral !== touchpoint.id.toString()) {
          query.referral = touchpoint.id;
        }
      } else if (touchpoint?.touchpointType === "ProjectProfile") {
        if (this.$route.query.sflId !== touchpoint.id.toString()) {
          query.sflId = touchpoint.id;
        }
      } else if (touchpoint?.touchpointType === "AtsCandidate") {
        if (this.$route.query.acId !== touchpoint.id.toString()) {
          query.acId = touchpoint.id;
        }
      }
      this.$router.replace({ query });
      this.currentTouchpoint = touchpoint;
    },
  },
};
