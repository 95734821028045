import SplashContainer from "@/components/SplashContainer/Component";

export default {
  name: "ContinueOnDesktop",
  components: {
    SplashContainer,
  },
  computed: {
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
  },
  setup() {
    return {};
  },
};
