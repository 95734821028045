<script setup>
import { computed, onMounted, watch, ref } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";

import CampaignsApi from "@/common/http/CampaignsApi";

import MessageEditorWrapper from "@/components/MessageGeneration/MessageEditorWrapper/MessageEditorWrapper";
import MessageGenerationButtons from "@/components/MessageGeneration/MessageGenerationButtons/MessageGenerationButtons";

const props = defineProps({
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
});

const {
  inAppSourcingMessage,
  inAppSourcingSelectedCampaign,
  selectedCandidateInAppSourcing,
  inAppSourcingSelectedProject,
  bulkSelectionAttributes,
} = useGetters([
  "inAppSourcingMessage",
  "inAppSourcingSelectedCampaign",
  "selectedCandidateInAppSourcing",
  "inAppSourcingSelectedProject",
  "bulkSelectionAttributes",
]);

const {
  setInAppSourcingMessage,
} = useMutations([
  "setInAppSourcingMessage",
]);

const route = useRoute();
const editorKeyVariable = ref(0);
const isCampaignFirstMessageGenerating = ref(false);
const isLoadingPreview = ref(false);

const isFirstMessagePersonalized = computed(() => {
  return inAppSourcingMessage.value.firstMessage.isPersonalized;
});

function messageChangeHandler(val) {
  inAppSourcingMessage.value.firstMessage = { ...val, isPersonalized: isFirstMessagePersonalized.value };
}

function responseHandler(message) {
  isCampaignFirstMessageGenerating.value = false;
  setInAppSourcingMessage({
    ...inAppSourcingMessage.value,
    firstMessage: message,
  });
  //when we are generating message, and providing it to editor, the editor is not reacting to that updated message and is showing the old message.
  //by updating :key after message generation, we kind of pushing the editor to update/re-render its value
  ++editorKeyVariable.value;
}

async function stopGeneratingHandler() {
  isCampaignFirstMessageGenerating.value = false;
  CampaignsApi.personalizeFirstMessage({
    cancelRequest: true,
  });
}

async function generateNew() {
  isCampaignFirstMessageGenerating.value = true;

  try {
    const { data } = await CampaignsApi.personalizeFirstMessage({
      projectId: inAppSourcingSelectedCampaign.value.projectId,
      senderId: inAppSourcingMessage.value.senderId,
      profileId: selectedCandidateInAppSourcing.value?.profileId,
      tfId: selectedCandidateInAppSourcing.value?.tfId,
      playbookId: inAppSourcingSelectedCampaign.value.playbookId,
    });

    responseHandler({ ...data.message, isPersonalized: data.isPersonalized });
  } catch (e) {
    failureHandler(e);
  }
}

async function generateNewForBulk() {
  isCampaignFirstMessageGenerating.value = true;
  try {
    const { data } = await CampaignsApi.generateFirstMessage(inAppSourcingSelectedCampaign.value.id);
    responseHandler(data.message);
  } catch (e) {
    failureHandler(e);
  }
}

function failureHandler(e) {
  if (e.status !== undefined) {
    stopGeneratingHandler();
  } else {
    isCampaignFirstMessageGenerating.value = false;
  }
}

function generateNewHandler() {
  bulkSelectionAttributes.value ? generateNewForBulk() : generateNew();
}

async function getPreviewContent() {
  if (bulkSelectionAttributes.value || isFirstMessagePersonalized.value !== undefined || props.messagingModeOnly) return;

  isLoadingPreview.value = true;

  const params = {
    message: inAppSourcingMessage.value.firstMessage.body,
    subject: inAppSourcingMessage.value.firstMessage.subject,
    senderId: inAppSourcingMessage.value?.senderId,
    campaignId: inAppSourcingSelectedCampaign.value?.id,
    projectId: route.params.projectId || inAppSourcingSelectedProject.value?.id,
    referrals: false,
    profileId: selectedCandidateInAppSourcing.value?.profileId,
    tfId: selectedCandidateInAppSourcing.value?.tfId,
    playbookId: inAppSourcingSelectedCampaign.value?.playbookId,
  };
  const { data } = await CampaignsApi.previewMessage(params);

  if (data.isPersonalized) {
    responseHandler({
      ...inAppSourcingMessage.value.firstMessage,
      subject: data.subject,
      body: data.message,
      isPersonalized: data.isPersonalized,
    })
  } else {
    inAppSourcingMessage.value.firstMessage.isPersonalized = data.isPersonalized;
  }
  isLoadingPreview.value = false;
}

watch(inAppSourcingSelectedCampaign, (newValue) => {
  if (newValue) {
    getPreviewContent();
  }
});

onMounted(() => {
  getPreviewContent();
});

</script>

<template>
  <div id="in-app-sourcing-first-message">
    <div v-if="inAppSourcingSelectedCampaign" class="card-container relative">
      <MessageEditorWrapper
        :key="inAppSourcingSelectedCampaign.id + editorKeyVariable"
        type="firstMessage"
        :loading="isCampaignFirstMessageGenerating || isLoadingPreview"
        :show-variables="!isFirstMessagePersonalized"
        :show-preview="!isFirstMessagePersonalized"
        @message-change="messageChangeHandler"
      />

      <MessageGenerationButtons
        class="first-message-generation-button"
        :local-message-generating="isCampaignFirstMessageGenerating"
        @generate-new="generateNewHandler"
        @stop-generating="stopGeneratingHandler"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
#in-app-sourcing-first-message {
  width: 100%;

  .first-message-generation-button {
    position: absolute;
    top: 12px;
    right: 16px;
  }
  ::v-deep #message-editor .quillWrapper {
    max-height: none !important;
  }
}
</style>
