export function cleanUpEmployments(employments) {
  if (!employments?.length) return [];

  const currentEmployments = employments;
  const newEmployments = [];

  for (let index = 0; index < currentEmployments.length; index++) {
    const nextEmployment = currentEmployments[index + 1];
    const currentEmployment = currentEmployments[index];

    const sameRole = currentEmployment.role === nextEmployment?.role;
    const sameCompanyName = currentEmployment.name === nextEmployment?.name;
    const sameStartYear = currentEmployment.startDate?.year === nextEmployment?.startDate?.year;
    const sameEndYear = currentEmployment.endDate?.year === nextEmployment?.endDate?.year;

    const sameEmployment = sameRole && sameCompanyName && sameStartYear && sameEndYear;
    const sameDescription = currentEmployment.description === nextEmployment?.description;

    // If it's the same employment but different descriptions and both descriptions exist we wanna show both
    const onlyOneDescriptionExists = (!!currentEmployment.description && !nextEmployment?.description) || (!currentEmployment.description && !!nextEmployment?.description)
    if (sameEmployment && onlyOneDescriptionExists) {
      // If current employment same as the next one and only one description between both use the one with content
      currentEmployments[index + 1].description = nextEmployment.description || currentEmployment.description;

    } else if (!sameEmployment || (sameEmployment && !sameDescription)) {
      // Add to the new array if current and next employment not the same OR same employment but different description
      newEmployments.push(currentEmployment);
    }
  }

  return newEmployments;
}
