import { useMutation, useQueryClient } from "@tanstack/vue-query";
import ProjectsApi from "@/common/http/ProjectsApi";
import { onSettled } from "./onSettled";

export const mutationKey = ["create-recommendation-feedback"];

export const useCreateRecommendationFeedback = (props = {}) => {
  const queryClient = useQueryClient();

  const { mutate, ...mutationRest } = useMutation({
    mutationFn: ProjectsApi.addRecommendationFeedback,
    mutationKey,
    onSettled: onSettled({ queryClient }),
    ...props,
  });

  return {
    createRecommendationFeedback: mutate,
    ...mutationRest,
  };
}
