import { mapMutations, mapGetters } from "vuex";
import DateTimeHelper from "@/common/DateTimeHelper";
import RouteContext from "@/common/RouteContext";

export default {
  props: {
    candidate: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showBestAttributes: {
      type: Boolean,
      default: false,
    },
    displayMode: {
      type: String,
      default: "detailed",
    },
  },
  data() {
    return {
      visibleModalKey: null,
      routeContext: new RouteContext(this.$route.name),
    };
  },
  computed: {
    ...mapGetters([
      "currentCampaign",
      "currentProject",
      "isReferralsEnabled",
      "profileIdsInReview",
      "reviewData",
      "selectedCandidates",
    ]),
    profileIsInReview() {
      return this.profileIdsInReview.includes(this.candidate.profileId);
    },
    recentTouchpointStatusTooltipWithDate() {
      const { recentTouchpoint } = this.candidate;
      if (recentTouchpoint) {
        return `${recentTouchpoint.activity} - ${DateTimeHelper.MMMDDYYYY(recentTouchpoint.lastActivityAt)}`;
      }
      return null;
    },
    isAnyCandidateSelected() {
      return this.selectedCandidates.length > 0;
    },
    isRecentTouchpointIntro() {
      return this.candidate.recentTouchpoint?.type === "IntroPack";
    },
    isRecentTouchpointInbound() {
      return this.candidate.recentTouchpoint?.isInbound;
    },
    isCandidateSavedForLater() {
      const candidateIsSavedForLater = !!this.candidate.projectIdsSavedForLater?.length;
      const candidateIsSavedForLaterForCurrentProject = this.currentProject.id && this.candidate.projectIdsSavedForLater?.includes(Number(this.currentProject.id));

      return (
        (candidateIsSavedForLater && !this.candidate.recentTouchpoint) ||
        candidateIsSavedForLaterForCurrentProject
      );
    },
    isCandidateOnlyConnected() {
      return (
        !this.candidate.projectIdsSavedForLater?.length &&
        !this.candidate.recentTouchpoint &&
        (this.candidate.connectors.length || this.candidate.isConnectedToMe)
      );
    },
    selected: {
      get() {
        return this.selectedCandidates.some(c => c.tfId === this.candidate.tfId);
      },
      set(value) {
        this.$emit("selected", { candidate: this.candidate, value });
      },
    },
  },
  methods: {
    ...mapMutations([
      "setCandidateSelected",
    ]),
    showModal(key) {
      this.visibleModalKey = key;
    },
    modalClosed() {
      this.visibleModalKey = null;
    },
    viewCandidate() {
      const params = {
        candidate: this.candidate,
      };

      this.$bus.$emit("view-candidate", params);
    },
  },
};
