import { unref, computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import ProjectsApi from "@/common/http/ProjectsApi";

export const queryKey = ({ projectId, tokenType }) => (
  ["recommendation-feedback-boolean", projectId, tokenType]
);

export const useRecommendationFeedbackBoolean = ({
  projectId,
  tokenType,
  ...rest
}) => {
  const query = useQuery({
    queryKey: queryKey({ projectId, tokenType }),
    queryFn: () => ProjectsApi.getRecommendationFeedbackBoolean({
      projectId: unref(projectId),
      tokenType: unref(tokenType),
    }),
    ...rest,
  });

  const recommendationFeedbackBoolean = computed(() => (
    unref(query.data)?.data?.boolean?.text
  ))

  return {
    ...query,
    recommendationFeedbackBoolean,
  };
}
