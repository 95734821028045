import ApiClient from "@/common/http/ApiClient";

class ProfilesApi {

  // team profiles
  static search(params, allowMultiRequests = false) {
    return ApiClient.get("team_profiles", params, { allowMultiRequests }); // Searches recruiter app data
  }
  static recommendations(params, allowMultiRequests = false) {
    return ApiClient.get(`projects/${params.projectId}/recommendations`, params, { allowMultiRequests }); // Searches pensieve using similarity
  }
  static pipelineCounts(params) {
    return ApiClient.get("team_profiles/pipeline_counts", params);
  }
  static review(params) {
    return ApiClient.post("team_profile_reviews", params);
  }
  static undoReview(id) {
    return ApiClient.delete(`team_profile_reviews/${id}`);
  }
  static showProfile(params) {
    return ApiClient.get("team_profiles/profile", params);
  }
  static showRecommendation(params) {
    return ApiClient.get(`projects/${params.projectId}/recommendations/profile`, params);
  }
  static showProfileSummary(params) {
    return ApiClient.get("profile_summaries", params);
  }
  static showProfileCrm(params) {
    return ApiClient.get("team_profiles/profile_crm", params);
  }
  static showProfileOutreach(params) {
    return ApiClient.get("team_profiles/profile_outreach", params);
  }
  static tagOptions() {
    return ApiClient.get("team_profiles/tag_options");
  }
  static dedupeMessage(params) {
    if (params.profileId) {
      return ApiClient.get("team_profiles/dedupe_message", params);
    }
    return ApiClient.get(`projects/${params.projectId}/recommendations/dedupe_message`, params);
  }
  static download(params) {
    return ApiClient.put("team_profiles/download", params);
  }
  static liMessagingContent(params) {
    return ApiClient.get("team_profiles/li_messaging_content", params);
  }
  static markAsSentViaInMail(params) {
    return ApiClient.put("team_profiles/mark_as_sent_via_inmail", params);
  }
  static watchProfile(params) {
    return ApiClient.put("team_profiles/watch", params);
  }
  static updateProfileTags(params) {
    return ApiClient.put("team_profiles/update_tags", params);
  }
  static addToCampaign(params, useRecommendations) {
    if (useRecommendations) {
      return ApiClient.post(`projects/${params.recommendationsProjectId}/recommendations/add_to_campaign`, params);
    }
    return ApiClient.post("team_profiles/add_to_campaign", params);
  }
  static notes(params) {
    return ApiClient.get("team_profile_notes", params);
  }
  static createNote(params) {
    return ApiClient.post("team_profile_notes", params);
  }
  static deleteNote(id) {
    return ApiClient.delete(`team_profile_notes/${id}`);
  }
  static fetchAllForReview() {
    return ApiClient.get("team_profile_reviews");
  }
  static fetchReviewDataForProject(projectId) {
    return ApiClient.get(`team_profile_reviews/for_project/${projectId}`);
  }
  static fetchCountForReview() {
    return ApiClient.get("team_profile_reviews/count");
  }
  static createReminder(params) {
    return ApiClient.post("team_profile_reminders", params);
  }
  static deleteReminder(id) {
    return ApiClient.delete(`team_profile_reminders/${id}`);
  }
  static updateProfile(params) {
    return ApiClient.put("team_profiles/update_team_profile", params);
  }
  static deleteCandidate(params) {
    return ApiClient.put("team_profiles/delete_candidate", params);
  }
  static profileIntros(params) {
    return ApiClient.get("team_profiles/profile_intros", params);
  }

  // INTRO
  static candidateConnectors(params) {
    return ApiClient.get("connectors", params);
  }
  static saveCandidateConnectors(params) {
    return ApiClient.post("intro_packs", params);
  }
  static addIntroToIntroPack(introPackId, params) {
    return ApiClient.post(`intro_packs/${introPackId}/intros`, params);
  }
  static cancelIntro(id, params) {
    return ApiClient.post(`intros/${id}/cancel`, params);
  }
  static cancelIntroPack(id, params) {
    return ApiClient.post(`intro_packs/${id}/cancel`, params);
  }
  static forceFallbackForIntroPacks(id, params) {
    return ApiClient.post(`intro_packs/${id}/fallback`, params);
  }
}

export default ProfilesApi;
