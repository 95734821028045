export const formatEducation = (education) => {
  let str = [education.name, education.degree].filter(v => v).join(" - ");

  if (education.yearStart) {
    str = str + `, Start: ${education.yearStart}`;
  }

  if (education.yearEnd) {
    str = str + `, End: ${education.yearEnd}`;
  }

  if (education.fieldOfStudy && education.fieldOfStudy !== education.degree) {
    str = str + `: ${education.fieldOfStudy}`;
  }

  return str;
};
