import _ from "lodash";
import { ref, watch, onMounted, onUnmounted, provide } from "vue";
import { useIsMutating } from "@tanstack/vue-query";
import { useRoot } from "@/composables/misc/useRoot";
import { mutationKey as createRecommendationFeedbackMutationKey } from "@/composables/recommendationFeedbacks/useCreateRecommendationFeedback";
import { mutationKey as updateRecommendationFeedbackMutationKey } from "@/composables/recommendationFeedbacks/useUpdateRecommendationFeedback";
import { mutationKey as updateRecommendationFeedbackBooleanMutationKey } from "@/composables/recommendationFeedbackBooleans/useUpdateRecommendationFeedbackBoolean";
import { mutationKey as deleteRecommendationFeedbackMutationKey } from "@/composables/recommendationFeedbacks/useDeleteRecommendationFeedback";
import { mutationKey as deleteRecommendationFeedbacksMutationKey } from "@/composables/recommendationFeedbacks/useDeleteRecommendationFeedbacks";

const mutationKeys = [
  createRecommendationFeedbackMutationKey,
  updateRecommendationFeedbackMutationKey,
  updateRecommendationFeedbackBooleanMutationKey,
  deleteRecommendationFeedbackMutationKey,
  deleteRecommendationFeedbacksMutationKey,
];

export const useProvideIsMutatedRecommendationFeedbacks = () => {
  const root = useRoot();

  const isMutatedRecommendationFeedbacks = ref(false);

  const isMutating = useIsMutating({
    predicate: (mutation) => (
      _.some(mutationKeys, (mutationKey) => (
        _.isEqual(mutationKey, mutation.options.mutationKey)
      ))
    ),
  });

  watch([
    isMutating,
  ], (nextValue) => {
    if (!nextValue || _.isEqual(nextValue, [0])) return;

    isMutatedRecommendationFeedbacks.value = true;
  });

  const onCandidateListLoadInitialCandidates = () => {
    return isMutatedRecommendationFeedbacks.value = false;
  };

  onMounted(() => {
    root.$bus.$on("candidate-list-load-initial-candidates", onCandidateListLoadInitialCandidates);
  });

  onUnmounted(() => {
    root.$bus.$off("candidate-list-load-initial-candidates", onCandidateListLoadInitialCandidates);
  });

  provide("isMutatedRecommendationFeedbacks", isMutatedRecommendationFeedbacks);
}
