<script setup>
import { ref, watchEffect } from "vue";
import { useActions, useGetters } from "vuex-composition-helpers";
import { useRouter, useRoute } from "vue-router/composables";

import LogoSpinner from "@/components/LogoSpinner/LogoSpinner";

const route = useRoute();
const router = useRouter();
const isInCampaignPage = ref(false);

const {
  currentCampaign,
  campaignsNotificationList,
} = useGetters([
  "currentCampaign",
  "campaignsNotificationList",
]);

const {
  regenerateAllMessages,
} = useActions([
  "regenerateAllMessages",
]);

const isMessagesShown = (notification) => {
  if (notification.show) {
    const query = Object.assign({}, route.query);

    if (query.modal === "new-campaign" || query.modal === "new-project") {
      return false;
    }

    if (notification.failed) {
      return true;
    }

    if (isInCampaignPage.value) {
      return notification.id !== currentCampaign.value.id;
    }

    return true;
  }

  return false;
};

const isActionShown = (notification) => {
  if (notification.failed) {
    return true;
  }
  if (isInCampaignPage.value && notification.isGenerating) {
    return notification.id !== currentCampaign.value.id;
  }

  return false;
};

const title = (notification) => {
  if (notification.failed) {
    if (!isInCampaignPage.value) {
      return `We couldn't personalize ${notification.name} this time, but you can try again when reviewing it.`;
    }
    return 'Sorry, we couldn\'t personalize your campaign this time. Click "Generate New" to try again.';
  }

  if (notification.isGenerating) {
    return `${notification.name} is being created`;
  }

  return `${notification.name} has been created`;
}

const actionLabel = (notification) => {
  if (notification.failed && isInCampaignPage.value) {
    return "GENERATE NEW";
  }

  return "REVIEW CAMPAIGN";
}

const actionHandler = (notification) => {
  // when campaign generation fails while campaign reviewing
  if (notification.failed && isInCampaignPage.value) {
    regenerateAllMessages().then();
    notification.show = false;

    return;
  }

  // review campaign
  const query = Object.assign({}, route.query);
  router.replace({
    name: "job-campaign-root",
    params: {
      projectId: notification.projectId, campaignId: notification.id,
    },
    query: { ...query, isReviewingCampaign: true },
  });
}

const closeClickHandler = (notification) => notification.show = false;

watchEffect(() => {
  isInCampaignPage.value = route.name === "job-campaign-root";
})

</script>

<template>
 <div class="notification-container">
   <div
     v-for="(notification, index) in campaignsNotificationList"
     :key="index"
   >
     <div
       v-if="isMessagesShown(notification)"
       class="notification-wrapper"
       :class="{ 'in-progress': notification.isGenerating, 'error': notification.failed }"
     >
       <div class="h-100 d-flex justify-content-between align-items-center">
         <div class="d-flex align-items-center justify-content-between w-100">
           <div class="d-flex align-items-center">
             <span v-if="notification.failed" class="material-icons-round text-color-orange-100 md-14">error</span>
             <span v-else-if="!notification.isGenerating" class="material-icons-round text-color-tiffany-blue-500 md-14">check_circle</span>
             <div v-else class="spinner-container">
               <LogoSpinner />
             </div>
             <span class="candidate-name ml-8">{{ title(notification) }}</span>
           </div>

           <el-button
             v-if="isActionShown"
             class="review-button"
             type="text"
             size="small"
             @click="actionHandler(notification)"
           >
             <span>{{actionLabel(notification)}}</span>
           </el-button>
         </div>

         <el-button class="review-button ml-16" type="text" size="small" @click="closeClickHandler(notification)">
           <div class="material-icons-round md-14">close</div>
         </el-button>
       </div>
     </div>
   </div>
 </div>
</template>

<style scoped lang="scss">
.notification-container {
  width: 600px;
  position: fixed;
  bottom: 20px;
  left: calc(100vw / 2 - 250px);
  z-index: 9999;
  font-size: 12px;

  .notification-wrapper {
    width: 600px;
    min-height: 30px;
    padding: 4px 16px;
    background: $tiffany-blue-10;
    border-bottom: 2px solid $tiffany-blue-500;
    color: #000;
    z-index: 9999;
    margin-top: 8px;

    &.in-progress {
      background: $blue-10;
      border-bottom: 2px solid $blue-100;
    }
    &.error {
      width: 750px;
      left: calc(100vw / 2 - 325px);
      background: $orange-10;
      border-bottom: 2px solid $orange-100;
    }
  }

  .spinner-container {
    max-width: 18px;
  }
}
</style>
