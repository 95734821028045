import { mapGetters } from "vuex";
import moment from "moment";
import UsersApi from "@/common/http/UsersApi";

export default {
  name: "UserSendAsPermissionRequestModal",
  components: {},
  props: {
    show: Boolean,
    sendAsTeammate: {
      type: Object,
      default: null,
    },
    replyHandlingTeammate: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
    title() {
      if (this.replyHandlingTeammate) {
        return `Request the ability for ${this.replyHandlingTeammate?.name} to send emails as ${this.sendAsTeammate?.name}?`;
      }
      return `Request the ability to send emails as ${this.sendAsTeammate?.name}?`;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(showModal) {
        if (showModal) {
          if (!this.sendAsTeammate) {
            this.modalClosed();
            return;
          }
          this.$nextTick(() => {
            this.$refs.requestButton.$el.focus();
          });
        }
      },
    },
  },
  methods: {
    modalClosed() {
      if (this.show) {
        const query = Object.assign({}, this.$route.query);

        delete query["send-as-teammate"];
        this.$router.push({ query });
      }
    },
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(String(date)).format("MMM DD YYYY");
    },
    requestPermissionToSendAs() {
      UsersApi.requestToSendAsTeammate({
        teammateId: this.sendAsTeammate.id,
        requestingUserId: this.replyHandlingTeammate?.id,
      }).then(() => {
        let message;
        if (this.replyHandlingTeammate) {
          message = `We have sent a request to ${this.sendAsTeammate?.name} to allow ${this.replyHandlingTeammate?.name} to send emails as them.`;
        } else {
          message = `We have sent a request on your behalf to ${this.sendAsTeammate?.name}.`;
        }

        this.$notify({
          message,
          position: "bottom-right",
          title: "Success",
          type: "success",
        });

        this.modalClosed();
      });
    },
  },
};
