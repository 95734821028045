<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

const props = defineProps({
  candidate: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const {
  currentProject,
  isRecommendationTokensHidden,
} = useGetters([
  "currentProject",
  "isRecommendationTokensHidden",
]);

const showRecommendationsTokens = computed(() => {
  return !!props.candidate.similarity?.analysis && !isRecommendationTokensHidden.value;
})
</script>

<template>
  <el-skeleton id="profile-location" :loading="loading" animated>
    <div slot="template" class="d-flex justify-content-center">
      <el-skeleton-item class="loading-indicator w-90" variant="text" />
    </div>

    <div v-if="candidate.location" class="location-container">
      <HighlightableField
        class="w-100 d-flex justify-content-center flex-wrap"
        :enabled="showRecommendationsTokens"
        :project="currentProject"
        :candidate="candidate"
        :token-text="candidate.location"
        token-type="location"
        :similarity-tokens="[]"
      />
    </div>
  </el-skeleton>
</template>

<style scoped lang="scss">
#profile-location {
  font-size: 14px;
  color: $tf-font-default;
  cursor: default;
  align-items: center;
  display: flex;

  .loading-indicator {
    height: 16px;
    margin-top: 5px;
  }

  .location-container {
    i {
      float: left;
      margin-top: 54px;
    }
  }
}
</style>
