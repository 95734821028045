import { render, staticRenderFns } from "./AboutMe.vue?vue&type=template&id=93a7de8a&scoped=true"
import script from "./AboutMe.vue?vue&type=script&setup=true&lang=js"
export * from "./AboutMe.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AboutMe.vue?vue&type=style&index=0&id=93a7de8a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93a7de8a",
  null
  
)

export default component.exports