<script setup>

import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";

const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
  hasCandidateReviewer: {
    type: Boolean,
    default: false,
  },
  hasApplicantReviewer: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(
  ["update:project"],
  ["update:hasCandidateReviewer"],
  ["update:hasApplicantReviewer"]
);

const {
  isInboundsEnabled,
} = useGetters([
  "isInboundsEnabled",
]);

const computedProject = computed({
  get() { return props.project; },
  set(value) { emit("update:project", value); },
});

const computedHasCandidateReviewer = computed({
  get() { return props.hasCandidateReviewer; },
  set(value) { emit("update:hasCandidateReviewer", value); },
});

const computedHasApplicantReviewer = computed({
  get() { return props.hasApplicantReviewer; },
  set(value) { emit("update:hasApplicantReviewer", value); },
});

</script>

<template>
  <div>
    <el-form-item>
      <el-form-item prop="createDefaultCampaign">
        <div class="form-item">
          <el-tooltip placement="left" content="Create a messaging campaign based on the information provided about the job" :open-delay="500">
            <el-switch v-model="computedProject.createDefaultCampaign"></el-switch>
          </el-tooltip>
          <p class="item-label inline-label">Create an outbound campaign for me</p>
        </div>
      </el-form-item>

      <div class="form-item">
        <el-tooltip
          placement="left"
          content="Choose a teammate who will approve or reject candidates"
          :open-delay="500"
        >
          <el-switch v-model="computedHasCandidateReviewer"></el-switch>
        </el-tooltip>
        <p class="item-label inline-label">Add an outbound candidate reviewer</p>
      </div>
    </el-form-item>

    <el-form-item v-if="computedHasCandidateReviewer" prop="outboundReviewerIds">
      <el-select
        v-model="computedProject.outboundReviewerIds"
        multiple
        filterable
        class="w-100"
        placeholder="Select a teammate(s)"
      >
        <el-option
          v-for="reviewer in project.reviewerOptions"
          :key="reviewer.id"
          :label="reviewer.label"
          :value="reviewer.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item v-if="isInboundsEnabled">
      <div class="form-item">
        <el-tooltip
          placement="left"
          content="Choose a teammate who will approve or reject applicants"
          :open-delay="500"
        >
          <el-switch v-model="computedHasApplicantReviewer"></el-switch>
        </el-tooltip>
        <p class="item-label inline-label">Add an inbound applicant reviewer</p>
      </div>
    </el-form-item>

    <el-form-item
      v-if="computedHasApplicantReviewer"
      prop="inboundReviewerIds"
    >
      <el-select
        v-model="computedProject.inboundReviewerIds"
        class="w-100"
        filterable
        multiple
        clearable
        placeholder="Select a teammate(s)"
      >
        <el-option
          v-for="reviewer in project.reviewerOptions"
          :key="reviewer.id"
          :value="reviewer.id"
          :label="reviewer.label"
        />
      </el-select>
    </el-form-item>

    <el-form-item v-if="!computedProject.atsJobId" prop="visibility">
      <div class="form-item">
        <el-tooltip
          placement="left"
          content="Choose who can source candidates and modify settings"
          :open-delay="500"
        >
          <el-switch v-model="computedProject.visibility" active-value="public" inactive-value="private" />
        </el-tooltip>
        <p class="item-label inline-label">All teammates can view this job</p>
      </div>
    </el-form-item>

    <el-form-item prop="defaultWatch">
      <div class="form-item">
        <el-tooltip placement="left" content="We will track employments for all candidates in this project and email you when they change. You may specify the frequency that you receive these email in your user settings." :open-delay="500">
          <el-switch v-model="computedProject.defaultWatch"></el-switch>
        </el-tooltip>
        <p class="item-label inline-label">Notify me via email about candidate employment changes</p>
      </div>
    </el-form-item>

  </div>
</template>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 16px;
}
.form-item {
  .item-label {
    margin: 0;
  }
  .inline-label {
    display: inline;
    margin-left: 16px;
  }
}
::v-deep .el-form-item__content {
  line-height: 32px;
}
</style>
