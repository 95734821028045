import TokenBlot from "@/common/quill/TokenBlot";

const TOKEN_MATCHER = /\{\{(\w*)\}\}/gi;
class Formatter {

  // Adds wrapping <p> around paragraphs in a way that doesn't break display of current HTML
  // It is essential for Quill to render newlines correctly. This is equivalent to Ruby StringUtils.tranform_for_quill
  //
  // More context: when Quill adds the <p> tags to regular old HTML (this is what older campaigns use)
  // it does so in a way that causes irregular line breaks:
  //     double newlines after <br><br>
  //     newline before and after token values
  // This function will wrapping tags ourselves and do so
  static transformForQuill(text) {
    if (!text) return;

    // remove whitespace between ul and li tags. quill will show bulleted list as blank otherwise
    text = text.replace(/<ul>\s<li>/g, "<ul><li>");
    text = text.replace(/<\/li>\s<\/ul>/g, "</li></ul>");
    text = text.replace(/<\/li>\s<li>/g, "</li><li>");

    // break if text has already been transformed. This transformed value would have come from DB
    if (text.startsWith("<p>")) {
      return text;
    }

    let transformed = text.replace(/(.*?)<br>/g, (match, p1) => `<p>${p1.trim()}</p>`);
    const lastPTagIndex = transformed.lastIndexOf("</p>");

    if (lastPTagIndex === -1) {
      transformed = `<p>${transformed.trim()}</p>`;
    } else if (lastPTagIndex < transformed.length - 4) {
      transformed = `${transformed.substring(0, lastPTagIndex + 4)}<p>${transformed.substring(lastPTagIndex + 4).trim()}</p>`;
    }

    return transformed;
  }

  static stripWrappingTags(text) {
    // Remove the opening and closing P tags that Quill imposes on every line
    return text.replace(/<p[^>]*>/gi, "").replace(/<\/p>/gi, "");
  }

  // Replace the token embeds from the Quill output with placeholder syntax.
  static htmlToMustacheTokens(htmlString, allowHtml) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlString, "text/html");
    dom.querySelectorAll(`.${TokenBlot.className}`).forEach(token => {
      const { dataset: { slug } } = token;
      token.replaceWith(`{{${slug}}}`);
    });

    return allowHtml ? dom.body.innerHTML : dom.body.innerText;
  }

  static mustacheToHtmlTokens(tokenizedString, svc, disabled) {
    if (!tokenizedString) {
      return;
    }
    return tokenizedString.replace(TOKEN_MATCHER, (match, slug) => {
      const allowedVariables = svc.values();
      // Ignore matches that aren't for a real token.
      if (!allowedVariables.includes(slug)) {
        return;
      }
      const variable = svc.variableFor(slug);
      const name = variable.name;

      return `<${TokenBlot.tagName} draggable="${!disabled}" class="${TokenBlot.className} ${disabled ? "disabled" : ""} ${variable.value.replace(/_/g, "-")}" contenteditable="false" data-title="${name}" data-slug="${variable.value}">${name}</${TokenBlot.tagName}>`;
    });
  }

  static autocorrectIssues(content) {
    if (!content) {
      return;
    }

    // https://github.com/davidroyer/vue2-editor/issues/216
    // These divs should only be legacy campaigns created w/ Froala
    content = content.replace(/<div/gi, "<p");
    content = content.replace(/<\/div>/gi, "</p>");
    content = content.replace(/<font/gi, "<p");
    content = content.replace(/<\/font>/gi, "</p>");

    content = content.replace(/&nbsp;?/gm, " ");

    // requires a space, HTML tag, or start of line before tokens
    content = content.replace(/([^\s>]){{/gm, "$1 {{");

    // requires a space, HTML tag, or word boundary after tokens
    content = content.replace(/}}(\b|{[^\s<])/gm, "}} $1");

    content = content.replace(/}}\s+/gm, "}} ")
    content = content.replace(/}} ([.,!])/gm, "}}$1")
    content = content.replace(/\s+{{/gm, " {{")
    return content;
  }
}

export default Formatter;
