export const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
export const MAIN_APP_URL = process.env.VUE_APP_MAIN_APP_URL;
export const EMPLOYEE_APP_URL = process.env.VUE_APP_EMPLOYEE_APP_URL;
export const EMPLOYEE_MARKETING_APP_URL = process.env.VUE_APP_EMPLOYEE_MARKETING_APP_URL;
export const EXTENSION_URL = process.env.VUE_APP_EXTENSION_URL;

export const AIRBRAKE_PROJECT_ID = process.env.VUE_APP_AIRBRAKE_PROJECT_ID;
export const AIRBRAKE_API_KEY = process.env.VUE_APP_AIRBRAKE_API_KEY;
export const AIRBRAKE_ENVIRONMENT = process.env.VUE_APP_AIRBRAKE_ENVIRONMENT;

export const SEGMENT_API_KEY = process.env.VUE_APP_SEGMENT_API_KEY;
export const STREAM_API_KEY = process.env.VUE_APP_STREAM_API_KEY;
export const BEACON_KEY = process.env.VUE_APP_BEACON_KEY;
