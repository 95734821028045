<template>
  <el-dialog
    id="review-feedback-modal"
    :visible="show"
    title="Leave feedback about this candidate"
    append-to-body
    @close="modalClosed"
  >
    <div class="modal-container">
      <mentionable-editor v-model="note" />
    </div>

    <!-- Footer -->
    <span id="footer" slot="footer">
      <el-button type="text" class="mr-16" @click="modalClosed">
        Cancel
      </el-button>

      <el-button type="primary" @click="save">
        {{ decisionButtonLabel }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MentionableEditor from "@/components/MentionableEditor";

export default {
  name: "ReviewFeedbackModal",
  components: {
    MentionableEditor,
  },
  props: {
    decision: {
      type: String,
      default: null,
    },
    show: Boolean,
  },
  data() {
    return {
      note: "",
    };
  },
  computed: {
    ...mapGetters([
      "mentionsTeammates",
    ]),
    decisionButtonLabel() {
      if (this.decision === "approve") {
        return "Approve and send";
      }
      return "Reject";
    },
  },
  methods: {
    save() {
      this.$emit("saved", this.note);
      this.modalClosed();
    },
    modalClosed() {
      if (this.show) {
        this.note = "";
        this.$emit("closed");
      }
    },
  },
};
</script>

<style lang="scss">
  .modal-container {
    word-wrap: normal;
    word-break: normal;

    h4 {
      color: $tf-font-ghost;
    }

    p {
      color: $tf-font-default;
    }
  }
</style>
