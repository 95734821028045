<template>
  <el-dialog
    title="Release notes"
    visible
    show-close
    append-to-body
    @close="closeModal"
  >
    <div v-for="(notes, dateGroup) in currentUser.releaseNotes" :key="dateGroup">
      <h3> {{ dateLabel(dateGroup) }} </h3>
      <div v-for="note in notes" :key="note.key" class="card-container">
        <h5>{{ note.title }}</h5>

        <el-alert
          v-if="note.internal && currentUser.actualUserIsInternal"
          class="pl-0 py-8 mb-8"
          title="Internal only."
          description="This note is for internal users only."
          type="info"
          :closable="false"
        />

        <p class="release-note-content" v-html="note.content" />

        <a v-if="note.link" :href="note.link"> {{ note.linkTitle }} </a>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ReleaseNotesModal",
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    ...mapMutations([
      "setReleaseNotesSeen",
    ]),
    closeModal() {
      this.setReleaseNotesSeen();
    },
    dateLabel(dateGroup) {
      let month = dateGroup.substring(0, 3);
      let year = dateGroup.substring(3, 8);
      month = month.charAt(0).toUpperCase() + month.slice(1).substring(0, 3);
      return `${month} ${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .release-note-content {
    ::v-deep img {
      max-width: 100%;
  }
}
</style>