<script setup>
import { computed } from "vue";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  sidebar: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const rating = computed(() => {
  if (props?.candidate?.similarity?.stars && props?.candidate?.similarity?.stars > 0) {
    return formatRating(props?.candidate?.similarity?.stars);
  }
  return 0;
});

const formatRating = (rating) => {
  if (rating && rating > 0) {
    return (Math.round(rating * 10) / 10).toFixed(1);
  }

  return 0;
}
</script>

<template>
<el-skeleton class="star-rating" :loading="loading" animated>
  <div slot="template" class="d-flex justify-content-center align-items-center">
    <el-skeleton-item style="width: 42px; height: 20px; border-radius: 100px;" variant="text" />
  </div>

  <el-tooltip v-if="rating > 0" content="Candidates are automatically ranked with our AI similarity engine based on your calibration candidates">
    <div
      class="start-rating-container d-flex justify-content-center align-items-center py-4 px-8"
      :class="{ 'high-intent': candidate?.highIntent }"
    >
      <i class="material-icons-round star">star</i>
      <span>{{ rating }}</span>
    </div>
  </el-tooltip>
</el-skeleton>
</template>

<style scoped lang="scss">
.star-rating {
  ::v-deep .start-rating-container {
    background-color: $black-5;
    border-radius: 100px;

    .star {
      font-size: 12px;
      color: #FB8731;
      margin-right: 2px;
    }

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 9px;
    }

    &.high-intent {
      background: #EFEAF4;

      &.sidebar {
        white-space: nowrap;
      }

      span, .star {
        color: #663399;
      }
    }
  }
}
</style>
