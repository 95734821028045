export default function(index) {
  let numFollowUp = "1st";
  if (index === 1) {
    numFollowUp = "2nd";
  } else if (index === 2) {
    numFollowUp = "3rd";
  } else if (index >= 3) {
    numFollowUp = `${index + 1}th`;
  }

  return `${numFollowUp} follow-up`;
}
