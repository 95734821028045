import _ from "lodash";

const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const parseParts = (text, tokenTexts) => {
  // Order by longest text first, because regex will split by order and if we
  // have "Senior Developer" and "Developer", we need to highlight longest match
  const escapedList = _.orderBy(tokenTexts, (text) => text.length, ["desc"]).map(escapeRegExp);
  const regex = new RegExp(`(^|\\W+)(${escapedList.join("|")})(\\W+|$)`, "gi");

  return text.split(regex);
}
