<script setup>
import { ref } from "vue";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";

import JobAndCampaignSelectorWrapper from "@/components/JobAndCampaignSelector/JobAndCampaignSelectorWrapper";

import GenerateNew from "./components/GenerateNew/GenerateNew";
import Tabs from "./components/Tabs/Tabs";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const {
  currentUser,
  candidateConnectors,
} = useGetters([
  "currentUser",
  "candidateConnectors",
]);

const {
  setInAppSourcingMessage,
  setInAppSourcingSelectedCampaign,
  setInAppSourcingSelectedProject,
} = useMutations([
  "setInAppSourcingMessage",
  "setInAppSourcingSelectedCampaign",
  "setInAppSourcingSelectedProject",
]);

const { generateNewCampaignForInAppSourcing } = useActions(["generateNewCampaignForInAppSourcing"]);

const selectedProject = ref(null);

function projectOrCampaignSelected({ project, campaign }) {
  if (currentUser.value.isOutreachCapped && campaign) { // TODO: When making changes to the recruit modal this can go away as the modal actions will only allow saving for later
    setInAppSourcingSelectedCampaign({ id: "save" });
  } else {
    setInAppSourcingSelectedCampaign(campaign);

    if (!candidateConnectors.value.length) {
      // activeTab.value = "messaging";
    }

    if (campaign) {
      setInAppSourcingMessage({
        firstMessage: {
          cc: campaign.cc,
          bcc: campaign.bcc,
          body: campaign.body,
          subject: campaign.subject,
        },
        yourReplyNegative: campaign.yourReplyNegative,
        yourReplyPositive: campaign.yourReplyPositive,
        yourReplyOoo: campaign.yourReplyOoo,
        followUps: campaign.followUps,
        senderId: campaign.pseudoSenderId,
        senderName: campaign.senderName,
        replyHandlerId: campaign.replyHandlerId,
        reviewerIds: campaign.reviewerIds,
      })
    } else {
      setInAppSourcingMessage(null);
    }
  }
  selectedProject.value = project;
  setInAppSourcingSelectedProject(project);
}
</script>

<template>
  <div class="d-flex flex-column h-100">
    <JobAndCampaignSelectorWrapper
      class="mb-8"
      hide-create-new-job
      @change="projectOrCampaignSelected"
    />

    <Tabs>
      <template #generate-new>
        <GenerateNew @generate="generateNewCampaignForInAppSourcing" />
      </template>
    </Tabs>
  </div>
</template>

<style lang="scss">

</style>
