class RouteContext {
  constructor(route) {
    this.route = route;
  }
  isInContext(contexts) {
    return contexts.includes(this.route);
  }
  searchContext() {
    return this.isInContext([
      "search",
    ]);
  }
  sourcingContext() {
    return this.isInContext([
      "sourcing",
    ]);
  }
  savedForLaterContext() {
    return this.isInContext([
      "job-saved-for-later",
    ]);
  }
  noEmailContext() {
    return this.isInContext([
      "job-no-email",
    ]);
  }
  screeningContext() {
    return this.isInContext([
      "job-screening",
    ]);
  }
  reviewRequestedContext() {
    return this.isInContext([
      "review-requested",
      "review-requested-project",
    ]);
  }
  reviewApprovalContext() {
    return this.isInContext([
      "job-for-approval",
    ]);
  }
  reviewContext() {
    return this.reviewApprovalContext() || this.reviewRequestedContext();
  }
}

export default RouteContext;
