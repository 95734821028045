<template>
  <el-tooltip :content="tooltip" :disabled="!tooltip" :open-delay="500" placement="left">
    <span class="tags-container">
      <el-tag
        v-for="tag in tags"
        :key="tag"
        :class="{ disabled }"
        :closable="!disabled"
        :size="size"
        :disable-transitions="false"
        @click="tagClicked(tag)"
        @close="removeTag(tag)"
      >
        {{ tag }}
      </el-tag>

      <template v-if="allowNewTag">
        <transition name="switch" mode="out-in">
          <el-autocomplete
            v-if="inputTagVisible"
            ref="saveTagInput"
            key="tag-selector"
            v-model="inputTag"
            class="new-tag-input"
            :placeholder="onlyAtsTags ? 'Select a tag' : 'Enter a tag'"
            :size="size"
            :fetch-suggestions="filteredTagOptions"
            @blur="hideInputTag"
            @keydown.enter.native="onEnterPressed"
            @select="addTag"
          />

          <el-tag
            v-else
            key="new-tag-button"
            class="new-tag-button"
            :size="size"
            :disabled="disabled"
            @click="showTagInput"
          >
            + Tag
          </el-tag>
        </transition>
      </template>
    </span>
  </el-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Tags",
  props: {
    tags: {
      type: Array,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["mini", "small", "medium", "default"].includes(value);
      },
    },
    allowNewTag: {
      type: Boolean,
      default: true,
    },
    onlyAtsTags: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputTagVisible: false,
      inputTag: "",
    }
  },
  computed: {
    ...mapGetters({
      isLoadingTagOptions: "isLoadingTagOptions",
      tagOptions: "candidateTagOptions",
    }),
    sortedTags() {
      return (this.tags || []).sort();
    },
  },
  methods: {
    ...mapActions([
      "fetchTagOptions",
    ]),
    removeTag(tag) {
      this.$emit("removed", tag);
    },
    hideInputTag() {
      setTimeout(() => {
        this.inputTagVisible = false;
      }, 400);
    },
    showTagInput() {
      this.inputTagVisible = true;
      setTimeout(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      }, 400);
    },
    filteredTagOptions(query, callback) {
      let results = this.tagOptions;
      if (query) {
        results = this.tagOptions.filter(t => {
          return (t.toLowerCase().indexOf(query.toLowerCase()) === 0);
        });
      }

      results = results.map(r => { return { label: r, value: r } });
      callback(results);
    },
    onEnterPressed() {
      if (this.onlyAtsTags) {
        return;
      }

      this.addTag()
    },
    addTag(tag) {
      tag = tag?.value || this.inputTag;
      tag = tag.trim();

      if (!this.onlyAtsTags) {
        // imported tags should not be lowercased
        tag = tag.toLowerCase();
      }

      if (tag) {
        this.$emit("added", tag)
        setTimeout(() => {
          this.inputTagVisible = false;
          this.inputTag = "";
        }, 400);
      }
    },
    tagClicked(tag) {
      this.$emit("clicked", tag)
    },
  },
  async created() {
    if (!this.tagOptions.length && !this.isLoadingTagOptions) {
      await this.fetchTagOptions();
    }
  },
};
</script>

<style lang="scss" scoped>
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
}

.new-tag-button {
  border: none;
  font-weight: 500;
  background-color: inherit !important;
}

.el-tag {
  margin: 0;
  color: $blue-100;
  background-color: $tf-color-background-ghost;

  &:hover {
    filter: brightness(95%) !important;
    backdrop-filter: brightness(95%) !important;
  }
}

.disabled {
  cursor: pointer !important;
  background-color: $tf-color-background !important;
  color: $tf-color-secondary !important;

  .el-tag__close {
    background-color: $tf-color-secondary !important;
  }
}

.new-tag-input {
  width: 200px;

  ::v-deep .el-input__inner {
    height: 32px;
  }
}
</style>
