import ApiClient from "@/common/http/ApiClient";

class UsersApi {
  static me() {
    return ApiClient.get("users/me");
  }

  static teammates() {
    return ApiClient.get("users/teammates");
  }

  static update(params = {}) {
    return ApiClient.patch("users/me", params);
  }

  static chooseRole(role) {
    return ApiClient.patch("users/choose_role", { role });
  }

  static updateTeammate(teammate, params = {}) {
    return ApiClient.patch(`users/${teammate.id}`, params);
  }

  static recordSearch(params = {}) {
    return ApiClient.patch("users/record_search", params);
  }

  static clearRecentSearch(params = {}) {
    return ApiClient.patch("users/clear_recent_search", params);
  }

  static updateSettings(params = {}) {
    return ApiClient.put("settings/update_all", params);
  }

  static updateCalendarSettings(params = {}) {
    return ApiClient.put("settings/update_calendar_settings", params);
  }

  static settings() {
    return ApiClient.get("settings");
  }

  static calendarOauthUrl(params) {
    return ApiClient.get("settings/calendar_oauth_url", params);
  }

  static calendarStatus() {
    return ApiClient.get("settings/calendar_status");
  }

  static listCalendars() {
    return ApiClient.get("settings/list_calendars");
  }

  static connectCalendar(identifier) {
    return ApiClient.post("settings/connect_calendar", { identifier });
  }

  static disconnectCalendar() {
    return ApiClient.delete("settings/disconnect_calendar");
  }

  static departments() {
    return ApiClient.get("departments");
  }

  static leaderboardCsv(param) {
    return ApiClient.get("leaderboard/download_csv", param);
  }

  static dashboard(params = {}) {
    return ApiClient.get("dashboard", params, { allowMultiRequests: true });
  }

  static leaderboard(params = {}) {
    return ApiClient.get("leaderboard", params);
  }

  static removeInvitationSuggestion(email) {
    return ApiClient.put("dashboard/remove_suggestion", { email });
  }

  static updateTeammatePermissionToSendAsMe(teammateId, isAllowed) {
    if (isAllowed) {
      return ApiClient.post("user_permissions", { teammateId });
    } else {
      return ApiClient.put("user_permissions/remove", { teammateId });
    }
  }

  static updateTeammatePermissionToSource(teammateId) {
    return ApiClient.post("user_permissions/grant_source_as", { teammateId });
  }

  static requestToSourceAsTeammate(teammateId) {
    return ApiClient.post("user_permissions/request_source_as", { teammateId });
  }

  static requestToSendAsTeammate(params) {
    return ApiClient.post("user_permissions/request_send_as", params);
  }

  static teamInvitations() {
    return ApiClient.get("team_invitations");
  }

  static inviteTeammate(email, role) {
    return ApiClient.post("team_invitations", { emails: [email], role });
  }

  static resendInviteTeammate({ id, role }) {
    return ApiClient.post(`team_invitations/${id}/resend`, { role });
  }

  static revokeInviteTeammate(invitationId) {
    return ApiClient.delete(`team_invitations/${invitationId}`);
  }

  static verifyGreenhouseKey(apiKey) {
    const params = { apiKey };
    return ApiClient.get("settings/verify_greenhouse_key", params);
  }

  static verifyLeverKey(apiKey) {
    const params = { apiKey };
    return ApiClient.get("settings/verify_lever_key", params);
  }

  static testGreenhouseKey() {
    return ApiClient.get("settings/test_greenhouse_key");
  }

  static testLeverKey() {
    return ApiClient.get("settings/test_lever_key");
  }

  static testClaraLeverKey() {
    return ApiClient.get("settings/test_clara_lever_key");
  }

  static mergeDevModalLinkToken() {
    return ApiClient.get("settings/merge_dev_modal_link_token");
  }

  static sendMergeDevAccountToken(params) {
    return ApiClient.put("settings/merge_dev_account_token", params);
  }

  static toggleTalentwall(params) {
    return ApiClient.put("settings/toggle_talentwall", params);
  }

  static deleteMyAccount() {
    return ApiClient.put("settings/delete_user");
  }

  static reportUrl(reportName, filters) {
    const params = { reportName, filters }
    return ApiClient.get("users/report_url", params);
  }

  static customReportUrl(objectType, objectId) {
    const params = { objectType, objectId }
    return ApiClient.get("users/custom_report_url", params);
  }

  static deleteCustomReport(objectType, objectId) {
    const params = { objectType, objectId }
    return ApiClient.delete("users/delete_custom_report", params);
  }

  static refreshCustomReportList() {
    return ApiClient.get("users/refresh_custom_reports");
  }
}

export default UsersApi;
