<script setup>
import { ref } from "vue";
import { useGetters } from "vuex-composition-helpers";
import CandidateTagModal from "./CandidateTagModal";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  plain: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
});

const isModalVisible = ref(false);

const {
  bulkSelectionAttributes,
} = useGetters([
  "bulkSelectionAttributes",
]);
</script>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <div v-if="dropdownItem">
      <el-dropdown-item>
        <div class="d-flex align-items-center" @click="isModalVisible = true">
          <span class="material-icons-round md-18 mr-8">sell</span>
          <span>Add a tag</span>
        </div>
      </el-dropdown-item>
    </div>

    <el-button
      v-else
      class="w-100 m-0"
      type="secondary"
      :size="size"
      :plain="plain"
      @click="isModalVisible = true"
    >
      Add a tag
    </el-button>

    <CandidateTagModal
      :visible.sync="isModalVisible"
      :candidate="candidate"
      :bulk-selection-attributes="bulkSelectionAttributes"
    />
  </el-skeleton>
</template>
