<script setup>
import { formatEducation } from "@/common/educations/formatEducation";
import { educationSimilarityTokens } from "@/common/projects/recommendations/educationSimilarityTokens";
import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
  education: {
    type: Object,
    required: true,
  },
  tokensEnabled: {
    type: Boolean,
    default: false,
  },
});

const tokens = educationSimilarityTokens({
  enabled: props.tokensEnabled,
  candidate: props.candidate,
  education: props.education,
})
</script>

<template>
  <div class="mb-4 education-item">
    <!-- TODO: Test if we can get rid of the if and just use :enabled="tokensEnabled" -->
    <HighlightableField
      v-if="tokensEnabled"
      :project="project"
      :candidate="candidate"
      :token-text="formatEducation(education)"
      token-type="edu-institution"
      :additional-token-types="['plain']"
      :similarity-tokens="[tokens.institution]"
      :filter-tokens="candidate.filterHighlights?.educationInstitutionNameTokens"
    />

    <span v-else>
      {{ formatEducation(education) }}
    </span>

    <span v-if="education.degree && education.endDate?.year" class="date-label">({{ education.endDate.year }})</span>

  </div>
</template>

<style scoped lang="scss">
.education-item {
  ::v-deep .nudge-left .highlight {
    margin-left: -5px;
  }

  i {
    float: left;
    margin-top: 4px;
  }

  .date-label {
    color: $tf-font-ghost;
    margin-left: 4px;
  }
}
</style>
