export default {
  methods: {
    showNewProjectModal() {
      const query = Object.assign({}, this.$route.query);
      query.parentModal = query.modal;
      query.modal = "new-project";

      this.$router.push({ query });
    },
    showInviteTeammateModal(teammateType) {
      const query = Object.assign({}, this.$route.query);
      query.modal = "invite-teammate";
      query["teammate-type"] = teammateType;
      this.$router.push({ query });
    },
  },
}
