const USER_COOKIE_NAME = "token";
const ADMIN_COOKIE_NAME = "admin_token";

const getCookieValue = cookieName => {
  const tokenRegex = new RegExp(`(^| )${cookieName}=([^;]+)`);

  const match = document.cookie.match(tokenRegex) || [];
  if (match) {
    return match[2];
  }
};

const getUserToken = () => getCookieValue(USER_COOKIE_NAME);
const getAdminToken = () => getCookieValue(ADMIN_COOKIE_NAME);

export default { getUserToken, getAdminToken };
