export const FILTER_DELIMITER = "\\";

// TODO: Start using this constant, needs to be updated with more values
export const FILTER_CONTEXT = Object.freeze({
  campaigns: "campaigns",
  candidates: "candidates",
  projects: "projects",
  jobPipeline: "job-pipeline",
  jobApplicants: "job-applicants",
  jobForApproval: "job-for-approval",
  jobRecommendations: "job-recommendations",
  leaderboard: "leaderboard",
  search: "search",
});

export const DIVERSITY_OPTIONS = [
  {
    value: "urg",
    label: "URG",
  },
  {
    value: "women",
    label: "Women",
  },
  {
    value: "military-experience",
    label: "Military",
  },
];

export const X_FACTOR_OPTIONS = [
  {
    value: "has-email",
    label: "Has email",
  },
  {
    value: "promotion",
    label: "Previously promoted",
  },
  {
    value: "top-schools",
    label: "Top schools",
  },
];

export const ORIGIN_BASE_OPTIONS = [
  { value: "extension", label: "Extension" },
  { value: "stream", label: "Stream" },
  { value: "csv", label: "CSV upload" },
  { value: "network", label: "Referral network" },
];

export const EMPLOYMENT_FUNDING_ROUNDS_OPTIONS = [
  "Grant",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series G",
  "Series H",
  "Series I",
  "Series J",
  "Seed Round",
  "Angel Round",
  "Post-IPO Debt",
  "Venture Round",
  "Debt Financing",
  "Pre seed Round",
  "Corporate Round",
  "Post-IPO Round",
  "Convertible Note",
  "Secondary Market",
  "Post-IPO Secondary",
  "Equity Crowdfunding",
  "Private Equity Round",
  "Product Crowdfunding",
  "Initial Coin Offering",
  "Non Equity Assistance",
];

export const AVG_RECENT_TENURE_OPTIONS = [
  { label: "Very short (0-1y)", value: "very-short-0-1" },
  { label: "Short (1-3y)", value: "short-1-3" },
  { label: "Medium (3-6y)", value: "medium-3-6" },
  { label: "Long (6-10y)", value: "long-6-10" },
  { label: "Very long (10y+)", value: "very-long-10-inf" },
];

export const EARLY_AT_COMPANY_OPTIONS = [
  { label: "Very early (0-1y)", value: "very-early-0-1" },
  { label: "Early (1-3y)", value: "early-1-3" },
  { label: "Middle (3-6y)", value: "mid-3-6" },
  { label: "Late (6-10y)", value: "late-6-10" },
  { label: "Very late (10y+)", value: "very-late-10-inf" },
];
