<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";

import TouchpointsApi from "@/common/http/TouchpointsApi";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
});

const {
  profileIdsInReview,
} = useGetters([
  "profileIdsInReview",
]);

const root = useRoot();

const confirmMessage = computed(() => {
  return `Stop all email activity with ${props.candidate?.name || "this candidate"}? Responses will not be detected by Teamable.`;
});

const disabled = computed(() => {
  return !props.candidate.recentTouchpoint.stoppable;
});

const tooltipContent = computed(() => {
  if (props.candidate.recentTouchpoint.wasOverridden) {
    return "This outreach status was manually changed. Teamable is no longer sending or syncing replies so there is no need to stop it.";
  } else if (!props.candidate.recentTouchpoint.stoppable) {
    return "This outreach is no longer active and there's no need for it to be stopped."
  } else if (profileIdsInReview.value.includes(props.candidate.profileId)) {
    return `Remove ${props.candidate?.name || "candidate"} from review and stop all Teamable activity`;
  }
  return `Stop all Teamable activity with ${props.candidate?.name || "the candidate"}`;
});

async function stopOutreach() {
  await TouchpointsApi.stop({
    id: props.candidate.recentTouchpoint.id,
    type: props.candidate.recentTouchpoint.type,
  });

  // TODO: add error handling
  root.$notify({
    title: "Success",
    position: "bottom-right",
    message: "Please allow us a few moments to perform this action",
    type: "success",
  });
};

</script>

<template>
  <el-popconfirm
    :title="confirmMessage"
    confirm-button-text="Yes"
    cancel-button-text="No, thanks"
    placement="top"
    hide-icon
    @confirm="stopOutreach"
  >
    <el-tooltip
      slot="reference"
      :content="tooltipContent"
      :open-delay="500"
    >
      <el-dropdown-item v-if="dropdownItem" :disabled="disabled">
        <div class="d-flex align-items-center">
          <span class="material-icons-round md-18 mr-8">stop_circle</span>Stop outreach
        </div>
      </el-dropdown-item>

      <el-button
        v-else
        class="w-100 m-0"
        type="danger"
        :size="size"
        :disabled="disabled"
      >
        <span v-if="onlyIcon" class="material-icons-round md-14 m-0">front_hand</span>
        <span v-else>Stop outreach</span>
      </el-button>
    </el-tooltip>
  </el-popconfirm>
</template>
