<script setup>
import { computed, ref, unref, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers"

import NewCampaignModalLoader from "@/components/Loaders/NewCampaignModalLoader";
import NewCampaignGenerationInfo from "@/components/NewCampaignGenerationInfo/NewCampaignGenerationInfo";

import CreationForm from "./components/CreationForm/CreationForm";

const route = useRoute();
const router = useRouter();

const isLoadingNewCampaign = ref(false);
const wasNewCampaignFetched = ref(false);
const showCampaignGenerationLoader = ref(false);

const {
  currentCampaign,
  currentProject,
  newCampaign,
  wasSourceableProjectListFetched,
} = useGetters([
  "currentCampaign",
  "currentProject",
  "newCampaign",
  "wasSourceableProjectListFetched",
]);

const {
  setNewCampaign,
} = useMutations([
  "setNewCampaign",
]);

const {
  generateNewCampaign,
  fetchNewCampaign,
  fetchSourceableProjectsList,
} = useActions([
  "generateNewCampaign",
  "fetchNewCampaign",
  "fetchSourceableProjectsList",
]);

const show = computed(() => {
  if (route.query.parentCampaignId) {
    return false;
  }
  return route.query.modal === "new-campaign";
});

const modalClosed = () => {
  if (unref(show)) {
    setNewCampaign(null);
    const query = Object.assign({}, route.query);

    delete query.modal;
    delete query.parentCampaignId;

    showCampaignGenerationLoader.value = false;
    router.replace({ query });
  }
};

const initNewCampaignOptions = async () => {
  isLoadingNewCampaign.value = true;

  if (!unref(wasSourceableProjectListFetched)) {
    await fetchSourceableProjectsList();
  }

  fetchNewCampaign({
    projectId: unref(currentProject).id,
  }).then(() => {
    wasNewCampaignFetched.value = true;
    isLoadingNewCampaign.value = false;
  });
};

function createCampaignHandler() {
  generateNewCampaign().then(() => {
    showCampaignGenerationLoader.value = true;
  });
}

watch(show, async (value) => {
  if (value || !wasNewCampaignFetched.value) {
    showCampaignGenerationLoader.value = false;
    await initNewCampaignOptions();
  } else {
    wasNewCampaignFetched.value = false;
  }
})
</script>

<template>
  <el-dialog
    id="new-campaign-modal"
    :visible="show"
    width="70%"
    append-to-body
    :show-close="false"
    :close-on-click-modal="false"
    @keydown.native.enter.prevent="createCampaignHandler"
    @close="modalClosed"
  >
    <NewCampaignGenerationInfo
      v-if="showCampaignGenerationLoader && currentCampaign.id"
      :campaign-id="currentCampaign.id"
      :parent-project-id="currentCampaign.projectId"
    />

    <template v-else>
      <template slot="title">
        <div class="title mb-8">Create new campaign</div>
        <div class="subtitle">This is a messaging campaign - it will automatically start when candidates are added to it.</div>
      </template>

      <!-- Body -->
      <div v-if="isLoadingNewCampaign || !wasSourceableProjectListFetched">
        <NewCampaignModalLoader />
      </div>
      <div v-else-if="newCampaign">
        <CreationForm @closed="modalClosed" @submit="createCampaignHandler" />
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
}
.subtitle {
  font-size: 14px;
}
</style>