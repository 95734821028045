<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";

import LogoSpinner from "@/components/LogoSpinner/LogoSpinner";

const emit = defineEmits(["generate"]);

const {
  bulkSelectionAttributes,
  inAppSourcingSelectedProject,
  isGeneratingNewCampaignForInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "inAppSourcingSelectedProject",
  "isGeneratingNewCampaignForInAppSourcing",
]);

const textData = computed(() => {
  if (!inAppSourcingSelectedProject.value) {
    return {
      title: "No job or campaign selected",
      actionName: null,
      description: "Please select a job or campaign above",
    }
  }
  let
    title = "",
    actionName = "",
    description = bulkSelectionAttributes.value ?
      "Please select a campaign above or generate one by clicking the button below" :
      "Please select a campaign above or generate personalized messaging by clicking the button below";

  if (isGeneratingNewCampaignForInAppSourcing.value) {
    title = "Generating campaign";
    actionName = "Stop generating";
    description = "Generation is usually instant but may occasionally take up to 30-90 seconds";
  } else {
    title = "No messaging selected";
    actionName = "Generate new";
  }

  return {
    title,
    description,
    actionName,
  };
});

</script>

<template>
  <div id="generate-new" :class="{'mt-0': isGeneratingNewCampaignForInAppSourcing}">
    <div v-if="isGeneratingNewCampaignForInAppSourcing" class="logo-container">
      <LogoSpinner />
    </div>

    <div class="generate-new-text">
      <div class="generate-new-primary-text">{{ textData.title }}</div>
      <div class="mt-16 w-70 generate-new-secondary-text">{{ textData.description }}</div>
    </div>

    <el-button
      v-if="textData.actionName"
      size="small"
      type="primary"
      plain
      class="py-0"
      @click="emit('generate')"
    >
      {{ textData.actionName }}
    </el-button>
  </div>
</template>

<style lang="scss" scoped>
#generate-new {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 400px);

  .generate-new-text {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    cursor: default;

    .generate-new-primary-text {
      font-size: $tf-no-results-primary-text-font-size;
      font-weight: $tf-no-results-primary-text-font-weight;
      color: $tf-no-results-primary-text-color;
      text-transform: uppercase;
    }

    .generate-new-secondary-text {
      font-size: $tf-no-results-secondary-text-font-size;
      font-weight: $tf-no-results-secondary-text-font-weight;
      color: $tf-no-results-secondary-text-color;
      margin: 0 auto;
    }
  }

  .logo-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 24px;
    margin-top: 24px;
    border: 3px solid $beige-100;
  }
}
</style>
