export const formatBulletedList = (text) => {
  if (!text) return "";

  return text
    .replace(/\s?•\s?/g, "<br>• ")
    .replace(/\s?-\s/g, "<br>• ") // The space after the - is not optional here to avoid splitting on on dashed words
    .replace(/\s?★\s?/g, "<br>• ")
    .replace(/\s?\*\s?/g, "<br>• ")
    .replace(/\s?◆\s?/g, "<br>• ")
    .replace(/\s?✔\s?/g, "<br>• ")
    .replace(/<\/?a[^>]*>/g, "")
    .replace(/^<br>/, "");
}
