class ApiError extends Error {
  constructor(message, status, substatus) {
    super(message);
    this.name = "ApiError";
    this.status = status;
    this.substatus = substatus;
  }
}

class SemanticError extends Error {
  constructor(message) {
    super(message);
    this.success = false;
    this.name = "SemanticError";
  }
}

export default ApiError;

export const handleApiError = error => {
  if (error.response) {
    if (error.response.status === 422 && error.response.data) {
      throw new SemanticError(error.response.data.message);
    } else {
      const errorMessage = `${error.response.status}: ${error.response.statusText}`;
      throw new ApiError(errorMessage, error.response.status, error.response.data?.substatus || "");
    }
  } else {
    throw new ApiError(`${error.reason || error.message}`);
  }
};
