export default function(index) {
  let subtitle = "Nudge candidates who haven't responded yet to help garner interest.";
  if (index === 1) {
    subtitle = "We suggest 2-3 follow-ups over time to keep this opportunity top of mind.";
  } else if (index >= 2) {
    subtitle = "Adding another follow-up several days out can be a helpful way to remind candidates.";
  }

  return subtitle;
}
