<template>
  <el-skeleton class="w-100" :loading="true" animated>
    <template #template>
      <div class="p-8">
        <div class="d-flex justify-content-between w-100">
          <el-skeleton-item class="w-50" variant="text" />
          <el-skeleton-item class="w-25" variant="rect" />
        </div>

        <div class="d-flex mt-8 w-100">
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="mr-8" variant="circle" />
        </div>

        <div class="d-flex justify-content-between mt-24">
          <div class="d-flex align-items-center w-100">
            <el-skeleton-item class="checkbox mr-8" variant="rect" />
            <el-skeleton-item class="w-10" variant="text" />
          </div>
          <el-skeleton-item class="w-10" variant="rect" />
        </div>

        <el-divider class="my-8" />

        <div class="d-flex align-items-center">
          <el-skeleton-item class="checkbox mr-8" variant="rect" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="w-20" variant="text" />
        </div>

        <el-divider class="my-8" />

        <div class="d-flex align-items-center">
          <el-skeleton-item class="checkbox mr-8" variant="rect" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="w-10" variant="text" />
        </div>

        <el-divider class="my-8" />

        <div class="d-flex align-items-center">
          <el-skeleton-item class="checkbox mr-8" variant="rect" />
          <el-skeleton-item class="mr-8" variant="circle" />
          <el-skeleton-item class="w-30" variant="text" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>

export default {
  name: "RequestIntroTabLoader",
};
</script>

<style lang="scss" scoped>
.checkbox {
  width: 16px;
  height: 16px;
}

.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.switcher {
  height: 25px;
}

.message-text {
  height: 40px;
}
</style>
