import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=7c54b0b6"
import script from "./Tabs.vue?vue&type=script&setup=true&lang=js"
export * from "./Tabs.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=7c54b0b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports