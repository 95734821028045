import { render, staticRenderFns } from "./ResumeView.vue?vue&type=template&id=65952326&scoped=true"
import script from "./ResumeView.vue?vue&type=script&setup=true&lang=js"
export * from "./ResumeView.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ResumeView.vue?vue&type=style&index=0&id=65952326&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65952326",
  null
  
)

export default component.exports