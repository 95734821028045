<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  isSaving: {
    type: Boolean,
    default: false,
  },
});

const showJustSaved = ref(false);

watch(() => props.isSaving, () => {
  showJustSaved.value = true;

  setTimeout(() => {
    showJustSaved.value = false;
  }, 3000);
});
</script>

<template>
  <div class="save-notice">
    <div v-if="isSaving">
      <div class="d-flex align-items-center">
        <span class="icon material-icons-round md-18 mr-4">sync</span>
        Saving
      </div>
    </div>

    <div v-else-if="showJustSaved">
      <div class="d-flex align-items-center">
        <span class="check material-icons-round md-18 mr-4">check_circle</span>
        Saved
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.save-notice {
  font-size: 14px;
  color: $tf-font-ghost;

  .icon {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .check {
    color: $tf-color-success;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>
