<script setup>
import { computed } from "vue";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  plain: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
});

const root = useRoot();

const {
  bulkSelectionAttributes,
  currentProject,
  isCandidateSidebarOpen,
  selectedCandidates,
} = useGetters([
  "bulkSelectionAttributes",
  "currentProject",
  "isCandidateSidebarOpen",
  "selectedCandidates",
]);

const {
  setCurrentProjectSavedForLaterCount,
} = useMutations([
  "setCurrentProjectSavedForLaterCount",
]);

const {
  removeCandidateFromProject,
  removeBulkCandidatesFromProject,
} = useActions([
  "removeCandidateFromProject",
  "removeBulkCandidatesFromProject",
]);

const isForSingleCandidate = computed(() => {
  return !!props.candidate;
});

const isForBulkCandidates = computed(() => {
  return !!bulkSelectionAttributes.value;
});

const removeCandidatePossible = computed(() => {
  return currentProject.value.id && props.candidate?.projectIdsSavedForLater?.includes(currentProject.value.id);
});

const removeBulkCandidatesPossible = computed(() => {
  if (!selectedCandidates.value.length) {
    return false;
  }

  return !selectedCandidates.value.some(candidate => {
    return !(candidate.projectIdsSavedForLater || []).includes(currentProject.value.id);
  });
});

const tooltip = computed(() => {
  if (isForSingleCandidate.value) {
    if (!removeCandidatePossible.value) {
      return `${props.candidate.name} isn't 'Saved for later' for this job`;
    }
    return `Removes ${props.candidate.name} from 'Saved for later'`;

  } else if (isForBulkCandidates.value) {
    if (!removeBulkCandidatesPossible.value) {
      return "Not all selected candidates are 'Saved for later' for this job";
    }
    return "Removes all selected candidates from 'Saved for later'";
  }

  return null;
});

function removeCandidate() {
  // TODO: Rename "candidate-recruited" to something more appropriate
  // since all it does is remove from currentCandidateList and either closes the sidebar or shows next candidate
  root.$bus.$emit("candidate-recruited", { candidate: props.candidate, showNext: isCandidateSidebarOpen.value });
  setCurrentProjectSavedForLaterCount(currentProject.value.savedForLaterCount - 1);

  removeCandidateFromProject({
    projectId: currentProject.value.id,
    candidate: props.candidate,
  });

  // TODO: Add candidate back to the list if the API call failed and show notification
  // same as we do for the save for later
}

async function removeBulk() {
  await removeBulkCandidatesFromProject({
    project: currentProject.value,
    bulkSelectionAttributes: bulkSelectionAttributes.value,
  });

  // TODO: add error handling

  root.$notify({
    title: "Success",
    message: "Success! You may refresh the page in a few moments to see updated data.",
    position: "bottom-right",
    type: "success",
  });
}

function remove() {
  if (isForSingleCandidate.value) {
    removeCandidate();
  } else {
    removeBulk();
  }
}
</script>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <el-tooltip
      :content="tooltip"
      :open-delay="500"
      :disabled="!tooltip"
    >
      <div v-if="dropdownItem">
        <el-dropdown-item :disabled="!removeCandidatePossible && !removeBulkCandidatesPossible">
          <div class="d-flex align-items-center" @click="remove">
            <span class="material-icons-round md-18 mr-8">work</span>
            <span>Remove from job</span>
          </div>
        </el-dropdown-item>
      </div>

      <el-button
        v-else
        class="w-100 m-0"
        type="warning"
        :size="size"
        :plain="plain"
        :disabled="!removeCandidatePossible && !removeBulkCandidatesPossible"
        @click="remove"
      >
        <span v-if="onlyIcon" class="material-icons-round md-14 m-0">work_off</span>
        <span v-else>Remove from job</span>
      </el-button>
    </el-tooltip>
  </el-skeleton>
</template>
