<script setup>
import { computed, onBeforeUnmount, ref, watchEffect } from "vue";
import { useRouter } from "vue-router/composables";
import { useGetters, useMutations } from "vuex-composition-helpers";

import LogoSpinner from "@/components/LogoSpinner/LogoSpinner";
import logEvent from "@/common/Analytics";

const props = defineProps({
  campaignId: {
    type: Number,
    required: true,
  },
  parentProjectId: {
    type: Number,
    default: null,
  },
});

const router = useRouter();
const isCampaignGenerated = ref(false);
const isDefaultCampaignGenerated = ref(false);

const {
  generatedCampaignStatus,
  currentProject,
  newCampaign,
} = useGetters([
  "generatedCampaignStatus",
  "currentProject",
  "newCampaign",
]);
const {
  setGeneratedCampaignStatus,
} = useMutations([
  "setGeneratedCampaignStatus",
]);

const createdCampaignTitle = computed(() => isDefaultCampaignGenerated.value ? "Default campaign created" : "Campaign created");

const createdCampaignSubtitle = computed(() => isDefaultCampaignGenerated.value ? "We couldn't personalize your campaign this time, but you can try again when reviewing it." : "You can now choose your next step");

const subtitle = computed(() => {
  return "Generation is usually instant but may occasionally take up to 30-90 seconds. Feel free to explore recommended candidates for this job in the meantime.";
});

const goToSourcing = () => {
  logEvent("outbound-go-to-recommendations", {
    isGenerating: !!generatedCampaignStatus.value,
    isReviewing: false,
  });
  setGeneratedCampaignStatus(null);

  router.replace({
    name: "sourcing",
    query: { projectId: props.parentProjectId || newCampaign.value?.projectId || currentProject.value?.id },
  });
};

const goToCampaignHandler = () => {
  logEvent("outbound-go-to-campaign", {
    isGenerating: !!generatedCampaignStatus.value,
    isReviewing: false,
  });

  setGeneratedCampaignStatus(null);
  router.replace({
    name: "job-campaign-root",
    params: { projectId: props.parentProjectId, campaignId: props.campaignId },
    query: { isReviewingCampaign: true },
  });
};

watchEffect(() => {
  generatedCampaignStatus.value && logEvent("outbound-waited-for-campaign-to-be-generated", {
    isGenerating: false,
    isReviewing: false,
  });
  isCampaignGenerated.value = generatedCampaignStatus.value;
  isDefaultCampaignGenerated.value = generatedCampaignStatus.value === "failed";
});

onBeforeUnmount(() => {
  setGeneratedCampaignStatus(null);
});
</script>

<template>
  <div class="campaign-creation-loader-wrapper">
    <div v-if="!isCampaignGenerated" class="header">
      <LogoSpinner />

      <div class="title">Generating campaign</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>

    <div v-else class="header">
      <div class="logo-wrapper success">
        <span class="material-icons-round md-48 success-icon">check_circle</span>
        <span v-if="isDefaultCampaignGenerated" class="material-icons-round md-36 error-icon">error</span>
      </div>

      <div class="title">{{createdCampaignTitle}}</div>
      <div class="subtitle">{{createdCampaignSubtitle}}</div>
    </div>

    <div class="mt-24">
      <el-button type="primary" @click="goToSourcing">Start sourcing</el-button>
      <el-button class="ml-8" @click="goToCampaignHandler">Go to campaign</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.campaign-creation-loader-wrapper {
  text-align: center;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 24px;
  border: 3px solid $beige-100;

  &.success {
    position: relative;
    border: 3px solid $spring-green-100;

    .success-icon {
      color: $spring-green-100;
    }

    .error-icon {
      position: absolute;
      color: $orange-500;
      background: #fff;
      border-radius: 50%;
      bottom: -9px;
      right: -5px;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #1C1C1C;
  margin-top: 8px;
  margin-bottom: 8px;
}
.subtitle {
  font-size: 14px;
  color: $black-100;
}
</style>