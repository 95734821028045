export const INVITE_NEW_TEAMMATE_SUBTITLE = `Ask a teammate for permission to reach out to candidates on their behalf.
          We typically see a 2x return when candidates receive outreach from VPs,
          executives, or hiring managers for the role.<br><br>Once your teammate says "OK", you'll be notified and can assign them as your campaign sender.`;

export const SAND_AS_TOOLTIP_LABEL = "We typically see a 2x return when candidates receive outreach from VPs, executives or hiring managers for the role. You can also choose someone else to reply on their behalf so they don't have to take any action.";
export const ROBO_TOOLTIP_LABEL = "When the reply handler differs from the sender, candidate replies won't appear in the sender's inbox. Teamable will send a notification to the reply handler so they can respond as if they were the sender (response will come from the sender's email address).";
export const REVIEWERS_TOOLTIP_LABEL = "Choose one or more teammates to review candidates. Outreach will be sent upon approval; only one approval or rejection is required.";
export const UNSENT_TASKS_EXIST = "There is pending outreach. Would you like to apply this change to all unsent outreach?";
export const DIFFERENT_SENDER = " Note that changing the sender will not affect sent threads. Follow-ups for those threads will continue to send as the original sender."
export const CONFIRM_FOLLOWUPS_CHANGE = "There are pending follow-ups. Would you like to apply this change to all scheduled follow-ups?";
export const CONFIRM_REMOVE_LAST_REVIEWER_FOR_OUTBOUNDS = "There are candidates in review. Clearing all reviewers auto-approves these candidates. Are you sure?";
export const CONFIRM_REMOVE_LAST_REVIEWER_FOR_INBOUNDS = "There are applicants in review. Clearing all reviewers will assign team admins. Are you sure?";
