export const WEEKDAYS = [
  { name: "Sun", index: 0 },
  { name: "Mon", index: 1 },
  { name: "Tue", index: 2 },
  { name: "Wed", index: 3 },
  { name: "Thu", index: 4 },
  { name: "Fri", index: 5 },
  { name: "Sat", index: 6 },
];

export const HOLIDAYS_OPTIONS = [
  { value: "united_states", label: "US holidays" },
  { value: "catholic_christmas_and_new_years", label: "Catholic Christmas and New Years" },
  { value: "orthodox_christmas_and_new_years", label: "Orthodox Christmas and New Years" },
  { value: "none", label: "None" },
];

export const EMAIL_TRACKING_OPTIONS = [
  { value: true, label: "Track opens and clicks" },
  { value: false, label: "Don't track opens and clicks" },
];

export const PREFERRED_EMAIL_OPTIONS = [
  { value: "work", label: "Work" },
  { value: "personal", label: "Personal" },
];

export const PREFERRED_EMAIL_ONLY_OPTIONS = [
  { value: true, label: "Only preferred email" },
  { value: false, label: "Best available email" },
];

export const TYPES = {
  FIRST_MESSAGE: "firstMessage",
  FOLLOW_UPS: "followUps",
  REPLIES: "replies",
  SETTINGS: "settings",
  INTRO_REQUESTS: "introRequest",
  INTRO_MESSAGE: "introMessage",
  APPROVAL: "approval",
  REJECTION: "rejection",
  AUTO_REPLY_OOO: "autoReplyOoo",
  AUTO_REPLY_NEGATIVE: "autoReplyNegative",
  AUTO_REPLY_POSITIVE: "autoReplyPositive",
  YOUR_REPLY_NEGATIVE: "yourReplyNegative",
  YOUR_REPLY_POSITIVE: "yourReplyPositive",
  YOUR_REPLY_OOO: "yourReplyOoo",
  METRICS: "metrics",
};

export const EXTENSION_INFO_URL = "https://support.teamable.com/article/21-using-the-teamable-chrome-extension";
