class CampaignMessageVariables {

  constructor({
    introRequestVariables = false,
    introMessageVariables = false,
    customSentenceEnabled = true,
    calendarEnabled = true,
    jobTitleEnabled = true,
    firstNameEnabled = true,
    chatbotEnabled = false,
    chatbotName = "Chatbot",
    lastNameEnabled = true,
    companyEnabled = true,
    senderFirstNameEnabled = true,
    signatureEnabled = true,
  } = {}) {
    this.signatureEnabled = signatureEnabled;
    this.senderFirstNameEnabled = senderFirstNameEnabled;
    this.introRequestVariables = introRequestVariables;
    this.introMessageVariables = introMessageVariables;
    this.customSentenceEnabled = customSentenceEnabled;
    this.calendarEnabled = calendarEnabled;
    this.chatbotEnabled = chatbotEnabled;
    this.chatbotName = chatbotName;
    this.jobTitleEnabled = jobTitleEnabled;
    this.firstNameEnabled = firstNameEnabled;
    this.lastNameEnabled = lastNameEnabled;
    this.companyEnabled = companyEnabled;
  }

  variableFor(value) {
    return this.allowed().find(v => v.value === value);
  }

  allowed() {
    // If referrals variables then do not use regular ones
    if (this.introRequestVariables || this.introMessageVariables) {
      return this.referralVariables();
    }

    const variables = [];

    if (this.firstNameEnabled) {
      variables.push({
        value: "first_name",
        name: "First Name",
        label: "{{first_name}}",
        description: "Candidate's first name",
      });
    }

    if (this.lastNameEnabled) {
      variables.push({
        value: "last_name",
        name: "Last Name",
        label: "{{last_name}}",
        description: "Candidate's last name",
      });
    }

    if (this.senderFirstNameEnabled) {
      variables.push({
        value: "sender_first_name",
        name: "Sender's Name",
        label: "{{sender_first_name}}",
        description: "Campaign sender's (\"Send as\" setting) first name",
      });
    }

    if (this.chatbotEnabled) {
      variables.push({
        value: "chatbot_link",
        name: this.chatbotName,
        label: "{{chatbot_link}}",
        description: `Chatbot URL`,
      });
    }

    if (this.companyEnabled) {
      variables.push({
        value: "company",
        name: "Company",
        label: "{{company}}",
        description: "Candidate's current company",
      });
    }

    if (this.customSentenceEnabled) {
      variables.push({
        value: "sentence",
        name: "Personalized Content",
        label: "{{sentence}}",
        description: "Content generated by us responsible for a 62% increase in interested replies",
      });
    }

    if (this.jobTitleEnabled) {
      variables.push({
        value: "job_title",
        name: "Job Title",
        label: "{{job_title}}",
        description: "Job title",
      });
    }

    if (this.calendarEnabled) {
      variables.push({
        value: "calendar",
        name: "Calendar",
        label: "{{calendar}}",
        description: "Campaign sender's (\"Send as\" setting) calendar link",
      });
    }

    if (this.signatureEnabled) {
      variables.push({
        value: "signature",
        name: "Signature",
        label: "{{signature}}",
        description: "Campaign sender's (\"Send as\" setting) signature",
      });
    }

    return variables;
  }

  referralVariables() {
    let variables = [
      {
        value: "first_name",
        name: "First Name",
        label: "{{first_name}}",
        description: "Candidate's first name",
      },
      {
        value: "last_name",
        name: "Last Name",
        label: "{{last_name}}",
        description: "Candidate's last name",
      },
    ]


    if (this.introRequestVariables) {
      variables.push({
        value: "sender_first_name",
        name: "Sender's Name",
        label: "{{my_first_name}}",
        description: "Requestor's first name",
      });
      variables.push({
        value: "candidate_with_link",
        name: "Full Name with Link",
        label: "{{candidate_with_link}}",
        description: "Candidate's full name with link to Teamable app",
      });
    }

    variables = variables.concat([
      {
        value: "employee_first_name",
        name: "Employee First Name",
        label: "{{employee_first_name}}",
        description: "Employee's first name",
      },
      {
        value: "employee_last_name",
        name: "Employee Last Name",
        label: "{{employee_last_name}}",
        description: "Employee's last name",
      },
    ])

    variables.push({
      value: "job_title",
      name: "Job Title",
      label: "{{job_title}}",
      description: "Job title",
    });

    if (this.introMessageVariables) {
      variables.push({
        value: "apply_link",
        name: "Apply Link",
        label: "{{apply_link}}",
        description: "Apply link for the job",
      });
    }

    if (this.introMessageVariables) {
      variables.push({
        value: "employee_signature",
        name: "Employee Signature",
        label: "{{employee_signature}}",
        description: "Employee's signature",
      });
    }

    if (this.introRequestVariables) {
      variables.push({
        value: "signature",
        name: "Signature",
        label: "{{signature}}",
        description: "Requestor's signature",
      });
    }
    return variables;
  }

  values() {
    return this.allowed().map((item) => {
      return item.value.replace(/{{|}}/g, "");
    });
  }
}

export default CampaignMessageVariables;
