<template>
  <span class="user-dropdown-menu">
    <el-dropdown
      v-if="showDropdown"
      trigger="click"
      @command="handleCommand"
    >
      <UserAvatar
        :image-url="currentUser.avatarUrl"
        :full-name="currentUser.name"
        hide-name-label
        :size="avatarSize"
      />

      <el-dropdown-menu slot="dropdown" class="user-menu-dropdown">
        <div id="user-details">
          <div class="d-flex align-items-center mr-16">
            <UserAvatar
              :image-url="currentUser.avatarUrl"
              :full-name="currentUser.name"
              hide-name-label
              size="medium"
            />
          </div>

          <div class="d-flex flex-column">
            <span>{{ currentUser.name }}</span>
            <span>{{ currentUser.email }}</span>
          </div>
        </div>

        <template v-if="!logoutOnly">
          <el-dropdown-item command="settings-root">
            Settings
          </el-dropdown-item>

          <el-dropdown-item command="team-management-root">
            Team management
          </el-dropdown-item>

          <el-dropdown-item v-if="showSubscriptions" command="subscriptions">
            Subscriptions
          </el-dropdown-item>

          <slot name="extra-items" />

          <el-divider class="my-8" />

          <el-dropdown-item v-if="isReferralsEnabled" class="external-link">
            <a :href="employeeAppUrl">
              Switch to Employee Portal to manage referrals
            </a>
          </el-dropdown-item>

          <el-divider class="my-8" />

          <el-dropdown-item command="example-campaigns">
            Example campaigns
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://support.teamable.com/category/43-common-issues" target="_blank" rel="noopener">
              FAQs <span class="material-icons-two-tone external-icon">launch</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a :href="chromeExtensionLink" target="_blank" rel="noopener">
              Chrome extension <span class="material-icons-two-tone external-icon">launch</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://www.teamable.com/pricing/" target="_blank" rel="noopener">
              Planning <span class="material-icons-two-tone external-icon">launch</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://www.teamable.com/blog/" target="_blank" rel="noopener">
              Blog <span class="material-icons-two-tone external-icon">launch</span>
            </a>
          </el-dropdown-item>

          <template v-if="(currentUser.actualUserIsInternal || currentUser.actualUserIsPartnerCsm) && !menuOnly">
            <el-divider class="my-8" />

            <el-dropdown-item
              v-for="(sudoOption, index) in filteredSudoOptions"
              :key="index"
              class="external-link"
            >
              <a :href="sudoAsLink(sudoOption)" target="_blank">
                {{ sudoOptionLabel(sudoOption) }}
              </a>
            </el-dropdown-item>

            <el-dropdown-item v-if="$route.path.includes('/reports')" class="external-link">
              <a href="https://justin.looker.com/login/" target="_blank">
                Internal Looker login
              </a>
            </el-dropdown-item>

            <el-dropdown-item class="d-flex">
              <span class="w-100" @click="copySudoLinkForPage">
                Copy sudo link for page
              </span>
            </el-dropdown-item>

            <el-dropdown-item
              v-for="(item, index) in adminMenuItems"
              :key="`${index}-admin`"
              class="external-link"
            >
              <a :href="item.route" target="_blank">
                {{ item.title }}
              </a>
            </el-dropdown-item>
          </template>
        </template>

        <template v-else>
          <slot name="extra-items" />
        </template>

        <el-divider class="my-8" />

        <el-dropdown-item command="sign-out">
          Logout
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { EMPLOYEE_APP_URL, MAIN_APP_URL, EXTENSION_URL } from "@/common/Env";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "UserMenu",
  components: {
    UserAvatar,
  },
  props: {
    logoutOnly: {
      type: Boolean,
      default: false,
    },
    menuOnly: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      sudoOptions: [],
      supportIsOpen: false,
      menuRoutes: ["settings", "user-settings", "team-settings", "example-campaigns", "subscriptions"],
    };
  },
  computed: {
    ...mapGetters([
      "currentTeam",
      "currentUser",
      "isReferralsEnabled",
      "isUserSudoing",
    ]),
    teamsListUrl() {
      return `${MAIN_APP_URL}/admin/teams`;
    },
    usersListUrl() {
      return `${MAIN_APP_URL}/admin/users`;
    },
    opsSourcingUrl() {
      return `${MAIN_APP_URL}/worker/sourcing_roles?sourcer_id=${this.currentUser.id}`;
    },
    adminUserUrl() {
      return `${MAIN_APP_URL}/admin/users/stop_sudo?redirect=${MAIN_APP_URL}/admin/users/${this.currentUser.id}/edit`
    },
    adminTeamUrl() {
      return `${MAIN_APP_URL}/admin/users/stop_sudo?redirect=${MAIN_APP_URL}/admin/teams/${this.currentUser.primaryTeamId}/edit`
    },
    adminMenuItems() {
      const items = [];

      if (this.currentUser.actualUserInternalSourcingOnly) {
        items.push({ title: "Ops sourcing", route: this.opsSourcingUrl, icon: "engineering" });
      } else {
        items.push({ title: "Users admin", route: this.usersListUrl, icon: "manage_accounts" });
        items.push({ title: "Teams admin", route: this.teamsListUrl, icon: "groups" });
      }

      if (this.currentUser.showAdminOptions) {
        items.push({ title: "Users page", route: this.adminUserUrl, icon: "manage_accounts" });
        items.push({ title: "Teams page", route: this.adminTeamUrl, icon: "groups" });
      }

      return items;
    },
    userNameLabel() {
      if (this.isUserSudoing) {
        return `Sudoing as ${this.currentUser.name }`;
      }
      return this.currentUser.name;
    },
    filteredSudoOptions() {
      return this.sudoOptions.filter(o => o.id?.toString() !== this.currentUser.id?.toString());
    },
    employeeAppUrl() {
      return EMPLOYEE_APP_URL;
    },
    chromeExtensionLink() {
      return EXTENSION_URL;
    },
    showSubscriptions() {
      return !!this.currentTeam?.allowPlatformPurchase && this.currentUser.isTeamAdmin;
    },
    showDropdown() {
      return !this.$route.meta.skipAuthRedirect;
    },
  },
  methods: {
    handleCommand(command) {
      this.$router.push({ name: command });
    },
    sudoOptionLabel(option) {
      let str = `Sudo as ${option.role}`;
      if (option.name) {
        str = `${str} - ${option.name}`
      }
      return str;
    },
    sudoAsLink(option) {
      return `${MAIN_APP_URL}/admin/users/${option.id}/sudo?redirect=${encodeURIComponent(window.location.href)}`;
    },
    async copySudoLinkForPage() {
      const url = `${MAIN_APP_URL}/admin/users/${this.currentUser.id}/sudo?redirect=${encodeURIComponent(window.location.href)}`;
      await navigator.clipboard.writeText(url);
      this.$notify({
        position: "bottom-right",
        message: "Sudo link copied",
        title: "Success",
        type: "success",
      });
    },
    assignSudoOptions(options) {
      this.sudoOptions = options || [];
    },
  },
  mounted() {
    this.$bus.$on("sudo-options-change", this.assignSudoOptions);
  },
  beforeDestroy() {
    this.$bus.$off("sudo-options-change", this.assignSudoOptions);
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown-menu {
  max-height: calc(100vh - 16px);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  #help-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $tf-icon-size-mini;
    color: $tf-font-color-primary;
  }
}

.external-link {
  display: flex;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    color: $tf-font-color-regular !important;
    text-decoration: none;
  }

  .external-icon {
    margin-left: 4px;
    color: $tf-font-color-regular;
    font-size: 16px;
  }

  &:hover {
    a {
      color: $tf-font-color-link !important;
    }

    .external-icon {
      color: $tf-font-color-link;
    }
  }
}
</style>
