<template>
  <div
    class="topbar-alert"
    :class="type"
  >
    <span>
      <slot name="title">
        <b>{{ title }}</b>
      </slot>

      <slot name="description">
        {{ description }}
      </slot>

      <slot name="cta"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "TopBarAlert",
  components: {},
  props: {
    type: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.topbar-alert {
  height: 40px;
  color: white;
  background-color: $blue-100;
  display: flex;
  justify-content: center;
  align-items: center;

  &.info {
    background-color: $blue-100;
  }
  &.warning {
    background-color: $orange-500;
  }
  &.error {
    background-color: $magenta-100;
  }
  &.success {
    background-color: $green-100;
  }

  a {
    color: white !important;
    text-decoration: underline;
  }
}
</style>
