<script setup>
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";
import LogoSpinner from "@/components/LogoSpinner/LogoSpinner";

const emit = defineEmits([
  "stop-generating",
  "generate-new",
]);

defineProps({
  localMessageGenerating: {
    type: Boolean,
    required: false,
  },
  generateNewDisabled: {
    type: Boolean,
    required: false,
  },
})

const {
  currentCampaign,
  isCampaignFollowUpGenerating,
} = useGetters([
  "currentCampaign",
  "isCampaignFollowUpGenerating",
]);
const {
  stopGenerateNewMessage,
} = useActions([
  "stopGenerateNewMessage",
]);
const {
  setIsCampaignFollowUpGenerating,
} = useMutations([
  "setIsCampaignFollowUpGenerating",
]);

const stopGenerateNewMessageHandler = () => {
  emit("stop-generating");
  if (isCampaignFollowUpGenerating.value) {
    setIsCampaignFollowUpGenerating(false);
  }
  if (currentCampaign.value.generatingTemplates) {
    stopGenerateNewMessage();
  }
}

</script>

<template>
  <div>
    <el-button
      v-if="currentCampaign.generatingTemplates || localMessageGenerating"
      size="small"
      class="mb-4 py-0 px-0 generate-button"
      @click="stopGenerateNewMessageHandler"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div class="w-10 mr-8">
          <LogoSpinner />
        </div>
        <span class="candidate-count-label">Stop generating</span>
      </div>
    </el-button>

    <div v-else class="d-flex flex-column">
      <el-button
        size="small"
        type="primary"
        plain
        class="mb-4 generate-button"
        :disabled="currentCampaign.generatingTemplates || generateNewDisabled"
        @click="emit('generate-new')"
      >
        <div class="d-flex align-items-center justify-content-center">
          <span class="material-icons-round mr-8">auto_awesome</span>
          <span class="candidate-count-label">Generate new</span>
        </div>
      </el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.generate-button {
  width: 165px;
}
</style>
