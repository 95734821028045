<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";

const emit = defineEmits(["generate-new-campaign"]);

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const {
  isUserSudoing,
  inAppSourcingSelectedProject,
  sourceableCampaignList,
  inAppSourcingGeneratedCampaign,
  isCampaignFirstMessageGenerating,
  isCampaignFollowUpGenerating,
  isGeneratingNewCampaignForInAppSourcing,
  bulkSelectionAttributes,
} = useGetters([
  "isUserSudoing",
  "inAppSourcingSelectedProject",
  "sourceableCampaignList",
  "inAppSourcingGeneratedCampaign",
  "isCampaignFirstMessageGenerating",
  "isCampaignFollowUpGenerating",
  "isGeneratingNewCampaignForInAppSourcing",
  "bulkSelectionAttributes",
]);

const {
  setInAppSourcingMessage,
  setInAppSourcingSelectedCampaign,
} = useMutations([
  "setInAppSourcingMessage",
  "setInAppSourcingSelectedCampaign",
]);

const selectedOption = ref(null);

const campaignList = computed(() => {
  return sourceableCampaignList.value.filter(campaign => campaign.projectId === inAppSourcingSelectedProject.value?.id);
});

const isSelectorDisabled = computed(() => {
  return (
    isCampaignFollowUpGenerating.value ||
    isCampaignFirstMessageGenerating.value ||
    isGeneratingNewCampaignForInAppSourcing.value
  );
});

const title = computed(() => {
  return `Messaging options for <strong>${inAppSourcingSelectedProject.value?.name}</strong>`
});

const generateNewTitle = computed(() => {
  if (inAppSourcingGeneratedCampaign.value) {
    return (
      bulkSelectionAttributes.value ?
        "Generated new messaging" :
        `Generated ${inAppSourcingGeneratedCampaign.value.personalized ? "personalized " : ""}messaging`
    );
  }
  return bulkSelectionAttributes.value ? "Generate new messaging" : "Generate personalized messaging";
});

const prefixIcon = computed(() => {
  if (!selectedOption.value) return "";

  if (selectedOption.value === "generate-new") {
    return "auto_awesome";
  }

  return "send";
});

function optionChangeHandler(option) {
  setInAppSourcingMessage(null);
  setInAppSourcingSelectedCampaign(null);

  if (!option) {
    localStorage.removeItem("previousSelectedProjectCampaign");
    option = null;
    return;
  }

  if (option === "generate-new") {
    setInAppSourcingSelectedCampaign(null);
    emit("generate-new-campaign");
    return;
  }

  setInAppSourcingMessage({
    firstMessage: {
      cc: option.cc,
      bcc: option.bcc,
      body: option.body,
      subject: option.subject,
    },
    yourReplyNegative: option.yourReplyNegative,
    yourReplyPositive: option.yourReplyPositive,
    yourReplyOoo: option.yourReplyOoo,
    followUps: option.followUps,
    senderId: option.pseudoSenderId,
    senderName: option.senderName,
    replyHandlerId: option.replyHandlerId,
    reviewerIds: option.reviewerIds,
  });

  setInAppSourcingSelectedCampaign(selectedOption.value);

  if (!isUserSudoing.value) {
    localStorage.setItem("previousSelectedProjectCampaign", JSON.stringify(selectedOption.value));
  }
}

watch(inAppSourcingSelectedProject, (newProject) => {
  if (newProject) {
    selectedOption.value = null;
  }
})

watch(inAppSourcingGeneratedCampaign, (newValue) => {
  if (newValue) {
    selectedOption.value = "generate-new";
  }
})

onMounted(() => {
  if (!campaignList.value.length) {
    return;
  }
  const previouslySelected = localStorage.getItem("previousSelectedProjectCampaign");

  if (!isUserSudoing.value && previouslySelected) {
    selectedOption.value = campaignList.value.find(item => item.id === JSON.parse(previouslySelected).id) || campaignList.value[0];
  } else  {
    selectedOption.value = campaignList.value[0];
  }
  optionChangeHandler(selectedOption.value);
})

</script>

<template>
  <div id="in-app-sourcing-messaging-selector">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="title" v-html="title" />
      <slot></slot>
    </div>

    <el-skeleton :loading="loading" animated>
      <div slot="template">
        <el-skeleton-item class="selector-loader" variant="text"/>
      </div>

      <el-select
        v-model="selectedOption"
        class="messaging-selector"
        placeholder="None selected"
        clearable
        :disabled="isSelectorDisabled"
        filterable
        value-key="id"
        :loading="loading"
        no-data-text="No messaging options"
        @change="optionChangeHandler"
      >
        <template v-if="selectedOption" #prefix>
         <span
           class="material-icons-round md-18 prefix-icon"
           :class="{'text-color-blue-100': prefixIcon === 'auto_awesome'}"
         >
           {{ prefixIcon }}
         </span>
        </template>

        <el-option-group>
          <el-option
            value="generate-new"
            :label="generateNewTitle"
          >
            <span class="d-flex align-items-center">
              <span class="material-icons-round md-18 mr-8 text-color-blue-100">auto_awesome</span>
              <span class="project-name">{{generateNewTitle}}</span>
            </span>
          </el-option>
        </el-option-group>

        <el-option-group v-if="campaignList.length" label="Or select a campaign">
          <el-option
            v-for="campaign in campaignList"
            :key="campaign.id + '_campaign'"
            :value="campaign"
            :disabled="campaign.isDisabled"
            :label="campaign.name"
          >
            <span class="d-flex align-items-center">
              <span class="material-icons-round md-18 mr-8">send</span>
              <span class="project-name">{{ campaign.name }}</span>
            </span>
          </el-option>
        </el-option-group>

      </el-select>
    </el-skeleton>
  </div>
</template>

<style lang="scss">
#in-app-sourcing-messaging-selector {
  .title {
    font-size: 12px;
  }

  .new-campaign-button {
    font-size: 12px;
    color: $blue-100;
    cursor: pointer;
  }

  .messaging-selector {
    width: 100%;

    .prefix-icon {
      margin-top: 11px;
    }
  }

  .selector-loader {
    width: 100%;
    height: 40px;
  }
}

</style>
