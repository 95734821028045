<script setup>
import { computed, onMounted, watch, ref } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";

import CampaignsApi from "@/common/http/CampaignsApi";

import { TYPES } from "@/components/MessageGeneration/constants";
import MessageEditorWrapper from "@/components/MessageGeneration/MessageEditorWrapper/MessageEditorWrapper";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
});

const {
  bulkSelectionAttributes,
  inAppSourcingMessage,
  inAppSourcingSelectedCampaign,
  inAppSourcingSelectedProject,
  selectedCandidateInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "inAppSourcingMessage",
  "inAppSourcingSelectedCampaign",
  "inAppSourcingSelectedProject",
  "selectedCandidateInAppSourcing",
]);

const route = useRoute();
const isLoadingPreview = ref(false)

const enabled = computed(() => {
  return inAppSourcingMessage.value[props.type].enabled;
})

const isReplyPersonalized = computed(() => {
  return inAppSourcingMessage.value[props.type].isPersonalized;
});

function autoReplyEnabledToggled() {
  inAppSourcingMessage.value[props.type].enabled = !inAppSourcingMessage.value[props.type].enabled;
}

function messageChangeHandler(val) {
  inAppSourcingMessage.value[props.type] = {
    ...inAppSourcingMessage.value[props.type],
    ...val,
  };
}

async function getPreviewContent() {
  if (bulkSelectionAttributes.value || isReplyPersonalized.value !== undefined || props.messagingModeOnly) return;

  isLoadingPreview.value = true;

  const params = {
    message: inAppSourcingMessage.value[props.type].body,
    senderId: inAppSourcingMessage.value?.senderId,
    campaignId: inAppSourcingSelectedCampaign.value?.id,
    projectId: route.params.projectId || inAppSourcingSelectedProject.value?.id,
    referrals: false,
    profileId: selectedCandidateInAppSourcing.value?.profileId,
    tfId: selectedCandidateInAppSourcing.value?.tfId,
  };
  const { data } = await CampaignsApi.previewMessage(params);

  if (data.isPersonalized) {
    messageChangeHandler({
      subject: data.subject,
      body: data.message,
      isPersonalized: data.isPersonalized,
    });
  } else {
    inAppSourcingMessage.value[props.type].isPersonalized = data.isPersonalized;
  }
  isLoadingPreview.value = false;
}

watch(inAppSourcingSelectedCampaign, (newValue) => {
  if (newValue) {
    getPreviewContent();
  }
});

watch(() => props.type, (newValue) => {
  if (newValue) {
    getPreviewContent();
  }
});

onMounted(() => {
  getPreviewContent();
});

</script>

<template>
  <div>
    <div class="card-container">
      <div class="d-flex justify-content-between">
        <div v-if="type === TYPES.YOUR_REPLY_POSITIVE">
          <h6>Positive reply</h6>
          <small>When the candidate is interested in the job</small>
        </div>

        <div v-if="type === TYPES.YOUR_REPLY_NEGATIVE">
          <h6>Not interested reply</h6>
          <small>Candidate does not wish to continue</small>
        </div>

        <div v-if="type === TYPES.YOUR_REPLY_OOO">
          <h6>Out of office reply</h6>
          <small>The candidate is out of office</small>
        </div>

        <el-switch
          :value="enabled"
          @click.native.stop
          @change="autoReplyEnabledToggled"
        />
      </div>

      <MessageEditorWrapper
        :key="type"
        :type="type"
        :loading="isLoadingPreview"
        :show-variables="!isReplyPersonalized"
        :show-preview="!isReplyPersonalized"
        @message-change="messageChangeHandler"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
h6 {
  font-size: 18px;
  color: #1C1C1C;
  margin: 0;
}
small {
  font-size: 12px;
}
::v-deep #message-editor .quillWrapper {
  max-height: none !important;
}
</style>
