<script setup>
import { ref, unref, watchEffect, watch, onMounted, computed } from "vue";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";
import _ from "lodash";

import { useRoot } from "@/composables/misc/useRoot";
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from "vue-router/composables";
import {
  CONFIRM_FOLLOWUPS_CHANGE,
  UNSENT_TASKS_EXIST,
} from "@/constants";
import logEvent from "@/common/Analytics";

import SaveIndicator from "@/components/SaveIndicator";
import CampaignMessageEditor from "@/components/CampaignMessageEditor/Component";
import { TYPES } from "@/components/MessageGeneration/constants";

import SaveAndResetButtons
  from "./components/SaveAndResetButtons";
import MessageLoader from "./components/MessageLoader";

import message from "./lib/message";
import isMessageSubjectEditable from "./lib/isMessageSubjectEditable";
import allowMessageSentenceVariable from "./lib/allowMessageSentenceVariable";
import defaultFollowUpSubject from "./lib/defaultFollowUpSubject";
import messageChanged from "./lib/messageChanged";

const emit = defineEmits([
  "new-followup-saved",
  "message-change",
]);

const props = defineProps({
  showSaveResetButtons: {
    type: Boolean,
    default: false,
  },
  showVariables: {
    type: Boolean,
    default: true,
  },
  showTabs: {
    type: Boolean,
    default: true,
  },
  showSubject: {
    type: Boolean,
    default: true,
  },
  showPreview: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: false,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  previewOnly: {
    type: Boolean,
    default: false,
  },
  interpolateVariables: {
    type: Boolean,
    default: true,
  },
});

const {
  currentUserSettings,
  currentCampaign,
  inAppSourcingMessage,
  isCampaignMessageValid,
  newCampaign,
  newGeneratedMessage,
  unsavedMessages,
} = useGetters([
  "currentUserSettings",
  "currentCampaign",
  "inAppSourcingMessage",
  "isCampaignMessageValid",
  "newCampaign",
  "newGeneratedMessage",
  "unsavedMessages",
]);

const {
  setCurrentUserSettings,
  setCurrentCampaignAutoReplyMessage,
  setCurrentCampaignFirstMessage,
  setCurrentCampaignFollowUpMessage,
  setIsCampaignMessageValid,
  setMessageChanged,
  setUnsavedMessages,
} = useMutations([
  "setCurrentUserSettings",
  "setCurrentCampaignAutoReplyMessage",
  "setCurrentCampaignFirstMessage",
  "setCurrentCampaignFollowUpMessage",
  "setIsCampaignMessageValid",
  "setMessageChanged",
  "setUnsavedMessages",
]);

const {
  createCampaignFollowUp,
  updateCampaignFollowUp,
  updateCurrentCampaignAutoReply,
  updateCurrentCampaign,
  updateCurrentCampaignReferralTemplate,
} = useActions([
  "createCampaignFollowUp",
  "updateCampaignFollowUp",
  "updateCurrentCampaignAutoReply",
  "updateCurrentCampaign",
  "updateCurrentCampaignReferralTemplate",
]);

const root = useRoot();
const route = useRoute();
const editorId = ref(0);
const currentMessage = ref({});
const isSaving = ref(false);
const isInboundMessageChanged = ref(false);
const isReferralMessageChanged = ref(false);
const showMessagePreview = ref(false);
const hasMessageChangedLocal = ref(false); // when pressing save from campaign root's unsaved-changes-dialog, need to define which one of registered editors are dirty

const activeCampaign = computed(() => {
  return inAppSourcingMessage.value || currentCampaign.value || newCampaign.value;
});

const isEditable = computed(() => {
  if (props.readonly) {
    return false;
  }

  const campaign = unref(activeCampaign);

  /* for inAppSourcing sidenav's campaigns those dont have canEdit isClosed and id  */
  if (!campaign?.id) {
    return true;
  }

  return campaign.canEdit && !campaign.isClosed;
});

const confirmFollowUp = computed(() => {
  const campaign = unref(activeCampaign);
  return props.type === TYPES.FOLLOW_UPS && campaign.unsentFollowupsExist;
});

const confirmFirstMessage = computed(() => {
  const campaign = unref(activeCampaign);
  return props.type === TYPES.FIRST_MESSAGE && campaign.unsentTasksCount > 0;
});

const applyToAllOutreach = computed(() => {
  return confirmFirstMessage.value || confirmFollowUp.value;
});

const subject = computed(() => {
  if (!currentMessage.value.atsTemplate && !currentMessage.value.subject && activeCampaign.value.firstMessage.subject) {
    return `Re: ${activeCampaign.value.firstMessage.subject}`;
  }
  return currentMessage.value.subject;
});

const showSaveResetButtons = computed(() => {
  return (
    props.showSaveResetButtons &&
    applyToAllOutreach.value &&
    !showMessagePreview.value &&
    messageChanged(message(activeCampaign.value, { index: props.index, type: props.type }), currentMessage.value)
  );
});

const isTemplatesSettings = computed(() => {
  return route.name === "templates-settings";
});

const debounceWaitTime = computed(() => {
  return route.query.modal === "in-app-sourcing" || isTemplatesSettings.value ? 200 : 1000;
});

const debounceChangeHandler = _.debounce(function ({ message, isValid }) {
  if (!isEditable.value) {
    return;
  }

  if (props.type === TYPES.AUTO_REPLY_NEGATIVE || props.type === TYPES.AUTO_REPLY_POSITIVE) {
    isInboundMessageChanged.value = !(currentMessage.value.body === message.body && currentMessage.value.subject === message.subject);
  }

  constructMessageObject(message);
  setIsCampaignMessageValid(isValid);
  setMessageChanged(true);
  setUnsavedMessages({ ...unsavedMessages.value, [props.type]: true });
  hasMessageChangedLocal.value = true;

  if (!applyToAllOutreach.value && props.showSaveResetButtons) {
    messageSaveHandler();
  }

  if (inAppSourcingMessage.value) {
    emit("message-change", message);
    return;
  }

  if (props.type === TYPES.FIRST_MESSAGE) {
    logEvent("outbound-first-message-manually-edited", {
      campaignName: activeCampaign.value.name,
      campaignId: activeCampaign.value.id,
    });
  }

  if (props.type === TYPES.FOLLOW_UPS) {
    logEvent("outbound-followup-message-manually-edited", {
      campaignName: activeCampaign.value.name,
      campaignId: activeCampaign.value.id,
      followupId: activeCampaign.value.followUps[props.index].id,
    });
  }
}, debounceWaitTime.value);

function editorPreviewSwitchHandler(tab) {
  showMessagePreview.value = tab.label === "Preview";
}

function constructMessageObject(message) {
  currentMessage.value.subject = message.subject;
  currentMessage.value.body = message.body;
  currentMessage.value.cc = message.cc;
  currentMessage.value.bcc = message.bcc;
}

function resetMessageHandler() {
  constructMessageObject(message(activeCampaign.value, { index: props.index, type: props.type }));

  if (props.type === TYPES.FIRST_MESSAGE) {
    logEvent("outbound-first-message-discard-changes", {
      campaignName: activeCampaign.value.name,
      campaignId: activeCampaign.value.id,
    });
  }

  if (props.type === TYPES.FOLLOW_UPS) {
    logEvent("outbound-followup-message-discard-changed", {
      campaignName: activeCampaign.value.name,
      campaignId: activeCampaign.value.id,
      followupId: activeCampaign.value.followUps[props.index].id,
    });
  }

  setMessageChanged(false);
  const unsavedMsg = unref(unsavedMessages.value);
  delete unsavedMsg[props.type];
  setUnsavedMessages(unsavedMsg);
  hasMessageChangedLocal.value = false;
  editorId.value = Date.now();
}

function messageSaveHandler() {
  if (!applyToAllOutreach.value) {
    isSaving.value = true;
  }

  const campaign = unref(activeCampaign);
  const newMessage = unref(currentMessage);

  switch (props.type) {
    case TYPES.AUTO_REPLY_POSITIVE:
    case TYPES.AUTO_REPLY_NEGATIVE:
    case TYPES.AUTO_REPLY_OOO:
      setCurrentCampaignAutoReplyMessage({ message: newMessage, type: props.type });
      campaign[props.type].confirmed = true;

      if (isTemplatesSettings.value) {
        let settings = { ...currentUserSettings.value };

        if (props.type === TYPES.AUTO_REPLY_POSITIVE) {
          settings = {
            ...settings,
            inboundAcceptBcc: currentMessage.value.bcc,
            inboundAcceptCc: currentMessage.value.cc,
            inboundAcceptSubject: currentMessage.value.subject,
            inboundAcceptBody: currentMessage.value.body,
          }
        } else {
          settings = {
            ...settings,
            inboundRejectBcc: currentMessage.value.bcc,
            inboundRejectCc: currentMessage.value.cc,
            inboundRejectSubject: currentMessage.value.subject,
            inboundRejectBody: currentMessage.value.body,
          }
        }

        setCurrentUserSettings(settings);
        root.$bus.$emit("update-settings");
      } else {
        updateCurrentCampaignAutoReply();
      }

      break;

    case TYPES.FIRST_MESSAGE:
      setCurrentCampaignFirstMessage(newMessage);
      updateCurrentCampaign(applyToAllOutreach.value).then(() => root.$bus.$emit("update-campaign-in-list", campaign));

      logEvent("outbound-first-message-saved", {
        campaignName: activeCampaign.value.name,
        campaignId: activeCampaign.value.id,
      });

      break;

    case TYPES.FOLLOW_UPS:
      if (newMessage.id) {
        setCurrentCampaignFollowUpMessage({ message: { ...newMessage, subject: defaultFollowUpSubject(activeCampaign.value?.firstMessage.subject) }, followUpId: newMessage.id });
        updateCampaignFollowUp({ id: newMessage.id, applyToAllOutreach: applyToAllOutreach.value });

        logEvent("outbound-followup-message-saved", {
          campaignName: activeCampaign.value.name,
          campaignId: activeCampaign.value.id,
          followupId: newMessage.id,
        });
      } else {
        // Uses default send_days and num_days and sequence num on backend
        createCampaignFollowUp({ campaign, message: { ...newMessage, subject: defaultFollowUpSubject(activeCampaign.value?.firstMessage.subject) }, idx: props.index });
        emit("new-followup-saved");

        if (props.type === TYPES.FOLLOW_UPS) {
          logEvent("outbound-new-followup-added", {
            campaignName: activeCampaign.value.name,
            campaignId: activeCampaign.value.id,
          });
        }
      }
      break;

    case TYPES.INTRO_REQUESTS:
    case TYPES.INTRO_MESSAGE:
      if (isTemplatesSettings.value) {
        if (props.type === TYPES.INTRO_REQUESTS) {
          const introRequestsObject = {
            referralsRequestBcc: currentMessage.value.bcc,
            referralsRequestCc: currentMessage.value.cc,
            referralsRequestBody: currentMessage.value.body,
            referralsRequestSubject: currentMessage.value.subject,
          };

          const introRequestsObjectDefault = {
            referralsRequestBcc: currentUserSettings.value.referralsRequestBcc,
            referralsRequestCc: currentUserSettings.value.referralsRequestCc,
            referralsRequestBody: currentUserSettings.value.referralsRequestBody,
            referralsRequestSubject: currentUserSettings.value.referralsRequestSubject,
          };

          isReferralMessageChanged.value = !_.isEqual(introRequestsObject, introRequestsObjectDefault);
          setCurrentUserSettings({
            ...currentUserSettings.value,
            ...introRequestsObject,
          });
        } else {
          const referralsMessageObject = {
            referralsMessageBcc: currentMessage.value.bcc,
            referralsMessageCc: currentMessage.value.cc,
            referralsMessageBody: currentMessage.value.body,
            referralsMessageSubject: currentMessage.value.subject,
          }

          const referralsMessageObjectDefault = {
            referralsMessageBcc: currentUserSettings.value.referralsMessageBcc,
            referralsMessageCc: currentUserSettings.value.referralsMessageCc,
            referralsMessageBody: currentUserSettings.value.referralsMessageBody,
            referralsMessageSubject: currentUserSettings.value.referralsMessageSubject,
          }

          isReferralMessageChanged.value = !_.isEqual(referralsMessageObject, referralsMessageObjectDefault);
          setCurrentUserSettings({
            ...currentUserSettings.value,
            ...referralsMessageObject,
          });
        }
        root.$bus.$emit("update-settings");
      } else {
        updateCurrentCampaignReferralTemplate({
          messageContent: newMessage,
          messageType: props.type,
        });
      }
      break;

    default:
      break;
  }

  setMessageChanged(false);

  const unsavedMsg = unref(unsavedMessages.value);
  delete unsavedMsg[props.type];
  setUnsavedMessages(unsavedMsg);

  if (applyToAllOutreach.value) {
    let notifyMessage = "Message saved.";
    if (applyToAllOutreach.value) {
      notifyMessage += ` All pending ${props.type === TYPES.FIRST_MESSAGE ? "outreach" : "follow-ups"} updated.`;
    }
    root.$notify({
      title: "Success",
      position: "bottom-right",
      message: notifyMessage,
      type: "success",
    });
  } else {
    setTimeout(() => {
      isSaving.value = false;
    }, 2000);
  }
}

function promptApplyChangesToAll() {
  let confirmHandler;

  if (confirmFollowUp.value) {
    confirmHandler = () => {
      return root.$confirm(CONFIRM_FOLLOWUPS_CHANGE, "Apply changes to all?", {
        confirmButtonText: "Apply to all",
        cancelButtonText: "Don't change scheduled follow-ups",
        distinguishCancelAndClose: true,
      })
    };
  } else if (confirmFirstMessage.value) {
    confirmHandler = () => {
      return root.$confirm(UNSENT_TASKS_EXIST, "Apply changes to all?", {
        confirmButtonText: "Apply to all",
        cancelButtonText: "Don't change unsent outreach",
        distinguishCancelAndClose: true,
      })
    };
  }

  if (confirmHandler) {
    confirmHandler().then(() => {
      messageSaveHandler();
    }).catch(action => {
      if (action === "cancel") {
        messageSaveHandler();
      }
    });
  } else {
    messageSaveHandler();
  }
}

function saveUnsavedChangesHandler() {
  if (hasMessageChangedLocal.value) {
    promptApplyChangesToAll();
    setMessageChanged(false);

    const unsavedMsg = unref(unsavedMessages.value);
    delete unsavedMsg[props.type];
    setUnsavedMessages(unsavedMsg);

    hasMessageChangedLocal.value = false;
  }
}

watch(newGeneratedMessage, (val) => {
  if (val.subject || props.index === val.idx) {
    currentMessage.value.subject = val?.subject || currentMessage.value.subject;
    currentMessage.value.body = val.body;
    setMessageChanged(true);
    setUnsavedMessages({ ...unsavedMessages.value, [props.type]: true });
    hasMessageChangedLocal.value = true;

    if (!applyToAllOutreach.value) {
      messageSaveHandler();
    }
  }
});

watchEffect(() => {
  currentMessage.value = { ...message(activeCampaign.value, { index: props.index, type: props.type }) };
});

watchEffect(() => {
  if (activeCampaign.value) {
    constructMessageObject(message(activeCampaign.value, { index: props.index, type: props.type }));
  }
});

onMounted(() => {
  root.$bus.$on("save-unsaved-changes", saveUnsavedChangesHandler);
});

function applyChangesToAll(next) {
  if (isTemplatesSettings.value && (isInboundMessageChanged.value || isReferralMessageChanged.value)) {
    root.$confirm(`Apply this change to all existing ${isInboundMessageChanged.value ? "inbound" : "referrals"} campaigns?`, "Apply change to all", {
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "warning",
    }).then(() => {
      setCurrentUserSettings({
        ...currentUserSettings.value,
        backfillInboundCampaignsFromDefaultTemplate: isInboundMessageChanged.value,
        backfillReferralsCampaignsFromDefaultTemplate: isReferralMessageChanged.value,
      });
      root.$bus.$emit("update-settings");
      next();
    }).catch(() => {
      next();
    });
  } else {
    next();
  }
  root.$bus.$off("save-unsaved-changes", saveUnsavedChangesHandler);
}

onBeforeRouteUpdate((to, from, next) => {
  applyChangesToAll(next);
});

onBeforeRouteLeave((to, from, next) => {
  applyChangesToAll(next);
});
</script>

<template>
  <div class="relative">
    <el-tabs v-if="showTabs" @tab-click="editorPreviewSwitchHandler">
      <el-tab-pane v-if="!previewOnly" label="Editor"></el-tab-pane>
      <el-tab-pane v-if="showPreview" label="Preview"></el-tab-pane>
    </el-tabs>

    <SaveIndicator class="save-indicator" :is-saving="isSaving" />

    <MessageLoader v-if="loading || (activeCampaign.generatingTemplates && (type === TYPES.FIRST_MESSAGE || type === TYPES.FOLLOW_UPS))" />

    <div v-else>
      <div class="w-100">
        <CampaignMessageEditor
          :key="editorId"
          :type="type"
          :disabled="!isEditable"
          :cc="currentMessage.cc"
          :bcc="currentMessage.bcc"
          :subject="subject"
          :show-variables="showVariables"
          :show-subject="showSubject"
          :body="currentMessage.body"
          :is-subject-editable="isEditable && isMessageSubjectEditable(type, activeCampaign)"
          :allow-sentence="allowMessageSentenceVariable(type, activeCampaign?.inbound)"
          :allow-calendar="activeCampaign?.senderHasCalendar"
          :allow-company="!activeCampaign.inbound"
          :show-preview="showMessagePreview"
          :sender-id="activeCampaign.senderId"
          :use-intro-request-variables="type === TYPES.INTRO_REQUESTS"
          :use-intro-message-variables="type === TYPES.INTRO_MESSAGE"
          :interpolate-variables="interpolateVariables"
          :preview-only="previewOnly"
          @message-change="debounceChangeHandler"
        />
        <slot></slot>
      </div>
    </div>

    <SaveAndResetButtons
      v-if="showSaveResetButtons"
      :save-disabled="!isCampaignMessageValid"
      @reset="resetMessageHandler"
      @message-save="promptApplyChangesToAll"
    />

  </div>
</template>

<style scoped lang="scss">
.save-indicator {
  position: absolute;
  top: 12px;
  right: 0;
}
</style>
