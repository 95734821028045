<template>
  <div id="sudo-user" class="sudo-indicator d-flex justify-content-between align-items-center px-16">
    <span>You are sudoing as <b>{{ currentUser.name }}</b> of <b>{{ currentTeam.name }}</b></span>

    <div class="d-flex justify-content-between align-items-center">
      <a class="text-decoration-none" :href="stopSudoLink">
        <el-button class="sudo-indicator-button p-0" type="text" size="mini">
          <span class="d-flex align-items-center">
            <span class="material-icons-round md-18">pause_circle</span>
            <span>Stop Sudoing</span>
          </span>
        </el-button>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MAIN_APP_URL } from "@/common/Env";

export default {
  name: "SudoUser",
  computed: {
    ...mapGetters([
      "currentUser",
      "currentTeam",
    ]),
    stopSudoLink() {
      return `${MAIN_APP_URL}/admin/users/stop_sudo`;
    },
  },
};
</script>

<style lang="scss" scoped>
#sudo-user {
  height: 40px;
  font-size: 14px;
  color: $tf-card-color;
  background-color: $magenta-30;

  .sudo-section-divider {
    background-color: $tf-card-color;
  }

  .copy-sudo-button {
    color: $tf-card-color;
  }

  a {
    font-size: 14px;

    .sudo-indicator-button {
      font-weight: 600;
      color: $magenta-100 !important;
    }
  }
}
</style>
