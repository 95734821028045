import ApiClient from "@/common/http/ApiClient";

class CheckoutApi {
  static reviewPlatform() {
    return ApiClient.post("payments/create_platform_review");
  }

  static checkout(params = {}) {
    return ApiClient.post("payments/checkout", params);
  }

  static getSubscriptions() {
    return ApiClient.get("payments/subscriptions");
  }

  static getSubscription() {
    return ApiClient.get("stripe_subscriptions");
  }

  static loadReviewInfo(uuid = "") {
    const params = { uuid };
    return ApiClient.get("payments/show_review/", params);
  }

  static getCustomerPortalLink() {
    return ApiClient.get("payments/customer_portal");
  }

  static requestSubscriptionCancel(reason, message) {
    return ApiClient.post("payments/user_cancel_request", { reason, message });
  }

  static requestPlatformUpgrade() {
    return ApiClient.put("payments/team_request_platform_upgrade");
  }

  static requestPlatformPremium(params) {
    return ApiClient.post("payments/team_request_platform_premium", params);
  }

  static approvePlatformSeat(id) {
    return ApiClient.put(`payments/approve_platform_seat?id=${id}`);
  }
}

export default CheckoutApi;
