import { extractNameRates } from "./extractNameRates";

export const tokenTooltip = (token) => {
  switch (token.type) {
    case "role-current":
    case "role-previous":
    case "role": {
      const employments = token.employments
        .map(it => it.years > 0 ? `${it.name} for ${it.years} years` : it.name)
        .join(", ");

      return `Worked at ${employments}`;
    }
    case "edu-institution": {
      const educations = token.educations
        .map(it => it.years > 0 ? `${it.name} for ${it.years} years` : it.name)
        .join(", ");

      return `Went to ${educations}`;
    }
    case "employer-current":
    case "employer-previous":
    case "employer": {
      if (token.yearsTotal) {
        return `Worked for ${token.yearsTotal} years`;
      }
      return `Worked at ${token.text}`
    }
    case "emp-industry": {
      if (token.yearsTotal) {
        return `Worked in ${token.text} for ${token.yearsTotal} years`;
      }

      break;
    }
    case "emp-start-size": {
      const { from, to } = extractNameRates(token.text);
      if (!to || !from) return;
      if (to === "0") return;

      const companyNames = token.employments
        .map(it => it.name)
        .join(", ");

      return `Worked for ${companyNames} when there were ${from} - ${to} people`;
    }
    case "emp-growth-rate": {
      const { name, from, to } = extractNameRates(token.text);

      const showPercents = to && from && to !== "0";

      const companyNames = token.employments
        .map(it => it.name)
        .join(", ");

      return `Experienced ${name.toLowerCase()} ${showPercents ? ` (${from}% - ${to}%)` : ""} growth while at ${companyNames}`;
    }
  }
}
