export const CALENDAR_DOMAINS = [
  "calendly.com",
  "meetings.hubspot.com",
  "youcanbook.me",
  "meetingbird.com",
  "x.ai/calendar",
  "goodtime.io",
  "cal.mixmax.com",
  "calendar.google.com",
  "interviewschedule.com",
  "info.salesinsider.io",
];

export const DEFAULT_TEMPLATE_CAMPAIGN_ID = "templates-settings";

export const TOUCHPOINT_STATUSES = [
  "sourced",
  "reached",
  "responded",
  "interested",
  "interview",
  "hired",
];

export const INBOUND_STATUSES = [
  null,
  null,
  null,
  "applied ", // empty values precede this so that "applied" index matches "interested"
  "interview",
  "hired",
];

export const INTRO_PACK_STATUSES = [
  "intro requested",
  "intro sent",
  "intro opened",
  "applied",
  "interview",
  "hired",
];

export const GREENHOUSE = { label: "Greenhouse", slug: "greenhouse" };

export const INTRO_TIMELINE_STATUSES = Object.freeze({
  resumeUploaded:      { id: "resume_uploaded",    label: "Referral submitted" },
  requested:           { id: "requested",          label: "Intro requested" },
  employeeResponded:   { id: "employee_responded", label: "Employee responded" },
  linkGenerated:       { id: "link_generated",     label: "Intro sent" },
  linkOpened:          { id: "link_opened",        label: "Intro opened" },
  applied:             { id: "applied",            label: "Applied" },
  outreach:            { id: "outreach",           label: "Fallback outreach" },
});

export const REFERRAL_CAMPAIGN_STATISTICS_KEYS = Object.freeze({
  sourced:          { slug: "sourcedCount",       tooltip: "Intro requested" },
  reached:          { slug: "reachedCount",       tooltip: "Intro sent" },
  responded:        { slug: "respondedCount",     tooltip: "Intro viewed" },
  respondedRate:    { slug: "respondedRate",      tooltip: "Intro viewed rate" },
  interested:       { slug: "interestedCount",    tooltip: "Total Applications" },
  interestedRate:   { slug: "interestedRate",     tooltip: "Applied rate" },
  interview:        { slug: "interviewCount",     tooltip: "Interview" },
  interviewRate:    { slug: "interviewRate",      tooltip: "Interview rate" },
  hired:            { slug: "hiredCount",         tooltip: "Hired" },
  hiredRate:        { slug: "hiredRate",          tooltip: "Hired rate" },
});

export const OUTREACH_CAMPAIGN_STATISTICS_KEYS = Object.freeze({
  clicked:            { slug: "emailClicksCount",       tooltip: "Clicked" },
  clickedRate:        { slug: "emailClicksRate",        tooltip: "Clicked rate" },
  ethnicity:          { slug: "reachedEthnicityCount",  tooltip: "URG" },
  ethnicityRate:      { slug: "reachedEthnicityRate",   tooltip: "URG rate" },
  hired:              { slug: "hiredCount",             tooltip: "Hired" },
  hiredRate:          { slug: "hiredRate",              tooltip: "Hired rate" },
  interested:         { slug: "interestedCount",        tooltip: "Interested" },
  interestedRate:     { slug: "interestedRate",         tooltip: "Interested response rate" },
  interview:          { slug: "interviewCount",         tooltip: "Interview" },
  interviewRate:      { slug: "interviewRate",          tooltip: "Interview rate" },
  opened:             { slug: "emailOpenCount",         tooltip: "Opened" },
  openedRate:         { slug: "emailOpenRate",          tooltip: "Opened rate" },
  reached:            { slug: "reachedCount",           tooltip: "Reached" },
  responded:          { slug: "respondedCount",         tooltip: "Responded" },
  respondedRate:      { slug: "respondedRate",          tooltip: "Responded rate" },
  chatbot:            { slug: "chatbotCompleteCount",   tooltip: "Chatbot completion" },
  chatbotRate:        { slug: "chatbotCompleteRate",    tooltip: "Chatbot completion rate" },
  sourced:            { slug: "sourcedCount",           tooltip: "Sourced" },
  veterans:           { slug: "reachedMilitaryCount",   tooltip: "Veterans" },
  veteransRate:       { slug: "reachedMilitaryRate",    tooltip: "Veterans rate" },
  women:              { slug: "reachedWomenCount",      tooltip: "Women" },
  womenRate:          { slug: "reachedWomenRate",       tooltip: "Women rate" },
});

export const INBOUND_CAMPAIGN_STATISTICS_KEYS = Object.freeze({
  sourced:          null,
  reached:          null,
  responded:        null,
  respondedRate:    null,
  interested:       { slug: "interestedCount",    tooltip: "Applied" },
  interestedRate:   { slug: "interestedRate",     tooltip: "Applied rate" },
  interview:        { slug: "interviewCount",     tooltip: "Interview" },
  interviewRate:    { slug: "interviewRate",      tooltip: "Interview rate" },
  hired:            { slug: "hiredCount",         tooltip: "Hired" },
  hiredRate:        { slug: "hiredRate",          tooltip: "Hired rate" },
});

export const HIGHLIGHT_TOKEN_TYPE = Object.freeze({
  plain: "plain",
  location: "location",
  // TODO...
});

export const TITLE_REGEX = /^[^"<>=]+$/;
export const MAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/;
export const LINKEDIN_REGEX = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/in\/[\w_-]+\/?$/;

export * from "./checkout";
export * from "./candidate";
export * from "./project";
export * from "./filter";
export * from "./text";
export * from "./review";
