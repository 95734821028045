<template>
  <CampaignMessageModal
    v-if="newCampaign"
    :show="show"
    type="firstMessage"
    save-button-label="Create new campaign"
    :body="newCampaign.firstMessage.body"
    :subject="newCampaign.firstMessage.subject"
    :cc="newCampaign.cc"
    :bcc="newCampaign.bcc"
    :sender-id="newCampaign.senderId"
    :allow-sentence="allowSentence"
    :allow-calendar="allowCalendar"
    @closed="closed"
    @saved="saved"
  />
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex";
import logEvent from "@/common/Analytics";
import CampaignMessageModal from "@/components/CampaignMessageModal/Component";
export default {
  name: "NewCampaignMessageModal",
  components: {
    CampaignMessageModal,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "currentCampaign",
      "isNewCampaignValidForFirstMessage",
      "newCampaign",
    ]),
    allowSentence() {
      return this.newCampaign && !this.newCampaign.inbound;
    },
    allowCalendar() {
      return this.newCampaign?.senderHasCalendar;
    },
    isNewFirstMessage() {
      return this.$route.query.modal === "new-campaign-first-message";
    },
    show() {
      return this.isNewFirstMessage && this.isNewCampaignValidForFirstMessage;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.isNewFirstMessage && !this.isNewCampaignValidForFirstMessage) {
          const query = Object.assign({}, this.$route.query);
          query.modal = "new-campaign";
          this.$router.replace({ query });
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "createCampaign",
    ]),
    ...mapMutations([
      "setNewCampaign",
      "setNewCampaignFirstMessage",
    ]),
    closed() {
      this.setNewCampaign(null);
    },
    saved(message) {
      this.setNewCampaignFirstMessage(message);
      logEvent("create-new-campaign-finished");

      this.createCampaign().then(() => {
        this.$bus.$emit("add-new-campaign-in-list", this.currentCampaign);
        this.$router.push({
          name: "job-campaign-root",
          params: {
            projectId: this.currentCampaign.projectId, campaignId: this.currentCampaign.id,
          },
        });
      });
    },
  },
};
</script>

