import { unref, computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import ProjectsApi from "@/common/http/ProjectsApi";

export const queryKey = ({ projectId }) => ["recommendation-feedbacks", projectId];

export const useRecommendationFeedbacks = ({
  projectId,
  ...rest
}) => {
  const query = useQuery({
    queryKey: queryKey({ projectId }),
    queryFn: () => ProjectsApi.getRecommendationsFeedbacks({ projectId: unref(projectId) }),
    ...rest,
  });

  const recommendationFeedbacks = computed(() => (
    unref(query.data)?.data?.feedbacks || []
  ));

  return {
    ...query,
    recommendationFeedbacks,
  };
}
