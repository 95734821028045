<script setup>
import { computed, ref } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";
import { useRoute, useRouter } from "vue-router/composables";

import { SAND_AS_TOOLTIP_LABEL, ROBO_TOOLTIP_LABEL, REVIEWERS_TOOLTIP_LABEL } from "@/constants";

import UserSendAsPermissionRequestModal from "@/components/UserSendAsPermissionRequestModal/Component";

const {
  inAppSourcingMessage,
  inAppSourcingSelectedCampaign,
  teamReplyHandlerOptions,
  teamReviewerOptions,
} = useGetters([
  "inAppSourcingMessage",
  "inAppSourcingSelectedCampaign",
  "teamReplyHandlerOptions",
  "teamReviewerOptions",
]);

const {
  setInAppSourcingMessage,
} = useMutations([
  "setInAppSourcingMessage",
]);

const route = useRoute();
const router = useRouter();
const requestSendAsTeammate = ref(null);

const isUserSendAsPermissionRequestModalVisible = computed(() => {
  return requestSendAsTeammate.value && !!route.query["send-as-teammate"];
});

const replyHandlerOptions = computed(() => {
  if (inAppSourcingSelectedCampaign.value.teamId) {
    return teamReplyHandlerOptions.value?.find(option => option.teamId === inAppSourcingSelectedCampaign.value.teamId)?.options || [];
  }
  return [];
});

const reviewerOptions = computed(() => {
  if (inAppSourcingSelectedCampaign.value.teamId) {
    return teamReviewerOptions.value?.find(option => option.teamId === inAppSourcingSelectedCampaign.value.teamId)?.options || [];
  }
  return [];
});

const senderOptions = computed(() => {
  return inAppSourcingSelectedCampaign.value.teammateSenderOptions || inAppSourcingSelectedCampaign.value.senderOptions;
});

const isSenderNameHardcoded = computed(() => {
  if (!inAppSourcingMessage.value?.senderName) {
    return false;
  }
  const senderFirstNameRegEx = new RegExp(inAppSourcingSelectedCampaign.value?.senderName?.split(" ")[0], "gi");
  const bodies = [
    inAppSourcingMessage.value.firstMessage.body,
    ...inAppSourcingMessage.value.followUps.map(item => item.body),
    inAppSourcingMessage.value.yourReplyPositive.body,
    inAppSourcingMessage.value.yourReplyNegative.body,
    inAppSourcingMessage.value.yourReplyOoo.body,
  ];
  return bodies.some(item => item?.toLowerCase().match(senderFirstNameRegEx));
});

const isCalendarHardcoded = computed(() => {
  if (!inAppSourcingMessage.value?.senderName) {
    return false;
  }
  const senderFirstNameRegEx = new RegExp("calendar", "gi");
  const bodies = [
    inAppSourcingMessage.value.firstMessage.body,
    ...inAppSourcingMessage.value.followUps.map(item => item.body),
    inAppSourcingMessage.value.yourReplyPositive.body,
    inAppSourcingMessage.value.yourReplyNegative.body,
    inAppSourcingMessage.value.yourReplyOoo.body,
  ];
  return bodies.some(item => item?.toLowerCase().match(senderFirstNameRegEx));
});


const senderId = computed({
  get() {
    return senderOptions.value.find(item => item.id === inAppSourcingMessage.value.senderId);
  },
  set(teammate) {
    if (!teammate.canSendAs) {
      const query = Object.assign({}, route.query);
      query["send-as-teammate"] = teammate.id;
      requestSendAsTeammate.value = teammate;
      router.push({ query });
      return;
    }

    setInAppSourcingMessage({
      ...inAppSourcingMessage.value,
      senderId: teammate.id,
      senderName: teammate.name,
    });
  },
});

const replyHandlerId = computed({
  get() {
    return inAppSourcingMessage.value.replyHandlerId;
  },
  set(value) {
    setInAppSourcingMessage({
      ...inAppSourcingMessage.value,
      replyHandlerId: value,
    });
  },
});

const reviewerIds = computed({
  get() {
    return inAppSourcingMessage.value.reviewerIds;
  },
  set(value) {
    setInAppSourcingMessage({
      ...inAppSourcingMessage.value,
      reviewerIds: value,
    });
  },
});

const showHardcodedSenderNameAlert = computed(() => {
  return (inAppSourcingSelectedCampaign.value?.senderName !== inAppSourcingMessage.value.senderName) && isSenderNameHardcoded.value;
});

function isSenderDisabled(sender) {
  return !!(isCalendarHardcoded.value && !sender.hasCalendar);
}
</script>

<template>
  <div class="w-100 mb-16">
    <div class="d-flex align-items-center justify-content-between">
      <div class="w-100">
        <div class="input-title">
          <div class="d-flex align-items-center">
            <small class="mr-4">Send messages as</small>
            <el-tooltip
              :open-delay="500"
              :content="SAND_AS_TOOLTIP_LABEL"
            >
              <span class="material-icons-round md-18 cursor-help">help_outline</span>
            </el-tooltip>
          </div>
        </div>

        <el-select
          v-model="senderId"
          class="w-100"
          value-key="id"
          filterable
        >
          <el-option
            v-for="teammate in senderOptions"
            :key="teammate.id"
            :disabled="isSenderDisabled(teammate)"
            :label="teammate.label"
            :value="teammate"
          >
            <el-tooltip :disabled="!isSenderDisabled(teammate)" content="Sender does not have calendar, update messaging to enable this sender" :open-delay="500">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ teammate.label }}</span>
                <span v-if="!teammate.canSendAs" class="material-icons-round md-14 cursor-help">person_off</span>
              </div>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>

      <div class="w-100 ml-16">
        <div id="reply-handling" class="input-title">
          <span class="d-flex align-items-center">
            <small class="mr-4">Who should handle replies?</small>
            <el-tooltip :open-delay="500" :content="ROBO_TOOLTIP_LABEL">
              <span class="material-icons-round md-18 cursor-help">help_outline</span>
            </el-tooltip>
          </span>
        </div>
        <el-select
          v-model="replyHandlerId"
          class="w-100"
          value-key="id"
          filterable
        >
          <el-option
            v-for="teammate in replyHandlerOptions"
            :key="teammate.id"
            :label="teammate.label"
            :value="teammate.id"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ teammate.label }}</span>
            </div>
          </el-option>
        </el-select>
      </div>

      <div class="w-100 ml-16">
        <div id="review-list" class="input-title">
          <span class="d-flex align-items-center">
          <small class="mr-4">Reviewers</small>
            <el-tooltip :open-delay="500" :content="REVIEWERS_TOOLTIP_LABEL">
              <span class="material-icons-round md-18 cursor-help">help_outline</span>
            </el-tooltip>
          </span>
        </div>
        <el-select
          v-model="reviewerIds"
          class="w-100"
          value-key="id"
          multiple
          filterable
          collapse-tags
        >
          <el-option
            v-for="teammate in reviewerOptions"
            :key="teammate.id"
            :label="teammate.label"
            :value="teammate.id"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ teammate.label }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
    </div>

    <el-alert
      v-if="showHardcodedSenderNameAlert"
      type="warning"
      class="mt-16"
      description="Please modify all messaging to reflect the sender change"
      :closable="false"
      show-icon
    />

    <UserSendAsPermissionRequestModal
      :show="isUserSendAsPermissionRequestModalVisible"
      :send-as-teammate="requestSendAsTeammate"
    />
  </div>
</template>

<style lang="scss">

</style>
