<script setup>
import { computed, ref } from "vue";
import { useGetters, useActions } from "vuex-composition-helpers";
import JobAndCampaignSelectorWrapper from "@/components/JobAndCampaignSelector/JobAndCampaignSelectorWrapper";

const emit = defineEmits(["update:showModal"]);

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const selectedProject = ref(null);

const {
  bulkSelectionAttributes,
  selectedCandidateInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "selectedCandidateInAppSourcing",
]);

const {
  resetInAppSourcingSelectedProject,
} = useActions([
  "resetInAppSourcingSelectedProject",
]);

const computedShowModal = computed({
  get() {
    return props.showModal;
  },
  set(value) {
    emit("update:showModal", value);
  },
});

const title = computed(() => {
  if (selectedCandidateInAppSourcing.value) {
    return `Move <strong>${selectedCandidateInAppSourcing.value.name || "candidate"}</strong> to another job`;
  }

  if (bulkSelectionAttributes.value) {
    const candidateWord = bulkSelectionAttributes.value.count === 1 ? "candidate" : "candidates";
    return `Move these <strong>${bulkSelectionAttributes.value.count} ${candidateWord}</strong> to another job`;
  }

  return null;
});

function saveHandler() {
  computedShowModal.value = false;
  resetInAppSourcingSelectedProject(selectedProject.value);
  localStorage.removeItem("previousSelectedProjectCampaign");
}

function changeHandler(val) {
  selectedProject.value = val?.project;
}

function cancelHandler() {
  computedShowModal.value = false;
  localStorage.removeItem("project-campaign-selection");
  localStorage.removeItem("previousSelectedProjectCampaign");
}
</script>

<template>
  <el-dialog
    id="select-another-job-modal"
    :visible="computedShowModal"
    :append-to-body="true"
    width="50%"
    :show-close="false"
  >
    <template #title>
      <div class="title" v-html="title"></div>
    </template>
    <p class="mb-16">Select the job into which you'd like to source this candidate.</p>

    <JobAndCampaignSelectorWrapper
      class="mb-8"
      hide-create-new-job
      placeholder="Choose a job"
      :show-campaigns="false"
      @change="changeHandler"
    />

    <template #footer>
      <el-button
        class="mr-24"
        type="text"
        link
        @click="cancelHandler"
      >
        Cancel
      </el-button>

      <el-button
        :disabled="!selectedProject"
        type="primary"
        @click="saveHandler"
      >
        Continue
      </el-button>
    </template>
  </el-dialog>
</template>

<style lang="scss">
#in-app-sourcing-header {
  .title {
    font-size: 16px;
  }
}

</style>
