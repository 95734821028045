import moment from "moment";
import CheckoutApi from "@/common/http/CheckoutApi";

const state = {
  primarySubscription: {},
  subscriptions: [],
};

const getters = {
  primarySubscription(state) {
    return state.primarySubscription;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
};

const actions = {
  async fetchSubscriptions({ commit }) {
    const { data } = await CheckoutApi.getSubscriptions();
    commit("setSubscriptions", data);
    commit("setPrimarySubscriptionFromList", data);
  },
  async fetchSubscription({ commit }) {
    const { data } = await CheckoutApi.getSubscription();
    commit("setPrimarySubscription", data);
  },
};

const mutations = {
  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  setPrimarySubscriptionFromList(state, subscriptions) {
    // Sort by newest subscription and fall back to that as primary
    subscriptions.sort((a, b) => moment(a.billingAnchor).isBefore(b.billingAnchor) ? 1 : -1);
    state.primarySubscription = subscriptions.find((subscription) => subscription.expiration === null) || subscriptions[0];
  },
  setPrimarySubscription(state, subscription) {
    state.primarySubscription = subscription;
  },
};

export default {
  actions,
  getters,
  mutations,
  state,
};
