<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRouter, useRoute } from "vue-router/composables";

const props = defineProps({
  projectId: {
    type: Number,
    default: null,
  },
});

const {
  sourceableProjectList,
  currentCampaign,
} = useGetters([
  "sourceableProjectList",
  "currentCampaign",
]);

const route = useRoute();
const router = useRouter();

const isInMessagingPage = computed(() => {
  return route.name === "campaign-messaging";
});

const hasPostingDescription = computed(() => {
  return sourceableProjectList.value?.find(item => item.id === props.projectId)?.hasPostingDescription || false;
});

const isMissingJobDescriptionNotificationShown = computed(() => {
  if (currentCampaign.value?.inbound || currentCampaign.value.referrals) {
    return false;
  }
  let isHiddenForProject = false;
  const valueFromStorage = sessionStorage.getItem("hidden-missing-job-description-notification-project-ids");

  if (valueFromStorage) {
    const ids = JSON.parse(valueFromStorage);
    isHiddenForProject = ids.some(id => id === props.projectId);
  }
  return !isHiddenForProject && !!props.projectId && !props.projectClosed && !hasPostingDescription.value;
});

function addJobDescriptionHandler() {
  router.replace({ name: "job-configuration", params: { projectId: props.projectId }, hash: "#public-job-description" });
}

function closeHandler() {
  const projectIds = sessionStorage.getItem("hidden-missing-job-description-notification-project-ids");
  let dataToBeStored = [props.projectId];

  if (projectIds) {
    dataToBeStored.push(...JSON.parse(projectIds));
  }
  sessionStorage.setItem("hidden-missing-job-description-notification-project-ids", JSON.stringify(dataToBeStored));
}
</script>

<template>
 <div
   v-if="isMissingJobDescriptionNotificationShown"
   id="missing-job-description-notification"
   :key="projectId"
   class="notification-container"
   :class="{ 'fixed-alert-wrapper': isInMessagingPage }"
 >
   <el-alert
     type="info"
     class="mb-16 w-100"
     show-icon
     @close="closeHandler"
   >
     <template slot="title">
       <div class="w-100 d-flex align-items-center justify-content-between">
         <div>
           Provide more information about the job to improve the messaging content generated
         </div>
         <el-button
           class="uppercase h-auto p-0 mr-16"
           type="text"
           size="small"
           @click="addJobDescriptionHandler"
         >
           Add job description
         </el-button>
       </div>
     </template>
   </el-alert>
 </div>
</template>

<style scoped lang="scss">
#missing-job-description-notification {
  &.fixed-alert-wrapper {
    width: 800px;
    position: fixed;
    bottom: 20px;
    left: calc(100vw / 2 - 400px);
    z-index: 9999;
    font-size: 12px;
  }

  ::v-deep .el-alert__content {
    width: 100% !important;
  }

  ::v-deep .el-alert__title {
    width: 100% !important;
  }
}
</style>
