<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";

import logEvent from "@/common/Analytics";
import ProfilesApi from "@/common/http/ProfilesApi";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: "small",
  },
  plain: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const root = useRoot();

const isCandidateSidebarOpen = computed(() => {
  return !!route.query.sidebar;
});

const tooltip = computed(() => {
  if (!props.candidate.linkedinId) {
    return "Candidate has no LinkedIn profile"
  }
  return `
    Status will change to Awaiting Reply.
    LinkedIn InMail will open and campaign messaging will be copied
    to your clipboard.
  `;
});

async function send() {
  logEvent("candidate-action", { action: "mark-as-sent-via-in-mail" });
  root.$bus.$emit("candidate-recruited", { candidate: props.candidate, showNext: isCandidateSidebarOpen.value });

  const { data } = await ProfilesApi.markAsSentViaInMail({ profileId: props.candidate.profileId });
  let message = "Unable to copy messaging content to clipboard. Opening LinkedIn in a new tab.";

  if (data.type !== "error") {
    message = "Messaging copied to clipboard. Opening LinkedIn in a new tab.";
    navigator.clipboard.writeText(data.content).then(() => {
      setTimeout(() => window.open(data.url, "_blank"), 3000);
    });
  } else if (data.url) {
    setTimeout(() => window.open(data.url, "_blank"), 3000);
  }

  root.$notify({
    title: "Candidate is now 'Awaiting Reply'",
    position: "bottom-right",
    message,
    type: "success",
    duration: 10000,
  });
}
</script>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <el-tooltip
      :content="tooltip"
      :open-delay="500"
      :disabled="!tooltip"
    >
      <div v-if="dropdownItem">
        <el-dropdown-item :disabled="!candidate.linkedinId">
          <div class="d-flex align-items-center" @click="send">
            <i class="fab fa-linkedin mr-8"></i>
            <span>Send via InMail</span>
          </div>
        </el-dropdown-item>
      </div>

      <el-tooltip
        v-else
        content="Send via InMail"
        :disabled="!onlyIcon"
      >
        <el-button
          class="w-100 m-0"
          type="primary"
          :size="size"
          :plain="plain"
          :disabled="!candidate.linkedinId"
          @click="send"
        >
          <span v-if="onlyIcon" class="material-icons-round md-14 m-0">email</span>
          <span v-else>Send via InMail</span>
        </el-button>
      </el-tooltip>


    </el-tooltip>
  </el-skeleton>
</template>
