import { render, staticRenderFns } from "./RecommendationsTokens.vue?vue&type=template&id=0834049c&scoped=true"
import script from "./RecommendationsTokens.vue?vue&type=script&setup=true&lang=js"
export * from "./RecommendationsTokens.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RecommendationsTokens.vue?vue&type=style&index=0&id=0834049c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0834049c",
  null
  
)

export default component.exports