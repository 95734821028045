import ApiClient from "@/common/http/ApiClient";

class ProjectsApi {
  static index(params = {}) {
    return ApiClient.get("projects", params);
  }
  static indexForSourcing() {
    const params = { purpose: "sourcing", source: "app" };
    return ApiClient.get("projects/index_for_sourcing", params);
  }
  static search(params = {}) {
    return ApiClient.get("projects/search", params);
  }
  static nameSearch(params = {}) {
    return ApiClient.get("projects/name_search", params);
  }
  static new() {
    return ApiClient.get("projects/new");
  }
  static update(id, params = {}) {
    return ApiClient.put(`projects/${id}`, params);
  }
  static create(params = {}) {
    return ApiClient.post("projects", params);
  }
  static show(id, params = {}) {
    return ApiClient.get(`projects/${id}`, params);
  }
  static sourcingDetail(id) {
    return ApiClient.get(`projects/${id}/sourcing_detail`);
  }
  static sourcingActivity(id) {
    return ApiClient.get(`projects/${id}/sourcing_activity`);
  }
  static settings(id) {
    return ApiClient.get(`projects/${id}/settings`);
  }
  static open(id) {
    return ApiClient.put(`projects/${id}/open`);
  }
  static nudgeReviewer(id, params = {}) {
    return ApiClient.put(`projects/${id}/nudge_reviewer`, params);
  }
  static delete(id) {
    return ApiClient.delete(`projects/${id}`);
  }
  static favorite(id, params = {}) {
    return ApiClient.put(`projects/${id}/favorite`, params);
  }
  static bestMatchStatus(id) {
    return ApiClient.get(`projects/${id}/best_match_status`);
  }

  // project profiles
  static candidates(params = {}) {
    return ApiClient.get("project_profiles", params);
  }
  static addToProject(params) {
    return ApiClient.post("project_profiles", params);
  }
  static removeFromProject(id) {
    return ApiClient.delete(`project_profiles/${id}`);
  }
  static removeCandidate(params) {
    return ApiClient.delete("project_profiles/remove", { params });
  }
  static removeCandidatesBulk(params) {
    return ApiClient.put("project_profiles/bulk_remove", params)
  }
  static cancelRemoval(id) {
    return ApiClient.put(`project_profiles/${id}/cancel_removal`);
  }
  static cancelJobDescriptionUpload(id) {
    return ApiClient.put("job_postings/cancel_upload", { project_id: id })
  }

  // recommendations
  static hideRecommendation({ id, params }) {
    return ApiClient.post(`projects/${id}/recommendations/hide`, params);
  }

  static addRecommendationFeedback({ projectId, params }) {
    return ApiClient.post(`projects/${projectId}/recommendations/feedbacks`, params);
  }

  static deleteRecommendationFeedback({ projectId, feedbackId }) {
    return ApiClient.delete(`projects/${projectId}/recommendations/feedbacks/${feedbackId}`);
  }

  static deleteRecommendationFeedbacks({ projectId, feedbackIds }) {
    return ApiClient.post(`projects/${projectId}/recommendations/delete_feedbacks`, { feedbackIds });
  }

  static updateRecommendationFeedback({ projectId, feedbackId, params }) {
    return ApiClient.put(`projects/${projectId}/recommendations/feedbacks/${feedbackId}`, params);
  }

  static getRecommendationsFeedbacks({ projectId }) {
    return ApiClient.get(`projects/${projectId}/recommendations/feedbacks`);
  }

  static improveRecommendationsWithFeedback({ projectId, params }) {
    return ApiClient.post(`projects/${projectId}/recommendations/improve_feedback`, params);
  }

  static getRecommendationFeedbackBoolean({ projectId, tokenType }) {
    return ApiClient.get(`projects/${projectId}/recommendations/${tokenType}/boolean`);
  }

  static updateRecommendationFeedbackBoolean({ projectId, tokenType, params }) {
    return ApiClient.post(`projects/${projectId}/recommendations/${tokenType}/boolean`, params);
  }

  // recommendation filter templates
  static getFilterTemplates({ projectId }) {
    return ApiClient.get(`projects/${projectId}/recommendations/filter_templates`);
  }

  static createFilterTemplate({ projectId, filters, name }) {
    return ApiClient.post(`projects/${projectId}/recommendations/filter_templates`, { filters, name });
  }

  static updateFilterTemplate({ projectId, id, filters, name }) {
    return ApiClient.put(`projects/${projectId}/recommendations/filter_templates/${id}`, { filters, name });
  }

  // calibration candidates
  static updateCalibrationCandidate({ id, projectId, pinned }) {
    return ApiClient.put(`projects/${projectId}/example_candidates/${id}`, { pinned });
  }

  static deleteCalibrationCandidate({ id, projectId }) {
    return ApiClient.delete(`projects/${projectId}/example_candidates/${id}`);
  }

  static createCalibrationCandidate({ projectId, url }) {
    return ApiClient.post(`projects/${projectId}/example_candidates/`, { url });
  }
}

export default ProjectsApi;
