import _ from "lodash";
import DateTimeHelper from "@/common/DateTimeHelper";
import moment from "moment";

const formatDate = (dateObject, isCurrent) => {
  if (isCurrent) {
    return "Present";
  } else if (!dateObject) {
    return null;
  } else if (_.isString(dateObject)) {
    return moment(dateObject).format("YYYY");
  } else if (!dateObject.month) {
    const date = new Date(dateObject.year, 0);
    return DateTimeHelper.format(date, "YYYY");
  }

  const date = new Date(dateObject.year, dateObject.month - 1);

  return DateTimeHelper.format(date, "YYYY");
}

export const formatEmploymentPeriod = (employment, { includeBrackets = true } = { includeBrackets: true }) => {
  let dates = employment.dates;
  const isCurrent = employment.computedCurrent ?? (employment.startDate && !employment.endDate);
  const startDate = formatDate(employment.startDate);
  const endDate = formatDate(employment.endDate, isCurrent);

  if (startDate && endDate) {
    const result = `${startDate} - ${endDate}`;
    dates ||= includeBrackets ? `(${result})` : result;
  } else if (startDate && !endDate) {
    dates = startDate;
  } else if (!startDate && endDate) {
    dates = endDate;
  }

  return dates;
}
