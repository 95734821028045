import DateTimeHelper from "@/common/DateTimeHelper";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "CandidateNote",
  components: {
    UserAvatar,
  },
  props: {
    note: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    exactDateTimeInTimezone() {
      return DateTimeHelper.exactDateTimeInTimezone(this.note.createdAt);
    },
    relativeTimeFromNow() {
      return DateTimeHelper.relativeTimeFromNow(this.note.createdAt);
    },
  },
  methods: {
    deleteNote() {
      this.$emit("delete-note", this.note);
    },
  },
};
