import _ from "lodash";
import MentionableEditor from "@/components/MentionableEditor";

export default {
  name: "CandidateNoteInput",
  components: {
    MentionableEditor,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentNote: "",
    };
  },
  watch: {
    currentNote() {
      !this.showAddButton && this.addNoteWithDebounce();
    },
  },
  methods: {
    addNoteWithDebounce: _.debounce(function() {
      this.$emit("add-note", this.currentNote);
    }, 1000),
    addNote() {
      this.$emit("add-note", this.currentNote);
      this.currentNote = "";
    },
  },
};
