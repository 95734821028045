import ApiClient from "@/common/http/ApiClient.js";

class OutreachApi {
  static searchFilters(params = {}) {
    return ApiClient.get("outreach/filter_options", params);
  }

  static searchFiltersQuery(params = {}) {
    return ApiClient.get("outreach/filter_options_query", params);
  }

  static sendMessage(params) {
    return ApiClient.post("outreach", params);
  }
}

export default OutreachApi;
