import { mapGetters } from "vuex";

import MessageEditor from "@/components/MessageEditor/Component";
import CampaignMessageVariables from "@/common/CampaignMessageVariables";
import { TYPES } from "@/components/MessageGeneration/constants";

export default {
  name: "CampaignMessageTemplate",
  components: {
    MessageEditor,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    inbound: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cc: {
      type: String,
      default: null,
    },
    bcc: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    followUpNumDaysInit: {
      type: Number,
      default: null,
    },
    followUpSendHourInit: {
      type: Number,
      default: null,
    },
    followUpSendDaysInit: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeOptionPanels: [],
      followUpNumDays: this.followUpNumDaysInit,
      followUpSendHour: this.followUpSendHourInit ? `${this.followUpSendHourInit}:00` : null,
      showAtLeastOneDayMustBeSelectedError: false,
    };
  },
  computed: {
    ...mapGetters([
      "inAppSourcingSelectedCampaign",
      "isSavingCampaign",
      "currentTeam",
      "currentUser",
      "newCampaign",
    ]),
    showControls() {
      return this.type !== "firstMessage" && this.type !== "introRequest" && this.type !== "introMessage";
    },
    currentPlaybook() {
      if (!this.currentUser.chatbotEnabled) return;

      const playbookId = this.currentCampaign?.playbookId || this.newCampaign?.playbookId || this.inAppSourcingSelectedCampaign?.playbookId;
      if (playbookId) {
        return this.currentUser.playbookOptions?.find(playbook => playbook.id == this.currentCampaign.playbookId);
      }

      return null;
    },
    chatbotEnabled() {
      return this.currentUser.chatbotEnabled && (
        this.type === TYPES.FIRST_MESSAGE ||
        this.type === TYPES.FOLLOW_UPS ||
        this.type === TYPES.AUTO_REPLY_POSITIVE ||
        this.type === TYPES.YOUR_REPLY_POSITIVE
      );
    },
    msgVariablesService() {
      return new CampaignMessageVariables({
        introRequestVariables: this.type === "introRequest",
        introMessageVariables: this.type === "introMessage",
        companyEnabled: !this.inbound,
        chatbotEnabled: this.chatbotEnabled,
        chatbotName: this.currentPlaybook?.name,
      });
    },
    followUpDaysAfterText() {
      if (this.index === 0) {
        return "day(s) after the first message";
      } else {
        return `day(s) after the ${this.followUpMessageTitle(this.index - 1)}`;
      }
    },
    followUpSendDays() {
      let daysOfWeek = [
        { name: "S", index: 0 },
        { name: "M", index: 1 },
        { name: "T", index: 2 },
        { name: "W", index: 3 },
        { name: "T", index: 4 },
        { name: "F", index: 5 },
        { name: "S", index: 6 },
      ];

      if (this.followUpSendDaysInit) {
        daysOfWeek.forEach(d => d.active = this.followUpSendDaysInit.includes(d.index));
      }
      return daysOfWeek;
    },
  },
  methods: {
    deleteFollowUp(followUpId) {
      if (this.isSavingCampaign) {
        return;
      }

      this.$emit("delete", followUpId);
    },
    followUpMessageTitle(index = this.index) {
      let numFollowUp = "1st";
      if (index === 1) {
        numFollowUp = "2nd";
      } else if (index === 2) {
        numFollowUp = "3rd";
      } else if (index >= 3) {
        numFollowUp = `${index + 1}th`;
      }

      return `${numFollowUp} follow-up`;
    },
    followUpMessageSubtitle(index = this.index) {
      let subtitle = "Nudge candidates who haven't responded yet to help garner interest.";
      if (index === 1) {
        subtitle = "We suggest 2-3 follow-ups over time to keep this opportunity top of mind.";
      } else if (index >= 2) {
        subtitle = "Adding another follow-up several days out can be a helpful way to remind candidates.";
      }

      return subtitle;
    },
    autoReplyEnabledToggled() {
      this.$emit("enabled-toggled");
    },
    followUpDaysAfterChanged() {
      this.$emit("follow-up-changed", { numDays: this.followUpNumDays });
    },
    followUpSendHourChanged() {
      this.$emit("follow-up-changed", { sendHour: this.followUpSendHour });
    },
    toggleActiveSendDay(index) {
      if (this.followUpSendDays.filter(d => d.active).length === 1 && this.followUpSendDays[index].active) {
        return this.showAtLeastOneDayMustBeSelectedError = true;
      }

      let days = Object.assign([], this.followUpSendDays);
      days[index].active = !days[index].active;
      this.showAtLeastOneDayMustBeSelectedError = false;
      this.$emit("follow-up-changed", { sendDays: days.filter(d => d.active).map(d => d.index) });
    },
  },
};
