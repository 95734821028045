<script setup>
import { computed, ref } from "vue";
import { useGetters } from "vuex-composition-helpers";

import { TYPES } from "@/components/MessageGeneration/constants";

import FirstMessage from "./FirstMessage/FirstMessage";
import FollowUp from "./FollowUp/FollowUp";
import Replies from "./Replies/Replies";
import Settings from "./Settings/Settings";
import followUpMessageTitle from "./FollowUp/lib/followUpMessageTitle";

defineProps({
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
});

const {
  inAppSourcingMessage,
  inAppSourcingSelectedCampaign,
} = useGetters([
  "inAppSourcingMessage",
  "inAppSourcingSelectedCampaign",
]);

const activeTab = ref(TYPES.FIRST_MESSAGE);

const followUpMessage = computed(() => {
  return inAppSourcingMessage.value.followUps[activeTab.value.split("-")[1] || 0];
});

function tabClickHandler(val) {
  activeTab.value = val;
}

function openSubmenuHandler(val) {
  if (val === TYPES.FOLLOW_UPS) {
    if (!inAppSourcingMessage.value.followUps.length) {
      return;
    }
    activeTab.value = `${TYPES.FOLLOW_UPS}-0`;
    return;
  }
  activeTab.value = "replies-yourReplyPositive";
}

function addNewFollowUp() {
  inAppSourcingMessage.value.followUps.push({
    bcc: "",
    body: null,
    cc: "",
    sendDays: [1,2,3,4,5],
    numDays: 4,
  });

  activeTab.value = `${TYPES.FOLLOW_UPS}-${inAppSourcingMessage.value.followUps.length - 1}`;
}

function removeFollowUpHandler() {
  inAppSourcingMessage.value.followUps = inAppSourcingMessage.value.followUps.filter((item, index) => index !== +activeTab.value.split("-")[1]);
  activeTab.value = `${TYPES.FOLLOW_UPS}-${inAppSourcingMessage.value.followUps.length - 1}`;
}
</script>

<template>
  <div id="in-app-sourcing-message">
    <div v-if="inAppSourcingSelectedCampaign">
      <Settings />

      <div class="d-flex">
        <div class="w-20 mr-16">
          <el-menu
            class="el-menu-vertical-demo"
            :default-active="activeTab"
            unique-opened
            @select="tabClickHandler"
            @open="openSubmenuHandler"
          >
            <el-menu-item :index="TYPES.FIRST_MESSAGE">
              <template slot="title">First Message</template>
            </el-menu-item>

            <el-submenu :index="TYPES.FOLLOW_UPS">
              <template slot="title">
                <span>Follow-ups</span>
              </template>

              <el-menu-item
                v-for="(item, index) in inAppSourcingMessage.followUps"
                :key="index"
                class="pl-32"
                :index="TYPES.FOLLOW_UPS + '-' + (index)"
              >
                {{ followUpMessageTitle(index) }}
              </el-menu-item>

              <el-button
                type="secondary"
                size="small"
                class="w-100"
                @click="addNewFollowUp"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons-round">add</span>
                  Add a follow-up
                </div>
              </el-button>
            </el-submenu>

            <el-submenu :index="TYPES.REPLIES">
              <template slot="title">
                <span>Replies</span>
              </template>
              <el-menu-item class="pl-32" index="replies-yourReplyPositive">Positive</el-menu-item>
              <el-menu-item class="pl-32" index="replies-yourReplyNegative">Negative</el-menu-item>
              <el-menu-item class="pl-32" index="replies-yourReplyOoo">Out of office</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>

        <div class="w-80">
          <FirstMessage v-if="activeTab === TYPES.FIRST_MESSAGE" :messaging-mode-only="messagingModeOnly" />

          <FollowUp
            v-else-if="activeTab.split('-')[0] === TYPES.FOLLOW_UPS && inAppSourcingMessage.followUps.length"
            :key="+activeTab.split('-')[1]"
            :message="followUpMessage"
            :index="+activeTab.split('-')[1]"
            :messaging-mode-only="messagingModeOnly"
            @remove="removeFollowUpHandler"
          />

          <Replies
            v-else-if="activeTab.split('-')[1]"
            :messaging-mode-only="messagingModeOnly"
            :type="activeTab.split('-')[1]"
          />
        </div>
      </div>
    </div>

    <slot v-else name="generate-new"></slot>
  </div>
</template>

<style lang="scss">
#in-app-sourcing-message {
  .el-menu {
    border-right: none;
  }

  .el-menu-item {
    height: 50px;
    line-height: 50px;
    padding: 0;
    min-width: 100px;

    &.is-active {
      background: $blue-10;
    }
  }
}

</style>
