import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

import NavigationMenu from "./NavigationMenu/Component";
import SearchResultsPopover from "./SearchResultsPopover/Component";
import SudoUser from "./SudoUser";
import TopBarAlert from "./TopBarAlert";
import UserMenu from "@/components/UserMenu";

import { TITLE_REGEX, PRICING_PAGE } from "@/constants"

export default {
  name: "TopBar",
  components: {
    NavigationMenu,
    SearchResultsPopover,
    SudoUser,
    TopBarAlert,
    UserMenu,
  },
  props: {
    layout: {
      type: String,
      required: true,
      default: "default",
    },
  },
  data() {
    return {
      searchTerm: "",
      debouncedSearchTerm: "",
      isLoadingSearchResults: false,
      isLiveSearchVisible: false,
      isNoMailboxJustHidden: false,
      pricingPage: PRICING_PAGE,
      searchResultsPopoverStyle: "width: 300px;",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentTeam",
      "isAuthenticated",
      "isOnTrial",
      "isNoMailbox",
      "isUserSudoing",
      "globalQueryTerm",
    ]),
    isLayoutSimple() {
      return this.layout === "simple";
    },
    isLayoutMobile() {
      return this.layout === "mobile";
    },
    showSlashIcon() {
      return !this.isLiveSearchVisible;
    },
    trialDaysLeft() {
      const days = this.currentUser.trialDaysLeft;
      return `${days} day${days > 1 ? "s" : ""}`;
    },
    currentContextPage() {
      if (this.$route.path.startsWith("/campaigns")) {
        return "campaigns";
      } else if (this.$route.path.startsWith("/projects")) {
        return "projects";
      }
      return null; // Don't care about other cases
    },
    showNoMailboxWarning() {
      return !this.isNoMailboxJustHidden && this.isNoMailbox && !localStorage.getItem(`ignoreNoMailboxWarning:${this.currentUser.id}`);
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
        });
        if (this.$refs.search) {
          this.$refs.search.blur();
        }
      },
    },
    globalQueryTerm: {
      immediate: true,
      handler(value) {
        this.searchTerm = value;
        this.debouncedSearchTerm = value;
      },
    },
  },
  methods: {
    ...mapActions([
      "recordUserSearchTerm",
    ]),
    confirmNoMailbox() {
      this.$confirm("Would you like to ignore this warning?", "Dismiss permanently?", {
        confirmButtonText: "Yes, don't show it again",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.isNoMailboxJustHidden = true;
        localStorage.setItem(`ignoreNoMailboxWarning:${this.currentUser.id}`, true)
      }).catch(() => {});
    },
    fillInRecentSearchTerm(term) {
      this.searchTerm = term;
      this.doSearch();
    },
    showPopover() {
      this.calculateSearchResultsPopoverWidth();
      this.isLiveSearchVisible = true;
    },
    debounceAssignSearchTerm: _.debounce(function() {
      this.debouncedSearchTerm = this.searchTerm;
    }, 500),
    doSearch() {
      if (!this.isUserSudoing) {
        this.recordUserSearchTerm(this.searchTerm);
      }

      let query = { query: this.searchTerm };

      if (this.currentContextPage) {
        query.searchTab = this.currentContextPage;
      }

      this.$router.push({ name: "search", query });
    },
    clearSearch() {
      this.searchTerm = "";
      this.debouncedSearchTerm = "";
      this.$router.push({ name: "search" });
    },
    handleSearchKeydown(event) {
      if (!this.searchTerm) {
        return;
      }

      if (!this.searchTerm?.match(TITLE_REGEX)) {
        return;
      }

      if (event && event.key === "Enter") {
        this.doSearch();
      } else {
        this.debounceAssignSearchTerm();
      }
    },
    hideLiveSearch(e) {
      if (!e.relatedTarget) {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
        });
      }
    },
    keydownHandler(event) {
      let isMessageEditor = event.target?.className?.match(/ql-editor|el-input__inner|el-select__input|editable|new-value-input|el-textarea__inner/);
      isMessageEditor = isMessageEditor || event.target.formAction?.includes("templates");
      if (event.key === "/" && !isMessageEditor) {
        event.preventDefault();
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      } else if (event.key === "Escape") {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
          if (this.$refs.search) {
            this.$refs.search.blur();
          }
        });
      }
    },
    calculateSearchResultsPopoverWidth() {
      this.$nextTick(() => {
        const width = document.getElementById("search-input-container")?.getBoundingClientRect().width || 300;
        const searchResultsPopover = document.getElementsByClassName("el-popper popover-search")[0];
        searchResultsPopover.style.width = `${width}px`;
      });
    },
  },
  created() {
    window.addEventListener("keydown", this.keydownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keydownHandler);
  },
}
