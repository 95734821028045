<script setup>
import { ref, computed } from "vue";
import { useMutations, useGetters } from "vuex-composition-helpers";
import InAppSourcing from "@/components/InAppSourcing/InAppSourcing";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  switchJobs: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  plain: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
  wasReached: {
    type: Boolean,
    default: false,
  },
});

const {
  bulkSelectionAttributes,
  currentProject,
} = useGetters([
  "bulkSelectionAttributes",
  "currentProject",
]);

const {
  setInAppSourcingSelectedProject,
  setSelectedCandidateInAppSourcing,
} = useMutations([
  "setInAppSourcingSelectedProject",
  "setSelectedCandidateInAppSourcing",
]);

const isInAppSourcingSidebarVisible = ref(false);

const label = computed(() => {
  if (props.switchJobs) {
    return "Move to another job...";
  } else if (props.candidate?.connectors?.length && !bulkSelectionAttributes.value) {
    return "Request intro...";
  }
  // return "Recruit...";
  return props.wasReached ? "Re-engage..." : "Engage...";
});

function showInAppSidebar() {
  isInAppSourcingSidebarVisible.value = true;

  if (currentProject.value.id) {
    setInAppSourcingSelectedProject(currentProject.value);
  }

  if (props.candidate) {
    setSelectedCandidateInAppSourcing(props.candidate);
  }
}
</script>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <el-dropdown-item v-if="dropdownItem">
      <div class="d-flex align-items-center" @click="showInAppSidebar">
        <span class="material-icons-round md-18 mr-8">turn_sharp_left</span>
        <span>{{ label }}</span>
      </div>
    </el-dropdown-item>

    <el-tooltip v-else :disabled="!onlyIcon" :content="label">
      <el-button
        class="w-100 m-0"
        type="primary"
        :size="size"
        :plain="plain"
        @click="showInAppSidebar"
      >
        <span v-if="onlyIcon" class="material-icons-round md-14 m-0">thumb_up_alt</span>
        <span v-else>{{ label }}</span>
      </el-button>
    </el-tooltip>

    <InAppSourcing :visible.sync="isInAppSourcingSidebarVisible" :switch-jobs="switchJobs" />
  </el-skeleton>
</template>
