import { mapActions, mapGetters } from "vuex";

import logEvent from "@/common/Analytics";
import ProfilesApi from "@/common/http/ProfilesApi";
import TasksApi from "@/common/http/TasksApi";
import RouteContext from "@/common/RouteContext";

import { REVIEW_CONTEXT } from "@/constants";

import CandidateProfileModal from "@/components/CandidateProfileModal";

import AddNoteButton from "@/components/candidates/AddNoteButton/AddNoteButton";
import AddReminderButton from "@/components/candidates/AddReminderButton/AddReminderButton";
import AddTagButton from "@/components/candidates/AddTagButton/AddTagButton";
import CandidateReviewButtons from "@/components/CandidateReviewButtons/Component";
import RecommendationRejectButton from "@/components/candidates/RecommendationRejectButton";
import RecruitButton from "@/components/candidates/RecruitButton";
import RemoveFromJobButton from "@/components/candidates/RemoveFromJobButton";
import SendViaInMailButton from "@/components/candidates/SendViaInMailButton";
import SaveForLaterButton from "@/components/candidates/SaveForLaterButton";
import StopOutreachButton from "@/components/candidates/StopOutreachButton";
import UpdateStatusButton from "@/components/candidates/UpdateStatusButton/UpdateStatusButton";

export default {
  name: "CandidateMenu",
  components: {
    AddNoteButton,
    AddReminderButton,
    AddTagButton,
    CandidateProfileModal,
    CandidateReviewButtons,
    RecommendationRejectButton,
    RecruitButton,
    RemoveFromJobButton,
    SaveForLaterButton,
    SendViaInMailButton,
    StopOutreachButton,
    UpdateStatusButton,
  },
  props: {
    candidate: {
      type: Object,
      default: null,
    },
    showModalKey: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingCandidate: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    skipActions: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every(action => ["note", "remindMe", "tag", "updateStatus"].includes(action));
      },
    },
    size: {
      type: String,
      default: "default",
    },
    bulkActionsSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleModalKey: null,
      canSendInMail: null,
      isAllRejectReasonsModalVisible: false,
      routeContext: new RouteContext(this.$route.name),
    };
  },
  computed: {
    ...mapGetters([
      "bulkSelectionAttributes",
      "currentCandidate",
      "currentProject",
      "currentReviewContext",
      "currentTeam",
      "currentUser",
      "currentUserSettings",
      "isCandidateSidebarOpen",
      "profileIdsInReview",
      "selectedCandidates",
    ]),
    candidateMailboxUrl() {
      return this.candidate.urls?.find(u => u.type === "mailbox")?.value;
    },
    wasCandidateReached() {
      return this.candidate?.recentTouchpoint?.status && this.candidate.recentTouchpoint.status !== "Sourced" || (
        this.candidate?.furthestOtherTouchpointStatus &&
        this.candidate.furthestOtherTouchpointStatus.includes("Sourced")
      );
    },
    isCandidateNoEmail() {
      return this.candidate.recentTouchpoint?.activityIsNegative && !this.candidate.email;
    },
    isCandidateInReview() {
      return this.candidate.profileId && (
        this.profileIdsInReview.includes(this.candidate.profileId) || this.candidate.recentTouchpoint?.inReview
      );
    },
    isReviewableByMe() {
      return this.isCandidateInReview && this.candidate.recentTouchpoint?.isReviewableByMe;
    },
    isCandidateSavedForLater() {
      return this.candidate?.projectIdsSavedForLater?.includes(this.currentProject.id);
    },
    isAtsCandidate() {
      return this.candidate?.recentTouchpoint?.type === "AtsCandidate";
    },
    isReviewContextRequested() {
      return this.currentReviewContext?.reviewType === REVIEW_CONTEXT.requested;
    },
    gotoReviewLabel() {
      return this.candidate.recentTouchpoint?.activityKey === "applied" ? "Review applicant" : "Review candidate";
    },
    showDropdown() {
      return this.candidate.profileId;
    },
    showRecruitButton() {
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      return (
        this.candidate.recentTouchpoint?.activityKey !== "applied" &&
        this.candidate.recentTouchpoint?.activityKey !== "client_approval" &&
        !this.showSendInMailButton && (
          this.isCandidateSavedForLater ||
          this.isAtsCandidate ||
          this.isReviewContextRequested ||
          !this.candidate.recentTouchpoint ||
          (this.candidate.recentTouchpoint?.activityIsNegative) ||
          this.candidate.recentTouchpoint?.activityKey === "no_reply" ||
          this.candidate.recentTouchpoint?.activityKey === "chatbot_incomplete" ||
          (this.candidate.recentTouchpoint?.lastActivityAt && Date.parse(this.candidate.recentTouchpoint.lastActivityAt) < threeMonthsAgo)
        )
      );
    },
    showRecruitItem() {
      // intentionally exclude applicants
      return !this.showRecruitButton && this.candidate.recentTouchpoint?.activityKey === "client_approval";
    },
    showReviewButtons() {
      return (
        this.isReviewableByMe &&
        this.currentReviewContext &&
        this.currentReviewContext.id === this.currentProject.id
      );
    },
    showGotoReviewButton() {
      return (
        this.isReviewableByMe &&
        !this.showReviewButtons
      );
    },
    showRecommendationRejectButton() {
      return !this.candidate.profileId;
    },
    showRemoveFromJobButton() {
      return this.currentProject.id && this.isCandidateSavedForLater;
    },
    showRemoveFromJobItem() {
      return this.currentProject.id && !this.showRemoveFromJobButton;
    },
    showSaveForLaterButton() {
      return this.routeContext.sourcingContext() && !this.candidate.projectIdsSavedForLater?.includes(this.currentProject.id);
    },
    showUpdateStatusButton() {
      return [
        "reply_positive",
        "chatbot_complete",
        "interviewing",
        "offer",
      ].includes(this.candidate.recentTouchpoint?.activityKey) && this.candidate.recentTouchpoint.overridable;
    },
    showUpdateStatusItem() {
      return !this.showUpdateStatusButton && !this.isCandidateInReview;
    },
    showSendInMailButton() {
      return (
        this.isCandidateNoEmail &&
        this.candidate.recentTouchpoint &&
        !this.candidate.recentTouchpoint?.wasOverridden &&
        this.candidate.recentTouchpoint?.activityKey !== "client_reject" &&
        this.candidate.recentTouchpoint.projectId === this.currentProject.id
      );
    },
    showOpenInMailItem() {
      return (
        !this.showSendInMailButton &&
        this.candidate.recentTouchpoint &&
        !["responded", "interested", "interview", "hired"].includes(this.candidate.recentTouchpoint.statusKey)
      )
    },
    showAddNoteButton() {
      return false;
    },
    showAddNoteItem() {
      return !this.showAddNoteButton && this.candidate.profileId && !this.isCandidateSidebarOpen;
    },
    showAddReminderButton() {
      return false;
    },
    showAddReminderItem() {
      return !this.showAddReminderButton && this.candidate.profileId;
    },
    showStopOutreachButton() {
      return (
        !this.showOpenMailboxButton &&
        ["draft", "created", "awaiting_reply"].includes(this.candidate.recentTouchpoint?.activityKey)
      );
    },
    showStopOutreachItem() {
      return !this.isCandidateInReview && !this.showStopOutreachButton && this.candidate.recentTouchpoint?.stoppable;
    },
    showOpenMailboxButton() {
      return this.candidate.recentTouchpoint?.activityKey === "draft" && !!this.candidateMailboxUrl;
    },
    showOpenHistoryButton() {
      return (
        !this.isCurrentCandidate &&
        (
          this.candidate.recentTouchpoint?.type === "IntroPack" ||
          (this.wasCandidateReached && this.candidate.recentTouchpoint.activityKey !== "applied")
        )
      );
    },
    showComposeMessageButton() {
      return false;
    },
    showDismissRequiresAttentionButton() {
      return this.candidate.recentTouchpoint?.threadRequiresUserAttention;
    },
    showDeleteCandidateItem() {
      return this.candidate.profileId;
    },
    isAnyCandidateSelected() {
      return this.selectedCandidates.length > 0;
    },
    isCurrentCandidate() {
      return this.candidate.tfId === this.currentCandidate.tfId;
    },
    computedCanSendInMail() {
      return this.candidate.canOpenLiMessaging || this.canSendInMail;
    },
    followCandidateTooltip() {
      if (this.candidate?.watchable) {
        if (this.candidate.watched) {
          return "Following candidate, you are being notified about their profile updates";
        }
        return "Follow candidate to get notified when they update their profile";
      }
      return "Candidate profile is not followable";
    },
    openInMailTooltip() {
      if (this.computedCanSendInMail) {
        return "Copy message text and open LinkedIn to send InMail";
      }
      return "Candidate must be from LinkedIn and have valid profile fields"
    },
  },
  watch: {
    showModalKey: {
      immediate: true,
      handler(key) {
        if (key) {
          this.showModal(key);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "saveCandidateWatched",
    ]),
    checkCanSendInMail(visible) {
      if (visible && !this.computedCanSendInMail && this.canSendInMail === null) {
        ProfilesApi.liMessagingContent({ profileId: this.candidate.profileId }).then(({ data }) => {
          this.$nextTick(() => {
            this.canSendInMail = data.type !== "error";
          });
        });
      }
    },
    handleDropDownCommand(command) {
      switch (command) {
        case "change":
        case "profile":
          this.showModal(command);
          break;
        case "inmail":
          this.sendInMail();
          break;
        case "watch":
          this.watchCandidate();
          break;
      }
    },
    showModal(modalKey) {
      if (this.visibleModalKey === modalKey) {
        return;
      }

      this.visibleModalKey = modalKey;

      const query = Object.assign({}, this.$route.query);
      if (!query.modal) {
        query.modal = modalKey;
        this.$router.push({ query });
      }
    },
    async deleteCandidate() {
      logEvent("candidate-action", { action: "delete-candidate" });

      const params = { profileId: this.candidate.profileId }
      await ProfilesApi.deleteCandidate(params);

      // TODO: Rename "candidate-recruited" to something more appropriate
      // since all it does is remove from currentCandidateList and either closes the sidebar or shows next candidate
      this.$bus.$emit("candidate-recruited", { candidate: this.candidate, showNext: this.isCandidateSidebarOpen });

      this.$notify({
        title: "Success",
        position: "bottom-right",
        message: `${this.candidate.name} was deleted`,
        type: "success",
      });
    },
    watchCandidate() {
      if (!this.candidate?.watchable) {
        return;
      }
      const watched = !this.candidate.watched;
      this.saveCandidateWatched({
        candidate: this.candidate,
        watched,
      }).then(() => {
        this.$bus.$emit("candidate-list-item-changed", this.candidate);
      });
    },
    openCandidateReview() {
      if (this.candidate.recentTouchpoint.activityKey === "applied") {
        this.$router.push({ name: "sourcing", query: { projectId: this.candidate.recentTouchpoint.projectId, sourcingTab: "applicants", profileId: this.candidate.profileId } });
      } else {
        this.$router.push({ name: "job-for-approval", params: { projectId: this.candidate.recentTouchpoint.projectId }, query: { profileId: this.candidate.profileId } });
      }
    },
    openHistory() {
      this.$bus.$emit("view-candidate", {
        candidate: this.candidate,
        tab: "history",
      });
    },
    async markAsRead() {
      await TasksApi.update(this.candidate.recentTouchpoint.id, { threadRequiresUserAttention: false });
      this.candidate.recentTouchpoint.threadRequiresUserAttention = false;
      this.$bus.$emit("candidate-list-item-changed", this.candidate);
    },
    async sendInMail() {
      logEvent("candidate-action", { action: "send-in-mail" });
      const response = await ProfilesApi.liMessagingContent({ profileId: this.candidate.profileId });
      const candidateInMailData = response.data;
      if (candidateInMailData.type === "error") {
        const message = `Oops! There was an error copying the messaging content to clipboard.
          Please review the
          <a href="campaigns/${candidateInMailData.message}/templates?messageType=firstMessage">
            campaign template
          </a>.`;

        this.$message.error({
          dangerouslyUseHTMLString: true,
          message,
          showClose: true,
        });

        if (candidateInMailData.url) {
          setTimeout(() => window.open(candidateInMailData.url, "_blank"), 3000);
        }
      } else {
        this.$notify({
          title: "Success",
          position: "bottom-right",
          message: candidateInMailData.message,
          type: "success",
          duration: 10000,
        });

        navigator.clipboard.writeText(candidateInMailData.content).then(() => {
          setTimeout(() => window.open(candidateInMailData.url, "_blank"), 3000);
        });
      }
    },
    closeModal() {
      this.visibleModalKey = null;
      if (this.$route.query.modal) {
        const query = Object.assign({}, this.$route.query);
        delete query.modal;
        this.$router.replace({ query });
      }

      this.$emit("closed"); // This is for modals that being opened by other components
    },
    viewCandidate(tab = null) {
      const params = { candidate: this.candidate };
      if (tab) {
        params.tab = tab;
      }

      this.$bus.$emit("view-candidate", params);
    },
  },
};
