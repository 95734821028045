<script setup>
import { computed, ref, watch } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";
import { formatBulletedList } from "@/common/misc/formatBulletedList";

import RouteContext from "@/common/RouteContext";
import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

const props = defineProps({
  candidate: {
    type: Object,
    required: true,
  },
  tokensEnabled: {
    type: Boolean,
    default: false,
  },
})

const {
  currentProject,
} = useGetters([
  "currentProject",
]);

const humanTokens = props.candidate?.similarity?.analysis?.humanTokens || [];
const additionalTokenTypes = ["role-previous", "role-current", "employer-current", "employer-previous", "emp-start-size"];

const route = useRoute();
const routeContext = ref(new RouteContext(route.name));
const aboutMeRef = ref(null);
const isOnlyPartialTextVisible = ref(true);

const isTextTooLong = computed(() => {
  return aboutMeRef.value?.$el.offsetHeight > 85 || false;
});

const textSplit = computed(() => {
  let { aboutMe } = props.candidate;
  aboutMe = formatBulletedList(aboutMe);

  if (isTextTooLong.value && isOnlyPartialTextVisible.value) {
    const ratio = aboutMeRef.value ? Math.ceil(aboutMeRef.value.$el.offsetHeight / 85) : 1;
    return [`${aboutMe.slice(0, Math.floor(aboutMe.length / ratio))}...`, aboutMe.slice(Math.floor(aboutMe.length / ratio))];
  }
  return [aboutMe];
})

watch(route, (newRoute) => {
  routeContext.value = new RouteContext(newRoute.name);
}, { immediate: true });

</script>

<template>
  <div id="tab-profile-about-me">
    <h4>About me</h4>

    <div class="about-me-container">
      <ul>
        <li class="d-flex">
          <div>
            <span>
              <HighlightableField
                ref="aboutMeRef"
                :enabled="tokensEnabled"
                :project="currentProject"
                :candidate="candidate"
                :token-text="textSplit[0]"
                token-type="plain"
                :additional-token-types="additionalTokenTypes"
                :similarity-tokens="humanTokens"
              />
            </span>

            <span v-if="isTextTooLong" class="d-flex align-items-center">
              <el-button
                class="ml-8"
                type="text"
                size="small"
                @click="isOnlyPartialTextVisible = !isOnlyPartialTextVisible"
              >
                {{ isOnlyPartialTextVisible ? "Read more" : "Read less" }}
              </el-button>

              <HighlightableField
                v-if="!isOnlyPartialTextVisible"
                class="pl-8"
                :project="currentProject"
                :candidate="candidate"
                :token-text="textSplit[1]"
                token-type="plain"
                :enabled="tokensEnabled"
                :additional-token-types="additionalTokenTypes"
                :similarity-tokens="humanTokens"
                show-only-tokens
              />
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
#tab-profile-about-me {
  font-size: 14px;
  color: $tf-font-ghost;
  cursor: default;

  h4 {
    font-size: 20px;

    [class^="material-icons-"], [class*=" material-icons-"] {
      color: $grey-600;
    }
  }

  .about-me-container {
    margin-left: 16px;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 8px;

        i {
          float: left;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
