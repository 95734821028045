import DateTimeHelper from "@/common/DateTimeHelper";

export default {
  name: "CandidateOutreachMessage",
  components: {
  },
  props: {
    item: Object, // progressItem
    expanded: {
      type: Boolean,
      default: true,
    },
    isLinkedinInmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      messageExpanded: this.expanded,
    };
  },
  computed: {
    pending() {
      return this.item.status === "pending";
    },
    fromName() {
      return this.item.message.from.name;
    },
    fromEmail() {
      return this.item.message.from.email;
    },
    toName() {
      return this.item.message.to.name;
    },
    toEmail() {
      return this.item.message.to.email;
    },
    fromInitials() {
      if (this.fromName !== "") {
        return this.fromName.split(" ").map((n)=>n[0]).join("");
      }
      return "?";
    },
    ccName() {
      return this.item.message.cc?.name;
    },
    ccEmail() {
      return this.item.message.cc?.email;
    },
    bccName() {
      return this.item.message.bcc?.name;
    },
    bccEmail() {
      return this.item.message.bcc?.email;
    },
    subject() {
      return this.item.message.subject;
    },
    date() {
      return DateTimeHelper.MMMDoYYYY(this.item.statusAt);
    },
    body() {
      return this.item.message.body;
    },
    exactDateTimeInTimezone() {
      return DateTimeHelper.exactDateTimeInTimezone(this.item.statusAt);
    },
  },
  methods: {
    toggleExpand() {
      this.messageExpanded = !this.messageExpanded;
    },
  },
};
