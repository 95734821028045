import { TYPES } from "@/components/MessageGeneration/constants";

export default function ({
  campaignId,
  selectedProject,
  candidate,
  bulkSelectionAttributes,
  inAppSourcingMessage,
  allowDupes,
  reviewContext,
  playbookId,
  route,
}) {
  return {
    campaignId,
    projectId: selectedProject?.id,
    profileId: candidate?.profileId,
    tfId: candidate?.tfId,
    bulkSelectionAttributes,
    subject: inAppSourcingMessage.firstMessage?.subject,
    firstMessage: inAppSourcingMessage.firstMessage?.body,
    allowDupes: allowDupes.value,
    cc: inAppSourcingMessage.firstMessage?.cc,
    bcc: inAppSourcingMessage.firstMessage?.bcc,
    isRerouteFromReview: reviewContext,
    playbookId,
    senderId: inAppSourcingMessage.senderId,
    reviewerIds: inAppSourcingMessage.reviewerIds,
    replyHandlerId: inAppSourcingMessage.replyHandlerId,
    recommendationsProjectId: route.params.projectId || route.query.projectId,
    followUps: inAppSourcingMessage.followUps,
    autoReplies: {
      positiveAnswerEnabled: inAppSourcingMessage[TYPES.YOUR_REPLY_POSITIVE].enabled,
      positiveAnswer: inAppSourcingMessage[TYPES.YOUR_REPLY_POSITIVE].body,
      positiveSubject: `Re: ${inAppSourcingMessage.firstMessage?.subject}`,
      positiveCc: inAppSourcingMessage[TYPES.YOUR_REPLY_POSITIVE].cc,
      positiveBcc: inAppSourcingMessage[TYPES.YOUR_REPLY_POSITIVE].bcc,
      negativeAnswerEnabled: inAppSourcingMessage[TYPES.YOUR_REPLY_NEGATIVE].enabled,
      negativeAnswer: inAppSourcingMessage[TYPES.YOUR_REPLY_NEGATIVE].body,
      negativeSubject: `Re: ${inAppSourcingMessage.firstMessage?.subject}`,
      negativeCc: inAppSourcingMessage[TYPES.YOUR_REPLY_NEGATIVE].cc,
      negativeBcc: inAppSourcingMessage[TYPES.YOUR_REPLY_NEGATIVE].bcc,
      oooAnswerEnabled: inAppSourcingMessage[TYPES.YOUR_REPLY_OOO].enabled,
      oooAnswer: inAppSourcingMessage[TYPES.YOUR_REPLY_OOO].body,
      oooCc: inAppSourcingMessage[TYPES.YOUR_REPLY_OOO].cc,
      oooBcc: inAppSourcingMessage[TYPES.YOUR_REPLY_OOO].bcc,
    },
  };
}
