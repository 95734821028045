import { Quill } from "vue2-editor";

const Embed = Quill.import("blots/embed");

export default class TokenBlot extends Embed {
  static blotName = "placeholder-token";
  static tagName = "span";
  static className = "token";

  static create({ title, slug }) {
    let node = super.create();
    node.textContent = title;
    node.classList.add(slug.replace(/_/g, "-"));
    node.setAttribute("draggable", "true");
    node.ondragstart = (event) => {
      const text = `{{${slug}}}`;
      const json = JSON.stringify({ title, slug });
      event.dataTransfer.setData("text/plain", text);
      event.dataTransfer.setData("application/vnd.placeholder.token", json);
    };
    node.ondragend = () => {
      node.remove();
    };

    node.dataset.title = title;
    node.dataset.slug = slug;

    return node;
  }

  static value(node) {
    return node.dataset;
  }

  length() {
    return 1;
  }
}
