<script setup>
import { formatEmploymentPeriod } from "@/common/employments/formatEmploymentPeriod";
import { formatBulletedList } from "@/common/misc/formatBulletedList";
import { employmentSimilarityTokens } from "@/common/projects/recommendations/employmentSimilarityTokens";

import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

import Company from "./Company";

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
  employment: {
    type: Object,
    required: true,
  },
  tokensEnabled: {
    type: Boolean,
    default: false,
  },
});

const tokens = employmentSimilarityTokens({
  enabled: props.tokensEnabled,
  employmentIndex: props.index,
  candidate: props.candidate,
});

const isCurrentEmployment = props.employment.startDate && !props.employment.endDate;
const roleFilterTokens = props.candidate.filterHighlights && (isCurrentEmployment ? props.candidate.filterHighlights.currentTitleTokens : props.candidate.filterHighlights.previousTitleTokens);
</script>

<template>
  <div class="experience-section">
    <div class="d-flex justify-content-between align-items-center">
      <div class="experience-title">
        <HighlightableField
          :enabled="tokensEnabled"
          :project="project"
          :candidate="candidate"
          :token-text="employment.role"
          :token-type="isCurrentEmployment ? 'role-current' : 'role-previous'"
          :additional-token-types="['plain']"
          :similarity-tokens="[tokens.role]"
          :filter-tokens="roleFilterTokens"
        />
      </div>
      <div class="experience-dates">
        <div>
          <span>{{ formatEmploymentPeriod(props.employment, { includeBrackets: false }) }}</span>
          <span v-if="employment.tenure"> {{ props.employment.tenure }} </span>
          <span v-else-if="employment.computedTenureStr"> ({{ props.employment.computedTenureStr }}) </span>
        </div>
      </div>
    </div>

    <Company
      :index="index"
      :project="project"
      :candidate="candidate"
      :employment="employment"
      :tokens-enabled="tokensEnabled"
    />

    <!-- TODO: Test if we can get rid of the if and just use :enabled="tokensEnabled" -->
    <div v-if="tokensEnabled" class="experience-description">
      <HighlightableField
        :project="project"
        :candidate="candidate"
        :token-text="formatBulletedList(employment.description)"
        token-type="plain"
        :similarity-tokens="tokens.plain"
        :filter-tokens="candidate.filterHighlights?.queryTokens"
      />
    </div>
    <div v-else-if="employment.description" class="experience-description" v-html="formatBulletedList(employment.description)"></div>

  </div>
</template>

<style scoped lang="scss">
  .experience-section {
    width: 100%;
  }

  .experience-title {
    font-weight: 600;

    ::v-deep .nudge-left .highlight {
      margin-left: -5px;
    }
  }

  .experience-dates {
    color: $tf-font-default;
  }

  .experience-description {
    color: $tf-font-ghost;
  }
</style>
