<template>
  <el-dialog
    id="invite-teammate-modal"
    :visible="visible"
    :show-close="false"
    append-to-body
    @close="modalClosed"
  >
    <div slot="title" class="row">
      <div class="col-12">
        <h4>Invite Your Team</h4>
      </div>
    </div>

    <div class="container modal-container">
      <div v-html="text"></div>
    </div>

    <!-- Footer -->
    <span id="footer" slot="footer">
      <el-button type="text" class="mr-16" @click="modalClosed">
        Cancel
      </el-button>
      <router-link :to="{ name: 'invitations-management' }" replace>
        <el-button type="primary">
          Open the invitations page
        </el-button>
      </router-link>
    </span>
  </el-dialog>
</template>

<script>
import { INVITE_NEW_TEAMMATE_SUBTITLE } from "@/constants";

export default {
  name: "InviteTeammateModal",
  props: {
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      previousRouteQuery: null,
    };
  },
  computed: {
    visible() {
      return this.$route.query.modal === "invite-teammate" && !!this.$route.query["teammate-type"];
    },
    text() {
      const routeQuerySender = this.$route.query["teammate-type"] === "sender";
      if (routeQuerySender) {
        return INVITE_NEW_TEAMMATE_SUBTITLE;
      }
      return `Invite a teammate to become your candidate reviewer.
        Using the Teamable web or mobile app, they will approve or
        reject candidates that you find. Teamable will automatically
        reach out to approved candidates.`;
    },
  },
  watch: {
    "$route.query"() {
      if (!this.visible) {
        this.previousRouteQuery = this.$route.query;
      }
    },
  },
  methods: {
    modalClosed() {
      if (this.visible) {
        this.$router.replace({ query: this.previousRouteQuery });
      }
    },
  },
};
</script>

<style lang="scss">
  .modal-container {
    cursor: default;
    word-wrap: normal;
    word-break: normal;
    h4 {
      color: $tf-font-ghost;
    }
    p {
      color: $tf-font-default;
    }
  }
</style>
