<template>
  <div id="loader">
    <content-loader
      :width="100"
      :height="height"
      :speed="2"
      primary-color="#F4E3CB"
      secondary-color="#FAF1E6"
    >
      <rect x="0" y="0" rx="0" ry="0.5" width="30" height="1" />
      <rect x="0" y="2" rx="1" ry="1" width="100" height="4" />

      <rect x="0" y="12" rx="0" ry="2" width="30" height="1" />
      <rect x="0" y="14" rx="1" ry="2.5" width="100" height="4" />

      <rect x="0" y="25" rx="0" ry="3" width="30" height="1" />
      <rect x="0" y="27" rx="1" ry="3.5" width="100" height="4" />

      <rect x="0" y="38" rx="0" ry="4" width="30" height="1" />
      <rect x="0" y="40" rx="1" ry="4.5" width="100" height="4" />

      <rect v-if="isInbound" x="0" y="51" rx="0.5" ry="5" width="30" height="1" />
      <rect v-if="isInbound" x="0" y="53" rx="1" ry="5.5" width="100" height="4" />

      <rect v-if="isInbound" x="0" y="64" rx="0.5" ry="6" width="30" height="1" />
      <rect v-if="isInbound" x="0" y="66" rx="1" ry="7.5" width="100" height="4" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  name: "NewCampaignModalLoader",
  components: {
    ContentLoader,
  },
  props: {
    isInbound: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    height() {
      return this.isInbound ? 72 : 44;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
