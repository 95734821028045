<script setup>
import { onMounted, ref, watch } from "vue";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";

import Tabs from "./components/Tabs/Tabs";
import MessagingSelector from "./components/MessagingSelector/MessagingSelector";
import SelectAnotherProjectModal from "./components/SelectAnotherProjectModal/SelectAnotherProjectModal";
import GenerateNew from "./components/GenerateNew/GenerateNew";

const props = defineProps({
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  switchJobs: {
    type: Boolean,
    default: false,
  },
});

const {
  inAppSourcingGeneratedCampaignFailed,
  inAppSourcingGeneratedCampaign,
} = useGetters([
  "inAppSourcingGeneratedCampaignFailed",
  "inAppSourcingGeneratedCampaign",
]);
const {
  setInAppSourcingMessage,
  setIsGeneratingNewCampaignForInAppSourcing,
  setInAppSourcingSelectedCampaign,
} = useMutations([
  "setInAppSourcingMessage",
  "setIsGeneratingNewCampaignForInAppSourcing",
  "setInAppSourcingSelectedCampaign",
]);
const {
  generateNewCampaignForInAppSourcing,
} = useActions([
  "generateNewCampaignForInAppSourcing",
]);

const showModal = ref(false);

watch(inAppSourcingGeneratedCampaign, (campaign) => {
  if (campaign) {
    updateSelectedCampaign(campaign);
    updateInAppSourcingMessage(campaign);
  }
});

watch(inAppSourcingGeneratedCampaignFailed, (value) => {
  if (value) {
    setIsGeneratingNewCampaignForInAppSourcing(false);
  }
});

function updateInAppSourcingMessage(campaign) {
  setInAppSourcingMessage({
    firstMessage: campaign.firstMessage,
    yourReplyNegative: campaign.autoReplyNegative,
    yourReplyPositive: campaign.autoReplyPositive,
    yourReplyOoo: campaign.autoReplyOoo,
    followUps: campaign.followUps,
    senderId: campaign.pseudoSenderId,
    replyHandlerId: campaign.replyHandlerId,
  });
}

function updateSelectedCampaign(campaign) {
  setInAppSourcingSelectedCampaign({
    ...campaign,
    yourReplyNegative: campaign.autoReplyNegative,
    yourReplyPositive: campaign.autoReplyPositive,
    yourReplyOoo: campaign.autoReplyOoo,
  });
}

async function generateNewCampaignHandler() {
  // if we already have generated campaign
  if (inAppSourcingGeneratedCampaign.value) {
    updateSelectedCampaign(inAppSourcingGeneratedCampaign.value);
    updateInAppSourcingMessage(inAppSourcingGeneratedCampaign.value);
    return;
  }

  // generating new campaign
  generateNewCampaignForInAppSourcing();
}

function selectNewJob() {
  showModal.value = true;
}

onMounted(() => {
  showModal.value = props.switchJobs;
});
</script>

<template>
  <div class="d-flex flex-column h-100">
    <MessagingSelector
      :loading="loading"
      @generate-new-campaign="generateNewCampaignHandler"
    >
      <div
        v-if="!messagingModeOnly"
        class="new-campaign-button d-flex align-items-center"
        @click="selectNewJob"
      >
        <span class="material-icons-round md-18 mr-8 text-color-blue-100">sync_alt</span>
        <span class="project-name">Select another job</span>
      </div>
    </MessagingSelector>

    <Tabs :messaging-mode-only="messagingModeOnly">
      <template #generate-new>
        <GenerateNew @generate="generateNewCampaignHandler" />
      </template>
    </Tabs>

    <SelectAnotherProjectModal
      v-if="!loading"
      :show-modal.sync="showModal"
    />
  </div>
</template>
