<template>
  <el-dialog
    id="candidate-profile-modal"
    :visible="true"
    title="Edit profile"
    append-to-body
    @close="closeModal"
  >
    <el-form
      :model="candidateFormObject"
      :rules="rules"
      label-width="120px"
      @validate="checkValidation"
    >
      <el-form-item prop="name" label="Name">
        <el-input v-model="candidateFormObject.name" placeholder="Enter a name..." />
      </el-form-item>

      <el-form-item prop="company" label="Company">
        <el-input v-model="candidateFormObject.company" placeholder="Enter a company..." />
      </el-form-item>

      <el-form-item prop="email" label="Email">
        <el-input v-model="candidateFormObject.email" placeholder="Enter an email address..." />
      </el-form-item>

      <el-form-item prop="phone" label="Phone">
        <el-input v-model="candidateFormObject.phone" placeholder="Enter a phone number..." />
      </el-form-item>

      <el-form-item prop="url" label="Custom URL">
        <el-input v-model="candidateFormObject.customUrl" placeholder="Enter a custom url..." />
      </el-form-item>
    </el-form>

    <!-- Footer -->
    <div slot="footer" class="d-flex py-8 justify-content-end">
      <el-button type="text" class="mr-16" @click="closeModal">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="submitButtonDisabled || isSaving"
        @click="save">
        Save
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import logEvent from "@/common/Analytics";
import { mapActions } from "vuex";

export default {
  name: "CandidateProfileModal",
  components: {
  },
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  data() {
    const rules = {
      name: [
        { required: true, message: "Please input a candidate name", trigger: "blur" },
      ],
      company: [
        { required: true, message: "Please input the current company", trigger: "blur" },
      ],
      email: [
        { type: "email", message: "Please input a correct email address", trigger: "blur" },
      ],
      url: [
        { validator: this.isValidHttpUrl, trigger: "blur" },
      ],
    }
    return {
      rules,
      candidateFormObject: {},
      submitButtonDisabled: false,
      isSaving: false,
    };
  },
  computed: {
  },
  methods: {
    ...mapActions([
      "updateCandidate",
    ]),
    isValidHttpUrl(rule, value, callback) {
      let url;

      try {
        url = new URL(this.candidateFormObject.customUrl);
        const valid = url.protocol === "http:" || url.protocol === "https:";
        if (valid) {
          callback();
          return;
        }
      } catch {
        callback(new Error("Please input a valid url"));
      }
      callback(new Error("Please input a valid url"));
    },
    checkValidation(field, valid) {
      this.submitButtonDisabled = !valid;
    },
    closeModal() {
      this.$emit("closed");
    },
    async save() {
      this.isSaving = true;
      await this.updateCandidate(this.candidateFormObject);
      this.$notify({
        position: "bottom-right",
        message: "Profile saved.",
        title: "Success",
        type: "success",
      });
      this.isSaving = false;
      this.closeModal();
      this.$bus.$emit("candidate-list-item-changed", this.candidateFormObject);
    },
    _keyDownListener(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        this.save();
      }
    },
  },
  async mounted() {
    this.candidateFormObject = Object.assign({}, this.candidate);
    logEvent("candidate-action-intent", { action: "edit-profile" });

    const element = document.getElementById("candidate-profile-modal");
    if (element) {
      element.addEventListener("keydown", this._keyDownListener);
    }
  },
};
</script>

<style lang="scss" scoped>
#candidate-profile-modal {
  ::v-deep .el-form-item__label {
    line-height: normal !important;
    padding: 8px !important;
  }
}

</style>
