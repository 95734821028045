// Changes made to this file should be synced to extension, main app, and vue 'CampaignMessageValidator'
class CampaignMessageValidator {
  constructor(allowedVariables) {
    this.allowedVariables = allowedVariables;
  }

  generalSubjectMessageErrors(subject) {
    const errors = [];

    if (!subject || subject.trim() === "" || subject.trim() === "<br>") {
      errors.push("subject is required");
      return errors;
    }

    let subjectSentenceMatch = subject.match(/{{sentence}}/g);
    let subjectCalendarMatch = subject.match(/{{calendar}}/g);
    let subjectSignatureMatch = subject.match(/{{signature}}/g);

    if (subjectCalendarMatch) {
      errors.push('"Calendar" can\'t be used in the subject.');
    }

    if (subjectSignatureMatch) {
      errors.push('"Signature" can\'t be used in the subject.');
    }

    if (subjectSentenceMatch) {
      errors.push('"Personalized Content" can\'t be used in the subject.');
    }

    return errors;
  }

  generalBodyMessageErrors(body) {
    const errors = [];

    if (!body || this.getTextFromHtml(body).trim() === "") {
      errors.push("message is required");
      return errors;
    }

    let bodySentenceMatch = body.match(/{{sentence}}/g);
    let bodySignatureMatch = body.match(/{{signature}}/g);

    if (bodySentenceMatch && bodySentenceMatch.length > 1) {
      errors.push('"Personalized Content" can be used only once.');
    }

    if (bodySignatureMatch && bodySignatureMatch.length > 1) {
      errors.push('"Signature" can be used only once.');
    }

    if (this.isMidSentence("{{sentence}}", body)) {
      errors.push('"Personalized Content" cannot be mid-sentence.');
    }

    return errors;
  }

  // Strip html but add a newline before every <br> and <p>
  getTextFromHtml(html) {
    let htmlWithNewLines = html.replace(/<br/img, "\n<br").replace(/<p/img, "\n<p").replace(/&nbsp;/img, " ");
    return (new DOMParser).parseFromString(htmlWithNewLines, "text/html").documentElement.textContent;
  }

  invalidVariableSyntax(text) {
    let syntaxErrors = [];

    let misalignedBracket = text.match(/(^|[^{]){[^{}]+}}/); // {variable}}
    misalignedBracket = misalignedBracket || text.match(/{{[^}]+}([^}]|$)/); // {{variable}
    misalignedBracket = misalignedBracket || text.match(/{{{[^}]+}}|{{[^{]+}}}/); // {{{variable}} or {{variable}}}
    misalignedBracket = misalignedBracket || text.match(/(({{)[^{}]*(?:{|$)|(?:}|^)[^{}]*(}}))/); // }} variable }} or {{ asdasd {{ or missing brackets completely on one side
    if (misalignedBracket && misalignedBracket[0] !== "") syntaxErrors.push(`Incorrect bracket usage: ${misalignedBracket[0]}.`);

    return syntaxErrors;
  }

  variableErrors(text) {
    if (!text) return [];

    const regexString = `{{([^{}]*)}}`;
    const re = new RegExp(regexString, "g");
    const matchTexts = text.match(re);
    let unallowed = [];
    let errors = [];
    let calendarError = false;

    if (matchTexts !== null) {
      for (const matchText of matchTexts) {
        const variableName = matchText.substring(2, matchText.length - 2);
        if (!this.allowedVariables.includes(variableName)) {
          if (variableName === "calendar") {
            calendarError = true;
          } else {
            unallowed.push(variableName);
          }
        }
      }
    }

    if (unallowed.length) {
      errors = [`Variable "${unallowed.join('", "')}" is not allowed. Allowed variables are: "${this.allowedVariables.join('", "')}"`];
    }

    if (calendarError) {
      errors = ['Please setup "Your calendar link" in the campaign sender\'s user settings.'];
    }

    let syntaxErrors = this.invalidVariableSyntax(text);
    errors = errors.concat(syntaxErrors);

    return errors;
  }

  isMidSentence(tag, body) {
    // redeclare to simplify interface
    const bodyToValidate = this.getTextFromHtml(body);
    const bodyCustomIndex = bodyToValidate?.indexOf(tag);
    if (!bodyCustomIndex || bodyCustomIndex === -1) {
      return false;
    }

    // We use replace instead of trim to remove spaces but not new lines
    let beforeCustom = bodyToValidate.substr(0, bodyCustomIndex).replace(/ /g, "");
    let afterCustom = bodyToValidate.substr(bodyCustomIndex + tag.length).replace(/ /g, "");
    let prevChar = beforeCustom.slice(-1);
    let nextChar = afterCustom.charAt(0);

    let beforePunctuation = [".", "?", "!", "\n"]; // before must be one of these
    let afterPunctuation = [".", "?", "!", ";", "-", ","]; // after cannot be any of these

    let beforeIsProblem = ((beforeCustom !== "") && (beforePunctuation.indexOf(prevChar) === -1));
    let afterIsProblem = ((nextChar >= "a" && nextChar <= "z") || (afterPunctuation.indexOf(nextChar) !== -1));

    return (beforeIsProblem || afterIsProblem);
  }
}

export default CampaignMessageValidator;
