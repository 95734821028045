<template>
  <div id="error-logo">
    <img :src="require('@/assets/images/illustrations/error.svg')" alt="Oops..." />
    <div id="text">
      <div id="primary">{{ text }}</div>
      <div id="secondary">Try refreshing this page</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ErrorLogo",
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
#error-logo {
  width: 100%;
  height: calc(100vh - 2 * #{$tf-topbar-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 70px;
    max-height: 70px;
  }

  #text {
    margin-top: 24px;
    text-align: center;
    cursor: default;

    #primary {
      font-size: 16px;
      text-transform: uppercase;
    }

    #secondary {
      font-size: 12px;
    }
  }
}
</style>