import { render, staticRenderFns } from "./SaveIndicator.vue?vue&type=template&id=32f18981&scoped=true"
import script from "./SaveIndicator.vue?vue&type=script&setup=true&lang=js"
export * from "./SaveIndicator.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SaveIndicator.vue?vue&type=style&index=0&id=32f18981&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f18981",
  null
  
)

export default component.exports