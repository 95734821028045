export const employmentSimilarityTokens = ({ candidate, employmentIndex, enabled }) => {
  if (!enabled) {
    return {};
  }

  const humanTokens = candidate?.similarity?.analysis?.humanTokens;
  if (!humanTokens) {
    return {};
  }

  const role = humanTokens.find(token =>
    (
      token.type === "role" ||
      token.type === "role-current" ||
      token.type === "role-previous"
    ) &&
    token.employments.find(e => e.index === employmentIndex)
  );

  const employer = humanTokens.find(token =>
    (
      token.type === "employer" ||
      token.type === "employer-current" ||
      token.type === "employer-previous"
    ) &&
    token.employments.find(e => e.index === employmentIndex)
  );

  const plain = humanTokens.filter(token =>
    token.type === "plain" &&
    token.employments.find(e => e.index === employmentIndex)
  );

  return {
    role,
    employer,
    plain,
  };
}
