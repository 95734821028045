<script setup>
import { computed, unref } from "vue";
import RecommendationsTokenPill from "@/components/RecommendationsTokenPill/RecommendationsTokenPill";
import { useRecommendationFeedbacks } from "@/composables/recommendationFeedbacks/useRecommendationFeedbacks";

const props  = defineProps({
  project: {
    type: Object,
    required: true,
  },
  token: {
    type: Object,
    required: false,
    default: null,
  },
});

const {
  recommendationFeedbacks,
} = useRecommendationFeedbacks({ projectId: props.project.id });

const token = computed(() => ({
  ...props.token,
  recommendationsFeedback: unref(recommendationFeedbacks).find((feedbackToken) => props.token.canonical === feedbackToken.canonical),
}))
</script>

<template>
  <span v-if="token" class="highlight">
    <RecommendationsTokenPill
      :project="project"
      :token="token"
      :show-prefix="false"
      size="small"
    />
  </span>
  <span v-else>
    <slot></slot>
  </span>
</template>

<style scoped lang="scss">
.highlight {
  display: inline-block;
}
</style>
