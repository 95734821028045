import _ from "lodash";

export const extractNameRates = (text) => {
  if (!text) return;

  const parts = text.split("-"); // example: very-small-10-20 , small-10-20

  if (parts.length === 2) { // example: static-0
    return {
      name: _.startCase(parts[0]),
      from: parts[1],
      to: parts[1],
    }
  }

  const to = parts.pop()
  const from = parts.pop()
  const name = _.startCase(parts.join(" "))

  return {
    name,
    from,
    to,
  }
}
