import logEvent from "@/common/Analytics";
import _ from "lodash";

import ProfilesApi from "@/common/http/ProfilesApi";
import ProjectsApi from "@/common/http/ProjectsApi";

const state = {
  // current candidate properties
  currentCandidate: {},
  candidateConnectors: [],
  candidateDedupeMessage: null,
  candidateTagOptions: [],
  isLoadingCandidateConnectors: false,
  isLoadingCurrentCandidate: false,
  isLoadingCurrentCandidateCrm: false,
  isLoadingCurrentCandidateIntro: false,
  isLoadingCurrentCandidateOutreach: false,
  isLoadingCurrentCandidateSummary: false,
  isLoadingTagOptions: false,
  isRequestIntroEnabled: true,
  requestIntroConnectorsId: [],
  requestIntroFromAllConnectors: false,

  // current list properties in the store are for read only
  // to keep source of truth consistent- candidateList handles mutations, then synces state to store
  bulkSelectionAttributes: null, // store
  currentCandidateList: [],
  currentCandidateListCount: null,
  deselectedCandidates: [], // store
  selectedCandidates: [], // store
};

const getters = {
  currentCandidate(state) {
    return state.currentCandidate;
  },
  isLoadingTagOptions(state) {
    return state.isLoadingTagOptions;
  },
  isLoadingCurrentCandidate(state) {
    return state.isLoadingCurrentCandidate;
  },
  isLoadingCurrentCandidateCrm(state) {
    return state.isLoadingCurrentCandidateCrm;
  },
  isLoadingCurrentCandidateOutreach(state) {
    return state.isLoadingCurrentCandidateOutreach;
  },
  isLoadingCurrentCandidateSummary(state) {
    return state.isLoadingCurrentCandidateSummary;
  },
  isLoadingCandidateConnectors(state) {
    return state.isLoadingCandidateConnectors;
  },
  candidateTagOptions(state) {
    return state.candidateTagOptions;
  },
  candidateDedupeMessage(state) {
    return state.candidateDedupeMessage;
  },
  candidateConnectors(state) {
    return state.candidateConnectors;
  },
  selectedCandidates(state) {
    return state.selectedCandidates;
  },
  deselectedCandidates(state) {
    return state.deselectedCandidates;
  },
  currentCandidateList(state) {
    return state.currentCandidateList;
  },
  currentCandidateListCount(state) {
    return state.currentCandidateListCount;
  },
  isLoadingCurrentCandidateIntro(state) {
    return state.isLoadingCurrentCandidateIntro;
  },
  isLoadingCurrentCandidateHistory(state) {
    return state.isLoadingCurrentCandidateIntro || state.isLoadingCurrentCandidateOutreach;
  },
  isCurrentCandidateFullyLoaded(state, getters) {
    return (
      !state.isLoadingCurrentCandidate &&
      !state.isLoadingCurrentCandidateCrm &&
      !getters.isLoadingCurrentCandidateHistory
    )
  },
  isRequestIntroEnabled(state) {
    return state.isRequestIntroEnabled;
  },
  requestIntroFromAllConnectors(state) {
    return state.requestIntroFromAllConnectors;
  },
  requestIntroConnectorsId(state) {
    return state.requestIntroConnectorsId;
  },
  bulkSelectionAttributes(state) {
    return state.bulkSelectionAttributes;
  },
};

const actions = {
  fetchCurrentCandidate({ commit }, { profileId, tfId, projectId, accordWizardUuid, showSummary = false }) {
    commit("setIsLoadingCurrentCandidate", true);
    commit("clearCurrentCandidate");

    if (showSummary) {
      commit("setIsLoadingCurrentCandidateSummary", true);
      ProfilesApi.showProfileSummary({ profileId, tfId, projectId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidateSummary", false);
      })
    }
    if (profileId) {
      commit("setIsLoadingCurrentCandidateCrm", true);
      commit("setIsLoadingCurrentCandidateOutreach", true);
      commit("setIsLoadingCurrentCandidateIntro", true);

      ProfilesApi.showProfile({ profileId, projectId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidate", false);
      });
      ProfilesApi.showProfileCrm({ profileId, projectId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidateCrm", false);
      });
      ProfilesApi.showProfileOutreach({ profileId, projectId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidateOutreach", false);
      });
      ProfilesApi.profileIntros({ profileId, projectId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidateIntro", false);
      });
    } else {
      ProfilesApi.showRecommendation({ projectId, tfId, accordWizardUuid }).then(({ data }) => {
        commit("mergeCurrentCandidate", data.profile);
        commit("setIsLoadingCurrentCandidate", false);
      });
    }
  },
  async updateCandidateTags(context, { candidate, tags, bulkSelectionAttributes }) {
    let params = {
      tags,
      bulkSelectionAttributes,
      profileId: candidate?.profileId,
    };
    ProfilesApi.updateProfileTags(params);
  },
  async updateCandidate({ commit }, candidate) {
    await ProfilesApi.updateProfile({ ...candidate });
    commit("mergeCurrentCandidate", candidate)
  },
  async fetchCandidateDedupeMessage({ commit }, { profileId, tfId, projectId }) {
    const { data } = await ProfilesApi.dedupeMessage({ profileId, tfId, projectId });
    commit("setCandidateDedupeMessage", data.dedupeMessage);
  },
  async fetchCandidateConnectors({ commit }, { profileId }) {
    commit("setIsLoadingCandidateConnectors", true);
    const { data } = await ProfilesApi.candidateConnectors({ profileId });
    commit("setCandidateConnectors", data.connectors);
    commit("setIsLoadingCandidateConnectors", false);
  },
  async addCandidateReminder(context, { candidate, bulkSelectionAttributes, remindAt, reason }) {
    logEvent("candidate-action", { action: "remind-me-later" });
    const params = {
      bulkSelectionAttributes,
      profileId: candidate?.profileId,
      remindAt,
      reason,
    }
    const { data } = await ProfilesApi.createReminder(params);
    if (candidate?.reminders) {
      candidate.reminders.push(data.reminder);
    }
  },
  async deleteCandidateReminder(context, { candidate, reminder }) {
    await ProfilesApi.deleteReminder(reminder.id);

    const idx = candidate.reminders.findIndex(r => r.id == reminder.id);
    candidate.reminders.splice(idx, 1);
  },
  async addCandidateToProject(context, { candidate, projectId, bulkSelectionAttributes, isRerouteFromReview, remindDays, recommendationsProjectId }) {
    logEvent("candidate-action", { action: "add-to-project" });

    const { data } = await ProjectsApi.addToProject({
      bulkSelectionAttributes,
      isRerouteFromReview,
      profileId: candidate?.profileId,
      tfId: candidate?.tfId,
      projectId,
      recommendationsProjectId,
      remindDays,
    });
    if (candidate) {
      const candidateHasProject = candidate.projectProfiles?.map(pp => pp.projectId).includes(projectId);
      if (!candidate.projectProfiles) {
        candidate.projectProfiles = [data.projectProfile];
      } else if (!candidateHasProject) {
        candidate.projectProfiles.push(data.projectProfile);
      }
    }
  },
  async removeBulkCandidatesFromProject(context, { project, bulkSelectionAttributes }) {
    const params = {
      bulkSelectionAttributes,
      projectId: project.id,
    }

    await ProjectsApi.removeCandidatesBulk(params);
  },
  async fetchTagOptions({ commit }) {
    commit("setIsLoadingTagOptions", true);
    const { data } = await ProfilesApi.tagOptions();
    commit("setCandidateTagOptions", data.tagOptions);
    commit("setIsLoadingTagOptions", false);
  },
  async removeCandidateFromProject({ commit }, {  candidate, projectId, projectProfileId }) {
    if (projectProfileId) {
      ProjectsApi.removeFromProject(projectProfileId);
    } else {
      const { data } = await ProjectsApi.removeCandidate({ profileId: candidate.profileId, projectId });
      projectProfileId = data.projectProfileId;
    }

    commit("removeProjectProfile", { candidate, projectProfileId });
  },
  async saveCandidateWatched(state, { candidate, watched, bulkSelectionAttributes }) {
    logEvent("candidate-action", { action: "watch" });
    ProfilesApi.watchProfile({
      profileId: candidate?.profileId,
      bulkSelectionAttributes,
      watch: watched,
    });

    if (candidate) {
      candidate.watched = watched;
    }
  },
};

const mutations = {
  setIsLoadingTagOptions(state, value) {
    state.isLoadingTagOptions = value;
  },
  mergeCurrentCandidate(state, candidate) {
    // _ for deep merge
    // Object.assign since vue isn't rerendering when nested properties are changed
    state.currentCandidate = Object.assign({}, _.merge(state.currentCandidate, candidate));
  },
  setCandidateTagOptions(state, tagOptions) {
    state.candidateTagOptions = tagOptions;
  },
  addTagOptionToCandidateTagOptions(state, tag) {
    if (!state.candidateTagOptions.includes(tag)) {
      state.candidateTagOptions.push(tag);
    }
  },
  clearCurrentCandidate(state) {
    state.currentCandidate = {};
  },
  setCandidateDedupeMessage(state, value) {
    state.candidateDedupeMessage = value;
  },
  setCandidateConnectors(state, value) {
    state.candidateConnectors = value;
  },
  setIsLoadingCandidateConnectors(state, value) {
    state.isLoadingCandidateConnectors = value;
  },
  setIsLoadingCurrentCandidate(state, value) {
    state.isLoadingCurrentCandidate = value;
  },
  setIsLoadingCurrentCandidateCrm(state, value) {
    state.isLoadingCurrentCandidateCrm = value;
  },
  setIsLoadingCurrentCandidateSummary(state, value) {
    state.isLoadingCurrentCandidateSummary = value;
  },
  setIsLoadingCurrentCandidateOutreach(state, value) {
    state.isLoadingCurrentCandidateOutreach = value;
  },
  setIsLoadingCurrentCandidateIntro(state, value) {
    state.isLoadingCurrentCandidateIntro = value;
  },
  removeProjectProfile(state, { candidate, projectProfileId }) {
    const idx = candidate.projectProfiles?.findIndex(p => p.id == projectProfileId);
    if (idx > -1) {
      candidate.projectProfiles.splice(idx, 1);
    }
  },
  setIsIntrosEnabledByUser(state, value) {
    state.isRequestIntroEnabled = value;
  },
  setRequestIntroFromAllConnectors(state, value) {
    state.requestIntroFromAllConnectors = value;
  },
  setRequestIntroConnectorsId(state, value) {
    state.requestIntroConnectorsId = value;
  },
  setBulkSelectionAttributes(state, value) {
    state.bulkSelectionAttributes = value;
  },
  setSelectedCandidates(state, value) {
    state.selectedCandidates = value.map(c => {
      return { profileId: c.profileId, tfId: c.tfId, projectIdsSavedForLater: c.projectIdsSavedForLater };
    });
  },
  setDeselectedCandidates(state, value) {
    state.deselectedCandidates = value.map(c => {
      return { profileId: c.profileId, tfId: c.tfId };
    });
  },
  setCurrentCandidateList(state, candidates) {
    state.currentCandidateList = candidates;
  },
  setCurrentCandidateListCount(state, newValue) {
    state.currentCandidateListCount = newValue;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
