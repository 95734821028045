<template>
  <el-tooltip
    v-if="task.threadRequiresUserAttention"
    :content="'The candidate is waiting on ' + task.teamName + ' to respond'"
    :open-delay="500"
  >
    <span class="ml-8 material-icons-round align-items-center text-color-black-50 md-18">
      mark_unread_chat_alt
    </span>
  </el-tooltip>
</template>

<script>

export default {
  name: "ThreadRequiresUserAttentionIcon",
  props: {
    task: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
