import { REVIEW_CONTEXT } from "@/constants";
import ProfilesApi from "@/common/http/ProfilesApi";
import _ from "lodash";

const state = {
  isLoadingReviewData: false,
  reviewData: null,
  currentProjectReviewData: null,
  undoReviewData: null,
  currentReviewContext: null,
  reviewForApprovalCountData: { count: null, updatedAt: new Date() },
  reviewApplicantsCountData: { count: null, updatedAt: new Date() },
  reviewForApprovalCountPolledData: { count: null, updatedAt: new Date() },
  reviewContextForApprovalCount: 0,
  reviewContextRequestedInitialCount: 0,
};

const sideloadReviewDataObjects = (data) => {
  let reviewData = Object.assign({}, data);
  // builds nested data from sideloaded objects
  for (const [reviewType, reviewDataForType] of Object.entries(reviewData)) {
    const userDataById = _.groupBy(reviewDataForType.users, "id");
    const campaignDataById = _.groupBy(reviewDataForType.campaigns, "id");
    const tprDataByProjectId = _.groupBy(reviewDataForType.tprs, "projectId");
    const tprDataByCampaignId = _.groupBy(reviewDataForType.tprs, "campaignId");

    reviewDataForType.tprs = reviewDataForType.tprs.map(tpr => {
      if (tpr.sourcerId) {
        tpr.sourcer = userDataById[tpr.sourcerId][0]
      }
      return tpr;
    });

    reviewDataForType.projects = reviewDataForType.projects.map(project => {
      project.tprs = tprDataByProjectId[project.id];
      project.users = project.userIds.map(id => userDataById[id][0]);
      project.campaigns = project.campaignIds.map(id => {
        let campaign = campaignDataById[id][0];
        campaign.tprs = tprDataByCampaignId[campaign.id];
        campaign.users = campaign.userIds.map(id => userDataById[id][0]);

        return campaign;
      });
      return project;
    });

    reviewData[reviewType] = reviewDataForType;
  }
  return reviewData;
};

const getters = {
  reviewData(state) {
    return state.reviewData;
  },
  isLoadingReviewData(state) {
    return state.isLoadingReviewData;
  },
  currentProjectReviewData(state) {
    return state.currentProjectReviewData;
  },
  undoReviewData(state) {
    return state.undoReviewData;
  },
  reviewProjectListForApproval(state, getters) {
    if (!state.reviewData) {
      return [];
    }
    return getters.reviewData.forApproval.projects || [];
  },
  reviewProjectListApplicants(state, getters) {
    if (!state.reviewData) {
      return [];
    }
    return getters.reviewData.applicants.projects || [];
  },
  reviewProjectListRequested(state, getters) {
    if (!state.reviewData) {
      return [];
    }
    return getters.reviewData.requested.projects || [];
  },
  reviewRequestedCount(state) {
    if (!state.reviewData) {
      return;
    }
    return state.reviewData.requested.profileCount;
  },
  reviewForApprovalCount(state) {
    if (state.reviewForApprovalCountData.updatedAt > state.reviewForApprovalCountPolledData.updatedAt) {
      return state.reviewForApprovalCountData.count;
    }
    return state.reviewForApprovalCountPolledData.count;
  },
  reviewApplicantsCount(state) {
    return state.reviewApplicantsCountData.count;
  },
  reviewContextForApprovalCount(state) {
    return state.reviewContextForApprovalCount;
  },
  reviewContextRequestedInitialCount(state) {
    return state.reviewContextRequestedInitialCount;
  },
  profileIdsInReview(state, getters) {
    if (!getters.currentReviewContextReviewData) {
      return [];
    }

    let tprs = getters.currentReviewContextReviewData.tprs;
    if (state.currentReviewContext.id != null) {
      tprs = tprs.filter(tpr => tpr["projectId"] && tpr["projectId"].toString() === state.currentReviewContext.id.toString());
    }
    return tprs.map(tpr => tpr.profileId);
  },
  currentReviewType(state) {
    return state.currentReviewContext?.reviewType;
  },
  currentReviewContext(state) {
    return state.currentReviewContext;
  },
  currentReviewContextReviewData(state, getters) {
    if (!state.currentReviewContext?.reviewType || !state.currentProjectReviewData) {
      return;
    }

    return state.currentProjectReviewData[getters.currentReviewType];
  },
};
const actions = {
  async fetchReviewDataForProject({ commit }, projectId) {
    const { data } = await ProfilesApi.fetchReviewDataForProject(projectId);
    commit("setCurrentProjectReviewData", { data });
    commit("setReviewContextForApprovalCount", data.forApproval.profileCount);
    commit("setReviewContextRequestedInitialCountData", data.requested.profileCount);
  },
  async fetchReviewData({ commit }) {
    if (state.isLoadingReviewData) {
      return;
    }

    if (!state.reviewData) {
      commit("setIsLoadingReviewData", true);
    }
    const { data } = await ProfilesApi.fetchAllForReview();
    commit("setReviewData", { data });
    commit("setReviewForApprovalCountData", data.forApproval.profileCount);
    commit("setReviewApplicantsCountData", data.applicants.profileCount);
    commit("setIsLoadingReviewData", false);
  },
  async fetchReviewCount({ commit }) {
    const { data } = await ProfilesApi.fetchCountForReview();
    commit("setReviewForApprovalCountPolledData", data.count);
  },
};
const mutations = {
  setReviewData(state, { data }) {
    state.reviewData = sideloadReviewDataObjects(data);
  },
  setCurrentProjectReviewData(state, { data }) {
    state.currentProjectReviewData = sideloadReviewDataObjects(data);;
  },
  setUndoReviewData(state, data) {
    state.undoReviewData = data;
  },
  removeCandidateFromReview(state, { candidate }) {
    const reviewType = state.currentReviewContext?.reviewType;

    if (!state.currentProjectReviewData || !reviewType) {
      return;
    }

    const idx = state.currentProjectReviewData[reviewType].tprs.findIndex(p => p.profileId === candidate.profileId);

    if (idx > -1) {
      state.currentProjectReviewData[reviewType].tprs.splice(idx, 1);

      // Full reassignment is the only way for Vue to detect data change, cause observer exists at root variable level within state
      if (reviewType === REVIEW_CONTEXT.forApproval) {
        state.reviewForApprovalCountPolledData = {
          ...state.reviewForApprovalCountPolledData,
          count: state.reviewForApprovalCountPolledData.count - 1,
        };

        state.reviewForApprovalCountData = {
          ...state.reviewForApprovalCountData,
          count: state.reviewForApprovalCountData.count - 1,
        };

        state.reviewContextForApprovalCount -= 1;

      } else if (reviewType === REVIEW_CONTEXT.applicants) {
        state.reviewApplicantsCountData = {
          ...state.reviewApplicantsCountData,
          count: state.reviewApplicantsCountData.count - 1,
        };
      }
    }

    if (state.reviewData) {
      let tprs = state.reviewData[state.currentReviewContext.reviewType].tprs;
      const allIdx = tprs.findIndex(p => p.profileId === candidate.profileId);
      if (allIdx > -1) {
        tprs.splice(idx, 1);
      }
    }
  },
  addCandidateBackToReview(state, { tpr }) {
    const reviewType = state.currentReviewContext?.reviewType;
    if (!state.currentProjectReviewData || !reviewType) {
      return;
    }

    if (state.reviewData) {
      state.reviewData[reviewType].tprs.splice(0, 0, tpr);
    }

    // Add to review data
    state.currentProjectReviewData[reviewType].tprs.splice(0, 0, tpr);

    // Full reassignment is the only way for Vue to detect data change, cause observer exists at root variable level within state
    if (reviewType === REVIEW_CONTEXT.forApproval) {
      state.reviewForApprovalCountPolledData = {
        ...state.reviewForApprovalCountPolledData,
        count: state.reviewForApprovalCountPolledData.count + 1,
      };

      state.reviewForApprovalCountData = {
        ...state.reviewForApprovalCountData,
        count: state.reviewForApprovalCountData.count + 1,
      };

      state.reviewContextForApprovalCount += 1;

    } else if (reviewType === REVIEW_CONTEXT.applicants) {
      state.reviewApplicantsCountData = {
        ...state.reviewApplicantsCountData,
        count: state.reviewApplicantsCountData.count + 1,
      };
    }
  },
  setCurrentReviewContext(state, { project, reviewType }) {
    state.currentReviewContext = {
      id: project?.id,
      name: project?.name,
      reviewType,
      // TODO: is probably cleaner to store currentReviewContextReviewData here
    };
  },
  setReviewForApprovalCountData(state, count) {
    state.reviewForApprovalCountData["count"] = count;
    state.reviewForApprovalCountData["updatedAt"] = new Date();
  },
  setReviewForApprovalCountPolledData(state, count) {
    state.reviewForApprovalCountPolledData["count"] = count;
    state.reviewForApprovalCountPolledData["updatedAt"] = new Date();
  },
  setReviewApplicantsCountData(state, count) {
    state.reviewApplicantsCountData["count"] = count;
    state.reviewApplicantsCountData["updatedAt"] = new Date();
  },
  setReviewContextForApprovalCount(state, count) {
    state.reviewContextForApprovalCount = count;
  },
  setReviewContextRequestedInitialCountData(state, count) {
    state.reviewContextRequestedInitialCount = count;
  },
  setIsLoadingReviewData(state, value) {
    state.isLoadingReviewData = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
