<script setup>

const { title, subtitle, actionName } = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  "actionClick",
]);

const actionClickHandler = () => {
  emit("actionClick");
}

</script>

<template>
  <div class="empty-slot-wrapper">
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle" v-html="subtitle"></div>
    <el-button type="primary" @click="actionClickHandler">
      {{ actionName }}
    </el-button>
  </div>
</template>

<style scoped lang="scss">

.empty-slot-wrapper {
  padding: 16px;
  max-width: 60vw;

  .title {
    color: $black-90;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .subtitle {
    color: $black-90;
    font-size: 14px;
    margin-bottom: 16px;
  }
}

</style>