export const tokenCategory = (token) => {
  switch (token.type) {
    case "role-current":
    case "role-previous":
    case "role": {
      return "experience";
    }
    case "employer-current":
    case "employer-previous":
    case "employer": {
      return "experience";
    }
    case "emp-industry": {
      return "insights";
    }
    case "edu-institution": {
      return "experience";
    }
    case "emp-tenure": {
      return "insights";
    }
    case "emp-start-size": {
      return "insights";
    }
    case "emp-growth-rate": {
      return "insights";
    }
    case "plain": {
      return "skills";
    }
    default: {
      return "insights";
    }
  }
}
