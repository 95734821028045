<script setup>

import { computed } from "vue";
import moment from "moment-timezone";
import ProjectsApi from "@/common/http/ProjectsApi";
import { useRoot } from "@/composables/misc/useRoot";

const root = useRoot();
const emit = defineEmits(["closed"]);
const props = defineProps({
  projectProfile: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const removeAt = computed(() => {
  return moment(props.projectProfile.removeAt).format("MMM DD YYYY");
});

function onClose() {
  emit("closed");
}

function cancelRemoval() {
  root.$confirm("Do you wish to keep this candidate as saved for later in this job?", {
    confirmButtonText: "Yes, cancel the scheduled removal",
    cancelButtonText: "Nevermind",
  }).then(() => {
    ProjectsApi.cancelRemoval(props.projectProfile.id).then(() => {
      root.$bus.$emit("refresh-current-candidate");
    });
  });
}
</script>

<template>
  <div>
    <div class="d-flex align-items-center justify-content-end">
      <router-link :to="{ name: 'job-root', params: { projectId: projectProfile.projectId } }">
        <el-button type="secondary">
          View job
        </el-button>
      </router-link>

      <span class="material-icons-round text-color-black-50 ml-8 md-18 close-touchpoint" @click="onClose">
        close
      </span>
    </div>

    <div v-if="projectProfile.removeAt">
      <el-alert type="warning" class="m-16" :closable="false">
        <div class="d-flex align-items-center">
          <span>
            Candidate will be auto-removed from this job on {{ removeAt }}. This was scheduled according to your <router-link :to="{ name: 'user-settings', hash: '#save-for-later' }">user settings</router-link> when the candidate was saved for later.
          </span>
          <div @click="cancelRemoval"><i class="ml-16 remove-icon" /></div>
        </div>
      </el-alert>
    </div>
  </div>
</template>
