import UsersApi from "@/common/http/UsersApi";

export function initialize(linkToken) {
  window.MergeLink.initialize({
    linkToken,
    onSuccess: (public_token) => onSuccess(public_token),
    //     onReady: () => (),
    // A value of `true` for `shouldSendTokenOnSuccessfulLink` makes Link call `onSuccess`
    // immediately after an account has been successfully linked instead of after the user
    // closes the Link modal.
    shouldSendTokenOnSuccessfulLink: true,
    // tenantConfig: {
    // apiBaseURL: "https://api-eu.merge.dev" /* OR specified single tenant API base URL */
    // },
  })
}

export function loadScript() {
  if (window.MergeLink) {
    return;
  }

  new Promise(function (resolve, reject) {
    const src = "https://cdn.merge.dev/initialize.js";
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const el = document.createElement("script");
    el.type = "text/javascript";
    el.async = true;
    el.src = src;
    el.addEventListener("load", resolve);
    el.addEventListener("error", reject);
    el.addEventListener("abort", reject);
    document.head.appendChild(el);
  }).then();
}

function onSuccess(public_token) {
  // Send public_token to server (Step 3)
  UsersApi.sendMergeDevAccountToken({
    mergeDevPublicToken: public_token,
  })
}

export function openLink() {
  window.MergeLink.openLink();
}
