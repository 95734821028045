import { mapGetters, mapMutations } from "vuex";
import { EXTENSION_URL } from "@/common/Env";

export default {
  name: "NavigationMenu",
  data() {
    return {
      activeIndex: "-1",
      teamLogoError: false,
    };
  },
  computed: {
    ...mapGetters([
      "cannedSearches",
      "currentTeam",
      "currentUser",
      "isExtensionInstalled",
      "isReferralsEnabled",
      "reviewForApprovalCount",
    ]),
    sideMenuItems() {
      let items = [
        { title: "Sourcing", route: { name: "sourcing", keyword: "sourcing" }, icon: "person_search" },
        { title: "Jobs", route: { name: "job-list", keyword: "jobs" }, icon: "work_outline" },
        { title: "Campaigns", route: { name: "campaign-list", keyword: "campaigns" }, icon: "send" },
      ];

      let candidateCannedSearches = this.cannedSearches.candidates;
      if (!this.isReferralsEnabled && candidateCannedSearches?.referralNetwork) {
        delete candidateCannedSearches.referralNetwork;
      }

      items.push({
        title: "CRM",
        route: { name: "crm", keyword: "crm" },
        icon: "groups",
        children: Object.values(candidateCannedSearches || {}),
      });

      items.push(...[
        { title: "Review", route: { name: "review", keyword: "review" }, icon: "sensor_occupied" },
        { title: "Reports", route: { name: "reports", keyword: "reports" }, icon: "leaderboard" },
      ]);

      return items;
    },
    reviewCountLabel() {
      if (!isNaN(this.reviewForApprovalCount) && this.reviewForApprovalCount > 0) {
        return this.reviewForApprovalCount;
      }
      return;
    },
    teamLogoClass() {
      if (this.currentTeam.showNameNextToLogo) {
        return "team-logo-next-to-name";
      } else {
        return "h-100";
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: "setActiveIndex",
    },
  },
  methods: {
    ...mapMutations([
      "setWindowInnerWidth",
    ]),
    setActiveIndex() {
      if (this.$route.name === "dashboard") {
        this.activeIndex = "-1";
      } else {
        const index = this.sideMenuItems.findIndex(item => this.$route.path.startsWith(`/${item.route.keyword}`));
        this.activeIndex = index?.toString();
      }
    },
    openExtensionLink() {
      window.open(EXTENSION_URL, "_blank");
    },
  },
  created() {
    this.setActiveIndex();
  },
};
