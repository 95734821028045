import { mapGetters } from "vuex";
import DateTimeHelper from "@/common/DateTimeHelper";
import ChatbotTranscriptModal from "@/components/ChatbotTranscriptModal";
import _ from "lodash";

export default {
  name: "CandidateStatusDiagram",
  components: {
    ChatbotTranscriptModal,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    currentTouchpoint: {
      type: Object,
      default: null,
    },
    touchpointsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalChatbotActivity: null,
      skippedTypes: ["thread_reply", "thread_sent_email"],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    activities() {
      const items = this.touchpointsList.map(t => t.progressItems || []).flat();
      const activities = items.filter(i => i.title && !this.skippedTypes.includes(i.type));
      return _.sortBy(activities, (t) => t.statusAt).reverse();
    },
  },
  methods: {
    computedActivityTitle(activity) {
      if (activity.type === "sourced") {
        const verb = ["IntroPack", "AtsCandidate"].includes(activity.touchpointType)  ? "for" : "into";
        if (activity.title.startsWith("Applied")) {
          return activity.title;
        }
        return `${activity.title} ${verb} ${activity.projectName}`;
      }
      return activity.title;
    },
    viewTranscript(activity) {
      this.modalChatbotActivity = activity;
    },
    isActivityActive(activity) {
      return activity.touchpointType === this.currentTouchpoint?.touchpointType && activity.touchpointId === this.currentTouchpoint?.id;
    },
    exactDateTimeInTimezone(time) {
      return DateTimeHelper.exactDateTimeInTimezone(time);
    },
    viewCandidate() {
      this.$emit("view-candidate");
    },
    viewTouchpoint(activity) {
      this.$emit("view-touchpoint", { id: activity.touchpointId, type: activity.touchpointType });
    },
  },
};
