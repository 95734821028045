import { mapGetters, mapActions } from "vuex";

import AtsConnect from "@/components/AtsConnect/Component";

export default {
  name: "AtsConnectModal",
  components: {
    AtsConnect,
  },
  props: {},
  data() {
    return {
      isLoadingSettings: true,
      atsSaved: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserSettings",
    ]),
    title() {
      if (this.isLoadingSettings) {
        return "Loading...";
      }

      if (this.showConnected) {
        return `${this.currentUser.primaryTeamAtsName} Connected`;
      } else if (!this.atsSaved) {
        return "Connect your ATS to Teamable";
      }
      return "Enjoy your Teamable trial";
    },
    showConnected() {
      return this.atsSaved && !!this.currentUser.primaryTeamAtsName;
    },
  },
  methods: {
    ...mapActions([
      "fetchCurrentUserSettings",
    ]),
    closeModal() {
      this.$router.replace({ name: "dashboard" });
    },
  },
  async mounted() {
    if (!this.$deepEqual(this.currentUserSettings, {})) {
      await this.fetchCurrentUserSettings();
    }
    this.isLoadingSettings = false;
  },
};
