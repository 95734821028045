import { mapGetters, mapActions } from "vuex";

import _ from "lodash";

import logEvent from "@/common/Analytics";
import moment from "moment";

import ProfilesApi from "@/common/http/ProfilesApi";
import RouteContext from "@/common/RouteContext";

import CandidateNote from "@/components/CandidateNote/Component";
import CandidateNoteInput from "@/components/CandidateNoteInput/Component";
import AddReminderButton from "@/components/candidates/AddReminderButton/AddReminderButton.vue";

export default {
  name: "CrmTab",
  components: {
    AddReminderButton,
    CandidateNote,
    CandidateNoteInput,
  },
  data() {
    return {
      routeContext: new RouteContext(this.$route.name),
    }
  },
  computed: {
    ...mapGetters({
      candidate: "currentCandidate",
      currentUser: "currentUser",
      campaign: "currentCampaign",
      project: "currentProject",
    }),
    showNotes() {
      return this.candidate.notes?.length > 0;
    },
    showReminders() {
      return this.candidate.reminders?.length > 0;
    },
    noCandidateProjects() {
      return this.candidate.projectProfiles?.length === 0;
    },
    noCandidateCampaigns() {
      return this.candidate.tasks?.length === 0;
    },
    noCandidateProjectsOrCampaigns() {
      return this.noCandidateProjects && this.noCandidateCampaigns;
    },
  },
  methods: {
    ...mapActions([
      "deleteCandidateReminder",
      "removeCandidateFromProject",
    ]),
    relativeTimeFromNow(time) {
      return moment(time).fromNow();
    },
    uniqProjectList(list) {
      if (!list) {
        return [];
      }
      return _.uniqBy(list, "projectId");
    },
    removeProject(projectProfile) {
      this.removeCandidateFromProject({
        projectProfileId: projectProfile.id,
        candidate: this.candidate,
      }).then(() => {
        this.$bus.$emit("candidate-list-item-changed", this.candidate);
      });
    },
    async addNote(note) {
      logEvent("candidate-action", { action: "note" });

      const campaignId = this.$route.path.includes("campaigns") ? this.campaign.id : null;
      const projectId = this.$route.path.includes("projects") ? this.project.id : null;
      const params = {
        note,
        projectId,
        campaignId,
        profileId: this.candidate.profileId,
      };
      const { data } = await ProfilesApi.createNote(params);
      this.candidate.notes.push(data.teamProfileNote);
      this.candidate.notesCount = this.candidate.notesCount + 1;
      this.$bus.$emit("candidate-list-item-changed", this.candidate);
    },
    deleteNote(note) {
      ProfilesApi.deleteNote(note.id).then(() => {
        const idx = this.candidate.notes.findIndex(n => n.id === note.id);
        this.candidate.notes.splice(idx, 1);
        this.candidate.notesCount -= 1;

        this.$bus.$emit("candidate-list-item-changed", this.candidate);
      });
    },
    deleteReminder(reminder) {
      this.deleteCandidateReminder({ candidate: this.candidate, reminder });
    },
  },
};
