<script setup>
import { computed } from "vue";
import UserAvatar from "@/components/UserAvatar";

const props = defineProps({
  connectors: {
    type: Array,
    default: () => [],
  },
  maxConnectorsToDisplay: {
    type: Number,
    default: 1,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const displayConnectors = computed(() => props.connectors.slice(0, props.maxConnectorsToDisplay));
const extraConnectorsCount = computed(() => props.connectors.length - props.maxConnectorsToDisplay);
const extraConnectorsLabel = computed(() => props.connectors.slice(props.maxConnectorsToDisplay).map(item => item.name).join(", "));
const showExtraConnectors = computed(() => extraConnectorsCount.value > 0);
</script>

<template>
<el-skeleton class="candidate-connectors" :loading="loading" animated>
  <div slot="template" class="d-flex">
    <UserAvatar
      hide-name-label
    />
    <UserAvatar
      class="overlap"
      hide-name-label
    />
    <UserAvatar
      class="overlap"
      hide-name-label
    />
  </div>

  <div v-if="connectors.length" class="d-flex align-items-center">
    <div
      v-for="(connector, index) in displayConnectors"
      :key="index"
      :class="{ 'overlap': index > 0 }"
    >
      <UserAvatar
        class="cursor-help"
        :image-url="connector.avatarUrl"
        :full-name="connector.name"
        :tooltip="connector.name"
        hide-name-label
      />
    </div>

    <el-tooltip v-if="showExtraConnectors" :content="extraConnectorsLabel">
      <UserAvatar
        class="overlap cursor-help"
        :full-name="`+ ${extraConnectorsCount}`"
        hide-name-label
      />
    </el-tooltip>

    <slot name="suffix"></slot>
  </div>
</el-skeleton>
</template>

<style scoped lang="scss">
.candidate-connectors {
  .overlap {
    margin-left: -4px;
  }
}
</style>
