export const educationSimilarityTokens = ({ candidate, education, enabled }) => {
  if (!enabled || !education) {
    return {};
  }

  const humanTokens = candidate?.similarity?.analysis?.humanTokens;
  if (!humanTokens) {
    return {};
  }

  const institution = humanTokens.find(token =>
    token.type === "edu-institution" &&
    token.educations.find(e => e.name === education.name) &&
    token.text === education.name
  );

  return {
    institution,
  }
}
