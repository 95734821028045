<template>
  <!-- TODO: Add show-icon when the new design is live and remove the icon from the backend -->
  <el-alert
    v-if="currentUser.outreachLimitMessage"
    id="outreach-limit-message"
    type="warning"
    :closable="false"
    v-html="currentUser.outreachLimitMessage"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OutreachLimitMessage",
  props: {
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
};
</script>

<style lang="scss" scoped>
#outreach-limit-message {
  margin-bottom: 8px;
  color: $tf-color-warning-dark !important;

  i {
    margin-left: -8px;
    margin-right: 4px;
  }
}
</style>
