<script setup>
import { computed, ref, watch } from "vue";
import { useMutations, useGetters, useActions } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";

import RouteContext from "@/common/RouteContext";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  project: {
    type: Object,
    required: true,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "small",
  },
});

const {
  bulkSelectionAttributes,
  currentCandidate,
  currentCandidateList,
  currentProject,
  selectedCandidates,
} = useGetters([
  "bulkSelectionAttributes",
  "currentCandidate",
  "currentCandidateList",
  "currentProject",
  "selectedCandidates",
]);

const {
  setCurrentProjectSavedForLaterCount,
  setSelectedCandidates,
} = useMutations([
  "setCurrentProjectSavedForLaterCount",
  "setSelectedCandidates",
]);

const {
  addCandidateToProject,
} = useActions([
  "addCandidateToProject",
]);

const root = useRoot();
const route = useRoute();

const isLoading = ref(false);
const isSaved = ref(false);
const routeContext = ref(new RouteContext(route.name));

watch(route, (newRoute) => {
  routeContext.value = new RouteContext(newRoute.name);
}, { immediate: true });

const tooltipContent = computed(() => {
  if (props.candidate) {
    return `Add ${props.candidate.name} to favorites list for this job if you aren't sure you want to reach out yet.`;
  }

  return `Add selected ${bulkSelectionAttributes.value?.count > 1 ? "candidates" : "candidate"} to favorites list for this job if you aren't sure you want to reach out yet.`;
});

function updateSavedForLaterCount({ increment }) {
  if (currentProject.value.id && currentProject.value.id === props.project.id) {
    let newSavedForLaterCount = currentProject.value.savedForLaterCount;

    if (increment) {
      newSavedForLaterCount += props.candidate ? 1 : bulkSelectionAttributes.value?.count;
    } else {
      newSavedForLaterCount -= props.candidate ? 1 : bulkSelectionAttributes.value?.count;
    }

    setCurrentProjectSavedForLaterCount(newSavedForLaterCount);
  }
}

function handleRemindCommand(command) {
  const remindDays = command.split("save-later-")[1];
  saveToProject({ remindDays });
}

function saveToProject({ remindDays = null }) {
  isLoading.value = true;
  const showNext = props.candidate && currentCandidate.value.tfId === props.candidate.tfId;

  setTimeout(() => {
    isSaved.value = true;
    isLoading.value = false;

    setTimeout(() => {
      updateSavedForLaterCount({ increment: true });
      let candidateIndex = null;
      if (props.candidate) {
        candidateIndex = currentCandidateList.value.findIndex(c => c.tfId && c.tfId === props.candidate.tfId);
        root.$bus.$emit("candidate-recruited", { candidate: props.candidate, showNext });

        if (selectedCandidates.value?.length) {
          setSelectedCandidates(selectedCandidates.value.filter(c => c.tfId !== props.candidate.tfId));
        }

        if (showNext) {
          const message = `${props.candidate.name} has been saved for later. Advancing to the next candidate.`;
          root.$notify({
            type: "success",
            title: "Success",
            message,
            position: "bottom-right",
          });
        }
      } else {
        if (bulkSelectionAttributes.value?.isIndividualCandidates) {
          bulkSelectionAttributes.value.teamProfileQueryParams.tfIds.forEach(tfId => {
            candidateIndex = currentCandidateList.value.findIndex(c => c.tfId && c.tfId === tfId);
            root.$bus.$emit("candidate-recruited", {
              candidate: currentCandidateList.value[candidateIndex],
              showNext,
            });
          });

          setSelectedCandidates([]);
        }
      }

      const params = {
        isRerouteFromReview: routeContext.value.reviewContext(),
        projectId: props.project.id,
        recommendationsProjectId: route.params.projectId || route.query.projectId,
        remindDays,
      };

      if (props.candidate) {
        params.candidate = props.candidate;
      } else {
        params.bulkSelectionAttributes = bulkSelectionAttributes.value;
      }

      isSaved.value = false;

      addCandidateToProject(params).then(() => {}, () => {
        if (props.candidate) {
          root.$bus.$emit("candidate-list-item-added", { candidate: props.candidate, candidateIndex });
        }
        updateSavedForLaterCount({ increment: false });

        const message = props.candidate ? `Failed saving ${props.candidate.name} for later. Please try again.` : `Failed to save ${bulkSelectionAttributes.value.count} candidates for later`;

        root.$notify({
          type: "error",
          title: "Failed",
          message,
          position: "bottom-right",
        });
      });
    }, 500);
  }, 500);
}
</script>

<template>
  <el-skeleton class="w-100" :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <div class="sfl-buttons">
      <el-dropdown
        class="w-100 dropdown"
        type="secondary"
        placement="bottom-start"
        trigger="click"
        :size="size"
        split-button
        @click.stop="saveToProject"
        @command="handleRemindCommand"
      >
        <el-tooltip :content="tooltipContent" :open-delay="500">
          <div class="w-100 m-0 align-items-center d-flex justify-content-center">
            <div v-if="!isLoading">
              <div v-if="isSaved" :class="{ 'm-0': onlyIcon }" class="material-icons-round md-14 text-color-success">check_circle</div>
              <div v-else :class="{ 'm-0': onlyIcon }" class="material-icons-round md-14 text-color-magenta-100">favorite_border</div>
            </div>
            <div v-if="!onlyIcon">{{ isSaved ? 'Saved' : 'Save' }} for later</div>
          </div>
        </el-tooltip>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>
            Save now and remind me in:
          </el-dropdown-item>


          <el-dropdown-item command="save-later-3">
            3 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-7">
            7 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-14">
            14 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-30">
            30 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-60">
            60 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-90">
            90 days
          </el-dropdown-item>

          <el-dropdown-item command="save-later-180">
            180 days
          </el-dropdown-item>

          <el-dropdown-item divided command="save-later-">
            Use my default setting
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-skeleton>
</template>

<style lang="scss" scoped>
.sfl-buttons {
  display: flex;
  flex-shrink: 0;
  gap: 8px;

  .dropdown {
    flex-grow: 1;

    ::v-deep .el-button-group {
      display: flex;

      .el-button:first-child {
        flex-basis: 100%;
      }
    }
  }
}
</style>
