import ApiClientOldDomain from "@/common/http/ApiClientOldDomain";

class UsersApiOldDomain {
  static generateCookie(userToken, adminToken) {
    return ApiClientOldDomain.post("auth/generate_cookie", {
      userToken: userToken,
      adminToken: adminToken,
    }, {
      // this allows Set-Cookie header from the server
      withCredentials: true,
    });
  }
}

export default UsersApiOldDomain;
