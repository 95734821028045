<script setup>
import { ref } from "vue";

defineProps({
  phone: {
    type: String,
    default: null,
  },
});

const justCopied = ref(false);

const copyTextToClipboard = (text) => {
  justCopied.value = true;
  navigator.clipboard.writeText(text).then(() => {
    setTimeout(() => justCopied.value = false, 3000);
  });
};
</script>

<template>
  <el-tooltip
    :content="justCopied ? 'Copied' : 'Click to copy the phone number'"
    :open-delay="250"
  >
    <img
      class="clickable"
      :src="require('@/assets/images/candidate-card/phone.svg')"
      @click.stop="copyTextToClipboard(phone)"
    />
  </el-tooltip>
</template>
