<script setup>
import { computed } from "vue";
import _ from "lodash";
import RecommendationsTokenPill from "@/components/RecommendationsTokenPill/RecommendationsTokenPill";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  project: {
    type: Object,
    default: null,
  },
  tokens: {
    type: Array,
    default: () => [],
  },
});

const categories = ["experience", "skills", "insights"];

const groupedTokens = computed(() => {
  const sorted = _.sortBy(props.tokens, (token) => categories.indexOf(token.category));
  const uniqSorted = _.uniqBy(sorted, (token) => token.text);
  return _.groupBy(uniqSorted, "category");
});
</script>

<template>
  <div class="mt-8">
    <div v-for="(list, categoryName) in groupedTokens" :key="categoryName" class="category">
      <div class="pills-container">
        <RecommendationsTokenPill
          v-for="token in list"
          :key="token.text"
          :project="project"
          :token="token"
          :highlighted="token.highlighted"
          :is-inverted-color="true"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category-name-container {
  margin-bottom: 4px;
  padding-top: 6px;
}

.category-name {
  display: flex;
  padding-right: 12px;
  width: 77px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  flex-shrink: 0;
}

.pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.category {
  margin-bottom: 4px;
  display: flex;

  @media screen and (max-width: $md-max-width) {
    flex-direction: column;
  }
}

.recommendations-tokens {
  #candidate-list-content & {
    .category-name {
      justify-content: flex-end;
    }
  }
}
</style>
