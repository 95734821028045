<script setup>
import { computed } from "vue";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const statusTooltip = computed(() => {
  return props.project.isClosed ? "This job is closed" : "This job is open";
});

</script>

<template>
  <div class="project-list-item-search-popover w-100">
    <div>
      <div class="d-flex align-items-center clickable mx-8">
        <router-link
          :to="{ name: 'job-root', params: { projectId: project.id } }"
          class="anchor-wrapper w-100"
        >
          <h6 class="d-flex align-items-center link mb-8">
            <el-tooltip :content="statusTooltip" :open-delay="500">
              <div class="relative">
                <i
                  class="project-tag material-icons-round pr-8"
                  :class="project.isClosed ? 'text-color-black-50' : 'text-color-blue-50'"
                >
                  work
                </i>
                <img v-if="project.featuredJob" class="feature-job" :src="require('@/assets/images/featured-job.svg')" />
              </div>
            </el-tooltip>

            <el-tooltip :content="project.name" :open-delay="500">
              <span class="ml-8 ellipsis" :class="{ 'closed': project.isClosed }">
                {{ project.name }}
              </span>
            </el-tooltip>
          </h6>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-list-item-search-popover {
  padding: 0px;
  color: $tf-font-dark;
  background-color: #ffffff;
  font-size: 16px;

  .feature-job {
    position: absolute;
    bottom: 2px;
    left: 10px;
    width: 18px;
  }
}
</style>
