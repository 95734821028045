<script setup>
import { ref, unref, computed } from "vue";
import _ from "lodash";

import DropdownOption from "./DropdownOption";

const feedbackConfigs = [
  {
    feedback: "positive_filter",
    label: "Hard criteria",
    src: require("@/assets/images/recommendations/must-have.svg"),
    selectable: true,
  },
  {
    feedback: "positive_recent_employments_filter",
    label: "Hard criteria (recent employments only)",
    src: require("@/assets/images/recommendations/must-have.svg"),
    selectable: true,
  },
  {
    feedback: "positive",
    label: "Soft criteria",
    src: require("@/assets/images/recommendations/should-have.svg"),
    selectable: true,
  },
  {
    feedback: "negative_filter",
    label: "Disqualifier",
    src: require("@/assets/images/recommendations/must-not-have.svg"),
    selectable: true,
  },
  {
    feedback: "complex_filter",
    label: "Boolean expression",
    src: require("@/assets/images/recommendations/boolean.svg"),
    selectable: false,
  },
];

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  availableFeedbacks: {
    type: Array,
    default: undefined,
  },
  type: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "large",
  },
  tooltip: {
    type: String,
    default: null,
  },
  isInvertedColor: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  onChange: {
    type: Function,
    required: true,
  },
  onDelete: {
    type: Function,
    required: false,
    default: null,
  },
});

const containerRef = ref(null);

defineExpose({
  containerRef,
});

const availableFeedbackConfigs = computed(() => {
  let selectable = _.filter(feedbackConfigs, (fc) => fc.selectable);

  return _.filter(selectable, (fc) => (!props.availableFeedbacks || _.includes(props.availableFeedbacks, fc?.feedback)) && fc?.feedback !== currentFeedbackConfig.value?.feedback);
});

const pillClasses = computed(() => {
  let classes = [
    "value-content-container",
    `value-content-container-${props.type}`,
    `value-content-container-${props.size}`,
    {
      "value-content-container-no-value": !props.value,
      "value-content-container-inverted": props.isInvertedColor,
    },
  ];
  if (currentFeedbackConfig.value) {
    classes.push(`value-content-container-${currentFeedbackConfig.value.feedback}`);
  }
  return classes;
});

const currentFeedbackConfig = computed(() => {
  return _.find(feedbackConfigs, { feedback: props.value })
});

const canChangeFeedbackType = computed(() => unref(availableFeedbackConfigs).length > 0);

const dropdownCaretIcon = ref("expand_more");
function onDropdownVisible(visible) {
  dropdownCaretIcon.value = visible ? "expand_less" : "expand_more";
}
</script>

<template>
  <div ref="containerRef" class="value-container">
    <el-dropdown trigger="click" @visible-change="onDropdownVisible">
      <el-tooltip
        placement="top"
        :content="tooltip"
        :disabled="!tooltip"
        :open-delay="100"
      >
        <!-- <img
          v-if="currentFeedbackConfig"
          :src="currentFeedbackConfig.src"
          class="value-content-image"
        /> -->

        <div :class="pillClasses">
          <span class="value-label">
            <slot />
          </span>
          <i class="material-icons-round md-18">{{ dropdownCaretIcon }}</i>
        </div>
      </el-tooltip>

      <el-dropdown-menu slot="dropdown" class="dropdown-menu-container">
        <template v-if="canChangeFeedbackType">
          <div class="value-dropdown-description">
            <span v-if="type === 'similarity'">
              We've suggested this attribute as <b>similar</b> to your job criteria. <br><br>Mark it as a:
            </span>
            <span v-else-if="currentFeedbackConfig?.feedback === 'positive_filter'">
              This attribute is part of this job's <b>hard criteria</b>. <br><br>We will only show you candidates that contain this attribute. <br><br>Change it to:
            </span>
            <span v-else-if="currentFeedbackConfig?.feedback === 'positive_recent_employments_filter'">
              This attribute is part of this job's <b>hard criteria</b>.<br><br> Candidates must have applicable keywords in the past 5 years<br> and in their most recent three employments. <br>We will only show you candidates that contain this attribute.<br><br>Change it to:
            </span>
            <span v-else-if="currentFeedbackConfig?.feedback === 'positive'">
               This attribute is part of this job's <b>soft criteria</b>. <br><br>We rank candidates higher if they have more <b>soft criteria</b> attributes, but this doesn't exclude those who lack them.<br><br>Change it to:
            </span>
            <span v-else-if="currentFeedbackConfig?.feedback === 'negative_filter'">
              For this job, this attribute is a <b>disqualifier</b>. <br><br>Candidate profiles containing this attribute will be excluded. <br><br>Change it to:
            </span>
            <span v-else>
              For this job, this attribute is a:
            </span>
          </div>

          <el-dropdown-item
            v-for="(feedbackConfig, index) in availableFeedbackConfigs"
            :key="feedbackConfig.feedback"
            class="dropdown-item"
            :divided="index === 0"
          >
            <DropdownOption
              :type="feedbackConfig.feedback"
              @click="onChange(feedbackConfig.feedback)"
            >
              {{ feedbackConfig.label }}
            </DropdownOption>
          </el-dropdown-item>
        </template>

        <el-dropdown-item v-if="onDelete" class="dropdown-item" :divided="canChangeFeedbackType">
          <el-tooltip :disabled="!required" content="Filter is required. Please add a new one before removing this one.">
            <DropdownOption :disabled="required" @click="onDelete()">
              Remove
            </DropdownOption>
          </el-tooltip>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<style scoped lang="scss">
.value-container {
  display: inline-block;
  position: relative;
  flex-shrink: 0;

  .value-content-container {
    display: flex;
    cursor: pointer;
    padding: 3px 6px;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: $tf-global-border-radius;

    .value-content-image {
       height: 12px;
    }

    &.value-content-container-large {
      padding: 3px 6px;
      padding-right: 4px; // Smaller because of icon
    }

    &.value-content-container-small {
      padding: 0px 4px;
      padding-right: 2px; // Smaller because of icon

      .value-label {
        padding-right: 2px;
      }

      .value-content-image {
        margin-right: 2px;
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      background-color: hsla(30, 67%, 96%, 1);
    }

    .value-label {
      color: #2B3439;
      padding-right: 4px;
    }

    .value-content-image {
      margin-right: 4px;
    }

    &.value-content-container-positive_filter {
      background-color: $spring-green-10;
      border-color: $spring-green-5;
    }

    &.value-content-container-positive_recent_employments_filter {
      background-color: $spring-green-50;
      border-color: $spring-green-10;
    }

    &.value-content-container-similarity {
      background-color: $beige-30;
      border-color: $beige-10;
    }

    &.value-content-container-positive {
      background-color: $blue-10;
      border-color: $blue-5;
    }

    &.value-content-container-negative_filter {
      background-color: $magenta-30;
      border-color: $magenta-10;
    }

    &.value-content-container-no-value {
      &.value-content-container-inverted {
        background-color: white;
        border-color: #FAF1E6;
      }
    }
  }
}

.dropdown-item {
  padding: 0;

  &:hover {
    background-color: transparent !important;
  }
}

.dropdown-menu-container {
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;

  .value-dropdown-description {
    padding: 0 16px;
    color: $grey-500;
    font-size: 12px;
    font-weight: 500;
    max-width: 330px;
  }
}
</style>
