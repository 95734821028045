import { useMutation, useQueryClient } from "@tanstack/vue-query";
import ProjectsApi from "@/common/http/ProjectsApi";
import { onSettled } from "./onSettled";

export const mutationKey = ["update-recommendation-feedback-boolean"];

export const useUpdateRecommendationFeedbackBoolean = (props = {}) => {
  const queryClient = useQueryClient();

  const { mutate, ...mutationRest } = useMutation({
    mutationFn: ProjectsApi.updateRecommendationFeedbackBoolean,
    mutationKey,
    onSettled: onSettled({ queryClient }),
    ...props,
  });

  return {
    updateRecommendationFeedbackBoolean: mutate,
    ...mutationRest,
  };
}
