import { mapGetters } from "vuex";

import CampaignMessageEditor from "@/components/CampaignMessageEditor/Component";
import MessageEditorWrapper from "@/components/MessageGeneration/MessageEditorWrapper/MessageEditorWrapper";
import { TYPES } from "@/components/MessageGeneration/constants";
import AtsLogo from "@/components/AtsLogo/AtsLogo";

export default {
  name: "CampaignMessageModal",
  components: {
    AtsLogo,
    MessageEditorWrapper,
    CampaignMessageEditor,
  },
  props: {
    show: Boolean,
    type: String,
    allowSentence: Boolean,
    allowCalendar: Boolean,
    allowFirstName: {
      type: Boolean,
      default: true,
    },
    allowCompany: {
      type: Boolean,
      default: true,
    },
    defaultIsMessageValid: {
      type: Boolean,
      default: false,
    },
    fastClose: {
      type: Boolean,
      default: false,
    },
    isSubjectEditable: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    messageId: {
      type: Number,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    cc: {
      type: String,
      default: null,
    },
    bcc: {
      type: String,
      default: null,
    },
    saveButtonLabel: {
      type: String,
      default: "Save",
    },
    senderId: {
      type: [String, Number],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDirty: false,
      messageSaved: false,
      isMessageValid: false,
      showMessagePreview: false,
      message: {},
    };
  },
  computed: {
    ...mapGetters([
      "allTeammatesAndMe",
      "currentCampaign",
      "currentCandidate",
      "currentTeam",
      "isSavingCampaign",
      "isNewCampaignValidForFirstMessage",
    ]),
    isModal() {
      // don't show as a modal when the candidate sidebar is open
      // this can cause gray overlay screen issues
      // setting append-to-body doesn't work as it messes up the message editor toolbar
      return this.currentCandidate.profileId === null || this.currentCandidate.profileId === undefined;
    },
    isComputedSaving() {
      return this.isSavingCampaign || this.isSaving;
    },
    greenHouseTemplateLink() {
      return `https://${this.currentTeam.atsSubdomain}.greenhouse.io/account/email_templates/${this.currentCampaign.inboundRejectAtsEmailTemplateAtsRef}/edit`;
    },
    isEditRejectionMessageDisabled() {
      return this.type === TYPES.AUTO_REPLY_NEGATIVE && this.currentCampaign.inbound && !!this.currentCampaign.inboundRejectAtsEmailTemplateId;
    },
    atsTemplateName() {
      return this.currentCampaign.inboundRejectAtsTemplateOptions.find(item => item.id === this.currentCampaign.inboundRejectAtsEmailTemplateId);
    },
    isSaveDisabled() {
      if (this.messageSaved || this.isComputedSaving) {
        return true;
      }
      return !this.isMessageValid;
    },
    title() {
      switch (this.type) {
        case "firstMessage":
          if (this.isNewCampaignValidForFirstMessage) {
            return "Create new first message";
          } else {
            return "Edit first message";
          }
        case "followUp":
          if (this.messageId) {
            return "Edit follow-up";
          } else {
            return "Create new follow-up";
          }
        case "autoReplyPositive":
          if (this.currentCampaign.inbound) {
            return "Edit approval message";
          } else {
            return "Edit positive reply";
          }
        case "autoReplyNegative":
          if (this.currentCampaign.inbound) {
            return "Edit rejection message";
          } else {
            return "Edit not interested reply";
          }
        case "autoReplyOoo":
          return "Edit out of office reply";
        case "threadReply":
          return `Send message as ${this.senderName}`;
        case "introRequest":
          return "Edit referrals request";
        case "introMessage":
          return "Edit referrals message";
        default:
          return "";
      }
    },
    senderName() {
      return this.allTeammatesAndMe.find(u => u.id == this.computedSenderId).name;
    },
    computedSenderId() {
      return this.senderId || this.currentCampaign.senderId;
    },
    computedSaveButtonLabel() {
      if (this.isComputedSaving) {
        return "Saving...";
      }
      return this.saveButtonLabel;
    },
  },
  methods: {
    handleEditorPreviewSwitch(tab) {
      if (tab.label === "Editor") {
        this.showMessagePreview = false;
      } else if (tab.label === "Preview") {
        this.showMessagePreview = true;
      }
    },
    handleMessageChange({ message, isValid }) {
      this.message = message;
      this.isDirty = true;
      this.messageSaved = false;
      this.isMessageValid = isValid;
    },
    discard() {
      this.$emit("closed");
      this.isDirty = false;
    },
    save() {
      this.$emit("saved", this.message);
      this.messageSaved = true;
      this.isDirty = false;
    },
    modalClosed() {
      if (!this.show) {
        return;
      }

      if (this.isNewCampaignValidForFirstMessage || (this.isDirty && !this.fastClose)) {
        let text = "You have unsaved changes, save and proceed?";
        let discardButton = "Discard changes";
        let proceedButton = "Save";
        if (this.type === "threadReply") {
          text = "Would you like to send this message?";
          discardButton = "Discard";
          proceedButton = "Send";
        } else if (this.isNewCampaignValidForFirstMessage) {
          text = "You have an unsaved campaign, save and proceed?";
          discardButton = "Discard campaign";
        }
        this.$confirm(text, "Confirm", {
          confirmButtonText: proceedButton,
          cancelButtonText: discardButton,
          distinguishCancelAndClose: true,
        }).then(() => {
          this.save();
          this.discard();
        }).catch(action => {
          if (action === "cancel") {
            this.discard();
          };
        });
      } else {
        this.discard();
      }
    },
  },
  beforeMount() {
    if (this.defaultIsMessageValid) {
      this.isMessageValid = this.defaultIsMessageValid;
    }

    if (this.$route.query.parentCampaignId) {
      this.isDirty = true;
      this.isMessageValid = true;
    }
  },
};
