import { mapGetters } from "vuex";

import logEvent from "@/common/Analytics";

export default {
  name: "ConnectedAtsJobModal",
  components: {},
  props: {
    visible: Boolean,
    job: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
    title() {
      return `${this.currentUser.primaryTeamAtsName} job "${this.job.name}" is already connected`;
    },
  },
  methods: {
    closeModal() {
      logEvent("project-redirect-modal", { action: "modal-closed" });
      this.$emit("close");
    },
    goToProject() {
      logEvent("project-redirect-modal", { action: "go-to-connected" });
      this.$emit("go-to-project", this.job.project.id);
    },
  },
  mounted() {
    logEvent("project-redirect-modal", { action: "modal-opened" });
    this.$refs.atsProjectButton.$el.focus();
  },
};
