import { mapGetters, mapActions } from "vuex";

import CampaignListItemSearchPopover from "@/components/CampaignListItem/SearchPopover/Component";
import CandidateListItemSearchPopover from "@/components/CandidateListItem/SearchPopover/Component";
import ProjectListItemSearchPopover from "@/components/ProjectListItem/SearchPopover/SearchPopover";
import CampaignsApi from "@/common/http/CampaignsApi";
import ProfilesApi from "@/common/http/ProfilesApi";
import ProjectsApi from "@/common/http/ProjectsApi";

export default {
  name: "SearchResultsPopover",
  components: {
    CampaignListItemSearchPopover,
    CandidateListItemSearchPopover,
    ProjectListItemSearchPopover,
  },
  data() {
    return {
      searchQuery: {},
      campaignCount: null,
      candidateCount: null,
      projectCount: null,
      campaignList: [],
      candidateList: [],
      projectList: [],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    queryTerm: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    noRecentSearches() {
      return !this.currentUser.recentSearches?.length;
    },
    showRecentSearches() {
      return (!this.queryTerm && !this.noRecentSearches);
    },
    campaignCountLabel() {
      return this.campaignCount != null && this.campaignCount >= 0 ? this.campaignCount : "?";
    },
    candidateCountLabel() {
      return this.candidateCount != null && this.candidateCount >= 0 ? this.candidateCount : "?";
    },
    projectCountLabel() {
      return this.projectCount != null && this.projectCount >= 0 ? this.projectCount : "?";
    },
    viewResultsLabel() {
      if (this.candidateCount === null || this.projectCount === null || this.campaignCount === null) {
        return "View all results";
      }
      const count = this.candidateCount + this.projectCount + this.campaignCount;
      return `View all ${count} results`;
    },
  },
  watch: {
    visible(val) {
      if (val && this.queryTerm) {
        this.searchQuery = { query: this.queryTerm };
      }
    },
    queryTerm(val) {
      if (!this.visible || !val) {
        return;
      }

      this.campaignCount = null;
      this.candidateCount = null;
      this.projectCount = null;
      this.campaignList = [];
      this.candidateList = [];
      this.projectList = [];
      this.searchQuery = { query: val };

      this.fetchCampaigns();
      this.fetchCandidates();
      this.fetchProjects();
    },
  },
  methods: {
    ...mapActions([
      "clearUserRecentSearch",
      "deleteUserRecentSearchTerm",
      "recordUserSearchTerm",
    ]),
    async fetchCampaigns() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await CampaignsApi.search(requestParams);
      this.campaignList = data.campaigns;
      this.campaignCount = data.count;
    },
    async fetchCandidates() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await ProfilesApi.search(requestParams);
      this.candidateList = data.profiles;
      this.candidateCount = data.count;
    },
    async fetchProjects() {
      const requestParams = { pageSize: 3, ...this.searchQuery };
      const { data } = await ProjectsApi.search(requestParams);
      this.projectList = data.projects;
      this.projectCount = data.count;
    },
    searchRoute(tab = null) {
      this.recordUserSearchTerm(this.queryTerm);

      const route = {
        name: "search",
        query: {
          query: this.queryTerm,
        },
      };
      if (tab) {
        route.query.searchTab = tab;
      }

      return route;
    },
    selectSearchTerm(term) {
      this.$emit("recent-term-selected", term);
    },
    deleteTerm(term) {
      this.deleteUserRecentSearchTerm({ user: this.currentUser, term });
    },
    clearAllTerms() {
      this.clearUserRecentSearch({ user: this.currentUser });
    },
    hidePopover() {
      this.$emit("hide");
    },
  },
};
