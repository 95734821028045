<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";

import RequestIntroTab from "@/components/RequestIntroTab/RequestIntroTab";

import Messaging from "../../components/Messaging/Messaging";

const props = defineProps({
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
});

const {
  isReferralsEnabled,
  bulkSelectionAttributes,
  selectedCandidateInAppSourcing,
} = useGetters([
  "isReferralsEnabled",
  "bulkSelectionAttributes",
  "selectedCandidateInAppSourcing",
]);

const activeTab = computed(() => {
  if (selectedCandidateInAppSourcing.value?.connectors.length) {
    return "requestIntro";
  }
  return "messaging";
});
</script>

<template>
  <div>
    <el-tabs
      v-if="isReferralsEnabled && !bulkSelectionAttributes && !props.messagingModeOnly"
      id="message-tabs"
      v-model="activeTab"
    >
      <!-- INTRO -->
      <el-tab-pane name="requestIntro" label="Request intro">
        <RequestIntroTab :candidate="selectedCandidateInAppSourcing" />
      </el-tab-pane>

      <!-- MESSAGING -->
      <el-tab-pane name="messaging" label="Messaging">
        <Messaging>
          <template #generate-new>
            <slot name="generate-new"></slot>
          </template>
        </Messaging>
      </el-tab-pane>
    </el-tabs>

    <Messaging
      v-else
      class="mt-16"
      :messaging-mode-only="messagingModeOnly"
    >
      <template #generate-new>
        <slot name="generate-new"></slot>
      </template>
    </Messaging>
  </div>
</template>

<style lang="scss">
#message-tabs {
  ::v-deep .el-tabs__content {
    overflow: visible;
  }

  #follow-ups {
    a {
      text-decoration: none;
      color: $tf-font-ghost;
    }

    .message {
      padding: 16px;
    }
  }

  #your-reply {
    a {
      text-decoration: none;
      color: $tf-font-ghost;
    }
  }
}
</style>
