<script setup>
import { computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { cleanUpEmployments } from "@/common/employments/cleanUpEmployments";

import RecommendationsTokens from "@/components/RecommendationsTokens/RecommendationsTokens";
import Employment from "./Employment/Employment";
import AboutMe from "./AboutMe";
import Education from "./Education";

const {
  currentCandidate,
  currentProject,
  isLoadingCurrentCandidate,
  isRecommendationTokensHidden,
} = useGetters([
  "currentCandidate",
  "currentProject",
  "isLoadingCurrentCandidate",
  "isRecommendationTokensHidden",
]);

const showRecommendationsTokens = computed(() => {
  return !!currentCandidate.value.similarity?.analysis;
});

const recommendationTokensVisible = computed(() => {
  return showRecommendationsTokens.value && !isRecommendationTokensHidden.value;
});

const cleanedUpEmployments = computed(() => {
  return cleanUpEmployments(currentCandidate.value.employments);
});
</script>

<template>
  <div class="teamable-view">
    <div v-if="showRecommendationsTokens" class="recommendations-tokens-container">
      <RecommendationsTokens
        class="p-16"
        :candidate="currentCandidate"
        allow-hiding
        show-title
      />
    </div>

    <template v-if="!isLoadingCurrentCandidate">
      <AboutMe
        v-if="currentCandidate.aboutMe"
        :candidate="currentCandidate"
        :tokens-enabled="recommendationTokensVisible"
      />

      <h4>Experience</h4>

      <div v-if="currentCandidate.totalEmployment" class="total-experience-container">
        {{ currentCandidate.totalEmployment }}
      </div>

      <div class="experience-container">
        <ul>
          <li v-for="(employment, index) in cleanedUpEmployments" :key="index">
            <Employment
              :index="index"
              :project="currentProject"
              :candidate="currentCandidate"
              :employment="employment"
              :tokens-enabled="recommendationTokensVisible"
            />
            <el-divider class="m-8"></el-divider>

          </li>
        </ul>
      </div>

      <h4>Education</h4>
      <div class="educations-container">
        <div v-for="(education, index) in currentCandidate.educations" :key="index" class="d-flex">
          <Education
            :project="currentProject"
            :candidate="currentCandidate"
            :education="education"
            :tokens-enabled="recommendationTokensVisible"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.teamable-view {
  .recommendations-tokens-container {
    background: #FDF9F5;
    border-radius: $tf-global-border-radius;
    margin-bottom: 12px;
  }

  .educations-container {
    margin-left: 16px;
  }

  .total-experience-container {
    margin-left: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .experience-container {
    margin-left: 16px;

    ul {
      padding-left: 0px;
      list-style: none;

      li {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
