<script setup>
import { computed } from "vue";

import { extractNameRates } from "@/components/recommendationFeedbacks/lib/extractNameRates";
import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
  employment: {
    type: Object,
    required: true,
  },
  tokensEnabled: {
    type: Boolean,
    default: false,
  },
});

const humanTokens = props.candidate?.similarity?.analysis?.humanTokens;

const companySizeText = props.employment.companyInfo?.metrics?.startingSize?.bucket;
const companySize = extractNameRates(companySizeText);
const companySizeDisplayText = `${companySize?.name} (${companySize?.from}-${companySize?.to})`;
const companySizeToken = humanTokens?.find(token => {
  return (
    token.type === "emp-start-size" &&
    token.employments.find(e => e.name === props.employment.name) &&
    token.text === companySizeText
  );
});

const growthRateText = props.employment.companyInfo?.metrics?.compoundedAnnualGrowthRate?.bucket;
const growthRate = extractNameRates(growthRateText);
const growthRateDisplayText = growthRate?.name;
const growthRateToken = humanTokens?.find(token => {
  return (
    token.type === "emp-growth-rate" &&
    token.employments.find(e => e.name === props.employment.name) &&
    token.text === growthRateText
  );
});

const EARLY_AT_COMPANY = {
  "very-early-0-1": "Very early (0-1y)",
  "early-1-3": "Early (1-3y)",
  "mid-3-6": "Middle (3-6y)",
  "late-6-10": "Late (6-10y)",
  "very-late-10-inf": "Very late (10y+)",
};

const lastRoundRaised = computed(() => {
  const rounds = props.employment?.allFundingRounds;

  if (!rounds || !rounds.length || !rounds[rounds.length - 1].amount) {
    return;
  }

  return `(${rounds[rounds.length - 1].round}) ${rounds[rounds.length - 1].amount.toLocaleString()}${rounds[rounds.length - 1].currency}`;
});

const isCompanyInfoVisible = computed(() => {
  return props.employment.companyInfo.overview?.website ||
    props.employment.categories?.length ||
    props.employment.companyInfo.overview?.shortDescription;
});

const areInsightsVisible = computed(() => {
  return companySize ||
    growthRate ||
    props.employment.fundingRounds?.length ||
    lastRoundRaised.value ||
    props.employment.companyInfo?.metrics?.earlyAtCompany?.bucket ||
    props.employment.companyInfo?.metrics?.tenureNetSizeChange;
});

function similarityToken(type, category) {
  return humanTokens?.find(token => {
    return (
      token.type === type &&
      token.employments.find(e => e.name === props.employment.name) &&
      token.text === category
    );
  });
}
</script>

<template>
  <div>
    <template v-if="isCompanyInfoVisible">
      <div class="info-title">Company info</div>

      <div
        v-if="employment.companyInfo.overview?.shortDescription || employment.companyInfo.overview?.website"
        class="d-flex align-items-center mb-4 info-section"
      >
        <div class="mr-8">
          <span class="more-info-title">Description: </span>
          <span v-if="employment.companyInfo.overview?.shortDescription">
            {{employment.companyInfo.overview.shortDescription}}
          </span>
          <a v-if="employment.companyInfo.overview?.website" :href="employment.companyInfo.overview.website" target="_blank">
            {{employment.companyInfo.overview.website}}
          </a>
        </div>
      </div>

      <div v-if="employment.categories?.length" class="d-flex align-items-center mb-4 info-section">
        <div class="mr-8">
          <span class="more-info-title">{{employment.categories.length > 1 ? 'Industries' : 'Industry'}}</span>
          <ul class="pl-32">
            <li v-for="(item, index) in employment.categories" :key="index">
              <HighlightableField
                :enabled="tokensEnabled"
                token-type="emp-industry"
                :project="project"
                :candidate="candidate"
                :additional-token-types="['plain']"
                :similarity-tokens="[similarityToken('emp-industry', item.category)]"
                :token-text="item.category"
              />
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template v-if="areInsightsVisible">
      <div class="info-title">Insights during candidate tenure</div>

      <div v-if="companySize" class="d-flex align-items-center mb-4 info-section">
        <div class="mr-8">
          <span class="more-info-title">Size: </span>
          <span class="detail-text">
          <HighlightableField
            :enabled="tokensEnabled"
            token-type="emp-start-size"
            :project="project"
            :candidate="candidate"
            :additional-token-types="['plain']"
            :similarity-tokens="companySizeToken ? [companySizeToken] : []"
            :token-text="companySizeToken ? companySizeText : companySizeDisplayText"
            :display-text="companySizeDisplayText"
          />
        </span>
        </div>
      </div>

      <div v-if="growthRate" class="d-flex align-items-center mb-4 info-section">
        <div class="mr-8">
          <span class="more-info-title">Growth rate: </span>
          <span class="detail-text">
          <HighlightableField
            :enabled="tokensEnabled"
            token-type="emp-growth-rate"
            :project="project"
            :candidate="candidate"
            :additional-token-types="['plain']"
            :similarity-tokens="growthRateToken ? [growthRateToken] : []"
            :token-text="growthRateToken ? growthRateText : growthRateDisplayText"
            :display-text="growthRateDisplayText"
          />
        </span>
        </div>
      </div>

      <div v-if="lastRoundRaised" class="d-flex align-items-center mb-4 info-section">
        <div class="mr-8">
          <span class="more-info-title">Last round raised:</span> {{lastRoundRaised}}
        </div>
      </div>

      <div v-if="employment.fundingRounds.length" class="d-flex align-items-center mb-4 info-section">
        <div class="mr-8">
          <span class="more-info-title">Funding round stages: </span>
          <ul class="pl-32">
            <li v-for="(item, index) in employment.fundingRounds" :key="index">
              <HighlightableField
                :enabled="tokensEnabled"
                token-type="plain"
                :project="project"
                :candidate="candidate"
                :similarity-tokens="[similarityToken('plain', item.fundingRound)]"
                :token-text="item.fundingRound"
              />
            </li>
          </ul>
        </div>
      </div>

      <div v-if="employment.companyInfo?.metrics?.earlyAtCompany?.bucket" class="d-flex align-items-center mb-4 info-section">
        <div class="d-flex align-items-center">
          <span class="more-info-title mr-4">Joined company (since company start):</span>
          <span>{{ EARLY_AT_COMPANY[employment.companyInfo.metrics.earlyAtCompany.bucket] }}</span>
        </div>
      </div>

      <div v-if="employment.companyInfo?.metrics?.endingSize?.value" class="d-flex align-items-center mb-4 info-section">
        <div class="d-flex align-items-center">
          <span class="more-info-title mr-4">Tenure net size change:</span>
          <span v-if="employment.companyInfo.metrics.tenureNetPercentChange">
            {{ employment.companyInfo.metrics.tenureNetPercentChange >= 0 ? '+' : '' }}{{ employment.companyInfo.metrics.tenureNetPercentChange }}% ({{ employment.companyInfo.metrics.endingSize.value.toLocaleString() }})
          </span>
          <span v-else>{{ employment.companyInfo.metrics.endingSize.value.toLocaleString() }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.info-title {
  border-bottom: 1px solid $black-10;
  color: $tf-font-default;
  font-weight: 500;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.info-section {
  padding: 2px 8px;
  color: $tf-font-ghost;

  .more-info-title {
    color: $tf-font-default;
  }

  &:hover {
    background-color: $beige-30;
  }
}

.more-info {
  padding: 2px 6px;
  background-color: $blue-5;
  color: $blue-100;
  display: inline;
  margin-left: 8px;
  font-size: 11px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}
</style>
