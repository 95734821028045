<script setup>
import { computed, unref } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";
import { useRecommendationFeedbacks } from "@/composables/recommendationFeedbacks/useRecommendationFeedbacks";
import { tokenCategory } from "./lib/tokenCategory";
import { tokenHighlighted } from "./lib/tokenHighlighted";

import Title from "./Title";
import Tokens from "./Tokens";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  allowHiding: {
    type: Boolean,
    default: false,
  },
  showTitle: {
    type: Boolean,
    default: false,
  },
});

const {
  currentProject,
  isRecommendationTokensHidden,
} = useGetters([
  "currentProject",
  "isRecommendationTokensHidden",
]);

const {
  setIsRecommendationsHidden,
} = useMutations([
  "setIsRecommendationsHidden",
]);

const showTokens = computed(() => !isRecommendationTokensHidden.value);

const projectId = computed(() => (
  unref(currentProject)?.id
));

const enabled = computed(() => (
  !!unref(projectId)
));

const {
  recommendationFeedbacks,
} = useRecommendationFeedbacks({
  projectId,
  enabled,
});

const tokens = computed(() => {
  if (!props?.candidate?.similarity?.analysis?.humanTokens) {
    return [];
  }

  return props?.candidate?.similarity?.analysis?.humanTokens.map((token) => {
    const recommendationsFeedback = unref(recommendationFeedbacks).find((feedbackToken) => token.canonical === feedbackToken.canonical);

    return {
      ...token,
      recommendationsFeedback,
      feedback: recommendationsFeedback?.feedback,
      category: tokenCategory(token),
      highlighted: tokenHighlighted({ token, candidate: props.candidate }),
    };
  });
});

const switchChangeHandler = (value) => {
  showTokens.value = value;
  setIsRecommendationsHidden(!value)
}

</script>

<template>
<div v-if="tokens.length > 0" class="recommendations-tokens">
  <template v-if="allowHiding">
    <div class="d-flex">
      <Title v-if="showTitle" slot="title" :candidate="candidate" :show-tokens="showTokens" />
      <el-switch
        v-model="showTokens"
        @change="switchChangeHandler"
      />
    </div>
    <Tokens
      v-if="showTokens"
      :candidate="candidate"
      :project="currentProject"
      :tokens="tokens"
      class="tokens-container"
    />
  </template>

  <template v-else>
    <Title v-if="showTitle" :candidate="candidate" />
    <Tokens :candidate="candidate" :project="currentProject" :tokens="tokens" />
  </template>
</div>
</template>

<style scoped lang="scss">
.tokens-container {
  margin-left: 24px;
}
.recommendations-tokens {
  ::v-deep {
    .el-collapse, .el-collapse-item__header, .el-collapse-item__wrap {
      border-top: 0;
      border-bottom: 0;
      font-size: inherit !important;
      border: none;
      overflow: visible;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .el-collapse-item__header {
      font-weight: 400;
      height: 32px;
    }
  }
}
</style>
