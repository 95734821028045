<script setup>
import { computed } from "vue";
import { useRecommendationFeedbacks } from "@/composables/recommendationFeedbacks/useRecommendationFeedbacks";

import { splitBlocks } from "./lib/splitBlocks";
import Block from "./Block";

const props = defineProps({
  enabled: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  showOnlyTokens: {
    type: Boolean,
    default: () => false,
  },
  project: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
  tokenType: {
    type: String,
    required: true,
  },
  tokenText: {
    type: String,
    required: true,
  },
  displayText: {
    type: String,
    required: false,
    default: null,
  },
  similarityTokens: {
    type: Array,
    required: false,
    default: () => [],
  },
  filterTokens: {
    type: Array,
    required: false,
    default: () => [],
  },
  additionalTokenTypes: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const tokenTypes = computed(() => ([props.tokenType, ...props.additionalTokenTypes]));
const projectId = computed(() => (props.project.id));
const enabled = computed(() => (!!props.project.id && props.enabled));
const { recommendationFeedbacks } = useRecommendationFeedbacks({ projectId, enabled });

const blocks = computed(() => {
  const all = splitBlocks({
    tokenText: props.tokenText,
    displayText: props.displayText,
    tokenType: props.tokenType,
    tokenTypes: tokenTypes.value,
    similarityTokens: props.similarityTokens,
    filterTokens: props.filterTokens,
    recommendationFeedbacks: recommendationFeedbacks.value,
  });

  if (props.showOnlyTokens) {
    return all.filter(b => b.recommendationsFeedback || b.similarityToken || b.filterToken);
  }
  return all;
});
</script>

<template>
  <span v-if="enabled">
    <span
      v-for="(block, index) in blocks"
      :key="index + block.text"
      :class="{ 'nudge-left': index === 0, 'ml-8': showOnlyTokens }"
    >
      <Block
        :project="project"
        :candidate="candidate"
        :block="block"
      />
    </span>
  </span>

  <span v-else v-html="displayText || tokenText" />
</template>
