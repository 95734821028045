import { render, staticRenderFns } from "./CandidateSidebar.html?vue&type=template&id=060d4e61&scoped=true&external"
import script from "./CandidateSidebar.js?vue&type=script&lang=js&external"
export * from "./CandidateSidebar.js?vue&type=script&lang=js&external"
import style0 from "./CandidateSidebar.scss?vue&type=style&index=0&id=060d4e61&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060d4e61",
  null
  
)

export default component.exports