import { queryKey as useRecommendationFeedbacksQueryKey } from "@/composables/recommendationFeedbacks/useRecommendationFeedbacks";
import { queryKey as useRecommendationFeedbackBooleanQueryKey } from "@/composables/recommendationFeedbackBooleans/useRecommendationFeedbackBoolean";

export const onSettled = ({
  queryClient,
}) => ((data) => {
  queryClient.invalidateQueries({
    queryKey: useRecommendationFeedbacksQueryKey({
      projectId: data.data.boolean.projectId,
    }),
  });
  queryClient.invalidateQueries({
    queryKey: useRecommendationFeedbackBooleanQueryKey({
      projectId: data.data.boolean.projectId,
      tokenType: data.data.boolean.tokenType,
    }),
  });
})
