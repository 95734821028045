import ApiClient from "@/common/http/ApiClient.js";

class TouchpointsApi {
  static manualStatusOptions(params) {
    return ApiClient.get("touchpoints/manual_status_options", params)
  }
  static stop(params) {
    return ApiClient.post("touchpoints/stop", params);
  }

  static bulkStop(params) {
    return ApiClient.post("touchpoints/stop_bulk", params);
  }

  static overrideStatus(params) {
    return ApiClient.post("touchpoints/override", params);
  }
}

export default TouchpointsApi;
