import { mapGetters, mapActions } from "vuex";

import logEvent from "@/common/Analytics";
import CandidateNoteInput from "@/components/CandidateNoteInput/Component";
import CandidateNote from "@/components/CandidateNote/Component";
import ProfilesApi from "@/common/http/ProfilesApi";

export default {
  name: "CandidateNoteModal",
  components: {
    CandidateNoteInput,
    CandidateNote,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    bulkSelectionAttributes: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      notes: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      "currentCampaign",
      "currentCandidate",
      "currentProject",
    ]),
    innerVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit("update:visible", isVisible);
      },
    },
    title() {
      if (this.candidate) {
        return `Notes for ${this.candidate.name || "Name Unknown"}`;
      }
      if (this.bulkSelectionAttributes) {
        const candidatesText = this.bulkSelectionAttributes.count === 1 ? "candidate" : "candidates";
        return `Add a note to ${this.bulkSelectionAttributes.count} ${candidatesText}`;
      }
    },
    showNoNotesInfo() {
      return this.candidate?.profileId && !this.notes.length;
    },
  },
  watch: {
    "currentCandidate.notes": {
      handler(notes) {
        if (notes) {
          this.isLoading = false;
          this.notes = notes;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "fetchCurrentCandidate",
    ]),
    async onModalOpen() {
      logEvent("candidate-action-intent", { action: "note" });
      this.notes = [];

      if (this.candidate?.profileId) {
        this.fetchCurrentCandidate({ profileId: this.candidate.profileId }); // The watcher of the current candidate will set the loading to false
      } else if (!this.candidate && !this.bulkSelectionAttributes) {
        this.innerVisible = false;
      } else {
        this.isLoading = false;
      }
    },
    async addNote(note) {
      logEvent("candidate-action", { action: "note" });

      const campaignId = this.$route.path.includes("campaigns") ? this.currentCampaign.id : null;
      const projectId = this.$route.path.includes("projects") ? this.currentProject.id : null;
      const params = {
        note,
        projectId,
        campaignId,
        bulkSelectionAttributes: this.bulkSelectionAttributes,
        profileId: this.candidate?.profileId,
      };
      const { data } = await ProfilesApi.createNote(params);

      if (this.candidate) {
        this.notes.push(data.teamProfileNote);
      } else {
        this.$notify({
          title: "Success",
          position: "bottom-right",
          message: "You may refresh the page in a few moments to see updated data. ",
          type: "success",
        });

        this.innerVisible = false;
      }
    },
    deleteNote(note) {
      ProfilesApi.deleteNote(note.id).then(() => {
        const idx = this.notes.findIndex(n => n.id === note.id);
        this.notes.splice(idx, 1);
      });
    },
  },
};
