import CampaignsApi from "@/common/http/CampaignsApi";
import { handleApiError } from "@/common/http/ApiError";

const state = {
  currentCampaign: {},
  isSavingCampaign: false,
  isSourceableCampaignListFirstTimeLoading: true,
  newCampaign: null,
  sourceableCampaignList: [],
  teamReviewerOptions: [],
  teamReplyHandlerOptions: [],
  wasSourceableCampaignListFetched: false,
};

const getters = {
  isSavingCampaign(state) {
    return state.isSavingCampaign
  },
  currentCampaign(state) {
    return state.currentCampaign;
  },
  isSourceableCampaignListFirstTimeLoading(state) {
    return state.isSourceableCampaignListFirstTimeLoading;
  },
  wasSourceableCampaignListFetched(state) {
    return state.wasSourceableCampaignListFetched;
  },
  newCampaign(state) {
    return state.newCampaign;
  },
  sourceableCampaignList(state) {
    return state.sourceableCampaignList;
  },
  teamReviewerOptions(state) {
    return state.teamReviewerOptions;
  },
  teamReplyHandlerOptions(state) {
    return state.teamReplyHandlerOptions;
  },
  isNewCampaignValidForFirstMessage(state) {
    const campaign = state.newCampaign;
    if (!campaign?.name?.trim()) {
      return false;
    }

    return !!campaign.senderId;
  },
};

const actions = {
  async fetchCampaign({ commit }, campaignId) {
    const { data } = await CampaignsApi.show(campaignId);
    commit("setCurrentCampaign", data.campaign);
  },
  async fetchCampaignForCopy({ commit }, { parentCampaignId, currentUserId }) {
    const { data } = await CampaignsApi.show(parentCampaignId, { for_copy: true });
    commit("setNewCampaignForCopy", { currentUserId, parentCampaignId, newCampaign: data.campaign });
  },
  async fetchSourceableCampaignList({ commit }) {
    const { data } = await CampaignsApi.indexForSourcing();
    commit("setSourceableCampaignList", data.campaigns);
    commit("setTeamReplyHandlerOptions", data.teamReplyHandlerOptions);
    commit("setTeamReviewerOptions", data.teamReviewerOptions);
    commit("setSourceableCampaignListFetched", true);
    commit("setSourceableCampaignListFirstTimeLoading", false);
  },
  async fetchNewCampaign({ commit }, { inbound, referrals, projectId }) {
    const { data } = await CampaignsApi.new({ inbound, referrals, projectId });
    commit("setNewCampaign", data.campaign);
  },
  async openCampaign({ commit }, id) {
    const response = await CampaignsApi.open(id);
    if (state.currentCampaign.id) {
      commit("setCurrentCampaignClosed", false);
      commit("setCurrentCampaignCanActivate", false);
      commit("setCurrentCampaignCanEdit", true);
    }
    return response;
  },
  async closeCampaign({ commit }, { id, stopOutreach }) {
    const response = await CampaignsApi.close(id, { stopOutreach });
    if (state.currentCampaign.id) {
      commit("setCurrentCampaignClosed", true);
      commit("setCurrentCampaignCanActivate", true);
      commit("setCurrentCampaignCanEdit", false);
      commit("removeCampaignFromSourceableList", state.currentCampaign.id)
    }
    return response;
  },
  async saveCampaignCopy({ commit, dispatch }, { id }) {
    const { data } = await CampaignsApi.copy(id);
    commit("setCurrentCampaign", data.campaign);
    dispatch("fetchSourceableCampaignList");
  },
  async togglePinCampaign(context, campaign) {
    campaign.favorite = !campaign.favorite;
    const params = { campaign: { favorite: campaign.favorite } };
    CampaignsApi.update(campaign.id, params);
  },
  async createCampaign({ state, getters, commit, dispatch }) {
    state.isSavingCampaign = true;
    const params = Object.assign({}, getters.newCampaign);
    params.autosend = getters.newCampaign.pseudoSenderId.split("/")[1] === undefined;

    try {
      const { data } = await CampaignsApi.create({ campaign: params });
      commit("setCurrentCampaign", data.campaign);
      commit("setNewCampaign", null);
      dispatch("fetchSourceableCampaignList");
      state.isSavingCampaign = false;
    } catch (e) {
      handleApiError(e);
    }
  },
  async updateCurrentCampaign({ getters, commit, dispatch }, applyToAllOutreach = null) {
    const params = Object.assign({}, getters.currentCampaign);

    // inbound campaigns don't use this
    if (getters.currentCampaign.pseudoSenderId) {
      params.autosend = getters.currentCampaign.pseudoSenderId.split("/")[1] === undefined;
    }

    const { data } = await CampaignsApi.update(state.currentCampaign.id, { campaign: params, applyToAllOutreach } );
    commit("setCurrentCampaign", data.campaign);
    dispatch("fetchSourceableCampaignList");
  },
  async updateCurrentCampaignReferralTemplate({ state, getters }, { messageContent, messageType }) {
    state.isSavingCampaign = true;
    state.currentCampaign[messageType] = messageContent;

    const params = { [messageType]: messageContent };
    await CampaignsApi.updateReferralSettings(getters.currentCampaign.referralSettingId, params );
    state.isSavingCampaign = false;
  },
  async updateCurrentCampaignAutoReply({ state, getters }) {
    state.isSavingCampaign = true;
    const campaign = getters.currentCampaign;

    let params = {
      positiveConfirmed: campaign.autoReplyPositive.confirmed,
      positiveAnswerEnabled: campaign.autoReplyPositive.enabled,
      positiveAnswer: campaign.autoReplyPositive.body,
      positiveSubject: campaign.autoReplyPositive.subject,
      positiveCc: campaign.autoReplyPositive.cc,
      positiveBcc: campaign.autoReplyPositive.bcc,
      negativeConfirmed: campaign.autoReplyNegative.confirmed,
      negativeAnswerEnabled: campaign.autoReplyNegative.enabled,
      negativeAnswer: campaign.autoReplyNegative.body,
      negativeSubject: campaign.autoReplyNegative.subject,
      negativeCc: campaign.autoReplyNegative.cc,
      negativeBcc: campaign.autoReplyNegative.bcc,
      oooAnswerEnabled: campaign.autoReplyOoo.enabled,
      oooAnswer: campaign.autoReplyOoo.body,
      oooCc: campaign.autoReplyOoo.cc,
      oooBcc: campaign.autoReplyOoo.bcc,
      inboundRejectAtsEmailTemplateId: campaign.inboundRejectAtsEmailTemplateId,
    };

    await CampaignsApi.updateAutoReply(campaign.autoReplyId, { campaignAutoReply: params });
    state.isSavingCampaign = false;
  },
  async updateCampaignFollowUp({ state, getters, dispatch }, { id, applyToAllOutreach = null }) {
    state.isSavingCampaign = true;
    const followUp = getters.currentCampaign.followUps.find(fu => fu.id == id);
    await CampaignsApi.updateFollowUp(id, { campaignFollowUp: followUp, applyToAllOutreach });
    dispatch("fetchSourceableCampaignList");
    state.isSavingCampaign = false;
  },
  async createCampaignFollowUp({ state, getters, commit }, { message, campaign, idx = null }) {
    state.isSavingCampaign = true;
    const params = message;
    params.campaignId = campaign.id;

    const { data } = await CampaignsApi.createFollowUp({ campaignFollowUp: params });

    if (idx !== null) {
      commit("setCurrentCampaignFollowUpMessageByIndex", { message: data.campaignFollowUp, idx })
    } else {
      getters.currentCampaign.followUps.push(data.campaignFollowUp);
    }

    state.isSavingCampaign = false;
  },
  async deleteCampaignFollowUp({ commit }, { id, applyToAllOutreach = null }) {
    CampaignsApi.deleteFollowUp(id, { applyToAllOutreach });
    commit("removeFollowUpFromCurrentCampaign", id);
  },
};

const mutations = {
  setCurrentCampaign(state, campaign) {
    state.currentCampaign = campaign;
  },
  setCurrentCampaignClosed(state, value) {
    state.currentCampaign.isClosed = value;
  },
  setCurrentCampaignCanActivate(state, value) {
    state.currentCampaign.canActivate = value;
  },
  setCurrentCampaignCanEdit(state, value) {
    state.currentCampaign.canEdit = value;
  },
  setSourceableCampaignListFirstTimeLoading(state, value) {
    state.isSourceableCampaignListFirstTimeLoading = value;
  },
  setSourceableCampaignListFetched(state, value) {
    state.wasSourceableCampaignListFetched = value;
  },
  setNewCampaign(state, newValue) {
    state.newCampaign = newValue;
  },
  setNewCampaignForCopy(state, { currentUserId, parentCampaignId, newCampaign }) {
    state.newCampaign = newCampaign;
    state.newCampaign.id = null;
    state.newCampaign.name = `Copy of ${state.newCampaign.name}`;
    state.newCampaign.projectId = null;
    state.newCampaign.parentCampaignId = parentCampaignId;
    if (state.newCampaign.ownerIsJimmy) {
      state.newCampaign.pseudoSenderId = currentUserId;
      state.newCampaign.senderId = currentUserId;
      state.newCampaign.replyHandlerId = currentUserId;
      state.newCampaign.userId = currentUserId;
    }
  },
  setSourceableCampaignList(state, campaigns) {
    state.sourceableCampaignList = campaigns;
  },
  addToSourceableCampaignList(state, campaign) {
    state.sourceableCampaignList.push(campaign);
  },
  setTeamReplyHandlerOptions(state, options) {
    state.teamReplyHandlerOptions = options;
  },
  setTeamReviewerOptions(state, options) {
    state.teamReviewerOptions = options;
  },
  setNewCampaignFirstMessage(state, { cc, bcc, subject, body }) {
    state.newCampaign.subject = subject;
    state.newCampaign.cc = cc;
    state.newCampaign.bcc = bcc;
    state.newCampaign.body = body;
  },
  setCurrentCampaignFirstMessage(state, { cc, bcc, subject, body }) {
    state.currentCampaign.subject = subject;
    state.currentCampaign.cc = cc;
    state.currentCampaign.bcc = bcc;
    state.currentCampaign.body = body;
  },
  setCurrentCampaignAutoReplyMessage(state, { message, type }) {
    state.currentCampaign[type].cc = message.cc;
    state.currentCampaign[type].bcc = message.bcc;
    state.currentCampaign[type].subject = message.subject;
    state.currentCampaign[type].body = message.body;
  },
  setCurrentCampaignFollowUpMessage(state, { message, followUpId }) {
    const followUp = state.currentCampaign.followUps.find(fu => fu.id == followUpId);
    followUp.subject = message.subject;
    followUp.cc = message.cc;
    followUp.bcc = message.bcc;
    followUp.body = message.body;
  },
  setCurrentCampaignFollowUpMessageByIndex(state, { message, idx }) {
    let followUp = state.currentCampaign.followUps[idx];
    followUp.campaignId = message.campaignId;
    followUp.cc = message.cc;
    followUp.bcc = message.bcc;
    followUp.body = message.body;
    followUp.id = message.id;
    followUp.numDays = message.numDays;
    followUp.sendDays = message.sendDays;
    followUp.sendHour = message.sendHour;
    followUp.sequenceNum = message.sequenceNum;
  },
  setCurrentCampaignFollowUpData(state, { sendDays, numDays, sendHour, followUpId }) {
    const followUp = state.currentCampaign.followUps.find(fu => fu.id == followUpId);
    if (sendDays) {
      followUp.sendDays = sendDays;
    }
    if (numDays) {
      followUp.numDays = numDays;
    }
    followUp.sendHour = sendHour;
  },
  removeCampaignFromSourceableList(state, campaignId) {
    const idx = state.sourceableCampaignList.findIndex(c => c.id == campaignId);
    if (idx >= 0) {
      state.sourceableCampaignList.splice(idx, 1);
    }
  },
  removeFollowUpFromCurrentCampaign(state, followUpId) {
    const idx = state.currentCampaign.followUps.findIndex(fu => fu.id == followUpId);
    state.currentCampaign.followUps.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
