<script setup>
import { computed, onMounted, ref } from "vue";

import { employmentSimilarityTokens } from "@/common/projects/recommendations/employmentSimilarityTokens";
import HighlightableField from "@/components/projects/HighlightableField/HighlightableField";

import CompanyDetails from "./CompanyDetails";

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
  employment: {
    type: Object,
    required: true,
  },
  tokensEnabled: {
    type: Boolean,
    default: false,
  },
});

const activeOption = ref("");
const isSectionOpened = ref(false);
const matchedTokensCount = ref(0);
const companyInfoId = `company-info-${props.index}`;

const tokens = employmentSimilarityTokens({
  enabled: props.tokensEnabled,
  employmentIndex: props.index,
  candidate: props.candidate,
});

const isCurrentEmployment = props.employment.startDate && !props.employment.endDate;
const companyFilterTokens = props.candidate.filterHighlights && (isCurrentEmployment ? props.candidate.filterHighlights.currentCompanyTokens : props.candidate.filterHighlights.previousCompanyTokens);

const isMoreInfoAvailable = computed(() => {
  if (!props.employment) {
    return false;
  }

  return props.employment.categories?.length ||
    props.employment.companyInfo?.metrics?.startingSize?.bucket ||
    props.employment.companyInfo?.metrics?.earlyAtCompany?.bucket ||
    props.employment.companyInfo?.metrics?.tenureNetSizeChange ||
    props.employment.companyInfo?.metrics?.compoundedAnnualGrowthRate?.bucket ||
    props.employment.allFundingRounds?.length ||
    props.employment.fundingRounds?.length ||
    props.employment.companyInfo?.overview?.website ||
    props.employment.companyInfo?.overview?.shortDescription;
})

function toggleHandler() {
  isSectionOpened.value = !isSectionOpened.value;
}

function checkForMatchedTokens() {
  const elementById = document.getElementById(companyInfoId);

  if (!elementById) {
    return;
  }

  const elementsByClassName = elementById.getElementsByClassName("highlight");
  matchedTokensCount.value = elementsByClassName.length;
}

onMounted(() => {
  checkForMatchedTokens();
})
</script>

<template>
  <div class="experience-company">
    <el-collapse
      v-if="isMoreInfoAvailable"
      v-model="activeOption"
      accordion
      class="w-100 mt-4 mb-8"
      @change="toggleHandler"
      @click.native.stop
    >
      <el-collapse-item :name="index" :class="{ 'no-collapse-border': !employment.description }">
        <div slot="title" class="collapse-title d-flex align-items-center">
          <span class="material-icons-round md-18 mr-4">{{ isSectionOpened ? 'unfold_less' : 'unfold_more' }}</span>
          <HighlightableField
            :enabled="tokensEnabled"
            :project="project"
            :candidate="candidate"
            :token-text="employment.name"
            :token-type="isCurrentEmployment ? 'employer-current' : 'employer-previous'"
            :additional-token-types="['plain']"
            :similarity-tokens="[tokens.employer]"
            :filter-tokens="companyFilterTokens"
          />

          <div v-if="tokensEnabled && matchedTokensCount" class="matched-attributes ml-8">{{matchedTokensCount}} matched attribute{{matchedTokensCount > 1 ? 's' : ''}}</div>
        </div>

        <div :id="companyInfoId">
          <CompanyDetails
            :tokens-enabled="tokensEnabled"
            :project="project"
            :candidate="candidate"
            :employment="employment"
          />
        </div>
      </el-collapse-item>
    </el-collapse>

    <div v-else class="d-flex align-items-center">
      <HighlightableField
        :enabled="tokensEnabled"
        :project="project"
        :candidate="candidate"
        :token-text="employment.name"
        :token-type="isCurrentEmployment ? 'employer-current' : 'employer-previous'"
        :additional-token-types="['plain']"
        :similarity-tokens="[tokens.employer]"
        :filter-tokens="companyFilterTokens"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.matched-attributes {
  padding: 3px 6px;
  background: $beige-30;
  border-radius: 8px;
}

.experience-company {
  display: inline;

  ::v-deep .nudge-left .highlight {
    margin-left: -5px;
  }
}

.info-section {
  padding: 2px 8px;

  &:hover {
    background-color: $beige-30;
  }
}

.collapse-title {
  height: 20px;
  line-height: 20px;
  margin-left: -21px;

  &:hover {
    color: $blue-100;
  }
}

::v-deep .el-collapse {
  border: none;
}

::v-deep .el-collapse-item__header {
  height: 30px;
  border: none;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0;
  margin-bottom: 16px;
  border: none;
}

::v-deep .el-collapse-item__arrow {
  display: none;
}

.no-collapse-border {
  ::v-deep .el-collapse-item__wrap {
    border-bottom: 0 !important;
  }
}
</style>
