<script>
import { mapGetters, mapActions } from "vuex";
import {
  REFERRAL_CAMPAIGN_STATISTICS_KEYS,
  INBOUND_CAMPAIGN_STATISTICS_KEYS,
  OUTREACH_CAMPAIGN_STATISTICS_KEYS,
} from "@/constants";
import logEvent from "@/common/Analytics";
import { MAIN_APP_URL } from "@/common/Env";

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionsVisible: false,
      closeCampaignPopoverSmallScreenVisible: false,
      dialogDeactivateVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentCampaign",
      "currentProject",
      "currentUser",
      "isSavingCampaign",
    ]),
    isCsvDisabled() {
      return this.campaign.isManaged && !this.currentUser.actualUserIsInternal;
    },
    isCreatorTheSender() {
      return this.campaign.ownerName === this.campaign.senderName;
    },
    areAcceptAndRejectSendersSame() {
      return this.campaign.inboundAcceptSenderName === this.campaign.inboundRejectSenderName;
    },
    isJimmyOwned() {
      return !this.campaign.referrals && !this.campaign.inbound && !this.campaign.personalized;
    },
    isBrokenSenderAlertVisible() {
      return this.campaign.inboundAcceptSenderBroken || this.campaign.inboundRejectSenderBroken;
    },
    brokenSenderAlert() {
      if (this.campaign.inboundAcceptSenderBroken && this.campaign.inboundRejectSenderBroken) {
        return {
          title: `Cannot send as ${this.campaign.inboundAcceptSenderName} and ${this.campaign.inboundRejectSenderName}.`,
          description: "Their mailboxes are no longer accessible.",
        }
      }

      return {
        title: `Cannot send as ${this.campaign.inboundAcceptSenderBroken ? this.campaign.inboundAcceptSenderName : this.campaign.inboundRejectSenderName}.`,
        description: "Their mailbox is no longer accessible.",
      }
    },
    showSuffixProjectName() {
      return !this.currentProject.id && (this.campaign.referrals || this.campaign.inbound);
    },
    creatorTooltip() {
      if (this.isCreatorTheSender) {
        return "Creator and sender";
      }
      return "Creator";
    },
    senderTooltip() {
      if (this.areAcceptAndRejectSendersSame) {
        return "Approval and rejection sender";
      }
      return "Approval sender";
    },
    csvImportTooltip() {
      if (this.campaign.isManaged && !this.currentUser.actualUserIsInternal) {
        return "Cannot CSV import into Stream campaigns";
      }
      return "Source candidates from a CSV";
    },
    pinCampaignTooltip() {
      return this.campaign.favorite ? "Unpin" : "Pin";
    },
    copyCampaignTooltip() {
      if (this.campaign.inbound) {
        return "Cannot copy inbound campaigns";
      }
      return "Copy into a new campaign";
    },
    statusTooltip() {
      return this.campaign.isClosed ? "This campaign is no longer active" : "This campaign is active";
    },
    activateTooltip() {
      if (this.campaign.projectIsClosed) {
        return "Can't reactivate campaign because the parent job is closed";
      }
      return "Reactivate campaign";
    },
    deactivateCampaignPrompt() {
      return `Deactivate '${this.campaign.name}'?`;
    },
    statistics() {
      if (this.campaign.referrals) {
        return REFERRAL_CAMPAIGN_STATISTICS_KEYS;
      } else if (this.campaign.inbound) {
        return INBOUND_CAMPAIGN_STATISTICS_KEYS;
      }

      return OUTREACH_CAMPAIGN_STATISTICS_KEYS;
    },
    computedCampaign: {
      get() { return this.campaign; },
      set(value) { this.$emit("update:campaign", value); },
    },
  },
  methods: {
    ...mapActions([
      "closeCampaign",
      "nudgeCampaignReviewer",
      "openCampaign",
      "saveCampaignCopy",
      "togglePinCampaign",
    ]),
    hideActions() {
      this.actionsVisible = false;
    },
    showActions() {
      if (!this.readOnly) {
        this.actionsVisible = true;
      }
    },
    notify(message, success) {
      this.$notify({
        position: "bottom-right",
        message,
        title: success ? "Success" : "Failed",
        type: success ? "success" : "error",
      });
    },
    handleDropDownCommand(command) {
      switch (command) {
        case "pin":
          this.togglePin();
          break;
        case "move":
          this.move();
          break;
        case "startCopy":
          this.startCopy();
          break;
        case "nudge":
          this.nudge();
          break;
        case "ops":
          this.opsLink();
          break;
        case "csvImport":
          this.csvImport();
          break;
        case "compare":
          this.compare();
          break;
        case "deactivate":
          this.dialogDeactivateVisible = true;
          break;
        case "open":
          this.open();
          break;
      }
    },
    opsLink() {
      const url = `${MAIN_APP_URL}/worker/campaigns/${this.campaign.id}/edit#sourcing`;
      window.open(url, "_blank");
      return;
    },
    togglePin() {
      if (!this.isSavingCampaign) {
        logEvent("campaign-action", { action: "pin" });
        this.togglePinCampaign(this.campaign);
      }
    },
    open() {
      logEvent("campaign-action", { action: "open" });
      this.openCampaign(this.campaign.id).then(({ data }) => {
        this.notify(data.message, data.success);
      });
    },
    deactivate(stopOutreach = true) {
      logEvent("campaign-action", { action: "close" });
      const params = {
        id: this.campaign.id,
        stopOutreach,
      };
      this.closeCampaign(params).then(({ data }) => {
        this.dialogDeactivateVisible = false;
        this.notify(data.message, data.success);
      });
    },
    nudge() {
      logEvent("campaign-action", { action: "nudge-reviewer" });
      this.nudgeCampaignReviewer(this.campaign.id).then(({ data }) => {
        this.notify(data.message, data.success);
      });
    },
    csvImport() {
      logEvent("campaign-action", { action: "csv-import" });
      this.$bus.$emit("assign-modal-campaign", this.campaign);
      const query = Object.assign({}, this.$route.query);

      query.modal = "csv";
      this.$router.push({ query });
    },
    move() {
      logEvent("campaign-action", { action: "move" });
      this.$bus.$emit("assign-modal-campaign", this.campaign);
      const query = Object.assign({}, this.$route.query);

      query.modal = "move";
      this.$router.push({ query });
    },
    compare() {
      logEvent("campaign-action", { action: "compare" });
      const filters = { "Name": this.campaign.name }
      this.$router.push({ name: "report", params: { filters: JSON.stringify(filters), reportName: "campaign-statistics-report" } });
    },
    startCopy() {
      logEvent("campaign-action", { action: "copy" });
      const query = Object.assign({}, this.$route.query);
      query.modal = "new-campaign";
      query.parentCampaignId = this.campaign.id;
      this.$router.push({ query });
    },
  },
}
</script>

<template>
<div></div>
</template>
