import { mapGetters } from "vuex";
import _ from "lodash";
import Draggable from "vuedraggable";
import AtsApi from "@/common/http/AtsApi";

export default {
  name: "AtsStages",
  components: {
    Draggable,
  },
  props: {
    availableAtsStages: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedAtsStages: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
    showStatusMessage() {
      return (!this.availableAtsStages || this.selectedAtsStages.length > 1);
    },
    stagesStatusMessage() {
      if (!this.availableAtsStages) {
        return `Syncing ${this.user.primaryTeamAtsName} stages... They will appear shortly`;
      }
      return "Reorder these stages to match your team's workflow";
    },
  },
  methods: {
    updateSelectedStages() {
      AtsApi.updateSelectedStages(this.selectedAtsStages).then(() => {
        this.$emit("updated");
      });
    },
    handleAtsStageSelected: _.debounce(function() {
      this.updateSelectedStages();
    }, 1000),
    handleAtsStagesOrderChange: _.debounce(function() {
      this.updateSelectedStages();
    }, 1000),
  },
  mounted() {
    this.isLoading = true;
    AtsApi.selectedStages().then(({ data }) => {
      this.selectedAtsStages = data.selectedStages;
      this.isLoading = false;
    });
  },
};
