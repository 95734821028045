import AtsApi from "@/common/http/AtsApi";
import ProjectsApi from "@/common/http/ProjectsApi";
import _ from "lodash";

const state = {
  sourceableProjectList: [],
  currentProject: {},
  projectsList: [],
  isSourceableProjectListFirstTimeLoading: true,
  wasSourceableProjectListFetched: false,
  rejectedRecommendations: {},
};

const getters = {
  sourceableProjectList(state) {
    return state.sourceableProjectList;
  },
  projectsList(state) {
    return state.projectsList;
  },
  currentProject(state) {
    return state.currentProject;
  },
  isSourceableProjectListFirstTimeLoading(state) {
    return state.isSourceableProjectListFirstTimeLoading;
  },
  wasSourceableProjectListFetched(state) {
    return state.wasSourceableProjectListFetched;
  },
  rejectedRecommendations(state) {
    return state.rejectedRecommendations;
  },
};

const actions = {
  async fetchSourceableProjectsList({ commit }) {
    const { data } = await ProjectsApi.indexForSourcing();
    commit("setSourceableProjectsList", data.writableProjects);
    commit("setSourceableProjectListFetched", true);
    commit("setSourceableProjectListFirstTimeLoading", false);
  },
  fetchCurrentProject({ commit }, { id }) {
    commit("setCurrentProject", {});
    ProjectsApi.show(id).then(({ data }) => {
      commit("mergeCurrentProject", data.project);
    });
    ProjectsApi.settings(id).then(({ data }) => {
      commit("mergeCurrentProject", data.project);
      commit("mergeCurrentProject", { settingsLoaded: true });
    });
  },
  async fetchCurrentProjectAtsStagesForJob({ commit }, atsJobId) {
    const { data } = await AtsApi.stagesForJob(atsJobId);
    commit("setCurrentProjectAtsStages", data.atsStages);
  },
  async createProject({ commit }, { params }) {
    params = { project: params };
    const { data } = await ProjectsApi.create(params);

    commit("addProjectToList", data.project);
    actions.fetchSourceableProjectsList({ commit });

    return new Promise(resolve => { resolve(data.project) });
  },
  async updateCurrentProject({ commit, getters }, { id, params, reloadCurrentProject = true }) {
    params = { project: params };
    const { data } = await ProjectsApi.update(id, params);
    if (getters.currentProject.id == id && reloadCurrentProject) {
      commit("mergeCurrentProject", data.project);
    }
    commit("replaceProjectInList", data.project);
  },
  async openProject({ commit }, id) {
    await ProjectsApi.open(id);
    if (state.currentProject.id) {
      commit("setCurrentProjectClosed", false);
      commit("removeProjectFromList", state.currentProject.id);
    }
  },
  async closeProject({ commit }, id) {
    await ProjectsApi.delete(id);
    if (state.currentProject.id) {
      commit("setCurrentProjectClosed", true);
      commit("removeProjectFromList", state.currentProject.id);
    }
  },
  async favoriteProject(context, project) {
    project.favorite = !project.favorite;
    ProjectsApi.favorite(project.id, { favorite: project.favorite });
  },
  hideRecommendation({ state }, { candidate, bulkSelectionAttributes }) {
    const params = { id: state.currentProject.id, params: {} };

    if (candidate) {
      params.params.tfId = candidate.tfId;
    } else if (bulkSelectionAttributes) {
      params.params.bulkSelectionAttributes = bulkSelectionAttributes;
    }

    ProjectsApi.hideRecommendation(params);

    // if (data.success) {
    //   TODO: Feedback modal is broken, don't show it until there is a better list of tokens
    //   rejectedRecommendations are also used as part of the feedback modal

    //   commit("setRejectedRecommendations", {
    //     projectId: state.currentProject.id,
    //     profiles: (state.rejectedRecommendations[state.currentProject.id] || []).concat(candidate),
    //   });

    //   This was implemented in the component, now it's in the store but not sure
    //   if it works in the store but moved it here since this method is used in multiple
    //   components, once we bring it back we will need to figure out how to implement this
    //   as it won't be just to comment this out

    //   if (state.rejectedRecommendations[state.currentProject.id]?.length >= 5) {
    //     feedbackModalVisible.value = true;
    //   }
    // }
  },
};
const mutations = {
  setSourceableProjectsList(state, projects) {
    state.sourceableProjectList = projects;
  },
  setProjectsList(state, { projects, append }) {
    if (append) {
      state.projectsList.push(...projects);
    } else {
      state.projectsList = projects;
    }
  },
  setCurrentProject(state, project) {
    state.currentProject = project;
  },
  setCurrentProjectSavedForLaterCount(state, savedForLaterCount) {
    state.currentProject.savedForLaterCount = savedForLaterCount;
  },
  mergeCurrentProject(state, project) {
    // _ for deep merge
    // Object.assign since vue isn't rerendering when nested properties are changed
    state.currentProject = Object.assign({}, _.merge(state.currentProject, project));
  },
  addProjectToList(state, project) {
    state.projectsList.push(project);

    // sourceable job attributes are different, this will lead to subtle bugs
    // state.sourceableProjectList.push(project);
  },
  replaceProjectInList(state, project) {
    const idx = state.projectsList.findIndex(c => c.id == project.id);
    state.projectsList.splice(idx, 1, project);
  },
  setCurrentProjectAtsStages(state, stages) {
    if (state.currentProject) {
      state.currentProject.atsStages = stages;
    }
  },
  setSourceableProjectListFirstTimeLoading(state, value) {
    state.isSourceableProjectListFirstTimeLoading = value;
  },
  setSourceableProjectListFetched(state, value) {
    state.wasSourceableProjectListFetched = value;
  },
  setCurrentProjectClosed(state, value) {
    state.currentProject.isClosed = value;
  },
  removeProjectFromList(state, id) {
    let idx = state.projectsList.findIndex(p => p.id == id);
    if (idx >= 0) {
      state.projectsList.splice(idx, 1);
    }

    idx = state.sourceableProjectList.findIndex(p => p.id == id);
    if (idx >= 0) {
      state.sourceableProjectList.splice(idx, 1);
    }
  },
  setRejectedRecommendations(state, { projectId, profiles }) {
    state.rejectedRecommendations[projectId] = profiles;
  },
  setInboundAcceptMessageConfirmed(state, value) {
    state.currentProject.isInboundAcceptMessageConfirmed = value;
  },
  setInboundRejectMessageConfirmed(state, value) {
    state.currentProject.isInboundRejectMessageConfirmed = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
