import { VueEditor, Quill } from "vue2-editor";
import { mapGetters } from "vuex";
import "quill-paste-smart";

import TokenVariable from "@/components/TokenVariable";
import TokenBlot from "@/common/quill/TokenBlot";
import Formatter from "@/common/quill/Formatter";
import QuillInit from "@/common/quill/QuillInit";

import { TOKEN_MODULE_NAME } from "@/common/quill/TokenDrop";

export default {
  name: "MessageEditor",
  components: {
    VueEditor,
    TokenVariable,
  },
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    disableEnterKey: {
      type: Boolean,
      default: false,
    },
    showDisabledVariablesOnly: {
      type: Boolean,
      default: false,
    },
    previewDisclaimer: {
      type: String,
      default: null,
    },
    senderId: {
      type: [String, Number],
      default: null,
    },
    messageVariablesService: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showVariables: {
      type: Boolean,
      default: true,
    },
    subjectQuillRef: null,
  },
  data() {
    let modules = {
      [TOKEN_MODULE_NAME]: true,
      toolbar: false,
      clipboard: {
        allowed: {
          tags: ["strong", "u", "a", "i", "p", "br", "ul", "ol", "li", "span", "img", "h1", "h2", "h3", "h4", "h5", "h6", "pre"],
          attributes: ["src", "class", "href", "rel", "target"],
        },
        keepSelection: false,
        magicPasteLinks: true,
      },
    };

    if (this.disableEnterKey) {
      modules.keyboard = {
        bindings: {
          tab: false,
          handleEnter: {
            key: 13,
            handler: function() { }, // disables enter key
          },
        },
      }
    } else {
      modules.keyboard = {
        bindings: {
          linkHandlerMac: {
            key: "k",
            metaKey: true,
            handler: this.handleLink,
          },
          linkHandlerWin: {
            key: "k",
            ctrlKey: true,
            handler: this.handleLink,
          },
        },
      }
    }
    const randomNumber = Math.round(Math.random() * 100);

    if (this.showToolbar) {
      modules.toolbar = {
        container: `.editor-toolbar-${randomNumber}`,
        handlers: {
          "emoji": function () {},
        },
      }
      modules["emoji-toolbar"] = true;
      modules["emoji-shortname"] = true;
      modules.history = {
        delay: 1000,
        maxStack: 100,
        userOnly: false,
      };
    }

    return {
      wasMounted: false,
      observer: null,
      randomNumber,
      showHtml: false,
      afterInitDisabled: false,
      initialTextSet: false,
      editorSettings: {
        formats: ["bold", "align", "link", "italic", "underline", "image", "indent", "list", "emoji", "size", TokenBlot.blotName],
        modules,
        placeholder: this.placeholder,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    inputVal: {
      get() {
        // Quill HAX.
        // vue-editor wraps lines in an HTML tag. There is no way around this. We want it to use <p> everytime.
        // Without safeVal Quill will use <span> wrapping tag for new messages. Span wrapping is worse for newline handling
        // as it requires double-enter keypress, and breaks link insertion.
        // Note that when Quill is initialized with content it will use <p> wrapping. IDK why it has different behavior
        // " " will also work instead of <p></p>
        // Quill SUX.
        const safeVal = this.value || "<p></p>";
        let content = safeVal;
        if (this.messageVariablesService) {
          content = Formatter.mustacheToHtmlTokens(safeVal, this.messageVariablesService, this.disabled);
        }
        content = Formatter.autocorrectIssues(content);
        return Formatter.transformForQuill(content);
      },
      set(val) {
        if (!this.disabled) {
          let content = Formatter.htmlToMustacheTokens(val, this.showToolbar);

          // If we don't show toolbar, then we don't want HTML
          // Primary use case here is email subject
          if (!this.showToolbar) {
            content = Formatter.stripWrappingTags(content)
          }
          this.$emit("input", Formatter.autocorrectIssues(content));
        }
      },
    },
    rawHtmlContent() {
      return Formatter.autocorrectIssues(this.value);
    },
    allowedVariables() {
      let allowed;
      if (this.messageVariablesService) {
        allowed = this.messageVariablesService.allowed();
        const oneTimeVariables = ["sentence", "signature"];

        oneTimeVariables.forEach(slug => {
          if (this.isVariableInBody(slug)) {
            const idx = allowed.findIndex(v => v.value == slug);
            allowed.splice(idx, 1);
          }
        });
      }
      return allowed;
    },
    quill() {
      if (this.$refs.quillInstance) {
        return this.$refs.quillInstance.quill;
      } else {
        return {};
      }
    },
    quillInsertRefs() {
      let options = {};
      if (this.subjectQuillRef) {
        options.body = this.$refs.quillInstance.quill;

        if (!this.subjectQuillRef.disabled) {
          options.subject = this.subjectQuillRef.quill;
        }
      }
      return options;
    },
  },
  watch: {
    disabled(val) {
      this.afterInitDisabled = val
    },
  },
  methods: {
    // Todo:: rtl
    onAlignmentChange(event) {
      if (this.$refs.quillInstance) {
        const element = this.$refs.quillInstance.$el.getElementsByClassName("ql-editor")[0];

        if (event.target?.selectedIndex === 1 || event === 1) {
          element.classList.add("rtl");
        } else {
          element.classList.remove("rtl");
        }
      }
    },
    handleLink(range) {
      if (range?.constructor.name === "MouseEvent" || range?.constructor.name === "PointerEvent") {
        range = this.quill.getSelection();
      }

      this.$prompt("To what URL should this link go? The link text may be edited from the message editor.", "Edit Link", {
        confirmButtonText: "OK",
        inputPlaceholder: "Enter a URL",
        cancelButtonText: "Cancel",
      }).then(({ value }) => {
        if (!value) {
          return;
        }
        value = value.trim();
        if (!value.startsWith("http")) {
          value = `https://${value}`
        }
        if (range && range.length > 0) {
          this.quill.formatText(range.index, range.length, "link", value);
        } else if (range) {
          this.quill.insertText(range.index, value, "link", value);
        } else {
          const index = this.quill.getLength() - 1;
          this.quill.insertText(index, value, "link", value);
        }
      }).catch(() => {});
    },
    handleImage() {
      const range = this.quill.getSelection();

      this.$prompt("What is the image URL?", "Add Image", {
        confirmButtonText: "OK",
        inputPlaceholder: "https://",
        cancelButtonText: "Cancel",
      }).then(({ value }) => {
        if (!value) {
          return;
        }

        const index = range ? range.index : this.quill.getLength() - 1;
        this.quill.insertEmbed(index, "image", value, Quill.sources.USER);
      }).catch(() => {});
    },
    isVariableInBody(slug) {
      if (!this.value) {
        return false;
      }
      return this.value.includes(`{{${slug}}}`);
    },
    toggleHtmlContent() {
      this.showHtml = !this.showHtml;
    },
    emitBlur() {
      this.$emit("blur");
    },
    textChangeHandler(_delta, _oldDelta, source) {
      if (source !== "user") {
        return;
      }

      const currentContents = this.quill.getContents();
      const { ops } = _oldDelta.diff(currentContents);

      if (ops[1]?.attributes) {
        let { link } = ops[1].attributes;
        if (link && !link.startsWith("https://") && !link.startsWith("http://")) {
          link = `https://${link}`;
          this.quill.formatText(ops[0].retain, ops[1].retain, "link", link);
        }
      }

      // Gives editor a chance to replace variables with pills then makes it readonly
      this.afterInitDisabled = this.disabled;

      if (!this.initialTextSet) {
        // fixes initial 'UNDO' clearing all content after first load
        this.initialTextSet = true;
        this.quill.history.clear();
      }

      this.$emit("text-change");
    },
    editorHasFocus(quill) {
      this.$bus.$emit("editor-has-focus", { quill });
      // TODO: rtl
      // this.onAlignmentChange(this.$refs.qlAlignSelector.options.selectedIndex);
    },
    dragStartHandler({ target, dataTransfer }) {
      if (target && target.classList?.contains("token") && target.dataset.slug !== undefined) {
        const text = `{{${target.dataset.slug}}}`;
        const json = JSON.stringify({ title: target.dataset.title, slug: target.dataset.slug });
        dataTransfer.setData("text/plain", text);
        dataTransfer.setData("application/vnd.placeholder.token", json);
      }
    },
    dragEndHandler({ target }) {
      if (target && target.classList && target.classList.contains("token") && !target.classList.contains("toolbar-token")) {
        target.remove();
      }
    },
    assignQuillEditorDragStartEndListener() {
      document.addEventListener("dragstart", this.dragStartHandler);
      document.addEventListener("dragend", this.dragEndHandler);
    },
    onQuillTooltipClassChange(classAttrValue) {
      const classList = classAttrValue.split(" ");
      if (!classList.includes("ql-hidden")) {
        const quillTooltip = document.getElementsByClassName("ql-tooltip")[0];
        if (parseInt(quillTooltip.style.left.split("px")[0]) < 0) {
          quillTooltip.style.left = "0px";
        }
      }
    },
    assignQuillTooltipListener() {
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          this.$nextTick(() => {
            this.onQuillTooltipClassChange(newValue);
          });
        }
      });

      const quillTooltip = document.getElementsByClassName("ql-tooltip")[0];
      if (quillTooltip) {
        this.observer.observe(quillTooltip, {
          attributes: true,
          attributeOldValue: true,
          attributeFilter: ["class"],
        });
      }
    },
  },
  created() {
    if (!Quill.registered) {
      QuillInit.run();
    }
  },
  mounted() {
    this.wasMounted = true;
    this.$nextTick(() => {
      this.$emit("ref-assigned", { ref: this.$refs.quillInstance });
    });

    if (this.disabled) {
      return;
    }

    // Allows dragging pills that are already in the message text
    this.assignQuillEditorDragStartEndListener();
    this.assignQuillTooltipListener();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    document.removeEventListener("dragstart", this.dragStartHandler);
    document.removeEventListener("dragEndHandler", this.dragEndHandler);
  },
};
