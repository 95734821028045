const hasToken = (tokens, text) => {
  if (!text || !tokens) {
    return false;
  }

  return tokens.some(ft => text.toLowerCase().includes(ft.toLowerCase()) )
}

export const tokenHighlighted = ({ token, candidate }) => {
  if (!candidate.filterHighlights) return false;

  if (token.type === "role-current") {
    return hasToken(candidate.filterHighlights.currentTitleTokens, token.text);
  }
  if (token.type === "role-previous") {
    return hasToken(candidate.filterHighlights.previousTitleTokens, token.text);
  }
  if (token.type === "role") {
    const employment = token.employments[0];

    if (employment) {
      if (employment.index === 0) {
        return hasToken(candidate.filterHighlights.currentTitleTokens, employment.role);
      } else {
        return hasToken(candidate.filterHighlights.previousTitleTokens, employment.role);
      }
    }
  }

  if (token.type === "employer-current") {
    return hasToken(candidate.filterHighlights.currentCompanyTokens, token.text);
  }
  if (token.type === "employer-previous") {
    return hasToken(candidate.filterHighlights.previousCompanyTokens, token.text);
  }
  if (token.type === "employer") {
    const employment = token.employments[0];

    if (employment) {
      if (employment.index === 0) {
        return hasToken(candidate.filterHighlights.currentCompanyTokens, employment.name);
      } else {
        return hasToken(candidate.filterHighlights.previousCompanyTokens, employment.name);
      }
    }
  }

  if (token.type === "emp-industry") {
    return hasToken(candidate.filterHighlights.employmentCategories, token.text);
  }

  if (token.type === "edu-institution") {
    return hasToken(candidate.filterHighlights.educationInstitutionNameTokens, token.text);
  }

  if (token.type === "plain") {
    return hasToken(candidate.filterHighlights.queryTokens, token.text);
  }

  return false;
}
