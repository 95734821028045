import OutreachApi from "@/common/http/OutreachApi";
import { TOUCHPOINT_STATUSES } from "@/constants";
import { CHANNEL_BASE_OPTIONS } from "@/components/Filters/constants";

import DateTimeHelper from "@/common/DateTimeHelper";

const today = new Date();
const thirtyDaysAgo = DateTimeHelper.dateDaysAgoInThePast(30);
const todayString = DateTimeHelper.MMDDYYYYWithHyphens(today);
const thirtyDaysAgoString = DateTimeHelper.MMDDYYYYWithHyphens(thirtyDaysAgo);

const state = {
  generalError: null,
  priorDocumentTitle: null,
  documentTitle: null,
  preSearchRoute: null,
  isRecommendationTokensHidden: !!localStorage.getItem("recommendations-token-hidden"),
  globalSearchFiltersAlreadyFetching: false,
  globalSearchFiltersAssigned: false,
  globalSearchObjectFilterOptions: {
    activity: [],
    origin: [],
    status: TOUCHPOINT_STATUSES,
    team: [],
    employmentCategories: [],
    project: [],
    campaign: [],
    location: [],
    atsSource: [],
    stage: [],
    rejectionReason: [],
    tag: [],
    channel: CHANNEL_BASE_OPTIONS,
    owner: [],
    sourcer: [],
    reviewer: [],
    sender: [],
    employee: [],
    allTeamUsers: [],
    avgRecentTenure: [],
    earlyAtCompany: [],
    atsProspectPool: [],
  },
  globalSearchObjectFilters: {
    activity: [],
    campaign: [],
    channel: [],
    employee: [],
    origin: [],
    owner: [],
    project: [],
    reviewer: [],
    sender: [],
    sourcer: [],
    stage: [],
    status: [],
    tag: [],
    team: [],
    location: [],
    department: [],
    allTeamUsers: [],
    anyEmploymentFundingRounds: [],
    avgRecentTenure: [],
    currentEmploymentFundingRounds: [],
    earlyAtCompany: [],
    employmentCategories: [],
    atsSource: [],
    atsProspectPool: [],
    rejectionReason: [],
  },
  globalSearchStringFilters: {
    campaignStatus: "",
    companyMonthsMax: "",
    companyMonthsMin: "",
    currentCompany: "",
    employmentYearsMax: "",
    employmentYearsMin: "",
    intent: "",
    intentPercent: "",
    employmentKeywords: "",
    educationInstitutionName: "",
    currentTitle: "",
    previousTitle: "",
    previousCompany: "",
    name: "",
    projectStatus: "",
    query: "",
    timezoneDelta: "",
    timezoneOffset: "",
    datePeriod: "",
  },
  globalSearchBooleanFilters: {
    approved: null,
    connected: null,
    notContacted: null,
    emailMissing: null,
    mineOnly: null,
    featuredJob: null,
    requiresAttention: null,
    snoozed: null,
    stream: null,
    topTalent: null,
    waiting: null,
    women: null,
    womenViaQuery: null,
    hasEmail: null,
    topSchools: null,
    includeRecruiters: null,
    promotion: null,
    urg: null,
    militaryExperience: null,
  },
  globalSearchDateFilters: {
    activityEndAt: "",
    activityStartAt: "",
    endAt: "",
    repliedEndAt: "",
    repliedStartAt: "",
    sentEndAt: "",
    sentStartAt: "",
    startAt: "",
  },
  globalSearchQuery: {},
  cannedSearches: {
    candidates: {
      referralNetwork: { title: "Referral network", route: { name: "candidates", query: { cannedSearchId: "referralNetwork", connected: "true" } }, alwaysVisibleFilterIds: ["connected", "employee", "query", "topTalent"] },
      recentlySourced: { title: "Recently sourced", route: { name: "candidates", query: { startAt: thirtyDaysAgoString, endAt: todayString } } },
      recentlyResponded: { title: "Recently responded", route: { name: "candidates", query: { repliedStartAt: thirtyDaysAgoString, repliedEndAt: todayString } } },
      recentlyInterested: { title: "Recently interested", route: { name: "candidates", query: { status: "interested", activityStartAt: thirtyDaysAgoString, activityEndAt: todayString } } },
      noResponse: { title: "No response", route: { name: "candidates", query: { status: "reached", activity: ["no_reply", "chatbot_incomplete"] } } },
      emailMissing: { title: "Stopped - No email", route: { name: "candidates", query: { emailMissing: true, activity: "dead", status: "sourced" } } },
    },
  },
  isCandidateSidebarOpen: false,
  windowInnerWidth: null,
};

const filtersToQuery = (filters) => {
  const filtersQuery = {};
  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      filtersQuery[key] = value;
    }
  }
  return filtersQuery;
};

const getters = {
  generalError(state) {
    return state.generalError;
  },
  isCandidateSidebarOpen(state) {
    return state.isCandidateSidebarOpen;
  },
  preSearchRoute(state) {
    return state.preSearchRoute;
  },
  globalSearchFiltersAlreadyFetching(state) {
    return state.globalSearchFiltersAlreadyFetching;
  },
  globalSearchFiltersAssigned(state) {
    return state.globalSearchFiltersAssigned;
  },
  globalSearchObjectFilters(state) {
    return state.globalSearchObjectFilters;
  },
  globalSearchObjectFilterOptions(state) {
    return state.globalSearchObjectFilterOptions;
  },
  globalSearchStringFilters(state) {
    return state.globalSearchStringFilters;
  },
  globalSearchBooleanFilters(state) {
    return state.globalSearchBooleanFilters;
  },
  globalQueryTerm(state) {
    return state.globalSearchStringFilters.query;
  },
  globalSearchDateFilters(state) {
    return state.globalSearchDateFilters;
  },
  globalSearchQuery(state) {
    const objectFilterIds = {};
    for (let [key, ids] of Object.entries(state.globalSearchObjectFilters)) {
      if (ids.length) {
        objectFilterIds[`${key}Ids`] = ids;
      }
    }

    return {
      ...objectFilterIds,
      ...filtersToQuery(state.globalSearchStringFilters),
      ...filtersToQuery(state.globalSearchDateFilters),
      ...filtersToQuery(state.globalSearchBooleanFilters),
    };
  },
  windowInnerWidth(state) {
    return state.windowInnerWidth;
  },
  isRecommendationTokensHidden(state) {
    return state.isRecommendationTokensHidden;
  },
  cannedSearches(state) {
    return state.cannedSearches;
  },
};

const actions = {
  async fetchSearchFilterOptions({ state, commit }) {
    state.globalSearchFiltersAlreadyFetching = true;
    const { data } = await OutreachApi.searchFilters();
    commit("setGlobalSearchFilterOptions", data.filterOptions);
    commit("setGlobalSearchFiltersAssigned", true);
  },
};

const mutations = {
  setError(state, error) {
    state.generalError = error;
  },
  popDocumentTitle(state) {
    document.title = state.priorDocumentTitle || "Teamable";
  },
  setDocumentTitle(state, title) {
    state.priorDocumentTitle = document.title;
    state.documentTitle = title;
    document.title = title;
  },
  setPreSearchRoute(state, route) {
    state.preSearchRoute = route;
  },
  setGlobalSearchFiltersAssigned(state, value) {
    state.globalSearchFiltersAssigned = value;
  },
  setGlobalSearchFilterOptions(state, filters) {
    for (let [key, { options }] of Object.entries(filters)) {
      state.globalSearchObjectFilterOptions[key] = options;
    }
  },
  setWindowInnerWidth(state, value) {
    state.windowInnerWidth = value;
  },
  setIsRecommendationsHidden(state, value) {
    state.isRecommendationTokensHidden = value;
    if (value) {
      localStorage.setItem("recommendations-tokens-hidden", value);
    } else {
      localStorage.removeItem("recommendations-tokens-hidden");
    }
  },
  setIsCandidateSidebarOpen(state, value) {
    state.isCandidateSidebarOpen = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
