<script setup>
import { computed, ref, watch } from "vue";
// import { onMounted, ref } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRoot } from "@/composables/misc/useRoot";
import { useRoute } from "vue-router/composables";
import RouteContext from "@/common/RouteContext";

import CandidateConnectors from "@/components/CandidateConnectors/CandidateConnectors";
import CandidateFavoritedIndicator from "@/components/CandidateFavoritedIndicator/CandidateFavoritedIndicator";
import CandidateMenu from "@/components/CandidateMenu/Component";
import CandidateProfileLinks from "@/components/CandidateProfileLinks";
import CandidateTags from "@/components/CandidateTags/Component";
import StarRating from "@/components/StarRating/StarRating";
import UserAvatar from "@/components/UserAvatar";
import Location from "./Location";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  showSummary: {
    type: Boolean,
    default: false,
  },
  showArrowNavigation: {
    type: Boolean,
    default: true,
  },
});

const root = useRoot();
const route = useRoute();
const routeContext = ref(new RouteContext(route.name));
// const route = useRoute();
// const visibleModalKey = ref(null);

const {
  currentCandidate,
  currentCandidateList,
  currentCandidateListCount,
  isLoadingCurrentCandidate,
  isLoadingCurrentCandidateSummary,
  isReferralsEnabled,
} = useGetters([
  "currentCandidate",
  "currentCandidateList",
  "currentCandidateListCount",
  "isLoadingCurrentCandidate",
  "isLoadingCurrentCandidateSummary",
  "isReferralsEnabled",
]);

const showRecommendationsTokens = computed(() => {
  return !!currentCandidate.value.similarity?.analysis;
})

const computedShowArrowNavigation = computed(() => {
  return currentCandidateListCount.value && props.showArrowNavigation;
});

const candidateIndex = computed(() => {
  return currentCandidateList.value.findIndex(candidate => candidate.tfId === currentCandidate.value.tfId);
});

const isNextCandidateNavigationDisabled = computed(() => {
  return candidateIndex.value >= currentCandidateList.value.length - 1;
});

const isPreviousCandidateNavigationDisabled = computed(() => {
  return candidateIndex.value <= 0;
});

// const isAtsCandidate = computed(() => {
//   return currentCandidate.value.recentTouchpoint?.type === "AtsCandidate";
// });

const countRounder = computed(() => {
  if (routeContext.value.sourcingContext() && currentCandidateListCount.value > 5000) {
    return "5000+";
  }
  return currentCandidateListCount.value;
});

const topTalentTooltip = computed(() => {
  return `${currentCandidate.value.topTalentCount > 1 ? "Teammates" : "One teammate" } favorited this candidate on the Employee Portal`;
});

watch(() => props.visible, () => {
  if (props.visible) {
    window.addEventListener("keydown", keydownHandler);
  } else {
    window.removeEventListener("keydown", keydownHandler);
  }
}, { immediate: true });

watch(candidateIndex, (newIndex) => {
  // The 3 is to make sure it fetches more candidates in time and not show a disabled next arrow
  if (~newIndex && !!currentCandidateListCount.value && newIndex >= currentCandidateList.value.length - 3) {
    root.$bus.$emit("page-scroll-end");
  }
});

watch(route, (newRoute) => {
  routeContext.value = new RouteContext(newRoute.name);
}, { immediate: true });

function nextCandidate() {
  let nextIndex = candidateIndex.value + 1;
  if (nextIndex >= currentCandidateList.value.length) {
    nextIndex = 0;
  }

  root.$bus.$emit("view-candidate", { candidate: currentCandidateList.value[nextIndex] });
}

function previousCandidate() {
  let previousIndex = candidateIndex.value - 1;
  if (previousIndex < 0) {
    previousIndex = currentCandidateList.value.length - 1;
  }

  root.$bus.$emit("view-candidate", { candidate: currentCandidateList.value[previousIndex] });
}

function keydownHandler(event) {
  if (isLoadingCurrentCandidate.value || !!route.query.modal) return;

  if (event.key === "ArrowLeft" && !isPreviousCandidateNavigationDisabled.value) {
    previousCandidate();
  } else if (event.key === "ArrowRight" && !isNextCandidateNavigationDisabled.value) {
    nextCandidate();
  }
}

// onMounted(() => {
//   visibleModalKey.value = route.query.modal;
// });

</script>

<template>
<div id="main-info" class="d-flex flex-column align-items-center">
  <UserAvatar
    size="extra-large"
    :image-url="currentCandidate.avatarUrl"
    :full-name="currentCandidate.name || 'Name Unknown'"
    :high-intent="currentCandidate.highIntent"
    :is-top-talent="!!currentCandidate.topTalentCount"
    hide-name-label
    :watched="currentCandidate.watched"
    :top-talent-tooltip="topTalentTooltip"
    preview
  />

  <StarRating
    v-if="showRecommendationsTokens"
    class="mt-8"
    :candidate="currentCandidate"
    :loading="isLoadingCurrentCandidate"
    sidebar
  />

  <el-skeleton class="w-100" :loading="isLoadingCurrentCandidate" animated>
    <div slot="template" class="d-flex flex-column align-items-center mt-16 w-100">
      <el-skeleton-item class="w-70" style="height: 30px;" variant="text" />
    </div>

    <div class="d-flex flex-column align-items-center mt-16">
      <span class="candidate-name">{{ currentCandidate.name || 'Name Unknown' }}</span>
    </div>
  </el-skeleton>

  <Location
    class="d-flex justify-content-center w-100"
    :candidate="currentCandidate"
    :loading="isLoadingCurrentCandidate"
  />

  <CandidateProfileLinks
    class="d-flex justify-content-center align-items-start w-100 mt-16"
    :candidate="currentCandidate"
    :loading="isLoadingCurrentCandidate"
  />

  <div
    v-if="isReferralsEnabled && (currentCandidate.connectors?.length || currentCandidate.topTalentCount)"
    class="d-flex align-items-center mt-24"
  >
    <CandidateConnectors
      :connectors="currentCandidate.connectors"
      :max-connectors-to-display="2"
      :loading="isLoadingCurrentCandidate"
    />

    <el-divider
      v-if="isLoadingCurrentCandidate || currentCandidate.connectors?.length"
      direction="vertical"
    />

    <CandidateFavoritedIndicator
      v-if="currentCandidate.topTalentCount"
      :favorite-count="currentCandidate.topTalentCount"
      compact
    />
  </div>

  <el-skeleton
    class="w-100 mt-24"
    :loading="isLoadingCurrentCandidate"
    animated
  >
    <div slot="template" class="d-flex flex-column w-100">
      <el-skeleton-item class="w-100 mb-8" style="height: 32px !important;" variant="button" />
      <el-skeleton-item class="w-100" style="height: 32px !important;" variant="button" />
    </div>

    <CandidateMenu
      class="w-100"
      size="small"
      :candidate="currentCandidate"
    />
  </el-skeleton>

  <el-skeleton
    v-if="currentCandidate.profileId"
    class="w-100"
    :loading="isLoadingCurrentCandidate"
    animated
  >
    <div slot="template">
      <el-divider class="w-100 my-24" />

      <el-skeleton-item class="w-20 mr-8" style="height: 32px !important;" variant="button" />
      <el-skeleton-item class="w-40 mr-8" style="height: 32px !important;" variant="button" />
      <el-skeleton-item class="w-30" style="height: 32px !important;" variant="button" />
    </div>

    <el-divider class="w-100 my-24" />

    <CandidateTags
      class="w-100 mb-16"
      :candidate="currentCandidate"
    />
  </el-skeleton>

  <el-skeleton
    v-if="showSummary"
    class="w-100"
    :loading="isLoadingCurrentCandidateSummary || isLoadingCurrentCandidate"
    animated
  >
    <div slot="template">
      <el-divider class="w-100 my-24" />

      <el-skeleton-item class="w-75 mr-8" style="height: 32px !important;" variant="text" />
      <el-skeleton-item class="w-100 mr-8" style="height: 24px !important;" variant="text" />
      <el-skeleton-item class="w-100 mr-8" style="height: 24px !important;" variant="text" />
      <el-skeleton-item class="w-100 mr-8" style="height: 24px !important;" variant="text" />
    </div>

    <div v-if="currentCandidate.summary" class="summary">
      <el-divider class="w-100 my-24" />

      <div class="d-flex align-items-center mb-8">
        <span class="material-icons-round 24 mr-4">auto_awesome</span>
        <h3 class="m-0">Why {{ currentCandidate.name?.split(" ")?.at(0) }}?</h3>
      </div>

      <p>{{ currentCandidate.summary }}</p>
    </div>
  </el-skeleton>

  <el-skeleton
    class="w-100 mt-auto"
    :loading="showArrowNavigation && isLoadingCurrentCandidate"
    animated
  >
    <div slot="template" class="d-flex justify-content-around w-100">
      <el-skeleton-item style="width: 24px; height: 24px !important;" variant="button" />
      <el-skeleton-item class="w-30" style="height: 24px !important;" variant="text" />
      <el-skeleton-item style="width: 24px; height: 24px !important;" variant="button" />
    </div>

    <div v-if="computedShowArrowNavigation" class="d-flex justify-content-around w-100">
      <el-button
        type="primary"
        class="next-previous-candidate-button only-icon"
        icon="fas fa-chevron-left"
        :disabled="isPreviousCandidateNavigationDisabled"
        plain
        @click="previousCandidate"
      />

      <div class="d-flex justify-content-center align-items-center">
        {{ candidateIndex + 1 }} of {{ countRounder }}
      </div>

      <el-button
        type="primary"
        class="next-previous-candidate-button only-icon"
        icon="fas fa-chevron-right"
        :disabled="isNextCandidateNavigationDisabled"
        plain
        @click="nextCandidate"
      />
    </div>
  </el-skeleton>

  <!-- <div v-if="isAtsCandidate" class="ats-candidate-context">
    <div>
      <div class="label">Status</div>
      <div>
        {{ currentCandidate.recentTouchpoint.status }} ({{ currentCandidate.recentTouchpoint.activity }})
      </div>
    </div>
    <div>
      <div class="label">Stage</div>
      <div>
        {{ currentCandidate.recentTouchpointActivityDetail }}
      </div>
    </div>

    <div>
      <div class="label">Info</div>
      <div>
        last updated in {{ currentCandidate.recentTouchpoint.atsName }} {{ touchpointTimestamp }}
      </div>
    </div>
  </div> -->
</div>
</template>

<style scoped lang="scss">
#main-info {
  overflow: auto;
  background-color: $tf-app-background-color;
  border-top-left-radius: $tf-global-border-radius;
  border-bottom-left-radius: $tf-global-border-radius;

  ::v-deep .el-skeleton {
    height: 100%;
  }

  .next-previous-candidate-button {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-disabled {
      background-color: transparent;
      color: #C1C4CB;
    }
  }

  .candidate-name {
    text-align: center;
    font-size: 20px;
  }
  .summary {
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }
  }
}
</style>
