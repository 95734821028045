<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";
import { useRoute } from "vue-router/composables";

import CampaignsApi from "@/common/http/CampaignsApi";

import MessageEditorWrapper from "@/components/MessageGeneration/MessageEditorWrapper/MessageEditorWrapper";
import MessageGenerationButtons from "@/components/MessageGeneration/MessageGenerationButtons/MessageGenerationButtons";

import followUpMessageSubtitle from "./lib/followUpMessageSubtitle";
import followUpMessageTitle from "./lib/followUpMessageTitle";
import { SCHEDULED_FOLLOWUPS_AT } from "../../../constants/text";

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "remove",
]);

const {
  bulkSelectionAttributes,
  inAppSourcingMessage,
  isCampaignFollowUpGenerating,
  inAppSourcingSelectedCampaign,
  inAppSourcingSelectedProject,
  selectedCandidateInAppSourcing,
} = useGetters([
  "bulkSelectionAttributes",
  "inAppSourcingMessage",
  "isCampaignFollowUpGenerating",
  "inAppSourcingSelectedCampaign",
  "inAppSourcingSelectedProject",
  "selectedCandidateInAppSourcing",
]);
const {
  setInAppSourcingMessage,
  setIsCampaignFollowUpGenerating,
} = useMutations([
  "setInAppSourcingMessage",
  "setIsCampaignFollowUpGenerating",
]);

const route = useRoute();

const isTerminal = parseInt(props.index) === inAppSourcingMessage.value.followUps.length - 1;
const newFollowUpSaved = ref(false);
const isLoadingPreview = ref(false)
const editorKeyVariable = ref(0);

const isFollowUpPersonalized = computed(() => {
  return inAppSourcingMessage.value.followUps[props.index].isPersonalized;
});

function messageChangeHandler(val) {
  setIsCampaignFollowUpGenerating(false);
  setInAppSourcingMessage({
    ...inAppSourcingMessage.value,
    followUps: inAppSourcingMessage.value.followUps.map((item, index) => {
      if (index === props.index) {
        return {
          ...val,
          scheduledAt: inAppSourcingMessage.value.followUps[props.index].scheduledAt,
          isPersonalized: val.isPersonalized || item.isPersonalized,
          numDays: item.numDays,
          sendDays: item.sendDays,
          sendHour: item.sendHour,
        };
      }
      return item;
    }),
  })
}

async function stopGeneratingHandler() {
  inAppSourcingMessage.value.followUps[props.index].isGenerating = false;
}

async function generateNewHandler() {
  inAppSourcingMessage.value.followUps[props.index].isGenerating = true;
  setIsCampaignFollowUpGenerating(true);

  try {
    const { data } = await CampaignsApi.personalizeFollowUp({
      projectId: inAppSourcingSelectedCampaign.value.projectId,
      isTerminal,
      profileId: selectedCandidateInAppSourcing.value?.profileId,
      tfId: selectedCandidateInAppSourcing.value?.tfId,
      senderId: inAppSourcingMessage.value.senderId,
    });

    if (!isCampaignFollowUpGenerating.value) {
      return;
    }

    messageChangeHandler({ ...data.message, isPersonalized: data.isPersonalized });
    setIsCampaignFollowUpGenerating(false);
    ++editorKeyVariable.value;
  } catch (e) {
    stopGeneratingHandler();
  }
}

async function getPreviewContent() {
  if (!props.message.body || bulkSelectionAttributes.value || isFollowUpPersonalized.value !== undefined || props.messagingModeOnly) return;

  isLoadingPreview.value = true;

  const params = {
    message: props.message.body,
    senderId: inAppSourcingMessage.value?.senderId,
    campaignId: inAppSourcingSelectedCampaign.value?.id,
    projectId: route.params.projectId || inAppSourcingSelectedProject.value?.id,
    referrals: false,
    profileId: selectedCandidateInAppSourcing.value?.profileId,
    tfId: selectedCandidateInAppSourcing.value?.tfId,
    playbookId: inAppSourcingSelectedCampaign.value?.playbookId,
  };

  const { data } = await CampaignsApi.previewMessage(params);

  if (data.isPersonalized) {
    messageChangeHandler({
      subject: data.subject,
      body: data.message,
      isPersonalized: data.isPersonalized,
    });
    ++editorKeyVariable.value;
  } else {
    inAppSourcingMessage.value.followUps[props.index].isPersonalized = data.isPersonalized;
  }
  isLoadingPreview.value = false;
}

watch(inAppSourcingSelectedCampaign, (newValue) => {
  if (newValue) {
    getPreviewContent();
  }
});
onMounted(() => {
  getPreviewContent();
});

</script>

<template>
  <div class="card-container">
    <div class="d-flex justify-content-between">
      <div class="d-flex w-100 justify-content-between">
        <div class="mb-16">
          <h6>{{ followUpMessageTitle(index) }}</h6>
          <small>{{ followUpMessageSubtitle(index) }}</small>
        </div>

        <div>
          <MessageGenerationButtons
            :local-message-generating="inAppSourcingMessage.followUps[index].isGenerating"
            :generate-new-disabled="isCampaignFollowUpGenerating"
            @generate-new="generateNewHandler"
            @stop-generating="stopGeneratingHandler"
          />

          <el-button
            class="mt-4 my-0 w-100"
            size="small"
            type="secondary"
            :disabled="isCampaignFollowUpGenerating"
            @click.stop="emit('remove')"
          >
            <div class="d-flex align-items-center justify-content-center">
              <span class="material-icons-round mr-8">delete</span>
              <span class="candidate-count-label">Remove follow-up</span>
            </div>
          </el-button>
        </div>
      </div>
    </div>

    <MessageEditorWrapper
      v-if="message"
      :key="`${inAppSourcingSelectedCampaign.id}-${index}-${editorKeyVariable}`"
      type="followUps"
      :index="index"
      :show-variables="!isFollowUpPersonalized"
      :show-preview="!isFollowUpPersonalized"
      :loading="inAppSourcingMessage.followUps[index].isGenerating || isLoadingPreview"
      @new-followup-saved="newFollowUpSaved = true"
      @message-change="messageChangeHandler"
    />

    <div class="scheduled-at d-flex align-items-center justify-content-end">
      <span class="material-icons-round md-18 mr-4">calendar_month</span>
      {{message.scheduledAt ? `Scheduled at: ${message.scheduledAt}` : SCHEDULED_FOLLOWUPS_AT}}
    </div>
  </div>
</template>

<style scoped lang="scss">
h6 {
  font-size: 18px;
  color: #1C1C1C;
  margin: 0;
}
small {
  font-size: 12px;
}
.remove-button {
  color: $magenta-100;

  &:hover {
    border-color: $magenta-30;
    background-color: $magenta-10;
  }
}
::v-deep #message-editor .quillWrapper {
  max-height: none !important;
}
.scheduled-at {
  font-size: 14px;
  color: $black-90;
  margin: 8px 0;
  width: 100%;
  text-align: right;
}
</style>
