import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

import CrmTab from "@/components/CandidateSidebar/CrmTab/Component";
import HistoryTab from "@/components/CandidateSidebar/HistoryTab/Component";
import ProfileTab from "@/components/CandidateSidebar/ProfileTab/Component";
import StatusFunnel from "@/components/CandidateListItem/Detailed/StatusFunnel";

import CandidateSidebarLoader from "@/components/Loaders/CandidateSidebarLoader";
import MainInfo from "../MainInfo/MainInfo";
import RouteContext from "@/common/RouteContext";

export default {
  name: "CandidateSidebar",
  components: {
    CandidateSidebarLoader,
    HistoryTab,
    CrmTab,
    MainInfo,
    ProfileTab,
    StatusFunnel,
  },
  data() {
    return {
      activeTab: "profile",
      changeStatusTask: null,
      changeStatusIntroPack: null,
      routeContext: new RouteContext(this.$route.name),
      hideArrowNavigation: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentCandidate",
      "currentProject",
      "currentUser",
      "isCandidateSidebarOpen",
      "isCurrentCandidateFullyLoaded",
      "isLoadingCurrentCandidate",
      "isLoadingCurrentCandidateCrm",
      "isLoadingCurrentCandidateHistory",
      "isReferralsEnabled",
      "globalSearchQuery",
    ]),
    showSummary() {
      return !!this.currentProject.id;
    },
    allowCloseOnEscape() {
      return !this.$route.query.modal;
    },
    crmTabLabel() {
      if (this.isLoadingTab("CRM")) {
        return "CRM";
      }

      const allCount = _.concat(
        this.currentCandidate.reminders || [],
        this.currentCandidate.notes || []
      ).length;

      if (allCount) {
        return `CRM (${allCount})`;
      }
      return "CRM";
    },
    historyTabLabel() {
      if (this.isLoadingTab("History")) {
        return "History";
      }

      const allCount = _.concat(
        this.currentCandidate.tasks || [],
        this.currentCandidate.introPacks || [],
        this.currentCandidate.savedForLaters || [],
        this.currentCandidate.atsCandidates || []
      ).length;
      return `History (${allCount})`;
    },
  },
  watch: {
    "$route.name": {
      handler() {
        this.routeContext = new RouteContext(this.$route.name);
      },
    },
    "$route.query.profileId": {
      immediate: true,
      handler(profileId) {
        if (profileId) {
          this.handleCandidateRouteChange({ profileId });
        }
      },
    },
    "$route.query.tfId": {
      immediate: true,
      handler(tfId) {
        if (tfId) {
          this.handleCandidateRouteChange({ tfId });
        }
      },
    },
    "$route.query.tab": {
      immediate: true,
      handler(value) {
        if (value) {
          // backwards compatibility
          if (value === "outreach" || value === "intro") {
            const query = Object.assign({}, this.$route.query);
            query.tab = "history"
            this.$router.replace({ query });
            return;
          }
          this.activeTab = value;
        }
      },
    },
    "isCurrentCandidateFullyLoaded": {
      handler(value) {
        if (this.$route.query.tab || !value || !this.isCandidateSidebarOpen) {
          return;
        }

        this.setActiveTab({ name: "profile" });
      },
    },
    "currentCandidate.profileId": {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.setDocumentTitle(`${this.currentCandidate.name || "Name Unknown"} - Teamable candidate`);
          });
        } else {
          this.popDocumentTitle();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "fetchCurrentCandidate",
    ]),
    ...mapMutations([
      "clearCurrentCandidate",
      "popDocumentTitle",
      "setDocumentTitle",
      "setIsCandidateSidebarOpen",
    ]),
    isLoadingTab(tab) {
      return this.isLoadingCurrentCandidate || this[`isLoadingCurrentCandidate${tab}`];
    },
    sidebarOpened() {
      this.$refs.candidateSidebar.$el.focus();
      this.setIsCandidateSidebarOpen(true);
    },
    sidebarClosed() {
      this.clearCurrentCandidate();

      const query = Object.assign({}, this.$route.query);
      this.setIsCandidateSidebarOpen(false);
      delete query.profileId;
      delete query.tfId;
      delete query.tab;
      delete query.outreachId;
      delete query.referral;
      delete query.sflId;
      delete query.acId;

      this.activeTab = "profile";

      this.$router.push({ query });
    },
    setActiveTab({ name }) {
      this.activeTab = name;
      const query = Object.assign({}, this.$route.query);

      query.tab = name;
      this.$router.replace({ query });
    },
    async copySudoLinkForPage() {
      const url = `${process.env.VUE_APP_MAIN_APP_URL}/admin/users/${this.currentUser.id}/sudo?redirect=${encodeURIComponent(window.location.href)}`;
      await navigator.clipboard.writeText(url);
      this.$notify({
        position: "bottom-right",
        message: "Sudo link copied",
        title: "Success",
        type: "success",
      });
    },
    refreshCurrentCandidateHandler() {
      // There is no functionality to refresh a recommendation
      // so no need to check for tfId
      if (!this.currentCandidate.profileId) {
        return;
      }
      this.fetchCurrentCandidate({
        profileId: this.currentCandidate.profileId,
        showSummary: this.showSummary,
      }).then(() => {
        this.$bus.$emit("candidate-list-item-changed", this.currentCandidate);
      });
    },
    viewCandidateHandler({
      candidate,
      tfId = null,
      tab,
      outreachId,
      introPackId,
      replace,
      hideArrowNavigation = false,
    }) {

      tfId ||= candidate?.tfId;

      this.hideArrowNavigation = hideArrowNavigation;

      if (tab !== this.activeTab || tfId !== this.currentCandidate.tfId) {
        const query = Object.assign({}, this.$route.query);

        delete query.outreachId
        delete query.referral

        if (candidate?.profileId) {
          query.profileId = candidate.profileId;
        }

        query.tfId = tfId;
        query.tab = tab;

        if (introPackId) {
          query.referral = introPackId
        } else if (outreachId) {
          query.outreachId = outreachId;
        }

        const isSameCandidate = this.currentCandidate.tfId === tfId;
        if (isSameCandidate || replace) {
          this.$router.replace({ query }); // only tab changed
        } else {
          this.$nextTick(() => this.$router.push({ query }));
        }
      }
    },
    handleCandidateRouteChange({ profileId = null, tfId = null }) {
      if (profileId || tfId) {
        this.sidebarOpened();

        if ((this.currentCandidate.profileId || this.currentCandidate.tfId) !== (profileId || tfId)) {
          if (this.isLoadingCurrentCandidate) {
            return;
          }

          const projectId = this.currentProject.id || this.$route.params?.projectId || this.$route.query?.projectId;

          this.clearCurrentCandidate();
          this.fetchCurrentCandidate({
            profileId,
            projectId,
            tfId,
            accordWizardUuid: this.$route.params.uuid,
            showSummary: this.showSummary,
          });
        }
      } else {
        this.sidebarClosed();
      }
    },
  },
  created() {
    this.$bus.$on("refresh-current-candidate", this.refreshCurrentCandidateHandler);
    this.$bus.$on("close-sidebar", this.sidebarClosed);
    this.$bus.$on("view-candidate", this.viewCandidateHandler);
  },
  beforeDestroy() {
    this.$bus.$off("refresh-current-candidate", this.refreshCurrentCandidateHandler);
    this.$bus.$off("close-sidebar", this.sidebarClosed);
    this.$bus.$off("view-candidate", this.viewCandidateHandler);
  },
};
