<script setup>
import { ref, computed } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { GREENHOUSE, REVIEW_CONTEXT } from "@/constants";

const emit = defineEmits(["rejected", "update:visible"]);

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const {
  currentUser,
  currentReviewContext,
  currentUserSettings,
} = useGetters([
  "currentUser",
  "currentReviewContext",
  "currentUserSettings",
]);

const selectedReason = ref(null);

const isAtsGreenhouse = computed(() => {
  return currentUser.value.primaryTeamAtsName === GREENHOUSE.label;
});

const isApplicantsContext = computed(() => {
  return currentReviewContext.value?.reviewType === REVIEW_CONTEXT.applicants;
});

const showSkipOption = computed(() => {
  return !isApplicantsContext.value || (isApplicantsContext.value && isAtsGreenhouse.value);
});

const innerVisible = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

const reject = () => {
  emit("rejected", selectedReason.value);
}
</script>

<template>
  <el-dialog
    id="reject-reason-modal"
    title="Reject reason"
    :visible.sync="innerVisible"
    :show-close="false"
    append-to-body
  >
    <div class="w-100 modal-container">
      <p class="mb-8">Start typing a reason and then select from the selections below</p>
      <el-select
        v-model="selectedReason"
        filterable
        placeholder="Enter reject reason"
        loading-text="Loading stages"
        class="w-100"
      >
        <el-option
          v-for="item in currentUserSettings.atsRejectReasons"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>

    <!-- Footer -->
    <span id="footer" slot="footer" class="d-flex">
      <div class="w-100 d-flex justify-content-between mr-24">
        <el-button type="text" class="mr-16" @click="innerVisible = false">
          Cancel
        </el-button>

        <el-button v-if="showSkipOption" type="text" @click="reject">
          Skip and reject
        </el-button>
      </div>

      <el-button type="primary" :disabled="!selectedReason" @click="reject">
        Reject
      </el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">
  .modal-container {
    cursor: default;
    word-wrap: normal;
    word-break: normal;
    h4 {
      color: $tf-font-ghost;
    }
    p {
      color: $tf-font-default;
    }
  }
</style>
