import UserAvatar from "@/components/UserAvatar";
import { useGetters } from "vuex-composition-helpers"

export default {
  name: "SplashContainer",
  components: {
    UserAvatar,
  },
  props: {
    showAvatar: Boolean,
    hideBackgroundOnMd: Boolean,
  },
  setup(props) {
    const {
      currentUser,
    } = useGetters(["currentUser"]);

    const backgroundClass = [
      "background",
      props.hideBackgroundOnMd ? "hide-background-on-md" : undefined,
    ];

    return {
      currentUser,
      backgroundClass,
    }
  },
};
