<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex justify-content-center">
      <el-skeleton-item class="loading-profile-link mr-8" variant="circle" />
      <el-skeleton-item class="loading-profile-link mr-8" variant="circle" />
      <el-skeleton-item class="loading-profile-link mr-8" variant="circle" />
      <el-skeleton-item class="loading-profile-link" variant="circle" />
    </div>

    <ul class="profile-links">
      <div v-if="candidate.resumeFileUrl" class="clickable">
        <el-tooltip :content="`${candidate.name} resume`" :open-delay="250">
          <a class="resume-icon-container" :href="candidate.resumeFileUrl" target="_blank">
            <img class="resume-icon" :src="require('@/assets/images/candidate-card/cv.svg')" />
          </a>
        </el-tooltip>
      </div>

      <ProfileEmail
        v-if="showEmailCopy"
        class="mr-8"
        :email="candidate.email"
      />

      <ProfilePhone
        v-if="candidate.phone"
        class="mr-8"
        :phone="candidate.phone"
      />

      <li v-for="link in allowedLinks" :key="link.value">
        <a
          :href="link.value"
          :class="link.type"
          class="profile-link"
          target="_blank"
          rel="noreferrer"
        >
          <el-tooltip
            v-if="link.type === 'edit'"
            class="d-flex"
            content="Edit"
            :open-delay="500"
          >
            <i class="fas fa-pen-square"></i>
          </el-tooltip>

          <el-tooltip
            v-else
            class="d-flex"
            :content="link.title"
            :open-delay="500"
          >
            <span>
              <img :src="require(`@/assets/images/candidate-card/${linkImageName(link)}`)" />
            </span>
          </el-tooltip>
        </a>
      </li>
    </ul>
  </el-skeleton>
</template>

<script>
import { mapGetters } from "vuex";

import ProfileEmail from "@/components/ProfileEmail";
import ProfilePhone from "@/components/ProfilePhone";

export default {
  name: "CandidateProfileLinks",
  components: {
    ProfileEmail,
    ProfilePhone,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    skip: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      justCopied: {
        phone: false,
        email: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentTeam",
    ]),
    showEmailCopy() {
      return this.candidate.email && !this.candidate.email.startsWith("––@");
    },
    allowedLinks() {
      if (!this.candidate.urls) {
        return [];
      }

      return this.candidate.urls.filter(l => {
        return !this.skip.includes(l.type);
      });
    },
  },
  methods: {
    linkImageName(link) {
      switch (link.type) {
        case "topfunnel":
          return "teamable.svg";
        case "greenhouse":
        case "greenhouse_web":
          return "greenhouse.svg";
        case "lever":
          return "lever.svg";
        case "smart_recruiters":
          return "smartrecruiters.svg";
        case "career_builder":
          return "career-builder.svg";
        case "gr8_people":
          return "gr8-people.svg";
        case "mailbox":
          return "gmail.svg";
        case "google":
          return "google.svg";
        case "github":
          return "github.svg";
        case "linkedin":
          return "linkedin.svg";
        case "ashby":
          return "ashby.svg";
        case "bamboohr":
          return "bamboohr.svg";
        case "chat":
          return "chat.svg";
        case "fountain":
          return "fountain.svg";
        case "icims":
          return "icims.svg";
        case "jazzhr":
          return "jazzhr.svg";
        case "jobvite":
          return "jobvite.svg";
        case "oracle":
          return "oracle.svg";
        case "sap":
          return "sap.svg";
        case "taleo":
          return "taleo.svg";
        case "workable":
          return "workable.svg";
        case "workday":
          return "workday.svg";
        default:
          return "custom.svg";
      }
    },
    copyTextToClipboard(text, type) {
      this.justCopied[type] = true;
      navigator.clipboard.writeText(text).then(() => {
        setTimeout(() => this.justCopied[type] = false, 3000);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.loading-profile-link {
  width: 24px;
  height: 24px;
}

.profile-links {
  margin-bottom: 0;
  padding: 0;
  display: flex;
  font-size: $tf-icon-size;
  transition: $tf-global-transition;
  cursor: pointer;
  list-style-type: none;

  .resume-icon-container {
    height: 24px; // Same as the rest of the icons

    .resume-icon {
      height: 36px; // The image needs to be bigger
      border-radius: 0;
    }
  }

  li {
    float: left;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    height: 24px;
    border-radius: 100%;
  }

  a {
    display: flex;
    color: gray;
    transition: $tf-global-transition;
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
