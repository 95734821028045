<template>
  <div id="loader">
    <content-loader
      height="600"
      width="400"
      :speed="2"
      primary-color="#F4E3CB"
      secondary-color="#FAF1E6"
    >
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
      <rect x="20" y="120" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="140" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="160" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="180" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="200" rx="3" ry="3" width="80" height="10" />

      <rect x="20" y="220" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="240" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="260" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="280" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="300" rx="3" ry="3" width="80" height="10" />

      <rect x="20" y="320" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="340" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="360" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="380" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="400" rx="3" ry="3" width="80" height="10" />

      <rect x="20" y="420" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="440" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="460" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="480" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="500" rx="3" ry="3" width="80" height="10" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  name: "CandidateSidebarLoader",
  components: {
    ContentLoader,
  },
};
</script>

<style lang="scss" scoped>
#loader {
}
</style>
