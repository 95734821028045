import ApiClient from "@/common/http/ApiClient";

class AtsApi {
  static allStages() {
    return ApiClient.get("settings/stages");
  }

  static stagesForJob(id) {
    if (id) {
      return ApiClient.get(`ats_jobs/${id}/ats_stages`);
    } else {
      return { data: { atsStages: [] } };
    }
  }

  static selectedStages() {
    return ApiClient.get("selected_ats_stages");
  }

  static updateSelectedStages(updatedSelectedStages = []) {
    return ApiClient.put("selected_ats_stages/update_all", {
      orderedStages: updatedSelectedStages,
    });
  }
}

export default AtsApi;
