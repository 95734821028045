<script setup>
import { computed, useSlots } from "vue";

const props = defineProps({
  atsName: {
    type: String,
    required: true,
  },
  problem: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const imageSrc = computed(() => {
  if (props.atsName) {
    let atsNameLowerCased = props.atsName.toLowerCase().replace(/ /g, "-");
    if (props.problem) {
      atsNameLowerCased += "-problem";
    }

    try {
      const images = require.context("@/assets/images/vendor-logos", false, /\.svg$/);
      return images("./" + atsNameLowerCased + ".svg");
    } catch (error) {
      return null;
    }
  }

  return null;
});

</script>

<template>
  <div>
    <img class="w-100" :src="imageSrc" :alt="atsName" />

    <el-divider v-if="slots.description" class="my-24" />

    <slot name="description"></slot>
  </div>
</template>
