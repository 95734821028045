import CandidateListItemBase from "@/components/CandidateListItem/CandidateListItemBase";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "CandidateListItemSearchPopover",
  extends: CandidateListItemBase,
  components: {
    UserAvatar,
  },
  methods: {
    jobDescription(job) {
      let jobStr =  [job.role, job.name].filter(j => !!j).join(", ");
      if (job.computedTenureStr) {
        jobStr = `${jobStr} - ${job.computedTenureStr}`;
      }
      return jobStr;
    },
  },
};
