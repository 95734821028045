<template>
  <div>
    <span class="empty-toolbar-to-fix-quill-smart-paste-bug" />
    <vue-editor
      v-model="inputVal"
      placeholder="Add a note. Use @mentions to notify a teammate."
      :editor-options="editorSettings"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor, Quill } from "vue2-editor";
import "quill-mention";
import "quill-mention/dist/quill.mention.min.css";
import Formatter from "@/common/quill/Formatter";

export default {
  name: "MentionableEditor",
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    const modules = {
      toolbar: { container: ".empty-toolbar-to-fix-quill-smart-paste-bug" },
      mention: {
        renderItem: (item) => {
          // TODO: maybe render user avatar
          return `${item.value} (${item.label})`;
        },
        source: (searchTerm, renderList) => {
          const values = this.mentionsTeammates;

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              const handleMatch = values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase());
              const nameMatch = values[i].label.toLowerCase().indexOf(searchTerm.toLowerCase());
              if (~handleMatch || ~nameMatch) {
                matches.push(values[i]);
              }
            }
            renderList(matches, searchTerm);
          }
        },
      },
    };

    return {
      editorSettings: {
        modules,
      },
    };
  },
  computed: {
    ...mapGetters([
      "mentionsTeammates",
    ]),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", Formatter.autocorrectIssues(val));
      },
    },
  },
  created() {
    const Block = Quill.import("blots/block");
    Block.tagName = "SPAN";
    Quill.register(Block, true);
  },
};
</script>

<style lang="scss" scoped>

::v-deep .ql-editor {
  min-height: 75px;
  font-size: $tf-font-size-default;
}

::v-deep .ql-container {
  border-radius: $tf-global-border-radius;
  color: $tf-font-dark;
}

::v-deep .ql-mention-list-container {
  background-color: $tf-color-background;
  border-radius: $tf-global-border-radius;
  box-shadow: $tf-global-box-shadow-hover;
  cursor: pointer;
  width: 300px;
  color: $tf-font-dark;
}

.empty-toolbar-to-fix-quill-smart-paste-bug {
  display: none;
}

::v-deep .ql-mention-list {
  max-height: 200px;
  overflow: auto;
}

::v-deep .ql-mention-list-item {
  padding: 5px 20px;
  line-height: 1.5rem;

  &.selected {
    background-color: $tf-select-option-item-background-color-hover;
  }
}

::v-deep .mention {
  color: $blue-100;
}
</style>
