<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useActions, useGetters, useMutations } from "vuex-composition-helpers";

import logEvent from "@/common/Analytics";
import RouteContext from "@/common/RouteContext";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SourcingFromJobPage from "./SourcingFromJobPage";
import SourcingOutOfJobPage from "./SourcingOutOfJobPage";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  messagingModeOnly: {
    type: Boolean,
    default: false,
  },
  switchJobs: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:visible"]);

const route = useRoute();
const isLoading = ref(false);
const routeContext = ref(new RouteContext(route.name));

const {
  currentProject,
  candidateDedupeMessage,
  selectedCandidateInAppSourcing,
  bulkSelectionAttributes,
  inAppSourcingSelectedCampaign,
} = useGetters([
  "currentProject",
  "candidateDedupeMessage",
  "selectedCandidateInAppSourcing",
  "bulkSelectionAttributes",
  "inAppSourcingSelectedCampaign",
]);

const {
  setCandidateDedupeMessage,
  setSelectedCandidateInAppSourcing,
  setCandidateConnectors,
} = useMutations([
  "setCandidateDedupeMessage",
  "setSelectedCandidateInAppSourcing",
  "setCandidateConnectors",
]);

const {
  fetchCandidateConnectors,
  fetchCandidateDedupeMessage,
  resetInAppSourcingSelectedProject,
  resetInAppSourcingMessageFlags,
} = useActions([
  "fetchCandidateConnectors",
  "fetchCandidateDedupeMessage",
  "resetInAppSourcingSelectedProject",
  "resetInAppSourcingMessageFlags",
]);

const innerVisible = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

const showDupeSwitch = computed(() => {
  return (
    bulkSelectionAttributes.value &&
    !selectedCandidateInAppSourcing.value &&
    inAppSourcingSelectedCampaign.value?.id !== "save" &&
    !routeContext.value.reviewContext()
  );
});

function onSidebarClosed() {
  resetInAppSourcingMessageFlags();
  resetInAppSourcingSelectedProject();
  setSelectedCandidateInAppSourcing(null);
  setCandidateDedupeMessage(null);
  setCandidateConnectors([]);
  innerVisible.value = false;
}

function onSidebarOpened() {
  logEvent("candidate-action-intent", { action: "add-to-campaign" });

  if (selectedCandidateInAppSourcing.value) {
    fetchCandidateDedupeMessage({
      profileId: selectedCandidateInAppSourcing.value.profileId,
      tfId: selectedCandidateInAppSourcing.value.tfId,
      projectId: route.params.projectId || route.query.projectId,
    });

    if (selectedCandidateInAppSourcing.value.profileId) {
      fetchCandidateConnectors({ profileId: selectedCandidateInAppSourcing.value.profileId });
    }
  }
}
</script>

<template>
  <el-drawer
    v-if="selectedCandidateInAppSourcing || bulkSelectionAttributes || messagingModeOnly"
    id="in-app-sourcing-sidebar"
    ref="inAppSourcingSidebar"
    append-to-body
    size="75%"
    :visible.sync="innerVisible"
    :with-header="false"
    @opened="onSidebarOpened"
    @closed="onSidebarClosed"
  >
    <el-button
      id="close-sidebar-button"
      class="only-icon"
      icon="fas fa-times"
      circle
      @click="onSidebarClosed"
    />

    <div
      class="content-wrapper h-100 d-flex flex-column"
      :class="{
        'with-dedupe-switch': showDupeSwitch,
        'with-dedupe-alert': candidateDedupeMessage,
      }"
    >
      <Header
        :loading="isLoading"
      />

      <SourcingFromJobPage
        v-if="currentProject.id"
        :loading="isLoading"
        :messaging-mode-only="messagingModeOnly"
        :switch-jobs="switchJobs"
      />

      <SourcingOutOfJobPage
        v-else
        :loading="isLoading"
      />
    </div>

    <slot name="footer">
      <Footer
        @close="onSidebarClosed"
      />
    </slot>
  </el-drawer>
</template>

<style lang="scss">
#in-app-sourcing-sidebar {
  ::v-deep .el-drawer {
    ::v-deep .el-drawer__body {
      overflow: hidden;
    }
  }

  .content-wrapper {
    padding: 24px 24px 0 24px;
    height: calc(100% - 90px) !important;
    overflow: auto;

    &.with-dedupe-switch {
      height: calc(100% - 120px) !important;
    }

    &.with-dedupe-alert {
      height: calc(100% - 135px) !important;
    }
  }

  #close-sidebar-button {
    width: 42px;
    position: absolute;
    left: -58px;
    top: $tf-app-content-padding;
  }
}

</style>
