import CampaignsApi from "@/common/http/CampaignsApi";

const state = {
  inAppSourcingSelectedProject: null,
  inAppSourcingSelectedCampaign: null,
  inAppSourcingMessage: null,
  inAppSourcingGeneratedCampaign: null,
  isGeneratingNewCampaignForInAppSourcing: false,
  inAppSourcingGeneratedCampaignFailed: false,
  selectedCandidateInAppSourcing: null,
};

const getters = {
  inAppSourcingSelectedProject(state) {
    return state.inAppSourcingSelectedProject;
  },
  inAppSourcingSelectedCampaign(state) {
    return state.inAppSourcingSelectedCampaign;
  },
  inAppSourcingMessage(state) {
    return state.inAppSourcingMessage;
  },
  inAppSourcingGeneratedCampaign(state) {
    return state.inAppSourcingGeneratedCampaign;
  },
  isGeneratingNewCampaignForInAppSourcing(state) {
    return state.isGeneratingNewCampaignForInAppSourcing;
  },
  inAppSourcingGeneratedCampaignFailed(state) {
    return state.inAppSourcingGeneratedCampaignFailed;
  },
  selectedCandidateInAppSourcing(state) {
    return state.selectedCandidateInAppSourcing;
  },
};

const actions = {
  async generateNewCampaignForInAppSourcing({ state, getters, commit }) {
    // stop campaign generation
    if (state.isGeneratingNewCampaignForInAppSourcing) {
      commit("setIsGeneratingNewCampaignForInAppSourcing", false);
      await CampaignsApi.personalizeNew({ cancelRequest: true });
      return;
    }
    commit("setIsGeneratingNewCampaignForInAppSourcing", true);

    // generating new campaign
    commit("setInAppSourcingGeneratedCampaignFailed", false);

    try {
      const params = {
        projectId: state.inAppSourcingSelectedProject.id,
        senderId: getters.currentUser.id,
        tfId: state.selectedCandidateInAppSourcing?.tfId,
        profileId: state.selectedCandidateInAppSourcing?.profileId,
      };
      const { data } = await CampaignsApi.personalizeNew(params);

      if (data) {
        const { campaign } = data;
        commit("setInAppSourcingGeneratedCampaign", campaign);
        commit("setInAppSourcingSelectedCampaign", campaign);
        commit("setIsGeneratingNewCampaignForInAppSourcing", false);
        commit("setInAppSourcingMessage", {
          ...campaign,
          yourReplyNegative: campaign.autoReplyNegative,
          yourReplyPositive: campaign.autoReplyPositive,
          yourReplyOoo: campaign.autoReplyOoo,
        });
      }
    } catch (e) {
      if (e.status !== undefined) {
        commit("setInAppSourcingGeneratedCampaignFailed", true);
      }
    }
  },
  resetInAppSourcingSelectedProject({ commit }, project) {
    commit("setInAppSourcingMessage", null);
    commit("setInAppSourcingSelectedCampaign", null);
    commit("setInAppSourcingGeneratedCampaign", null);
    commit("setInAppSourcingSelectedProject", project);
    commit("setIsGeneratingNewCampaignForInAppSourcing", false);
  },
};

const mutations = {
  setInAppSourcingSelectedProject(state, value) {
    state.inAppSourcingSelectedProject = value;
  },
  setInAppSourcingSelectedCampaign(state, value) {
    state.inAppSourcingSelectedCampaign = value;
  },
  setInAppSourcingMessage(state, value) {
    state.inAppSourcingMessage = value;
  },
  setInAppSourcingGeneratedCampaign(state, value) {
    state.inAppSourcingGeneratedCampaign = value;
  },
  setIsGeneratingNewCampaignForInAppSourcing(state, value) {
    state.isGeneratingNewCampaignForInAppSourcing = value;
  },
  setInAppSourcingGeneratedCampaignFailed(state, value) {
    state.inAppSourcingGeneratedCampaignFailed = value;
  },
  setSelectedCandidateInAppSourcing(state, value) {
    state.selectedCandidateInAppSourcing = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
