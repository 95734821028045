import { mapGetters } from "vuex";

import TouchpointsApi from "@/common/http/TouchpointsApi";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "CandidateChangeActivityStatusModal",
  components: {
    UserAvatar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    currentTask: {
      type: Object,
      default: null,
    },
    currentIntroPack: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activityStatus: null,
      connectorId: null,
      isSaving: false,
      statusOptions: [],
      connectorOptions: [],
      intro: {},
      chooseConnectorStatuses: ["hired", "offer", "interviewing"],
    };
  },
  computed: {
    ...mapGetters([
      "bulkSelectionAttributes",
    ]),
    innerVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit("update:visible", isVisible);
      },
    },
    title() {
      let suffix = "";
      if (this.candidate) {
        suffix = `for ${this.candidate.name} from '${this.initialActivityStatusName}'`;
      } else if (this.bulkSelectionAttributes) {
        const candidateWord = this.bulkSelectionAttributes.count === 1 ? "candidate" : "candidates";
        suffix = `for ${this.bulkSelectionAttributes.count} ${candidateWord}`;
      }

      if (this.currentIntroPack) {
        return `Change intro request status ${suffix}`;
      }

      return `Change outreach status ${suffix}`;
    },
    rules() {
      return {
        connectorId: [
          { required: this.showConnectors, message: "Please choose the referrer" },
        ],
      };
    },
    showConnectors() {
      if (!this.currentIntroPack || this.connectorOptions.length < 2) {
        return false;
      }
      return this.chooseConnectorStatuses.includes(this.activityStatus?.value);
    },
    touchpoint() {
      if (this.currentTask) {
        return {
          id: this.currentTask.id,
          type: "Task",
          activity: this.currentTask.activity.name,
          wasOverridden: this.currentTask.activity.wasOverridden,
        }
      } else if (this.currentIntroPack) {
        return {
          id: this.currentIntroPack.id,
          type: "IntroPack",
          activity: this.currentIntroPack.activityDisplay,
          wasOverridden: false,
        }
      }

      return this.candidate?.recentTouchpoint || {};
    },
    initialActivityStatusChanged() {
      return this.touchpoint.wasOverridden;
    },
    initialActivityStatusName() {
      return this.touchpoint.activity;
    },
    currentActivityStatusMessage() {
      if (this.initialActivityStatusChanged || !this.activityStatus) {
        return "";
      }

      const stoppable = ["client_approval", "created", "awaiting_next_sendable_day", "awaiting_reply"];

      if (stoppable.includes(this.touchpoint.activityKey)) {
        return "Outreach automation will be stopped.";
      }

      return ""
    },
    resetToOriginalActivityStatusMessage() {
      if (!this.currentActivityStatusMessage) {
        return "";
      }

      return "This action is not fully reversible. If status is reset at a future time, outreach automation will not be restored.";
    },
    activityStatusOptions() {
      let options = this.statusOptions.filter(o => this.initialActivityStatusName !== o.text);

      if (this.initialActivityStatusChanged) {
        options.push({
          value: "reset",
          text: "** Reset to original activity status",
        });
      }

      return options;
    },
  },
  methods: {
    async onModalOpen() {
      const { data } = await TouchpointsApi.manualStatusOptions({
        id: this.touchpoint.id,
        type: this.touchpoint.type,
      })

      this.statusOptions = data.statusOptions;
      this.connectorOptions = data.connectorOptions;
    },
    onModalClosed() {
      this.activityStatus = null;
    },
    validateAndSave() {
      this.$refs.form?.validate(valid => {
        if (!valid) {
          return false;
        }
      });

      this.save();
    },
    async saveCandidateStatus() {
      const { data } = await TouchpointsApi.overrideStatus({
        id: this.touchpoint.id,
        type: this.touchpoint.type,
        status: this.activityStatus.value,
        connectorId: this.intro.connectorId,
      });
      this.candidate.recentTouchpoint = data.recentTouchpoint;

      this.$bus.$emit("refresh-current-candidate");

      const typeLabel = this.touchpoint.type === "Task" ? "Outreach" : "Intro request"
      let message = `${typeLabel} status changed to ${this.activityStatus.text}.`;
      if (this.activityStatus.value === "reset") {
        message = `${typeLabel} status has been reset.`;
      }
      this.$notify({
        title: "Success",
        position: "bottom-right",
        message,
        type: "success",
      });
    },
    async saveBulkStatus() {
      await TouchpointsApi.overrideStatus({
        bulkSelectionAttributes: this.bulkSelectionAttributes,
        status: this.activityStatus.value,
      });

      // TODO: Add error handling

      this.$notify({
        title: "Success",
        position: "bottom-right",
        message: "Please allow us a few moments to perform this action",
        type: "success",
      });
    },
    async save() {
      this.isSaving = true;

      if (this.touchpoint.id) {
        await this.saveCandidateStatus();
      } else {
        await this.saveBulkStatus();
      }

      this.isSaving = false;
      this.innerVisible = false;
    },
  },
};
