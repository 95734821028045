import { parseParts } from "./parseParts";

function recommendationsFeedback({ feedbacks, text, tokenType, tokenTypes }) {
  const tokenTypeAndTextMatch = feedbacks.find(rf => rf.fullTokenType === tokenType && rf.tokenText.toLowerCase() === text.toLowerCase());
  const tokenTypeSecondaryAndTextMatch = feedbacks.find(rf => tokenTypes.includes(rf.fullTokenType) && rf.tokenText.toLowerCase() === text.toLowerCase());
  const feedbackToken = tokenTypeAndTextMatch || tokenTypeSecondaryAndTextMatch;

  if (feedbackToken) {
    // We need to return a cloned version since we changing the object properties
    // If we don't clone we are going to trigger an infinite render loop because all
    // of these objects are passed via reference and Vue is watching object changes deep
    const clonedFeedbackToken = Object.assign({}, feedbackToken);
    clonedFeedbackToken.tokenText = text;
    return clonedFeedbackToken;
  }

  return feedbackToken;
}

export function splitBlocks({
  tokenType,
  tokenTypes,
  tokenText,
  displayText,
  similarityTokens = [],
  filterTokens,
  recommendationFeedbacks = [],
}) {
  // If there is no tokenText return [] array
  if (!tokenText) {
    return [];
  }

  const similarityTokensFiltered = similarityTokens?.filter(st => !!st) || [];
  const safeRecommendationFeedbacks = recommendationFeedbacks || [];
  const safeFilterTokens = filterTokens || [];

  // If there are no tokens just return an array with a single object with the text
  if (!similarityTokensFiltered.length && !safeRecommendationFeedbacks.length && !safeFilterTokens.length) {
    return [{ text: tokenText }];
  }

  // If there is a displayText then we return an array with a single object
  if (displayText) {
    const similarityToken = similarityTokensFiltered.find(token => token.text === tokenText && token.type === tokenType);
    const feedback = recommendationsFeedback({
      feedbacks: safeRecommendationFeedbacks,
      tokenType,
      tokenTypes,
      text: tokenText,
    });

    return [{
      text: displayText,
      similarityToken,
      recommendationsFeedback: feedback,
    }];
  }

  const feedbackTokenTexts = safeRecommendationFeedbacks.map(rf => rf.tokenText);
  const similarityTokenTexts = similarityTokensFiltered.map(token => token.text);

  const tokenTypeAndTextMatch = safeRecommendationFeedbacks.find(rf => rf.fullTokenType === tokenType && tokenText.toLowerCase().includes(rf.tokenText.toLowerCase()));
  const tokenTypeSecondaryAndTextMatch = safeRecommendationFeedbacks.find(rf => tokenTypes.includes(rf.fullTokenType) && tokenText.toLowerCase().includes(rf.tokenText.toLowerCase()));
  const tokenTextRecommendationFeedback = tokenTypeAndTextMatch || tokenTypeSecondaryAndTextMatch;

  // If it's only 1 similarity token (not description field) and we find in text
  // partial matching recommendation token (example: Google in "Google Students",
  // do not split by similarity tokens, because we will not show it
  let allTokenTexts;
  if (similarityTokensFiltered.length === 1 && tokenTextRecommendationFeedback) {
    allTokenTexts = feedbackTokenTexts;
  } else {
    allTokenTexts = similarityTokenTexts.concat(feedbackTokenTexts);
  }

  allTokenTexts = allTokenTexts.concat(safeFilterTokens).filter(t => t?.length);

  const textWords = parseParts(tokenText, allTokenTexts);

  const mappedWords = textWords.map((text) => {
    const filterToken = safeFilterTokens.find(ft => ft === text);
    const similarityToken = similarityTokensFiltered.find(token => token.text === text && token.type === tokenType);
    const feedback = recommendationsFeedback({ feedbacks: safeRecommendationFeedbacks, tokenType, tokenTypes, text });

    return {
      text,
      filterToken,
      similarityToken,
      recommendationsFeedback: feedback,
    };
  });

  return mappedWords.filter(b => b.text !== "");
}
