<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import Pill from "@/components/recommendationFeedbacks/Pill/Pill";
import { useCreateRecommendationFeedback } from "@/composables/recommendationFeedbacks/useCreateRecommendationFeedback";
import { tokenText } from "../lib/tokenText";
import { tokenTooltip } from "../lib/tokenTooltip";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  token: {
    type: Object,
    default: () => {},
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
  isInvertedColor: {
    type: Boolean,
    default: false,
  },
  showPrefix: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "large",
  },
});

const route = useRoute();

const text = computed(() => tokenText({ showPrefix: props.showPrefix, route, ...props.token }));
const tooltip = computed(() => tokenTooltip(props.token));
const {
  createRecommendationFeedback,
} = useCreateRecommendationFeedback();

const onChange = (feedback) => {
  return createRecommendationFeedback({
    projectId: props.project.id,
    params: {
      feedback,
      tokenType: props.token.type,
      tokenText: props.token.text,
    },
  });
}
</script>

<template>
  <Pill
    :type="highlighted ? 'filter' : 'similarity'"
    :on-change="onChange"
    :tooltip="tooltip"
    :is-inverted-color="isInvertedColor"
    :size="size"
  >
    {{ text }}
  </Pill>
</template>
