<script setup>
import { computed, watch, ref } from "vue";
import { useGetters, useMutations } from "vuex-composition-helpers";

import UserAvatar from "@/components/UserAvatar";

import Loader from "./RequestIntroTabLoader";

const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
  selectedCampaign: {
    type: Object,
    default: null,
  },
});

const {
  candidateConnectors,
  isLoadingCandidateConnectors,
  requestIntroConnectorsId,
  isRequestIntroEnabled,
} = useGetters([
  "candidateConnectors",
  "isLoadingCandidateConnectors",
  "requestIntroConnectorsId",
  "isRequestIntroEnabled",
]);

const {
  setRequestIntroConnectorsId,
  setIsIntrosEnabledByUser,
} = useMutations([
  "setRequestIntroFromAllConnectors",
  "setRequestIntroConnectorsId",
  "setIsIntrosEnabledByUser",
]);

const requestIntroFromAllConnectors = ref(true);
const requestIntroFromAllConnectorsIndeterminate = ref(false);


const connectorsExist = computed(() => {
  return candidateConnectors.value.length;
});

const areManyConnectors = computed(() => {
  return candidateConnectors.value.length > 1;
});

const noConnectorsLabel = computed(() => {
  if (props.candidate.connectedToMe) {
    return "No one else from your team is connected to this candidate. You can reach out to them with an outbound campaign";
  }
  return "No one from your team is connected to this candidate. You can reach out to them with an outbound campaign";
});

const messageText = computed(() => {
  if (areManyConnectors.value) {
    const restConnectorsCount = candidateConnectors.value.length - 1;
    const pronoun = restConnectorsCount === 1 ? "other" : "others";
    return ` and ${restConnectorsCount} ${pronoun} in your company.`;
  } else {
    return " in your company.";
  }
});

const introEnabled = computed({
  get() {
    return isRequestIntroEnabled.value;
  },
  set(newValue) {
    setIsIntrosEnabledByUser(newValue);
  },
});

const currentConnectorIds = computed({
  get() {
    return requestIntroConnectorsId.value;
  },
  set(newValue) {
    setRequestIntroConnectorsId(newValue);
  },
});

const notSuccessfulIntroWarning = computed(() => {
  return currentConnectorIds.value.length > 1
    ? `If no introductions are successful, your selected campaign "${props.selectedCampaign.name}" will start automatically.`
    : `If an introduction is not successful, your selected campaign "${props.selectedCampaign.name}" will start automatically.`;
});

watch(candidateConnectors, (newValue) => {
  selectAllConnectors(newValue);
}, { immediate: true });

function selectedConnectorsListChangeHandler() {
  requestIntroFromAllConnectorsIndeterminate.value = currentConnectorIds.value.length > 0 && currentConnectorIds.value.length < candidateConnectors.value.length;
  requestIntroFromAllConnectors.value = currentConnectorIds.value.length === candidateConnectors.value.length;
}

function selectAllConnectorsChangeHandler(allSelected) {
  requestIntroFromAllConnectorsIndeterminate.value = false;

  if (allSelected) {
    selectAllConnectors();
  } else {
    currentConnectorIds.value = [];
  }
}

function selectAllConnectors() {
  currentConnectorIds.value = candidateConnectors.value.map(connector => connector.id);
}
</script>

<template>
  <div id="request-intro-tab">

    <!-- LOADING -->
    <loader v-if="isLoadingCandidateConnectors" />

    <!-- RESULTS -->
    <template v-else-if="connectorsExist">
      <div class="d-flex justify-content-between px-8">
        <div id="candidate-connectors-description">
          <UserAvatar
            :image-url="candidate.avatarUrl"
            :full-name="candidate.name"
            size="small"
            hide-name-label
          />

          <div class="ml-8">
            <strong>{{ candidate.name }}</strong> is connected to <strong>{{ candidateConnectors[0].name }}</strong>
            <br>
            {{ messageText }}
          </div>
        </div>

        <el-switch
          v-model="introEnabled"
          active-text="Enable intro request"
        />
      </div>

      <div class="d-flex justify-content-between mt-24 px-8">
        <el-checkbox
          v-model="requestIntroFromAllConnectors"
          class="select-all-checkbox uppercase"
          :indeterminate="requestIntroFromAllConnectorsIndeterminate"
          label="Select all"
          :disabled="!introEnabled"
          @change="selectAllConnectorsChangeHandler"
        />

        <span v-if="currentConnectorIds.length" class="connectors-selected uppercase">
        {{ currentConnectorIds.length }} of {{ candidateConnectors.length }} selected
      </span>
      </div>

      <el-divider class="mt-8 mb-0" />

      <el-checkbox-group
        v-model="currentConnectorIds"
        class="connector-list px-8 pt-8"
        :disabled="!introEnabled"
        @change="selectedConnectorsListChangeHandler"
      >
        <div v-for="(connector, index) in candidateConnectors" :key="connector.id">
          <el-checkbox :label="connector.id">
            <UserAvatar
              :image-url="connector.avatarUrl"
              :full-name="connector.name"
            />
          </el-checkbox>

          <el-divider v-if="index < candidateConnectors.length - 1" class="my-8" />
        </div>
      </el-checkbox-group>

      <el-alert
        v-if="currentConnectorIds.length && selectedCampaign"
        type="info"
        class="mt-16"
        :description="notSuccessfulIntroWarning"
        :closable="false"
        show-icon
      />
    </template>

    <div v-else>
      {{noConnectorsLabel}}
    </div>
  </div>
</template>

<style lang="scss">
#request-intro-tab {
  height: 100%;

  ::v-deep .select-all-checkbox {
    .el-checkbox__label {
      font-size: calc(#{$tf-font-size-default} - 2px);
      line-height: $tf-font-size-default;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    &:not(.is-checked) {
      .el-checkbox__label {
        color: $black-50;
      }
    }
  }

  .connectors-selected {
    font-size: calc(#{$tf-font-size-default} - 2px);
    line-height: $tf-font-size-default;
    font-weight: 400;
    color: $black-100;
    letter-spacing: 0.5px;
  }

  #candidate-connectors-description {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  // TODO: Make just the connector list scrollable
  // The tricky part is that the height is 100% so the scrollable area is bigger when there is no dedupe message
  .connector-list {
    max-height: 220px; // Number on purpose so that 4.5 connectors fit on the screen, the 0.5 is becuase it's going to indicate that it's scrollable
    overflow: auto;
  }
}
</style>
