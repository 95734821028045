import { mapGetters, mapActions, mapMutations } from "vuex";
import logEvent from "@/common/Analytics";
import Tags from "@/components/Tags";

export default {
  name: "CandidateTags",
  components: {
    Tags,
  },
  props: {
    candidate: {
      type: Object,
      default: null,
    },
    bulkSelectionAttributes: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bulkTags: [],
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentTeam",
      "candidateTagOptions",
    ]),
    tags() {
      return this.candidate?.tags || this.bulkTags;
    },
    tooltip() {
      if (this.isCandidateInAts) {
        return `Tags will be synced to ${this.currentUser.primaryTeamAtsName}.`;
      } else if (this.candidate && this.currentUser.primaryTeamAtsName) {
        return `This candidate isn't in ${this.currentUser.primaryTeamAtsName}. Tags will be synced when initial outreach is sent.`;
      } else if (!this.candidate && this.currentUser.primaryTeamAtsName) {
        return `Tags will be synced for candidates that are in your ${this.currentUser.primaryTeamAtsName}.`;
      }
    },
    isCandidateInAts() {
      if (!this.candidate || !this.candidate.urls) {
        return false;
      }

      return !!this.candidate.urls.find(link => link.ats === true);
    },
  },
  methods: {
    ...mapActions([
      "updateCandidateTags",
    ]),
    ...mapMutations([
      "addTagOptionToCandidateTagOptions",
    ]),
    clickedTag(tag) {
      this.$emit("clicked-tag", tag);
    },
    addTag(tag) {
      logEvent("candidate-action", { action: "add-tag" });
      if (this.candidate) {
        if (!this.candidate.tags.includes(tag)) {
          this.candidate.tags.push(tag);
        }
      } else {
        if (!this.bulkTags.includes(tag)) {
          this.bulkTags.push(tag);
        }
      }
      this.saveTags();
      this.updateTagOptionsWithTheNewTag(tag);
    },
    removeTag(tag) {
      if (this.candidate) {
        const idx = this.candidate.tags.indexOf(tag);
        this.candidate.tags.splice(idx, 1);
      } else {
        const idx = this.bulkTags.indexOf(tag);
        this.bulkTags.splice(idx, 1);
      }

      this.saveTags();
    },
    saveTags() {
      this.updateCandidateTags({
        candidate: this.candidate,
        bulkSelectionAttributes: this.bulkSelectionAttributes,
        tags: this.candidate?.tags || this.bulkTags,
      });

      if (this.bulkSelectionAttributes) {
        this.$notify({
          position: "bottom-right",
          message: "Tags saved",
          title: "Success",
          type: "success",
        });
      }
    },
    updateTagOptionsWithTheNewTag(tag) {
      this.addTagOptionToCandidateTagOptions(tag);
    },
  },
};
