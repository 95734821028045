import { mapGetters } from "vuex";
import UsersApi from "@/common/http/UsersApi";

export default {
  name: "TalentWall",
  props: {
    atsName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCopyLoading: false,
      isTalentwallEnabled: false,
      isRegeneratedKeyLoading: false,
      teamableApiKeyNewlyGenerated: null,
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserSettings",
    ]),
    talentWallAccountLink() {
      return this.atsName === "Greenhouse" ? "https://app.talentwall.io/account/edit" : "https://allegro.talentwall.io/";
    },
    teamableApiKey() {
      return this.teamableApiKeyNewlyGenerated || this.currentUserSettings.teamableApiKey;
    },
  },
  watch: {
    currentUserSettings: {
      immediate: true,
      deep: true,
      handler() {
        this.isTalentwallEnabled = this.currentUserSettings.talentwallEnabled;
      },
    },
  },
  methods: {
    toggleTalentwall() {
      this.isRegeneratedKeyLoading = true;
      UsersApi.toggleTalentwall({
        talentwallEnabled: this.isTalentwallEnabled,
      }).then(({ data }) => {
        this.isTalentwallEnabled = data.talentwallEnabled;
        this.teamableApiKeyNewlyGenerated = this.isTalentwallEnabled ? data.teamableApiKey : null;
        this.isRegeneratedKeyLoading = false;
      });
    },
    async copyTeamableApiKey() {
      this.isCopyLoading = true;
      await navigator.clipboard.writeText(this.teamableApiKeyNewlyGenerated);
      setTimeout(() => {
        this.isCopyLoading = false;
      }, 500);
    },
  },
};
