import axios from "axios";
import CookieService from "@/common/CookieService";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { handleApiError } from "@/common/http/ApiError";
import packageInfo from "@/../package.json";

const ADMIN_TOKEN_HEADER = "X-TF-Admin-Authorization";

class HTTPClient {
  static init() {
    this.$axios = axios.create({
      baseURL: this.baseURL,
      headers: this.headers,
    });

    this.$requestAbortSignal = {};

    // Axios middleware to convert all api responses to camelCase
    this.$axios.interceptors.response.use(response => {
      if (response.data && response.headers["content-type"].includes("application/json")) {
        response.data = camelcaseKeys(response.data, { deep: true });
      }

      return response;
    });

    // Axios middleware to convert all api requests to snake_case
    this.$axios.interceptors.request.use(config => {
      const newConfig = { ...config };
      if (!newConfig.headers["Content-Type"].includes("application/json")) {
        return newConfig;
      }

      if (config.params) {
        newConfig.params = snakecaseKeys(config.params, { deep: true });
      }

      if (config.data) {
        newConfig.data = snakecaseKeys(config.data, { deep: true });
      }

      return newConfig;
    });
  }

  static get fileHeaders() {
    let headers = this.headers;
    delete headers["Accept"];
    delete headers["Content-Type"];
    return headers;
  }

  static get headers() {
    const h = {
      Authorization: `Bearer ${CookieService.getUserToken()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-TF-Vue-Platform": "vue",
      "X-TF-Vue-Version": packageInfo.version,
    };

    let adminToken = CookieService.getAdminToken();
    if (adminToken) {
      h[ADMIN_TOKEN_HEADER] = adminToken;
    }

    return h;
  }

  static get(resource, params, { allowMultiRequests = false } = {}) {
    // Check if there are any previous pending requests
    let resourceForCancelToken = resource;

    if (!allowMultiRequests && this.$requestAbortSignal[resourceForCancelToken]) {
      this.$requestAbortSignal[resourceForCancelToken].abort(`${resourceForCancelToken} request canceled${params?.cancelRequest ? "." : " due to new request."}`);
    }

    // Save the cancel token for the current request
    this.$requestAbortSignal[resourceForCancelToken] = new AbortController();

    const config = { signal: this.$requestAbortSignal[resourceForCancelToken].signal };

    if (params) {
      config.params = params;
    }

    // we cancelRequest pass this param in order to cancel prev request w/o making new one
    // w/o this key it will also be canceled but will make new (same) request
    if (params?.cancelRequest) {
      this.$requestAbortSignal[resourceForCancelToken].abort();
      return { data: null, status: "canceled" };
    }

    return this.$axios.get(resource, config).catch((error) => {
      handleApiError({ ...error, reason: config.signal.reason });
    });
  }

  static post(resource, ...args) {
    return this.$axios.post(resource, ...args).catch(handleApiError);
  }

  static put(resource, ...args) {
    return this.$axios.put(resource, ...args).catch(handleApiError);
  }

  static patch(resource, ...args) {
    return this.$axios.patch(resource, ...args).catch(handleApiError);
  }

  static delete(resource, ...args) {
    return this.$axios.delete(resource, ...args).catch(handleApiError);
  }

  static syncPost(resource, params) {
    this.syncRequest("POST", resource, params);
  }

  static syncRequest(method, resource, params) {
    let request = new XMLHttpRequest();
    // False makes the request synchronous
    request.open(method, `${this.baseURL}/${resource}`, false);

    request.setRequestHeader(
      ADMIN_TOKEN_HEADER,
      this.headers[ADMIN_TOKEN_HEADER]
    );
    request.setRequestHeader("Authorization", this.headers["Authorization"]);
    request.setRequestHeader("Accept", this.headers["Accept"]);
    request.setRequestHeader("Content-Type", "application/json;charset=utf-8");
    request.send(JSON.stringify(params));

    // Sync requests use case doesn't imply a return value, these are last resort
    // calls before tab is closed. Sync requests should not be used in any other
    // circumstances as they block the thread and JS has only one thread.
    return null;
  }
}

export default HTTPClient;
