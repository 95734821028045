<script setup>
import { ref, computed, nextTick, onMounted } from "vue";
import { useGetters } from "vuex-composition-helpers";

import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import VueDocPreview from "vue-doc-preview";

const pdfCanvas = ref(null);

const {
  currentCandidate,
} = useGetters([
  "currentCandidate",
]);

const resumeFileType = computed(() => {
  return currentCandidate.value.resumeFileUrl.split(".").pop().toLowerCase();
});
const isResumeTypePdf = computed(() => {
  return resumeFileType.value === "pdf";
});
const isResumeTypeOffice = computed(() => {
  return ["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(resumeFileType.value);
});
const isResumeTypeTxt = computed(() => {
  return resumeFileType.value === "txt";
});
const isResumeTypeHtml = computed(() => {
  return ["html", "htm"].includes(resumeFileType.value);
});
const isResumeTypeImage = computed(() => {
  return ["png", "jpg", "jpeg"].includes(resumeFileType.value);
});
const isResumeTypeMd = computed(() => {
  return ["md"].includes(resumeFileType.value);
});

onMounted(async () => {
  if (isResumeTypePdf.value) {
    if (!GlobalWorkerOptions?.workerSrc) {
      GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }

    const pdfDocument = await getDocument(currentCandidate.value.resumeFileUrl).promise;
    const pages = [];
    let resumeWidth = 0;
    let resumeHeight = 0;
    let yOffset = 0;

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2, offsetX: 0, offsetY: 0 }); // Scale 2 for a sharper image, otherwise 1 is fine

      resumeWidth = Math.max(resumeWidth, viewport.width);
      resumeHeight += viewport.height;
      pages.push({ page, viewport, yOffset });
      yOffset += viewport.height; // Prepare offset for next page
    }

    await nextTick(); // Wait for the next tick so that the canvas is ready

    const canvas = pdfCanvas.value;
    const context = canvas.getContext("2d");
    canvas.width = resumeWidth;
    canvas.height = resumeHeight;

    pages.forEach(async ({ page, viewport, yOffset }) => {

      // Create an off-screen canvas for each page
      const offScreenCanvas = document.createElement("canvas");
      const offScreenContext = offScreenCanvas.getContext("2d");
      offScreenCanvas.width = viewport.width;
      offScreenCanvas.height = viewport.height;

      // Render page to off-screen canvas
      const renderContext = {
        canvasContext: offScreenContext,
        viewport,
      };
      await page.render(renderContext).promise;

      // Draw off-screen canvas to the main canvas
      context.drawImage(offScreenCanvas, 0, yOffset);
    });
  }
});
</script>

<template>
  <!-- If the Resume view is getting loaded first because it was cached as the last option then currentCandidate.resumeFileUrl is undefined until currentCandidate gets set -->
  <div v-if="currentCandidate.resumeFileUrl" class="resume-container">
    <canvas v-if="isResumeTypePdf" ref="pdfCanvas" class="w-100"></canvas>
    <VueDocPreview v-else-if="isResumeTypeTxt" :url="currentCandidate.resumeFileUrl" type="text" />
    <VueDocPreview v-else-if="isResumeTypeMd" :url="currentCandidate.resumeFileUrl" type="markdown" />
    <iframe v-else-if="isResumeTypeOffice" class="w-100 h-100" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${currentCandidate.resumeFileUrl}`" frameborder="0"></iframe>
    <div v-else-if="isResumeTypeHtml" class="w-100" :v-html="currentCandidate.resumeFileUrl"></div>
    <img v-else-if="isResumeTypeImage" class="w-100" :src="currentCandidate.resumeFileUrl">
    <div v-else class="d-flex flex-column justify-content-center align-items-center h-100">
      <img :src="require('@/assets/images/illustrations/error.svg')">
      <div class="mt-32 mb-16">Preview for <b class="uppercase">{{ resumeFileType }}</b> is not available; you can download it instead</div>
      <a class="w-25" :href="currentCandidate.resumeFileUrl" target="_blank">
        <el-button class="w-100" type="primary">
          Download
        </el-button>
      </a>
    </div>
  </div>
  <div v-else></div>
</template>

<style lang="scss" scoped>
.resume-container {
  width: 100%;
  height: calc(100vh - 238px); // Subtracted sidebar margin, padding, status funnel, tabs and resume view options
  overflow-y: auto;
}
</style>
