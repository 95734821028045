import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "UserSendAsPermissionModal",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "inviteeName",
      "inviteeReference",
      "showSendAsPermissionOptIn",
    ]),
    options() {
      // Uses default_allow_send_as on the backend
      const options = [
        { value: true, label: "Allow my team to send as me, including future teammates" },
        { value: false, label: "Do not allow my team to send as me, including future teammates" },
      ];

      if (this.inviteeName && this.inviteeReference) {
        const inviterOption = {
          value: `inviter_${this.inviteeReference}`,
          label: `Only allow ${this.inviteeName}`,
        };
        options.splice(1, 0, inviterOption);
      }

      return options;
    },
  },
  methods: {
    ...mapMutations([
      "setSeenSendAsPermissionModal",
    ]),
    ...mapActions([
      "updateCurrentUser",
    ]),
    opened() {
      this.setSeenSendAsPermissionModal();
    },
    submit() {
      this.updateCurrentUser().then(() => {
        this.$notify({
          position: "bottom-right",
          message: "Preference saved!",
          title: "Saved",
          type: "success",
        });
      });
      this.$router.replace({ name: "dashboard" });
    },
  },
  mounted() {
    if (!this.showSendAsPermissionOptIn) {
      this.$router.replace({ name: "dashboard" });
    }
    this.currentUser.defaultAllowSendAs = true;
  },
};
