<script setup>
import { ref } from "vue";
import { useGetters } from "vuex-composition-helpers";
import CandidateRemindModal from "./CandidateRemindModal/Component";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: "small",
  },
  label: {
    type: String,
    default: "Remind me...",
  },
  plain: {
    type: Boolean,
    default: false,
  },
  onlyIcon: {
    type: Boolean,
    default: false,
  },
  dropdownItem: {
    type: Boolean,
    default: false,
  },
});

const isModalVisible = ref(false);

const {
  bulkSelectionAttributes,
} = useGetters([
  "bulkSelectionAttributes",
]);
</script>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="d-flex">
      <el-skeleton-item class="w-100" variant="button" />
    </div>

    <div v-if="dropdownItem">
      <el-dropdown-item>
        <div class="d-flex align-items-center" @click="isModalVisible = true">
          <span class="material-icons-round md-18 mr-8">calendar_today</span>
          <span>{{label}}</span>
        </div>
      </el-dropdown-item>
    </div>

    <el-tooltip
      v-else
      :content="label"
      :disabled="!onlyIcon"
    >
      <el-button
        class="w-100 m-0"
        type="secondary"
        :size="size"
        :plain="plain"
        @click="isModalVisible = true"
      >
        {{ label }}
      </el-button>
    </el-tooltip>

    <CandidateRemindModal
      :visible.sync="isModalVisible"
      :candidate="candidate"
      :bulk-selection-attributes="bulkSelectionAttributes"
    />
  </el-skeleton>
</template>
