<template>
  <el-dialog
    id="candidate-tag-modal"
    :visible.sync="innerVisible"
    :title="title"
    append-to-body
    @close="innerVisible = false"
  >
    <CandidateTags
      :candidate="candidate"
      :bulk-selection-attributes="bulkSelectionAttributes"
    />
  </el-dialog>
</template>

<script>
import logEvent from "@/common/Analytics";
import CandidateTags from "@/components/CandidateTags/Component";

export default {
  name: "CandidateTagModal",
  components: {
    CandidateTags,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    bulkSelectionAttributes: {
      type: Object,
      default: null,
    },
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit("update:visible", isVisible);
      },
    },
    title() {
      if (this.candidate) {
        return `Tags for ${this.candidate.name}`;
      } else if (this.bulkSelectionAttributes) {
        const candidatesText = this.bulkSelectionAttributes.count === 1 ? "candidate" : "candidates";
        return `Add tags to ${this.bulkSelectionAttributes.count} ${candidatesText}`;
      }

      return null;
    },
  },
  methods: {
    onModalOpened() {
      logEvent("candidate-action-intent", { action: "add-tag" });

      if (!this.candidate && !this.bulkSelectionAttributes) {
        this.innerVisible = false;
      }
    },
  },
};
</script>
