import ApiClient from "@/common/http/ApiClient";

class CampaignsApi {

  static indexForSourcing() {
    const params = { purpose: "sourcing", source: "app" };
    return ApiClient.get("campaigns", params);
  }

  static search(params = {}) {
    return ApiClient.get("campaigns/search", params);
  }

  static new(params = {}) {
    return ApiClient.get("campaigns/new", params);
  }

  static personalizeNew(params = {}) {
    return ApiClient.get("campaigns/personalize_new", params);
  }

  static create(params = {}) {
    return ApiClient.post("campaigns", params);
  }

  static templateGenerationStatus(id) {
    return ApiClient.get(`campaigns/${id}/template_generation_status`);
  }

  static generateFirstMessage(id, params = {}) {
    return ApiClient.get(`campaigns/${id}/generate_first_message`, params);
  }

  static personalizeFirstMessage(params = {}) {
    return ApiClient.get("campaigns/personalize_first_message", params);
  }

  static stopGeneration(id) {
    return ApiClient.put(`campaigns/${id}/stop_generation`);
  }

  static stats(id) {
    return ApiClient.get(`campaigns/${id}/stats`);
  }

  static show(id, params = {}) {
    return ApiClient.get(`campaigns/${id}`, params);
  }

  static close(id, params = {}) {
    return ApiClient.delete(`campaigns/${id}`, { params });
  }

  static open(id, params = {}) {
    return ApiClient.put(`campaigns/${id}/open`, params);
  }

  static copy(id) {
    return ApiClient.post(`campaigns/${id}/duplicate`);
  }

  static move(id, params = {}) {
    return ApiClient.put(`campaigns/${id}/move`, params);
  }

  static update(id, params = {}) {
    return ApiClient.put(`campaigns/${id}`, params);
  }

  static regenerateAllMessages(id, params = {}) {
    return ApiClient.put(`campaigns/${id}/regenerate_all_messages`, params);
  }

  static updateReferralSettings(referralSettingId, params = {}) {
    return ApiClient.put(`campaign_referral_settings/${referralSettingId}`, params);
  }

  static updateAutoReply(id, params = {}) {
    return ApiClient.put(`campaign_auto_replies/${id}`, params);
  }

  static createFollowUp(params = {}) {
    return ApiClient.post("campaign_follow_ups", params);
  }

  static generateFollowUp(params = {}) {
    return ApiClient.get("campaign_follow_ups/generate", params);
  }

  static personalizeFollowUp(params = {}) {
    return ApiClient.get("campaign_follow_ups/personalize", params);
  }

  static updateFollowUp(id, params = {}) {
    return ApiClient.put(`campaign_follow_ups/${id}`, params);
  }

  static deleteFollowUp(id, params = {}) {
    return ApiClient.put(`campaign_follow_ups/${id}/remove`, params);
  }

  static previewMessage(params = {}) {
    return ApiClient.post(`campaigns/preview`, params);
  }
}

export default CampaignsApi;
