<template>
  <el-tooltip
    :content="description"
    placement="top"
    :open-delay="500"
  >
    <div
      v-if="isChatbotToken"
      class="d-flex"
      :draggable="isChatbotVariableDraggable"
      @dragstart="onDragStart"
    >
      <el-dropdown
        split-button
        class="chatbot-menu"
        @click="insertChatbotAtLastEditorPosition"
        @command="handleChatbotCommand"
      >
        <span v-if="selectedPlaybookName">
          {{ selectedPlaybookName }}
        </span>
        <span v-else>
          <span class="new-badge">NEW </span>Chatbot
        </span>

       <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="playbook in currentUser.playbookOptions"
            :key="playbook.id"
            :command="playbook.id"
          >
            {{ playbook.name }}
          </el-dropdown-item>
       </el-dropdown-menu>
     </el-dropdown>
    </div>

    <div
      v-else
      class="toolbar-token token"
      type="button"
      draggable
      @click="insertAtLastEditorPosition"
      @dragstart="onDragStart"
    >
      {{ name }}
    </div>
  </el-tooltip>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DEFAULT_TEMPLATE_CAMPAIGN_ID } from "@/constants";
import insertToken from "@/common/quill/insertToken";

export default {
  name: "TokenVariable",
  props: {
    name: {
      type: String,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    quillInsertRefs: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      lastEditorWithFocus: null,
      showInsertPopover: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "inAppSourcingSelectedCampaign",
      "currentCampaign",
      "currentUser",
      "currentUserSettings",
      "newCampaign",
    ]),
    isChatbotVariableDraggable() {
      return !!this.playbookId;
    },
    isChatbotToken() {
      return this.slug === "chatbot_link";
    },
    tokenData() {
      return {
        title: this.name,
        name: this.name,
        slug: this.slug,
      };
    },
    tokenClass() {
      return `${this.slug.replace(/_/g, "-")} ${this.disabled ? "disabled" : ""}`;
    },
    selectedPlaybookName() {
      return this.currentUser.playbookOptions?.find(o => o.id === this.playbookId)?.name;
    },
    activeCampaign() {
      return [this.currentCampaign, this.inAppSourcingSelectedCampaign].find(c => c?.id) || this.newCampaign;
    },
    isTemplateSettings() {
      return this.activeCampaign?.id && this.activeCampaign.id === DEFAULT_TEMPLATE_CAMPAIGN_ID && !!this.currentUserSettings;
    },
    playbookId: {
      get() {
        if (this.isTemplateSettings) {
          return this.currentUserSettings?.inboundAcceptPlaybookId;
        }
        return this.activeCampaign?.playbookId;
      },
      set(newValue) {
        if (this.isTemplateSettings) {
          this.currentUserSettings.inboundAcceptPlaybookId = newValue;
          this.$bus.$emit("update-settings");
        } else {
          if (this.activeCampaign) {
            this.activeCampaign.playbookId = newValue;
          }

          if (this.currentCampaign?.id) {
            this.updateCurrentCampaign();
          }
        }

      },
    },
  },
  methods: {
    ...mapActions([
      "updateCurrentCampaign",
    ]),
    onDragStart(event) {
      if (this.disabled) return;

      const text = `{{${this.slug}}}`;
      const json = JSON.stringify({
        title: this.name,
        name: this.name,
        slug: this.slug,
      });
      event.dataTransfer.setData("text/plain", text);
      event.dataTransfer.setData("application/vnd.placeholder.token", json);
    },
    insertChatbotAtLastEditorPosition() {
      if (!this.playbookId) return;

      this.insertAtLastEditorPosition();
    },
    insertAtLastEditorPosition() {
      if (this.disabled) return;

      const quill = this.lastEditorWithFocus || this.quillInsertRefs.body;
      const subjectHadFocus = quill === this.quillInsertRefs.subject;
      if (
        subjectHadFocus &&
        ["calendar", "sentence", "signature", "chatbot_link"].includes(this.slug)
      ) {
        // TODO: Move this to some inline error, maybe use alert component to show next to the element
        this.$message({
          message: `${this.name} is not allowed in the subject`,
          type: "warning",
        });
        return;
      }
      insertToken(quill, this.tokenData);
    },
    assignLastEditorFocus({ quill }) {
      this.lastEditorWithFocus = quill;
    },
    handleChatbotCommand(command) {
      this.playbookId = command;
    },
  },
  mounted() {
    this.$bus.$on("editor-has-focus", this.assignLastEditorFocus);
  },
  beforeDestroy() {
    this.$bus.$off("editor-has-focus", this.assignLastEditorFocus);
  },
};
</script>

<style lang="scss">
.chatbot-menu {
  height: 18px;
  white-space: nowrap;

  .new-badge {
    color: $blue-100;
    line-height: 1.0;
    font-weight: bold;
    font-size: 10px;
  }
  .el-button-group {
    margin-top: -1px;
  }
  .el-button {
    height: 18px;
    background-color: $blue-10;
    color: #2B3439;
    white-space: nowrap;
    font-size: 12px;
    padding: 0 8px;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: -1px;

  }
}
.token {
  height: 18px;
  background-color: $blue-10;
  color: #2B3439;
  border-radius: $tf-global-border-radius;
  display: inline-block;
  padding: 0 8px;
  line-height: 1.5;
  white-space: nowrap;
  font-size: 12px;
  user-select: none;
  margin-left: 1px;
  margin-right: 1px;
  cursor: grab;

  &.selected, &:hover {
    background-color: $blue-100;
    color: white;
  }
}

/*
Required for clicked token to be recognized by Parchment.
If a child element receives the click, Parchment is unable to determine which blot was clicked.
*/
.token * {
  pointer-events: none;
}
</style>
