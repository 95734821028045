<script setup>
import { computed, ref, onMounted } from "vue";
import { useActions, useGetters } from "vuex-composition-helpers";
import { useRoute, useRouter } from "vue-router/composables";

import OutreachLimitMessage from "@/components/OutreachLimitMessage";

import JobAndCampaignSelector from "./JobAndCampaignSelector";

const emit = defineEmits(["change", "loaded", "loading"]);

defineExpose({
  clearSelection,
});

const props = defineProps({
  defaultProjectId: {
    type: Number,
    default: null,
  },
  defaultProjectCampaignId: {
    type: Number,
    default: null,
  },
  projectSelectionDisabled: {
    type: Boolean,
    default: false,
  },
  disabledProjectIds:  {
    type: Array,
    default: () => [],
  },
  showCampaigns: {
    type: Boolean,
    default: true,
  },
  hideCreateNewJob: {
    type: Boolean,
    default: false,
  },
  showCampaignsOnlyForDefaultProject: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Choose a job or campaign",
  },
  cacheEnabled: {
    type: Boolean,
    default: true,
  },
  mineOnly: {
    type: Boolean,
    default: true,
  },
});

const {
  currentUser,
  isSourceableProjectListFirstTimeLoading,
  isSourceableCampaignListFirstTimeLoading,
  sourceableCampaignList,
  sourceableProjectList,
  wasSourceableCampaignListFetched,
  wasSourceableProjectListFetched,
} = useGetters([
  "currentUser",
  "isSourceableProjectListFirstTimeLoading",
  "isSourceableCampaignListFirstTimeLoading",
  "sourceableCampaignList",
  "sourceableProjectList",
  "wasSourceableCampaignListFetched",
  "wasSourceableProjectListFetched",
]);

const {
  fetchSourceableProjectsList,
  fetchSourceableCampaignList,
  refreshCurrentUser,
} = useActions([
  "fetchSourceableProjectsList",
  "fetchSourceableCampaignList",
  "refreshCurrentUser",
]);

const route = useRoute();
const router = useRouter();
const isLoading = ref(false);
const jobAndCampaignSelector = ref(null);

const projectCampaignsList = computed(() => {
  return {
    projects: sourceableProjectList.value,
    campaigns: sourceableCampaignList.value,
  };
})

const computedMineOnly = computed(() => {
  if (props.projectSelectionDisabled) {
    return false;
  }
  return props.mineOnly;
})

async function init() {
  refreshCurrentUser();

  // If it takes a long time to load and this component gets used better to not interrupt the initial load
  if (isSourceableProjectListFirstTimeLoading.value || isSourceableCampaignListFirstTimeLoading.value) return;

  if (!wasSourceableCampaignListFetched.value || !wasSourceableProjectListFetched.value) {
    await refresh();
  } else {
    refresh();
  }
}

async function refresh() {
  if (isLoading.value) return;

  isLoading.value = true;
  emit("loading");

  await Promise.all([fetchSourceableProjectsList(), fetchSourceableCampaignList()]);
  isLoading.value = false;
  emit("loaded");
}

function projectFromCurrentSelection(projectOrCampaign) {
  if (projectOrCampaign.campaigns) { // Only jobs have the key "campaigns"
    return projectOrCampaign;

  } else if (props.defaultProjectId) {
    return sourceableProjectList.value.find(item => item.id === props.defaultProjectId);

  } else if (projectOrCampaign.projectId) { // Find job based on the campaign.projectId
    return sourceableProjectList.value.find(item => item.id === projectOrCampaign.projectId);
  }

  return null;
}

function campaignFromCurrentSelection(projectOrCampaign) {
  if (projectOrCampaign.projectId || projectOrCampaign.id === "save") {
    return projectOrCampaign;
  }

  return null;
}

function projectCampaignSelectorComponentChangeHandler(selectedProjectCampaign) {
  if (selectedProjectCampaign) {
    localStorage.setItem("project-campaign-selection", JSON.stringify(selectedProjectCampaign));
    emit("change", {
      project: projectFromCurrentSelection(selectedProjectCampaign),
      campaign: campaignFromCurrentSelection(selectedProjectCampaign),
    })

  } else {
    localStorage.removeItem("previousSelectedProjectCampaign");
    localStorage.removeItem("project-campaign-selection");
    emit("change", { campaign: null, project: null });
  }
}

function showNewProjectModal() {
  const query = Object.assign({}, route.query);
  query.parentModal = query.modal;
  query.modal = "new-project";

  router.push({ query });
}

function clearSelection() {
  jobAndCampaignSelector.value.clearSelection();
};

onMounted(() => {
  init();
});

</script>

<template>
  <div id="project-and-campaign-selector">
    <OutreachLimitMessage v-if="currentUser.outreachLimitMessage" />

    <div v-if="!projectSelectionDisabled" class="d-flex justify-content-end">
      <el-button v-if="!hideCreateNewJob" class="create-new-project" type="text" @click="showNewProjectModal">
        <span class="material-icons-round">add</span>Create a new job
      </el-button>
    </div>

    <div class="d-flex align-items-center">
      <JobAndCampaignSelector
        ref="jobAndCampaignSelector"
        :projects-campaigns-data="projectCampaignsList"
        :loading="!wasSourceableCampaignListFetched || !wasSourceableProjectListFetched"
        :show-campaigns="showCampaigns"
        :placeholder="placeholder"
        :show-campaigns-only-for-default-project="showCampaignsOnlyForDefaultProject"
        :default-project-id="defaultProjectId"
        :default-project-campaign-id="defaultProjectCampaignId"
        :disabled-project-ids="disabledProjectIds"
        :project-selection-disabled="projectSelectionDisabled"
        :cache-enabled="cacheEnabled"
        :mine-only="computedMineOnly"
        @change="projectCampaignSelectorComponentChangeHandler"
      >
        <slot></slot>
      </JobAndCampaignSelector>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#project-and-campaign-selector {
  ::v-deep .el-input__prefix {
    margin-left: 4px;
  }

  .create-new-project {
    text-align: right;
    margin-bottom: -8px;
    margin-right: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}

</style>
